import { FormConstructor } from "src/common/commonViews.index";
import MobileViewSelect from "src/common/Mobile/MobileViewSelect";
import { nanoid } from "nanoid";
import styles from "../Contacts.module.scss";

export default function AddEditContact({
  selectedRecord,
  selectedView,
  logicMap,
  handleFunctions,
  handleResetView,
  recordTitle,
}) {
  const selectedViewFormFields = logicMap[selectedView].fields;
  const handleFunction = handleFunctions[selectedView];
  // If selected modal is edit customer then display labels above
  function inputGroupSwitch(fieldName) {
    if (selectedView === "edit-customer" || selectedView === "add-customer") {
      const label = logicMap[selectedView].fields[fieldName].formalName;
      return (
        <FormConstructor.FormGroup label={label}>
          {selectedViewFormFields[fieldName].fieldComponent({
            ...logicMap[selectedView].fields[fieldName],
          })}
        </FormConstructor.FormGroup>
      );
    }

    return <FormConstructor.TextInput className={"mb-3"} {...selectedViewFormFields[fieldName]} />;
  }

  return (
    <MobileViewSelect.SelectedRecordView
      newContact={!selectedRecord}
      handleResetView={handleResetView}
      recordTitle={recordTitle}
      selectedRecord={selectedRecord}
      viewButtons={[<MobileViewSelect.SaveButton key={nanoid()} form_id="contacts_form" />]}
    >
      <FormConstructor
        id="contacts_form"
        className={`${styles.add_contact_form} w-100 px-2 mb-4`}
        onSubmit={(formInput) => {
          handleFunction(formInput);
          handleResetView();
        }}
        initialFormValues={selectedRecord ? { ...selectedRecord } : logicMap[selectedView].initialFormValues}
      >
        <div className={`${styles.add_contact_form_inner_wrapper} mx-auto pe-4 `}>
          <div className="d-flex m-2">
            <div className="d-flex flex-column flex-grow-1">
              {inputGroupSwitch("first_name")}
              {inputGroupSwitch("last_name")}
              {inputGroupSwitch("phone_number")}
              {inputGroupSwitch("consent_bypass")}
            </div>
          </div>
        </div>
      </FormConstructor>
    </MobileViewSelect.SelectedRecordView>
  );
}
