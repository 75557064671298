import { sortAlphabetically } from "src/helpers/formattingHelpers";
import MobileViewSelect from "src/common/Mobile/MobileViewSelect";
import { ContactTile } from "src/common/commonViews.index";

export default function ContactsMain(props) {
  sortAlphabetically(props.data, "first_name");

  return (
    <>
      <MobileViewSelect.MobileSearchBar
        {...props}
        view={props.contactsList}
        setView={() => {
          if (props.contactsList === "customersList") {
            return props.setContactsList("usersInCode");
          } else {
            return props.setContactsList("customersList");
          }
        }}
      />
      <MobileViewSelect.GroupByLetterContainer
        data={props.data}
        groupKey={"first_name"}
      >
        {(contact) => (
          <ContactTile
            name={`${contact.first_name} ${contact.last_name}`}
            onClick={() => props.handleSelectRecord(contact)}
            acknowledged={contact.acknowledged}
          />
        )}
      </MobileViewSelect.GroupByLetterContainer>
      <MobileViewSelect.NewButton
        onClick={() => props.setSelectedView("add-customer")}
      />
    </>
  );
}
