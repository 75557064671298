// Libraries
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import oneSP from "src/assets/img/1sp.png";
// Styles
import styles from "./Login.module.scss";
import { useAuth } from "src/contexts";
import { Alerts } from "src/common/commonViews.index";
import LoginForm from "./LoginForm";
import LoginAuthActions from "./LoginAuthActions";
import authFormLogic from "./LoginForm.logic";
import { default as pkg } from "../../../../package.json";
import { Alert } from "react-bootstrap";


export default function Login() {
  const { VITE_MAINTENANCE_OVERRIDE } = import.meta.env
  const [alertResponse, setAlertResponse] = useState(null);
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Variables and Render Effects
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const { pathname } = useLocation();
  const { handleSignIn } = useAuth();

  // Used to fix mobile view height from moving around. Viewport height will change when address bar is visible, making the page overflow. This keeps
  // the height consistent whether address bar is visible or not.
  const rootElem = document.getElementById("app");

  // Render root element as a static element when the login component is displayed, and remove static positioning
  // on component unload.
  useEffect(() => {
    rootElem.style.position = "static";
    return () => (rootElem.style.position = "");
  });

  //Reset response when url path changes
  useEffect(() => {
    setAlertResponse(null);
  }, [pathname]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Layout methods
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // Return proper form based on url
  function formChoice() {
    if(VITE_MAINTENANCE_OVERRIDE) {
      const now = new Date();
      const endTime = new Date(VITE_MAINTENANCE_OVERRIDE);
      if(now < endTime) {
        const variant = `warning`;
        return (
          <Alert key={variant} variant={variant}>
            1SP Chat will be down for maintenance until {getDynamicMaintenanceTime(VITE_MAINTENANCE_OVERRIDE)}. Please refresh the page after then.
          </Alert>
        );
      }
    }
    
    if (pathname === "/auth/action") {
      return (
        <LoginAuthActions
          authFormLogic={authFormLogic}
          setAlertResponse={setAlertResponse}
        />
      );
    }

    return (
      <LoginForm
        authFormToMap={authFormLogic.login}
        handleFormSubmit={async (formInput) => {
          setAlertResponse(null);
          try {
            await handleSignIn(formInput);
          } catch (err) {
            setAlertResponse(err);
          }
        }}
      />
    );
  }

  function getDynamicMaintenanceTime(maintenanceTimestamp) {
    const localTime = new Date(maintenanceTimestamp);
    return localTime.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone});
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Return
  /////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div
      className={`h-100 w-100 d-flex justify-content-center align-items-center`}
    >
      <div className={`${styles.login_container_width} `}>
        <div className="d-flex flex-column justify-content-center text-center">
          <div className={`${styles.login_container_width}`}>
            <div className={`pb-2`}>
              <img src={oneSP} alt="1 Simple Phone" className="img-fluid" />
            </div>
            <h1 className="my-4">Welcome to 1SimplePhone</h1>
          </div>
          {/* Display any errors or responses from firebase */}
          <Alerts.ResponseAlert response={alertResponse} />
          {formChoice()}
          <small className="text-center pt-3">
            <strong>Copyright</strong> 1SimplePhone &copy; 2023 
            <br />
            Version { pkg.version }
          </small>
        </div>
      </div>
    </div>
  );
}
