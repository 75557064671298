import { editOrAddRecord } from "src/helpers/recordHelpers";
import { useApplicationStates } from "src/contexts";

export default function useCreateRoom() {
  const { sharedAppStates,socket } = useApplicationStates();
  const { setFullRoomsList } = sharedAppStates;

  function createCustomerRoom(
    { 
      selected_customers, 
      selected_users = [], 
      selected_did 
    },
    handleFunction
  ) {
    socket.emit(
      "requestCreateCustomerRoom",
      { 
        selected_customers, 
        selected_users, 
        selected_did 
      },
      newRoom => {
        setFullRoomsList((prev) => [...prev, newRoom]);
        return handleFunction
          ? handleFunction(newRoom)
          : "No function defined for handling new room";
      }
    );
  }

  // Use User specific controller to check for existing user and either return that or create one
  function createUserRoom(user_ids, handleFunction) {
    socket.emit("requestNewUserRoom", user_ids, (newRoom) => {
      setFullRoomsList((prev) => editOrAddRecord(newRoom, "room_id", prev));

      return handleFunction
        ? handleFunction(newRoom)
        : "No function defined for handling new room";
    });
  }

  return {
    createCustomerRoom,
    createUserRoom,
  };
}
