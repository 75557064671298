import { firebaseMessaging } from "./index";
import { getToken, deleteToken, onMessage } from "firebase/messaging";
import { notificationConstructor } from "src/helpers/notifications.helpers";
import { z } from "zod";
/**
 * Generates an FCM token for a user with a service worker associated to it.
 * @return A promise that resolves with a FCM registration token.
 */
export async function getFCMToken(swRegistration: ServiceWorkerRegistration) {
  const messaging = await firebaseMessaging;
  if (!messaging) return console.error("Messaging is not supported on this device");
  if(!swRegistration) return console.error("No Service Worker Registration Supplied");
  try {
    /* if (import.meta.env.MODE === "development") {
      console.warn("FCM Token is not retrieved in dev environment as service worker does not run");
    } */

    if (Notification.permission !== "granted") return console.error(`No notification permissions!`);
    const fcmToken = await getToken(messaging, {
      vapidKey: import.meta.env.VITE_FCM_VAPID_KEY,
      serviceWorkerRegistration: swRegistration ?? undefined, // Convert null to undefined to stay in spec
    });
    console.log(`fcmToken:`, fcmToken)
    return fcmToken;
  } catch (err) {
    const inDev = import.meta.env.MODE === "development";
    const errorString = "🛑 An error occurred while retrieving token.";
    inDev ? console.warn(errorString, err) : console.error(errorString, err);
  }
}

export async function deleteFCMToken() {
  const messaging = await firebaseMessaging;
  return messaging && deleteToken(messaging);
}

export async function onMessageListener(selectedMessageViewRoomID) {
  const messaging = await firebaseMessaging;

  if (!messaging) return false;

  function validatePayload(notificationPayload) {
    const FCM_Notification_Schema = z.object({
      title: z.string(),
      body: z.string(),
      link: z.string(),
      room_id: z.string(),
      icon: z.string(),
      badge: z.string(),
      fcmMessageId: z.string()
    });

    return FCM_Notification_Schema.safeParse(notificationPayload);
  }
  
  return onMessage(messaging, async (payload) => {
    try {
      // Payload Null Guard
      if(!payload.data) return;
      // Zod validation of the payload
      const notificationPayload = validatePayload(payload.data);
      // Failure guard
      if(!notificationPayload.success) return console.error("Notification Payload Failure");
      // Destructure values
      const { room_id, title } = notificationPayload.data;
      // Generate notification options object
      const notificationOptions = notificationConstructor(notificationPayload.data);
      // Send notification if the tab is active and the room is NOT currently in view
      const tabIsActive = !document.hidden;
      if (tabIsActive && selectedMessageViewRoomID?.toString() !== room_id) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.showNotification(title, notificationOptions);
        });
      }
    } 
    catch (err) {
      console.error(err);
    }
  });
}
