import { Form, Table } from "react-bootstrap";

export default function SelectionTable({
  list,
  headers,
  radio,
  onChange,
  checked = () => null,
  children,
}) {
  
  return (
    <Table
      /*      className={styles.DIDAssign_table} */
      style={{
        gridTemplateColumns:
          `minmax(auto, 100px)` +
          `minmax(auto, auto)`.repeat(headers.length - 1),
      }}
      striped
      bordered
      hover
    >
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {list.map((item, index) => {
          return (
            <tr key={index} onClick={() => onChange(item)}>
              <td>
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    className="d-flex justify-content-center"
                    type={radio ? "radio" : "checkbox"}
                    onChange={() => onChange(item)}
                    checked={checked(item) || list.length === 1}
                    name={"selection_table_group"}
                    required
                  />
                </Form.Group>
              </td>
              {children(item)}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
