// Libraries
import { Row, Col } from "react-bootstrap";
//Hooks
import useWindowDimensions, {
  BSBreakpoints,
} from "../../hooks/useWindowDimensions";
//Styles
import styles from "./StatsTiles.module.scss";

export default function StatsTiles({ tiles, dateRange }) {
  const { width } = useWindowDimensions();

  function getNumberOfDays() {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

    // Convert the date from dashes to slashes (otherwise javascript will create a date 1 day behind)
    const firstDate = dateRange.start
      ? new Date(dateRange.start.replace(/-/g, "/"))
      : new Date();

    const secondDate = dateRange.end
      ? new Date(dateRange.end.replace(/-/g, "/"))
      : new Date();

    // Set dates time to midnight
    firstDate.setHours(0, 0, 0, 0);
    secondDate.setHours(0, 0, 0, 0);

    // Get today and set to midnight
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Get the difference in days
    const diffDays = Math.abs(Math.round((firstDate - secondDate) / oneDay));

    if (!dateRange.start /* && secondDate.getTime() >= today.getTime() */) {
      return `Total`;
    }

    if (
      dateRange.start &&
      !dateRange.end &&
      firstDate.getTime() <= secondDate.getTime()
    ) {
      return `Last ${diffDays} ${diffDays === 1 ? "Day" : "Days"}`;
    }

    if (
      dateRange.start &&
      !dateRange.end &&
      firstDate.getTime() > secondDate.getTime()
    ) {
      return `Total`;
    }

    if (dateRange.start || dateRange.end) {
      if (diffDays <= 0) {
        return `${diffDays} ${diffDays === 1 ? "Day" : "Days"} From Today`;
      }
      return `${diffDays} ${diffDays === 1 ? "Day" : "Days"}`;
    } else {
      return "Total";
    }
  }

  const statsColLayout = (
    <Col className="text-center">
      {tiles.map((stat) => (
        <Row
          key={stat.title}
          className={`${styles.stat_container} mx-auto m-3 p-2 bg-white shadow`}
        >
          <div className="d-flex justify-content-center align-items-center pb-2 ">
            <span>{stat.title}</span>
            <label
              className="py-1 px-3 ms-auto rounded"
              style={{ backgroundColor: stat.labelColor }}
            >
              {getNumberOfDays()}
            </label>
          </div>
          <hr />
          <div className="fs-3">{stat.number}</div>
          <small className="ms-2">{stat.desc}</small>
        </Row>
      ))}
    </Col>
  );

  const statsRowLayout = (
    <Row className="justify-content-center">
      {tiles.map((stat) => (
        <Col
          key={stat.title}
          className={`${styles.stat_container} mx-3 px-3 py-2 bg-white shadow`}
        >
          <div className="d-flex justify-content-between pb-2">
            <span>{stat.title}</span>
            <label
              className="py-1 px-3 rounded"
              style={{ backgroundColor: stat.labelColor }}
            >
              {getNumberOfDays()}
            </label>
          </div>
          <hr />
          <div className="pt-2 fs-3 ms-2">{stat.number}</div>
          <small className="pb-2 ms-2">{stat.desc}</small>
        </Col>
      ))}
    </Row>
  );

  return (
    <Row className="g-0 px-3 d-flex flex-column align-items-center flex-grow-1 my-3">
      {width <= BSBreakpoints.lg ? statsColLayout : statsRowLayout}
    </Row>
  );
}
