//Maps following values to an object key pairs to table.
//First element is table header name and second is key of object to map to table cell.

import {
  convertDateFormat,
  sortByNumber,
  sortyByDate,
  sortAlphabetically,
} from "../../../../helpers/helpers";

import { formatPhoneNumber } from "../../../../helpers/formattingHelpers"

//Follows insertion order.
export default function contactsTableColumnMap(viewType, currentUser) {
  // Determine map based on selected view
  const contactsMap =
    viewType === "customersList"
      ? new Map([
          [
            "Name",
            {
              columnKey: "last_name",
              sort: true,
              sortFunc: sortAlphabetically,
              //formatFunc: formatName,
            },
          ],
          [
            "Phone Number",
            {
              columnKey: "phone_number",
              sort: true,
              sortFunc: sortByNumber,
              formatFunc: formatPhoneNumber,
            },
          ],
          [
            "Added By",
            {
              columnKey: "added_by_user",
              sortFunc: sortAlphabetically,
              sort: true,
            },
          ],
          [
            "Created",
            {
              columnKey: "customer_created_at",
              sort: true,
              sortFunc: sortyByDate,
              formatFunc: convertDateFormat,
            },
          ],
        ])
      : //TODO Need to figure out what fields should be displayed
        new Map([
          [
            "Name",
            {
              columnKey: "last_name",
              sort: true,
              sortFunc: sortAlphabetically,
              //formatFunc: formatName,
              default: true,
            },
          ],
        ]);

  if (
    viewType === "usersInCode" &&
    currentUser &&
    currentUser.user_role === "admin"
  ) {
    contactsMap.set("Billing Code", {
      columnKey: "billing_code",
      sort: true,
      sortFunc: sortAlphabetically,
    });
  }

  return contactsMap;
}
