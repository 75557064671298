// Libraries
import { z } from "zod"; 
import { createRoot } from "react-dom/client";
import { StrictMode } from "react";
import * as Sentry from "@sentry/react";
// Components
import App from "./App.jsx";
// Lets strings be undefined if in dev mode
const optionalForDev = () => import.meta.env.MODE === "development" ?  z.undefined() : z.string();
//import * as serviceWorkerRegistration from "./serviceWorkerRegistration.js";
const envSchema = z.object({
  VITE_FIREBASE_API_KEY: z.string(),
  VITE_FIREBASE_AUTH_DOMAIN: z.string(),
  VITE_FIREBASE_PROJECT_ID: z.string(),
  VITE_FIREBASE_STORAGE_BUCKET: z.string(),
  VITE_FIREBASE_MESSAGING_SENDER_ID: z.string(),
  VITE_FIREBASE_APP_ID: z.string(),
  VITE_FIREBASE_MEASUREMENT_ID: z.string(),
  VITE_FCM_VAPID_KEY: z.string(),
  VITE_BACKEND_URL: z.string(),
  VITE_BACKEND_AUTH_PATH: z.string(),
  VITE_BASENAME_URL: z.string(),
  VITE_SW_URL: z.string(),
  VITE_MAINTENANCE_OVERRIDE: z.union([z.string(), z.undefined()]),
  VITE_SENTRY_DSN: optionalForDev(),
  VITE_SENTRY_AUTH_TOKEN: optionalForDev()
});
// Safely Parse the schema
const validatedSchema = envSchema.safeParse(import.meta.env);
if(!validatedSchema.success) console.error(`There is a configuration i`);
// TS insisted on ternary assignment
const validEnv = validatedSchema.success ? validatedSchema.data : null;
// Enable Sentry Monitoring in production
if(import.meta.env.MODE === "production" && validEnv) {
  console.log(`🤖 Initializing`);
  Sentry.init({
    dsn: validEnv.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/smscola\.vippbx\.com/],
      }),
      new Sentry.Replay({
        maskAllInputs: false, // Mask values of <input> elements. Passes input values through maskFn before sending to server.
        maskAllText: false // Mask all text content. Will pass text content through maskFn before sending to server.
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const container = document.getElementById("app");

if(container) {
  const root = createRoot(container);
  root.render(
    <StrictMode> {/* Adds better error logging and highlighting for debugging */}
      <App />
    </StrictMode>
  );
}

