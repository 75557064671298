import { faClock, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useContext, useEffect, createContext } from "react";
import { Button, Form, InputGroup, Table } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import { useApplicationStates, useAuth } from "src/contexts";
import { formatPhoneNumber, formatUsernames } from "src/helpers/formattingHelpers";
import { convertTimestampToDate, parsePhoneNumbers } from "src/helpers/helpers";
import { combineFirstAndLastName } from "src/helpers/helpers.index";
import { Buttons } from "../commonViews.index";
import styles from "./FormConstructor.module.scss";
import { OccupiedRoomDIDSelect } from "./components";
import SelectionTable from "./components/SelectionTable";
import RoomDIDSelect from "./components/RoomDIDSelect";
import TextInput from "./components/TextInput";

// Declare context for sub components
export const FormConstructorContext = createContext();

// Define Main Form component
export default function FormConstructor({
  id,
  className,
  initialFormValues,
  onSubmit,
  children,
  // For Modals
  logicMap,
  selectedView,
  selectedRecord,
  foundRooms,
  setModalActive,
}) {
  const { userData } = useApplicationStates();
  const { currentUser } = userData;
  //TODO: Ask Derek, Not sure if this is reliable
  const [formInput, setFormInput] = useState(initialFormValues);
  const [validated, setValidated] = useState(false);

  /*   const onSubmitOverride = logicMap && logicMap[selectedView].onSubmitOverride; */

  useEffect(() => {
    // Create an empty object for the for values to be assigned to (allows changing personal phone value by useEffect without using the form input as a dep)
    let formObject = {};

    // If the selectedView is a setting view, use the currentUser object instead of selectedRecord if available, otherwise use initial form values
    if (currentUser && /setting-/.test(selectedView)) {
      Object.keys(initialFormValues).forEach(
        (formVal) => (formObject[formVal] = currentUser[formVal] || initialFormValues[formVal])
      );
    }

    if (selectedRecord && /edit-/.test(selectedView)) {
      formObject = selectedRecord;
    }

    // If the formObject contains the personal_phone property, pre format the phone number
    if (formObject["personal_phone"]) {
      formObject = {
        ...formObject,
        personal_phone: formatPhoneNumber(formObject.personal_phone),
      };
    }

    if (Object.keys(formObject).length) {
      return setFormInput(formObject);
    }
  }, [selectedRecord, currentUser, initialFormValues, selectedView]);

  function validateForm(e) {
    // If submission came from a delete action, don't validate anything skip to submit
    if (selectedRecord && /delete-/.test(selectedView)) {
      onSubmit();
      if (setModalActive) {
        return setModalActive(false);
      }
    }
    // Clear validation state if it was previously true
    setValidated(false);

    // Check form inputs for any phone numbers and parse them into DB friendly format
    const form = e.currentTarget;
    const parsedInput = formInput ? parsePhoneNumbers(formInput) : formInput;

    if (form.checkValidity()) {
      setValidated(false);
      onSubmit(parsedInput);

      // If a override function for submit is present, use that instead
      /* if (onSubmitOverride) {
        return onSubmitOverride(parsedInput);
      } */

      // If form constructor was triggered by a modal, close after successful validation
      if (setModalActive) {
        return setModalActive(false);
      }
    }

    setValidated(true);
  }

  function submitButtonSelect() {
    const submitType = logicMap[selectedView].submitType;
    const btnText = logicMap[selectedView].btnText;
    switch (submitType) {
      case "yes/no":
        return (
          <Buttons.YesNo
            className="w-100 pt-4 pb-2 d-flex justify-content-end"
            noBtnOnClick={() => setModalActive(false)}
          />
        );
      case "none":
        return null;
      default:
        return (
          <div className="w-100 pt-4 pb-2 d-flex justify-content-end">
            <Button type="submit">{btnText ? btnText : "Submit"}</Button>
          </div>
        );
    }
  }

  // If a logic map is in use (ie. anything other than the mobile views which use their own logic)
  // then find the selected view in the logic map and map its form fields.
  function formSelect() {
    if (logicMap) {
      const fields = logicMap[selectedView].fields;

      if (logicMap[selectedView].fieldSwitch) {
        return logicMap[selectedView].fieldSwitch.switchComponent({
          subFields: logicMap[selectedView].fieldSwitch.subFields,
          initialFormValues,
          setModalActive,
        });
      }

      if (fields) {
        // If found selectedView has a fields property, map those fields to the correct form component
        return (
          <>
            {Object.keys(fields).map((field, index) => {
              const foundField = logicMap[selectedView].fields[field];
              if (foundField.fieldComponent) {
                return (
                  <div key={index}>
                    <InputGroup key={foundField.keyName} className={`${styles.FormConstructor_InputGroup} mb-2`}>
                      {/* If inputGroup is explicitly declared false in logic render component without input group */}
                      {foundField.inputGroup !== false && foundField.formalName && (
                        <InputGroup.Text className={`${styles.FormConstructor_InputGroup_text}`}>
                          {foundField.formalName}
                        </InputGroup.Text>
                      )}

                      {foundField.fieldComponent({
                        ...foundField,
                        foundRooms,
                        selectedRecord,
                        setModalActive,
                        selectedView,
                        validated,
                        formInput,
                        setFormInput,
                      })}

                      <Form.Control.Feedback type="invalid">
                        {validated && foundField.isInvalid && foundField.isInvalid(formInput)}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </div>
                );
              } else {
                return null;
              }
            })}
            {submitButtonSelect()}
          </>
        );
      } else if (logicMap[selectedView].submitType === "yes/no") {
        return (
          <Buttons.YesNo
            className="w-100 pt-4 pb-2 d-flex justify-content-end"
            noBtnOnClick={() => setModalActive(false)}
          />
        );
      }
    }

    // If no logic map found then pass the children straight through.
    return children;
  }

  return (
    <FormConstructorContext.Provider value={{ formInput, setFormInput }}>
      <Form
        id={id || "constructed_form"}
        className={className}
        noValidate
        validated={validated}
        onSubmit={
          (e) => {
            e.preventDefault();

            /* const newErrors = findFormErrors();
          if (Object.keys(newErrors).length) {
            setErrors(newErrors);
          } else { */
            validateForm(e);
          }
          /* if (onSubmitOverride) {
            return onSubmitOverride(formInput, e);
          } else { */

          /*  } */
        } /* } */
      >
        {formSelect()}
      </Form>
    </FormConstructorContext.Provider>
  );
}
////////////////////////////////////////////////////////////////////////////////////////////////////
// PHONE INPUT
////////////////////////////////////////////////////////////////////////////////////////////////////
function PhoneInput({ keyName, placeHolder, className, isInvalid, validated, required }) {
  const { formInput, setFormInput } = useContext(FormConstructorContext);

  function handlePhoneNumber(e) {
    if (e.target.value === "") {
      return setFormInput({
        ...formInput,
        [keyName]: "",
      });
    }

    if (/^[\d ()-]+$/.test(e.target.value)) {
      const formattedNumber = formatPhoneNumber(e.target.value) || e.target.value;
      return setFormInput({
        ...formInput,
        [keyName]: formattedNumber,
      });
    }

    /* return formInput[keyName]; */
  }

  return (
    <>
      <Form.Control
        className={`${className}`}
        type="tel"
        placeholder={placeHolder}
        maxLength={14}
        pattern={"[0-9\\s\\(\\)\\-]{14}"}
        title="Phone Number"
        value={formInput[keyName]}
        onChange={(e) => handlePhoneNumber(e)}
        isInvalid={validated && (typeof isInvalid === "function" ? isInvalid(formInput) : isInvalid)}
        required={required === undefined ? true : required}
      />
    </>
  );
}

////////////////////////////////////////////////////////////////////////////////////////////////////
// PASSWORD FIELD
////////////////////////////////////////////////////////////////////////////////////////////////////
function PasswordField({ keyName, isInvalid, minLength, placeHolder, className, pattern, validated }) {
  const { formInput, setFormInput } = useContext(FormConstructorContext);

  return (
    <Form.Control
      className={`${className}`}
      type="password"
      minLength={minLength}
      placeholder={placeHolder}
      pattern={pattern && pattern(formInput)}
      isInvalid={validated && (typeof isInvalid === "function" ? isInvalid(formInput) : isInvalid)}
      onChange={(e) =>
        setFormInput({
          ...formInput,
          [keyName]: e.target.value,
        })
      }
      required
    />
  );
}

function FormGroup({ className, label, children }) {
  return (
    <Form.Group className={className} controlId={`formGroup${label}`}>
      <Form.Label>{label}</Form.Label>
      {children}
    </Form.Group>
  );
}

function AddContacts({ selectedRoom, keyName, primaryKey }) {
  const { formInput, setFormInput } = useContext(FormConstructorContext);
  const { sharedAppStates } = useApplicationStates();
  const { customersList, usersInCode } = sharedAppStates;

  const [contactsToDisplay, setContactsToDisplay] = useState([]);

  // Change filter list to display in table depending on the primary key prop
  useEffect(() => {
    if (primaryKey === "customer_id") {
      if (selectedRoom) {
        return setContactsToDisplay(
          customersList.filter(
            (customer) =>
              !selectedRoom.room_customers.some((roomCustomer) => roomCustomer.customer_id === customer.customer_id)
          )
        );
      } else {
        return setContactsToDisplay(customersList);
      }
    }

    if (primaryKey === "user_id") {
      if (selectedRoom) {
        const filteredUsers = setContactsToDisplay(
          usersInCode.filter((user) => !selectedRoom.room_users.some((roomUser) => roomUser.user_id === user.user_id))
        );

        if (selectedRoom.room_did_number) {
          return filteredUsers.filter((user) =>
            user.user_dids.some((did) => did.did_number === selectedRoom.room_did_number)
          );
        } else {
          return filteredUsers;
        }
      } else {
        if (formInput.selected_customers.length) {
          return setContactsToDisplay(
            usersInCode.filter((user) => user.user_dids.some((did) => did.did_number === formInput.selected_did))
          );
        } else {
          return setContactsToDisplay(usersInCode);
        }
      }
    }
  }, [formInput, selectedRoom, primaryKey, customersList, usersInCode]);

  function handleAssignContact(contact) {
    // Create shallow copy of form input did numbers
    const selectedContacts = [...formInput[keyName]];
    const index = selectedContacts.findIndex(
      (selectedContact) =>
        selectedContact[primaryKey] === contact[primaryKey] || selectedContact === contact[primaryKey]
      /* contactPropertySelect(contact, selContact) */
    );

    // If contact is currently selected, remove from array,
    if (index > -1) {
      selectedContacts.splice(index, 1);
      setFormInput({
        ...formInput,
        [keyName]: [...selectedContacts],
      });
    } else {
      // Else add them to the array
      setFormInput({
        ...formInput,
        [keyName]: [
          ...selectedContacts,
          // Use IIFE to store return value on array instead of function
          (() => {
            if (primaryKey === "customer_id") {
              return {
                customer_id: contact[primaryKey],
                phone_number: contact.phone_number,
                name: `${contact.first_name} ${contact.last_name}`,
              };
            } else {
              return contact[primaryKey];
            }
          })(),
        ],
      });
    }
  }

  function tableView() {
    return (
      <FormConstructor.SelectionTable
        list={contactsToDisplay}
        headers={["Selected", "Name"]}
        onChange={handleAssignContact}
        checked={(contact) =>
          formInput[keyName].some(
            (selectedContact) =>
              selectedContact[primaryKey] === contact[primaryKey] || selectedContact === contact[primaryKey]
          )
        }
        key={primaryKey}
      >
        {(contact) => <td key={contact[primaryKey]}>{combineFirstAndLastName(contact)}</td>}
      </FormConstructor.SelectionTable>
    );
  }

  return /* view === "mobile" ? mobileView() : */ tableView();
}
////////////////////////////////////////////////////////////////////////////////////////////////
// ROOM SELECT
////////////////////////////////////////////////////////////////////////////////////////////////
function RoomSelect({ view, foundRooms, changeRoom }) {
  const { formInput, setFormInput } = useContext(FormConstructorContext);
  const { userData, adminAppStates } = useApplicationStates();
  const { currentUser } = userData;
  const { adminDIDs } = adminAppStates;
  const { firebaseUser } = useAuth();

  function handleChangeRadio(val) {
    setFormInput((prev) => ({
      ...prev,
      selected_room: val,
    }));
  }

  function RoomDIDNumber(room) {
    let didNumbers;

    //TODO setup for managers
    if (currentUser.user_role === "admin" || currentUser.user_role === "dev") {
      didNumbers = adminDIDs;
    } else {
      didNumbers = currentUser.user_dids;
    }

    const foundDID = didNumbers.find((did) => did.did_number === room.room_did_number);

    return foundDID ? (
      <div>
        <div>{foundDID.did_description}</div>
        <div>{formatPhoneNumber(foundDID.did_number)}</div>
      </div>
    ) : (
      "No DID found"
    );
  }

  function mobileView() {
    return (
      <div className=" mx-auto" style={{ "max-width": "350px" }}>
        {foundRooms.map((room) => (
          <div key={room.room_id} className="d-flex border p-2" onClick={() => changeRoom({ selected_room: room })}>
            <div className="w-100">
              <div className="d-flex justify-content-between flex-wrap ">
                <div className={"line-clamp-1 py-1"}>{room.last_message_text}</div>
              </div>
              <div className="d-flex border-top">
                <div className="me-2">
                  <FontAwesomeIcon icon={faClock} size="sm" />
                </div>
                <div className="">{convertTimestampToDate(room.room_updated_at)}</div>
              </div>
              <div className="d-flex">
                <div className="me-2">
                  <FontAwesomeIcon icon={faPhone} size="sm" />
                </div>
                <div>{RoomDIDNumber(room)}</div>
              </div>
              <div className="d-flex">
                <div className="me-2">
                  <FontAwesomeIcon icon={faUser} size="sm" />
                </div>
                <div>
                  {room.room_users.map((user, index) => (
                    <div key={index}>{combineFirstAndLastName(user)}</div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  function roomTableRow(room) {
    const usernames = formatUsernames(room.room_users, firebaseUser);

    return (
      <tr key={room.room_id} onClick={() => handleChangeRadio(room)} /* className={`d-flex align-items-center`} */>
        <td>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check
              className="d-flex justify-content-center"
              type="radio"
              onChange={() => handleChangeRadio(room)}
              checked={formInput.selected_room === room}
            />
          </Form.Group>
        </td>
        {<td>{usernames}</td>}
        <td>{room.last_message_text ? room.last_message_text : <i>No Messages</i>}</td>
        <td>{convertTimestampToDate(room.room_updated_at)}</td>
      </tr>
    );
  }

  const tableView = (
    <Table
      //className={`my-2`}
      striped
      bordered
      hover
      style={{
        gridTemplateColumns: "minmax(auto, auto) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr)",
      }}
    >
      <thead>
        <tr>
          <th>Select</th>
          <th>Room Users</th>
          <th>Last Message</th>
          <th>Updated</th>
        </tr>
      </thead>
      <tbody>{foundRooms.map((room) => room.room_enabled ? roomTableRow(room): null)}</tbody>
    </Table>
  );

  return view === "mobile" ? mobileView() : tableView;
}

////////////////////////////////////////////////////////////////////////////////////////////////
// TEXT AREA
////////////////////////////////////////////////////////////////////////////////////////////////
function TextArea({ placeHolder, keyName }) {
  const { formInput, setFormInput } = useContext(FormConstructorContext);
  return (
    <TextareaAutosize
      className={`${styles.formConstructor_text_area} form-control`}
      placeholder={placeHolder}
      required
      onChange={(e) =>
        setFormInput({
          ...formInput,
          [keyName]: e.target.value,
        })
      }
      value={formInput[keyName]}
    />
  );
}
////////////////////////////////////////////////////////////////////////////////////////////////
// DELETE ALERT
////////////////////////////////////////////////////////////////////////////////////////////////
function DeleteAlert({ text }) {
  return <p>{text}</p>;
}
////////////////////////////////////////////////////////////////////////////////////////////////
// DID ASSIGN
////////////////////////////////////////////////////////////////////////////////////////////////
function DIDAssign({ keyName, selectedView, selectedRecord }) {
  const { formInput, setFormInput } = useContext(FormConstructorContext);
  const { userData, sharedAppStates, adminAppStates } = useApplicationStates();
  const { currentUser } = userData;
  const { billCodeDIDs } = sharedAppStates;
  const { adminDIDs } = adminAppStates;
  function handleDIDAssign(did) {
    // Create shallow copy of form input did numbers
    const didNumbers = [...formInput[keyName]];
    const index = didNumbers.findIndex((obj) => obj.did_number === did.did_number);

    // If DID is currently selected, remove DID from array,
    if (index > -1) {
      // Splice DID from array
      didNumbers.splice(index, 1);
      // and set formInput
      setFormInput({
        ...formInput,
        [keyName]: [...didNumbers],
      });
    } else {
      setFormInput({
        ...formInput,
        [keyName]: [
          ...didNumbers,
          {
            did_number: did.did_number,
          },
        ],
      });
    }
  }

  function mapDIDNumbers() {
    let didsToMap = [];

    // Assign DID numbers to a Bill Code
    if (keyName === "did_numbers") {
      didsToMap = adminDIDs.filter(
        (did) =>
          did.did_billing_code === null || (selectedRecord && did.did_billing_code === selectedRecord.billing_code)
      );
    } 
    
    if (keyName === "user_dids") {
      //If User is a manager
      if (currentUser.user_role === "manager") {
        //The DIDs to Map will be the bill code's DIDs
        return didsToMap = billCodeDIDs;
      } 
      //If User is an Admin or Dev
      if (currentUser.user_role === "admin" || currentUser.user_role === "dev") {
        //If editing a selected user filter by their own billing code
        if(selectedRecord?.billing_code) {
          didsToMap = adminDIDs.filter(did => did.did_billing_code === selectedRecord.billing_code);      
        }
        //If not editing a user (creating a user) select the did list by whatever bill code is selected in forms
        if(formInput.billing_code) {
          didsToMap = adminDIDs.filter(did => did.did_billing_code === formInput.billing_code);
        }
      } 
      // OLDER LOGIC CODE:
      /* //If the selected view starts with "add-" or "edit-"
      if (/^add-/.test(selectedView) || /^edit-/.test(selectedView)) {
        didsToMap = didList//.filter((did) => did.did_billing_code === formInput.billing_code);
      } else if (selectedRecord) {
        didsToMap = didList.filter((did) => did.did_billing_code === selectedRecord.billing_code);
      } */
    }

    return didsToMap.length ? didsToMap : [];
  }

  function didNumbers() {
    if (selectedView === "add-code") {
      return adminDIDs.filter((did) => did.did_billing_code === null);
    } else {
      return mapDIDNumbers();
    }
  }

  function noDIDsOrCodeWarning() {
    if (selectedView === "add-code") {
      return null;
    }

    if (!didNumbers().length) {
      return (
        <div className="w-100 pt-1 py-3 text-center">
          {
            formInput.billing_code 
              ? (<p>No DID Numbers are assigned to this bill code</p>) 
              : (<p>Please select a bill code to see the available DID Numbers</p>)}
        </div>
      );
    }

    return null;
  }

  return (
    <>
      <Table
        className={styles.DIDAssign_table}
        striped
        bordered
        hover
        style={{
          gridTemplateColumns: `minmax(50px, auto) minmax(140px, 1fr) ${
            !currentUser || currentUser.role === "manager" ? "" : "minmax(100px, auto)"
          } minmax(100px, 1fr)`,
        }}
      >
        <thead>
          <tr>
            <th></th>
            <th>DID Number</th>
            <th>Billing Code</th>
            <th>DID Description</th>
          </tr>
        </thead>
        <tbody>
          {didNumbers().map((did) => {
            return (
              <tr key={did.did_number} onClick={() => handleDIDAssign(did)}>
                <td>
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      className="d-flex justify-content-center"
                      type="checkbox"
                      onChange={() => handleDIDAssign(did)}
                      checked={
                        formInput[keyName].findIndex((didRecord) => didRecord.did_number === did.did_number) > -1
                          ? true
                          : false
                      }
                    />
                  </Form.Group>
                </td>
                <td>{formatPhoneNumber(did.did_number)}</td>
                {!currentUser || currentUser.role === "manager" ? null : <td>{did.did_billing_code}</td>}
                <td>{did.did_description}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {noDIDsOrCodeWarning()}
    </>
  );
}
////////////////////////////////////////////////////////////////////////////////////////////////
// OPTIONAL CONSENT BYPASS
////////////////////////////////////////////////////////////////////////////////////////////////
function ConsentBypassCheckBox({ keyName, isChecked }) {
  const { formInput, setFormInput } = useContext(FormConstructorContext);
  return (
    <div className="optional-consent">
      <span>Optional:</span>
      <br />
      <Form.Check
        key={keyName}
        id={keyName}
        checked={isChecked}
        onChange={() =>
          setFormInput({
            ...formInput,
            [keyName]: !formInput[keyName],
          })
        }
        label={`I confirm that I have received customer consent to send SMS messages`}
      />
    </div>
  );
}
////////////////////////////////////////////////////////////////////////////////////////////////
// DROPDOWN SELECT
////////////////////////////////////////////////////////////////////////////////////////////////
function DropdownSelect({ label, keyName, onChange, dataList, className }) {
  const { formInput, setFormInput } = useContext(FormConstructorContext);

  function checkUserVals() {
    const existingVal = formInput[keyName] ? formInput[keyName].toLowerCase() : null;
    const matchingDataVal = dataList.find((data) => data.toLowerCase() === existingVal);

    if (matchingDataVal && existingVal === matchingDataVal.toLowerCase()) {
      return matchingDataVal;
    }

    return formInput[keyName];
  }

  const selectVal = checkUserVals();

  return (
    <>
      {label ? <Form.Label className="d-flex align-items-center me-2 mb-0">{label} </Form.Label> : null}
      <Form.Select
        className={`${styles.form_select} ${className}`}
        value={selectVal}
        onChange={(e) => {
          setFormInput({
            ...formInput,
            [keyName]: e.target.value,
          });
          return onChange ? onChange(e) : null;
        }}
        required={keyName === "billing_code" ? true : false}
      >
        {/* TODO: No idea what this code was doing, ask Derek */
        /* keyName === "user_role" ? null : <option /> */}
        {keyName === "billing_code" ? (
          <option value="" selected disabled hidden>
            Select Bill Code
          </option>
        ) : null}
        {dataList.map((data) => (
          <option key={data}>{data[keyName] ? data[keyName] : data}</option>
        ))}
      </Form.Select>
    </>
  );
}
////////////////////////////////////////////////////////////////////////////////////////////////
// TOGGLE SWITCH
////////////////////////////////////////////////////////////////////////////////////////////////
function Switch({ keyName, formalName }) {
  const { formInput, setFormInput } = useContext(FormConstructorContext);

  return (
    <Form.Check
      key={keyName}
      id={keyName}
      type="switch"
      //!! Currently hardcoded to disable when user is marked as an admin
      //disabled={keyName === "status" && formInput.admin ? true : false}
      label={formalName}
      checked={formInput[keyName]}
      onChange={() =>
        setFormInput({
          ...formInput,
          //!! Currently uses integer, could use true or false and change on server/before submit
          [keyName]: formInput[keyName] === true ? false : true,
        })
      }
    />
  );
}
////////////////////////////////////////////////////////////////////////////////////////////////
// ADD CONTACTS CHECKBOX
////////////////////////////////////////////////////////////////////////////////////////////////
function AddContactsCheckboxes({ keyName }) {
  const { formInput, setFormInput } = useContext(FormConstructorContext);
  const { sharedAppStates } = useApplicationStates();
  const { serverResponse } = sharedAppStates;

  function handleAssignContact(user) {
    // Create shallow copy of form input did numbers
    const selectedUsers = [...formInput[keyName]];
    const index = selectedUsers.findIndex((userID) => userID === user.user_id);

    // If DID is currently selected, remove DID from array,
    if (index > -1) {
      // Splice DID from array
      selectedUsers.splice(index, 1);
      // and set formInput
      setFormInput({
        ...formInput,
        [keyName]: [...selectedUsers],
      });
    } else {
      setFormInput({
        ...formInput,
        [keyName]: [...selectedUsers, user.user_id],
      });
    }
  }

  return (
    <Table
      className={styles.DIDAssign_table}
      style={{
        gridTemplateColumns: `minmax(auto, auto) minmax(100px, 1fr)`,
      }}
      striped
      bordered
      hover
    >
      <thead>
        <tr>
          <th>Selected</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {serverResponse && serverResponse["add-contacts"]
          ? serverResponse["add-contacts"].map((user) => {
              return (
                <tr key={user.user_id} onClick={() => handleAssignContact(user)}>
                  <td>
                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Check
                        className="d-flex justify-content-center"
                        type="checkbox"
                        onChange={() => handleAssignContact(user)}
                        checked={formInput[keyName].some((userID) => userID === user.user_id) ? true : false}
                      />
                    </Form.Group>
                  </td>
                  <td>{combineFirstAndLastName(user)}</td>
                </tr>
              );
            })
          : null}
      </tbody>
    </Table>
  );
}

FormConstructor.TextInput = TextInput;
FormConstructor.TextArea = TextArea;
FormConstructor.PhoneInput = PhoneInput;
FormConstructor.PasswordField = PasswordField;
FormConstructor.FormGroup = FormGroup;
FormConstructor.DropdownSelect = DropdownSelect;
FormConstructor.Switch = Switch;
FormConstructor.RoomDIDSelect = RoomDIDSelect;
FormConstructor.RoomSelect = RoomSelect;
FormConstructor.DeleteAlert = DeleteAlert;
FormConstructor.DIDAssign = DIDAssign;
FormConstructor.AddContactsCheckboxes = AddContactsCheckboxes;
FormConstructor.OccupiedRoomDIDSelect = OccupiedRoomDIDSelect;
FormConstructor.AddContacts = AddContacts;
FormConstructor.SelectionTable = SelectionTable;
FormConstructor.ConsentBypassCheckBox = ConsentBypassCheckBox;
