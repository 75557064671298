import { FormatColumns } from "../../../../common/DataTable/components/DataTable.index";
import {
  convertDateFormat,
  convertTimestampToDate,
  sortAlphabetically,
  sortyByDate,
} from "../../../../helpers/helpers";

const managerUsersColumnMap = new Map([
  [
    "Name",
    {
      columnKey: "last_name",
      sort: true,
      sortFunc: sortAlphabetically,
      // formatFunc: formatName,
      default: true,
    },
  ],
  [
    "Email",
    {
      columnKey: "email",
      sort: true,
      sortFunc: sortAlphabetically,
      default: true,
    },
  ],
  [
    "Billing Code",
    {
      columnKey: "billing_code",
      sort: true,
      sortFunc: sortAlphabetically,
    },
  ],
  [
    "Role",
    {
      columnKey: "user_role",
      sort: true,
      sortFunc: sortAlphabetically,
      formatFunc: (role) => <FormatColumns.UserRoleCol role={role} />,
    },
  ],
  [
    "Status",
    {
      columnKey: "user_enabled",
      sort: false,
      formatFunc: (userEnabled) => (
        <FormatColumns.EnabledCol userEnabled={userEnabled} />
      ),
      //sortFunc: sortAlphabetically,
    },
  ],
  [
    "Verified",
    {
      columnKey: ["email_verified", "password_verified"],
      sort: false,
      formatFunc: (verifiedData) => (
        <FormatColumns.VerifiedCol verifiedData={verifiedData} />
      ),
      //sortFunc: sortAlphabetically,
    },
  ],
  [
    "Created",
    {
      columnKey: "user_created_at",
      sort: true,
      sortFunc: sortyByDate,
      formatFunc: convertDateFormat,
    },
  ],
  [
    "Last Login",
    {
      columnKey: "user_last_login",
      sort: true,
      sortFunc: sortyByDate,
      formatFunc: convertTimestampToDate,
    },
  ],
]);

export default managerUsersColumnMap;
