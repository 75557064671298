import { useApplicationStates } from "src/contexts";

export default function useUpdateRoom() {
  const { sharedAppStates, socket } = useApplicationStates();
  const { setFullRoomsList } = sharedAppStates;

  function updateCustomerRoomDID({ selectedDID, roomID }, handleFunction) {
    socket.emit("requestUpdateRoom", { selected_did: selectedDID, room_id: roomID }, updatedRoom => {
      setFullRoomsList(prev => {

        return [
        ...prev.filter( room => room.room_id !== updatedRoom.room_id), 
        updatedRoom 
      ]}
      );
      return handleFunction 
        ? handleFunction(updatedRoom)
        : "No function defined for handling updated rooms"
    })
  }

  return { updateCustomerRoomDID }
}