import { useApplicationStates } from "src/contexts";

export default function useRequestRoomAvailability() {
  const { sharedAppStates, socket } = useApplicationStates();
  const {
    /*  setFullRoomsList, customersList, setCustomersList,  */ setServerResponse,
  } = sharedAppStates;

  function requestRoomAvailability(customer_ids, { newRoomFunc, occupiedRoomFunc } = {}) {
    // If the customer ID argument is given a single customer record, convert that into an array
    if (!Array.isArray(customer_ids)) {
      customer_ids = [customer_ids];
    }

    function handleAvailabilityResponse([status, rooms]) {
      if (status) {
        return newRoomFunc
          ? newRoomFunc(rooms)
          : console.log(
              "No function to perform after available room was returned"
            );
      } else {
        setServerResponse({ "occupied-rooms": rooms });
        return occupiedRoomFunc
          ? occupiedRoomFunc()
          : console.warn(
              "No function to perform after occupied room was returned"
            );
      }
    }

    socket.emit(
      "requestRoomAvailabilityByCustomer",
      customer_ids,
      handleAvailabilityResponse
    );
  }

  return { requestRoomAvailability };
}
