import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import styles from "./ErrorModal.module.scss";
import { useEffect } from "react";
import * as Sentry from "@sentry/browser";

export default function ErrorModal({ error, setError }) {
  // Manually Capture an event to send to Sentry when the client gets errors 
  useEffect(() => {
    //Make sure there's an actual error first
    if(error) {
      // Prevents running the call in development
      if(import.meta.env.MODE === "production") Sentry.captureException(error);
    } 
  }, [error])
  
  return (
    <Modal
      show={error}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName={`${styles.warning_modal} mx-auto p-2`}
    >
      <Modal.Header closeButton={false}>
        <Modal.Title className={`mx-auto`} id="contain ed-modal-title-vcenter">
          <div className={`d-flex align-items-center`}>
            {/* <FontAwesomeIcon className={`me-2`} icon={faExclamationTriangle} /> */}
            <div className={`d-flex align-items-center`}>
              <FontAwesomeIcon className={`mt-1 me-2`} icon={faExclamationCircle} color={"red"} />
              <h1>Error</h1>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
        {/* Style Experiment for Cleaner Error Modals 
          <h2 className={`text-center fs-5 mb-2`}>The application has experienced the following error:</h2> 
        */}
        <h5 className="whitespace-pre-wrap">{error}</h5>
      </Modal.Body>
      <Modal.Footer className={`d-flex justify-content-center`}>
        <Button
          className={`fs-5 flex-grow-1`}
          onClick={() => {
            setError(null);
          }}
        >
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
