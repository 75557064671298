import { Spinner } from "react-bootstrap";
import oneSP from "../../assets/img/1sp.png";
import styles from "./Loading.module.scss";

export default function Loading() {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center flex-grow-1 overflow-hidden">
      <img
        src={oneSP}
        alt="1 Simple Phone"
        className={`${styles.loading_img} position-absolute top-50 left-50`}
      />
      <Spinner
        animation="border"
        role="status"
        className={styles.spinner}
      ></Spinner>
    </div>
  );
}
