// Libraries and Constants
import { useEffect, useState } from "react";
import { LAYOUTS } from "src/constants/index";
import { companyColumns, companyMobileColumns, companyFormLogic } from "./index";
//Contexts
import { useApplicationStates } from "src/contexts";
// Hooks
import { useMediaQueries, useSocketEvent } from "src/hooks";
// Components
import { Form, ReadRecord } from "src/common";
import { ViewLayoutSwitch } from "src/layout";
import { FormConstructor } from "src/common/commonViews.index";

export default function AdminCompanies() {
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // STATES
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedView, setSelectedView] = useState(null);
  const [companiesToDisplay, setCompaniesToDisplay] = useState([]);
  const [showDeletedCompanies, setShowDeletedCompanies] = useState(false);
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // HOOKS
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const { adminAppStates, sharedAppStates } = useApplicationStates();
  const { adminCompanies } = adminAppStates;
  const { setError } = sharedAppStates;
  const { emitEvent } = useSocketEvent();

  const layoutProps = useMediaQueries(["(min-width: 576px)"], [{ columns: companyColumns, layout: LAYOUTS.DESKTOP }], {
    columns: companyMobileColumns,
    layout: LAYOUTS.MOBILE,
  });

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // AFTER RENDER
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Allows constants below to update after state has changed as well as fire off read event
  useEffect(() => {
    if (!selectedCompany) return;
    setSelectedView(["read", COMPANY_VIEWS.get("read")]);
  }, [selectedCompany]);

  useEffect(() => {
    if(showDeletedCompanies) {
      return setCompaniesToDisplay(adminCompanies)
    }
    //Filter out companies with a deleted_at timestamp
    const filteredCompanies = adminCompanies.filter(company => company.deleted_at ? false : true);
    setCompaniesToDisplay(filteredCompanies);
  }, [adminCompanies, showDeletedCompanies]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // CONSTANTS
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const COMPANY_VIEWS = new Map([
    [
      "read",
      {
        title: selectedCompany?.company_name,
        children: (props) => (
          <ReadRecord
            record={selectedCompany}
            recordMap={companyFormLogic.read}
            buttonActions={[
              {
                onClick: () => {
                  const choice = confirm(`You're about to delete the company for ${selectedCompany.company_name} are you sure?`);
                  if(choice) {
                    handleSoftDeleteCompany(selectedCompany);
                  }
                  resetView();
                },
                text: "Delete Company",
              },
              {
                onClick: () => setSelectedView(["update", COMPANY_VIEWS.get("update")]),
                text: "Edit Company",
              },
            ]}
            stripKeyString="company_"
            {...props}
          />
        ),
      },
    ],
    [
      "create",
      {
        title: "New Company",
        children: <Form formLogic={companyFormLogic.create} onSubmit={handleCreateCompany} />,
      },
    ],
    [
      "update",
      {
        title: `Editing ${selectedCompany?.company_name}`,
        children: (
          <Form selectedRecord={selectedCompany} formLogic={companyFormLogic.update} onSubmit={handleUpdateCompany} />
        ),
      },
    ],
/*     ["delete",
      { 
        title: `Delete Company ${selectedCompany?.company_name}?`,
        children: (
          <Form selectedRecord={selectedCompany} formLogic={companyFormLogic.delete} onSubmit={handleSoftDeleteCompany}/>
        )
      }
    ] */
  ]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // HANDLERS
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // Reset view
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function resetView() {
    setSelectedView(null);
    setSelectedCompany(null);
  }
  // Create company
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function handleCreateCompany(newCompany) {
    emitEvent("requestCreateCompany", newCompany, (response) => {
      // Currently resets view, can change to display read or other view
      if (response.status === 200) return resetView();
      //TODO: Setup success and error display
    });
  }

  // Update company
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function handleUpdateCompany(editedCompany) {
    emitEvent("requestUpdateCompany", editedCompany, (response) => {
      // Currently resets view, can change to display read or other view
      if (response.status === 200) return resetView();
      
      //TODO: Setup success and error display
    });
  }

  function handleSoftDeleteCompany(company) {
    emitEvent("requestSoftDeleteCompany", company, response => resetView());
  }

  const tableProps = {
    data: companiesToDisplay,
    onRowClick: (record) => setSelectedCompany(record),
    createButton: {
      buttonTitle: "+ New Company",
      handler: () => setSelectedView(["create", COMPANY_VIEWS.get("create")]),
    },
    tableTitle: "Companies",
    ...layoutProps,
  };

  const recordProps = {
    record: selectedCompany,
    resetView: resetView,
    selectedView: selectedView,
    displayName: selectedCompany && selectedCompany["company_name"],
  };

  return <ViewLayoutSwitch tableProps={tableProps} selectedRecordProps={recordProps} layout={layoutProps.layout} />;
}
