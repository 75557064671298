import { useState, useEffect } from "react";
import { default as RbModal } from "react-bootstrap/Modal";

export default function Modal({ show, onExited, title, size = "lg", children }) {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (show) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [show]);

  return (
    <RbModal
      className={"d-flex"}
      contentClassName={"h-100 d-flex flex-column modal-content-custom"}
      dialogClassName={"d-flex flex-column justify-content-center w-100"}
      show={showModal}
      onHide={() => setShowModal(false)}
      onExited={onExited}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      data-testid="modal_dialog"
    >
      <RbModal.Header closeButton>
        {/* Hack to center title with close button */}
        <span className="me-auto" />
        <RbModal.Title id="contained-modal-title-vcenter">{title}</RbModal.Title>
      </RbModal.Header>
      <RbModal.Body className="w-100 d-flex flex-column overflow-hidden">{children}</RbModal.Body>
    </RbModal>
  );
}
