import { Buttons } from "../../../../common/commonViews.index";


export default function ManagerUsersTableHeaderConstructor(setSelectedView) {
  return {
    title: "Users",
    firstRowChildren: () => (
      <Buttons.AddNew setSelectedView={setSelectedView} view={"add-user"} />
    ),
  };
}
