// Libraries/Constants

import { useLocation } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { routesByRole } from "src/routes/routes";
// Hooks
import { useAuth } from "src/contexts";
// Components
import NavbarBillCodeSelect from "./NavbarBillCodeSelect";
import NavBarDropdown from "./NavbarDropdown";
// Styles/Assets
import styles from "./NavigationBar.module.scss";
import oneSP from "../../assets/img/1sp.png";
import { useMediaQueries } from "src/hooks";
import { LAYOUTS } from "src/constants/index";
import NavLink from "./NavLink";

export default function NavigationBar() {
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // HOOKS
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const { pathname } = useLocation();
  const { firebaseUser } = useAuth();
  const layoutProps = useMediaQueries(["(min-width: 992px)"], [{ layout: LAYOUTS.DESKTOP }], {
    layout: LAYOUTS.MOBILE,
  });

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // HANDLERS
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const switchLogoHref = () => {
    if (firebaseUser.role === "admin" || firebaseUser.role === "dev") {
      return /^\/admin/.test(pathname) ? "/admin/stats" : "/user/contacts";
    } else {
      return "/user/contacts";
    }
  };

  const routes = (() => {
    const adminRegex = /^\/*admin/i;
    const managerRegex = /^\/*manager/i;
    // Filter out paths that shouldn't be displayed in navbar (dropdown items, etc.)
    const authorizedPaths = routesByRole[firebaseUser.role].filter(({ displayInNav }) => displayInNav !== false);

    // Defaults by fall through to userPaths if no matches were returned, allows the navbar on 404 view to have links
    let rolePaths;
    switch (true) {
      case adminRegex.test(pathname):
        rolePaths = authorizedPaths.filter(({ path }) => adminRegex.test(path));
        if (rolePaths.length) return rolePaths;
      // fall through
      case managerRegex.test(pathname):
        rolePaths = authorizedPaths.filter(({ path }) => managerRegex.test(path));
        if (rolePaths.length) return rolePaths;
      // fall through
      default:
        rolePaths = authorizedPaths.filter(({ path }) => /^\/*user/i.test(path));
    }

    return rolePaths;
  })();

  const navLinks = () => routes.map((link) => <NavLink link={link} key={link.path} />);

  const displayPageTitle = () => {
    //Title component to display in center of navbar when page is below small viewport (992px)
    if (layoutProps.layout !== LAYOUTS.MOBILE) return null;
    return <span className="fs-2 fw-bold">{routes.find(({ path }) => path === pathname)?.title}</span>;
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // RENDER
  /////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <Navbar collapseOnSelect className={`${styles.nav} px-3 py-1 shadow-sm`} expand="lg">
        {/* Logo and homepage link */}
        <LinkContainer to={switchLogoHref()}>
          <Navbar.Brand className={`d-flex flex-align-center`}>
            <img src={oneSP} alt="1SimplePhone Logo" className={styles.brand_logo_img} />
          </Navbar.Brand>
        </LinkContainer>
        {/* If under 992px, display page title in center of Navbar */}
        {displayPageTitle()}
        {/* Nav hamburger menu */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {/* Collapsible part of nav */}
        <Navbar.Collapse id="basic-navbar-nav" className="py-2">
          <Nav className="me-auto fw-bold" data-testid="main_nav_links">
            {navLinks()}
          </Nav>
          <NavbarBillCodeSelect firebaseUser={firebaseUser} />
          <NavBarDropdown pathname={pathname} firebaseUser={firebaseUser} />
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
