import { Footer, Loading, NavigationBar, NotificationEnableToast } from "src/common/commonViews.index";
import { useEffect, useState, lazy, Suspense } from "react";
import { ApplicationStatesProvider } from "src/contexts/ApplicationStates";
import { FirebaseUser } from "src/services/firebase/useFirebase";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { DefaultRedirects } from "src/routes/routes";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AppUpdateModal from "src/common/Modals/AppUpdateModal";
import Banner from "src/common/Banner/Banner";

type RouteLayoutSwitchProps = {firebaseUser: FirebaseUser | false | null, defaultRedirects: DefaultRedirects}
export default function RouteLayoutSwitch({ firebaseUser, defaultRedirects }: RouteLayoutSwitchProps) {
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // HOOKS
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const navigate = useNavigate();
  const location = useLocation();
  
  const { MODE } = import.meta.env;
  const inDevMode = MODE in ["development", "staging"];
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // STATES
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Show loading while checking if firebase user is present
  const [isLoading, setIsLoading] = useState(true);
  const [queryClient] = useState(() => new QueryClient());
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // RENDER EFFECTS
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    // Set loading to false when authentication check has finished which starts initialized as null 
    // (null is used as a wait value) and returns false or the Firebase user object after checking
    if (firebaseUser !== null) setIsLoading(false);
    // If a firebaseUser object is present
    if (firebaseUser) {
      // TODO: Need to check, should navigate user to new password reset
      if (firebaseUser.tempPassword) return navigate("auth/action?mode=newPassword");
      // If path is root use default redirect
      if (location.pathname === "/") return navigate(defaultRedirects[firebaseUser.role]);
    }
    // If no user is logged in and path name isn't root redirect to it
    if (firebaseUser === false && location.pathname !== "/" && !/\/auth\/.*/.test(location.pathname))
      return navigate("/");
  }, [firebaseUser, defaultRedirects, navigate, location.pathname]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // HANDLERS
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Wrap components with Navbar and Footer if a user is logged in (and is not setting a permanent password
  const routingSwitch = () => {
    return firebaseUser && !firebaseUser?.tempPassword ? (
      <QueryClientProvider client={queryClient}>
        <ApplicationStatesProvider> {/* Datastore for application and DB data */}
          <Banner />
          <AppUpdateModal />
          <NotificationEnableToast />
          <NavigationBar />
          <Outlet />
          <Footer />
        </ApplicationStatesProvider>
        {/* Load the Query Dev Tools if in dev mode */}
        { inDevMode && <ReactQueryDevtools initialIsOpen={false} /> }
      </QueryClientProvider>
    ) : (
      <Outlet />
    );
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // RENDER
  /////////////////////////////////////////////////////////////////////////////////////////////////
  return isLoading ? <Loading /> : routingSwitch();
}
