import MobileViewSelect from "src/common/Mobile/MobileViewSelect";
import { FormConstructor } from "src/common/commonViews.index";
import { nanoid } from "nanoid";
import { faMoneyBill, faBuilding } from "@fortawesome/free-solid-svg-icons";
export default function AddEditUser(props) {
  // If selected modal is edit customer then display labels above
  return (
    <MobileViewSelect.SelectedRecordView
      newContact={!props.selectedRecord}
      handleResetView={props.handleResetView}
      recordTitle={props.recordTitle}
      selectedRecord={props.selectedRecord}
      viewButtons={[<MobileViewSelect.SaveButton key={nanoid} form_id="admin_user_form" />]}
    >
      <FormConstructor
        id="admin_user_form"
        className={`w-100 px-2`}
        onSubmit={(formInput) => {
          props.handleFunctions[props.selectedView](formInput);
          props.handleResetView();
        }}
        initialFormValues={
          props.selectedRecord ? { ...props.selectedRecord } : props.logicMap[props.selectedView].initialFormValues
        }
      >
        <div className={`mx-auto pe-4 `}>{/* {props.logicMap[props.selectedView].fieldComponent()} */}</div>
        <div className={`mx-auto pe-4 `}>
          <MobileViewSelect.MobileFormFieldLayout
            icon={faMoneyBill}
            fieldName="first_name"
            selectedView={props.selectedView}
            fieldMap={props.logicMap[props.selectedView].fields}
          />
          <MobileViewSelect.MobileFormFieldLayout
            icon={faBuilding}
            fieldName="last_name"
            selectedView={props.selectedView}
            fieldMap={props.logicMap[props.selectedView].fields}
          />
          <MobileViewSelect.MobileFormFieldLayout
            icon={faBuilding}
            fieldName="email"
            selectedView={props.selectedView}
            fieldMap={props.logicMap[props.selectedView].fields}
          />
          <MobileViewSelect.MobileFormFieldLayout
            icon={faBuilding}
            fieldName="phone_number"
            selectedView={props.selectedView}
            fieldMap={props.logicMap[props.selectedView].fields}
          />
          <MobileViewSelect.MobileFormFieldLayout
            icon={faBuilding}
            fieldName="role"
            selectedView={props.selectedView}
            fieldMap={props.logicMap[props.selectedView].fields}
          />
        </div>
      </FormConstructor>
    </MobileViewSelect.SelectedRecordView>
  );
}
