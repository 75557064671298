import { faEnvelope, faGlobe, faPhone } from "@fortawesome/free-solid-svg-icons";
import Form from "src/common/Form/Form";
import * as yup from "yup";

/* Top Level:
validationSchema: A json objects schema modeled with yup, is used for validation and initial values
fields (required): An object representing the form fields and their properties

** fields Keys:
{
  name: custom name to display for label, otherwise converts map key to title case name by default
  keyOverride: overrides the map key if present
  placeholder: placeholder for field input
  type: standard html type property for inputs
}
*/

const validationSchema = yup.object({
  company_name: yup.string().default("").required(),
  company_phone: yup.string().default("").nullable(),
  company_email: yup.string().email().default("").nullable(),
  website: yup.string().default("").nullable(),
});

const companyAttributeFields = new Map([
  [
    "company_name",
    {
      /*  placeHolder: "First name", */ // Not implemented
      component: (props, key) => <Form.FormInput {...props} key={key} />,
    },
  ],
  [
    "company_phone",
    {
      type: "tel",
      component: (props, key) => <Form.FormInput {...props} key={key} />,
      icon: faPhone,
    },
  ],
  [
    "company_email",
    {
      type: "email",
      component: (props, key) => <Form.FormInput {...props} key={key} />,
      icon: faEnvelope,
      formatter: (value) => <a href={`mailto:${value}`}>{value}</a>,
    },
  ],
  [
    "website",
    {
      component: (props, key) => <Form.FormInput {...props} key={key} />,
      icon: faGlobe,
      formatter: (value) => (
        // TODO: This should open in new tab but react-router is rerouting
        <a href="value" /* target="_blank" rel="noopener noreferrer" */>{value}</a>
      ),
    },
  ],
]);

const createCompany = {
  validationSchema,
  fields: companyAttributeFields,
};

const updateCompany = {
  validationSchema,
  fields: companyAttributeFields,
};

const readCompany = {
  fields: (() => {
    const fields = new Map(companyAttributeFields);
    fields.delete("company_name");
    return fields;
  })(),
};

export const companyFormLogic = {
  create: createCompany,
  update: updateCompany,
  read: readCompany,
};
