import { ContactCircle } from "../commonViews.index";
import styles from "./Tiles.module.scss";
import newCustomerLabel from "../DataTable/components/NewCustomerLabel.jsx";

export default function ContactTile({ name, onClick, active, className, square, formatHelper, acknowledged }) {
  const title = formatHelper ? formatHelper(name) : name;
  return (
    <div
      key={name}
      className={`w-100 d-flex align-items-center ${className} ${active && styles.active_item}`}
      onClick={onClick}
    >
      <ContactCircle circleNames={name} square={square} />
      <div className={`ms-3`}>{title}</div>
      {!acknowledged ? <div className="ms-auto">{newCustomerLabel()}</div> : null}
    </div>
  );
}
