import { useState, useEffect } from "react";
import useWindowDimensions, {
  BSBreakpoints,
} from "./useWindowDimensions";
import { Form, Pagination } from "react-bootstrap";

export default function usePagination(
  recordsListLength,
  selectedPageNum,
  setSelectedPageNum
) {
  const [displayIndexes, setDisplayIndexes] = useState({
    startIndex: 0,
    endIndex: 25,
  });

  //const [numOfResults, setNumOfResults] = useState(25);
  const [numOfPages, setNumOfPages] = useState(1);
  const [numResultsToDisplay, setNumResultsToDisplay] = useState(25);

  const defaultNumofResults = 25;

  // Calculates total # of page tiles to display
  useEffect(() => {
    setNumOfPages(
      Math.ceil(
        // Use default if no number of search results is present
        recordsListLength / (numResultsToDisplay || defaultNumofResults)
      ) ///+ 1
    );
  }, [recordsListLength, numResultsToDisplay]);

  // Returns selected page number to 1 when # of search results is changed.

  // Determines index to use when paginating array and passes back up to parent
  useEffect(() => {
    const searchStartIndex =
      selectedPageNum === 1
        ? 0
        : (selectedPageNum - 1) * (numResultsToDisplay || defaultNumofResults);
    const searchEndIndex =
      selectedPageNum === 1
        ? numResultsToDisplay || defaultNumofResults
        : selectedPageNum * (numResultsToDisplay || defaultNumofResults);

    handleRecordIndexes(searchStartIndex, searchEndIndex);
  }, [recordsListLength, numResultsToDisplay, selectedPageNum]);

  // Sets indexes used when slicing the contactsToDisplay array (returned from pagination component)
  function handleRecordIndexes(startIndex, endIndex) {
    setDisplayIndexes({ startIndex, endIndex });
  }

  // Components

  // Styles

  const { width } = useWindowDimensions();

  function paginate() {
    /////////////////////////////////////////////////////////////////////////////////////////////////
    // Pagination states/Defaults
    /////////////////////////////////////////////////////////////////////////////////////////////////
    //const [numOfPages, setNumOfPages] = useState(1); //Number of result pages in total, default to 1.
    //const [selectedPageNum, setSelectedPageNum] = useState(1); //Current page of clients displayed to user

    /////////////////////////////////////////////////////////////////////////////////////////////////
    // Render Logic
    /////////////////////////////////////////////////////////////////////////////////////////////////
    //TODO set default for numResultsToDisplay

    /* useEffect(() => {
    handlePageNumber(selectedPageNum);
  }, [selectedPageNum]); */

    // Determine max number of tiles (including page forward/back) to display depending on viewport
    const maxTiles = width < 400 ? 3 : width < BSBreakpoints.bp470 ? 5 : 7;

    // Determine number of page tiles to display depending on width of viewport
    const numOfTiles = width < 400 ? 1 : width < BSBreakpoints.bp470 ? 2 : 3;

    // Create an array with the page numbers for later mapping over
    //TODO maybe able to simplify truncated tile logic by having map only render tile numbers it will show
    function pageTiles() {
      let numArray = [];
      for (let i = 1; i <= numOfPages; i++) {
        numArray.push(i);
      }
      return numArray;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////
    // Viewport layout components
    /////////////////////////////////////////////////////////////////////////////////////////////////

    // Layout when number of pages is less than max tiles
    const allTiles = pageTiles().map((num) =>
      // If the number is not the selected page number render tile without active flag
      num !== selectedPageNum ? (
        <Pagination.Item key={num} onClick={() => setSelectedPageNum(num)}>
          {num}
        </Pagination.Item>
      ) : (
        // Else if num is selected page render tile with active flag
        <Pagination.Item className={"active_page_tile"} key={num} active>
          {selectedPageNum}
        </Pagination.Item>
      )
    );

    // Layout when number of tiles exceeds max tiles
    const truncatedTiles = (
      <>
        {/* First page and prev page tiles */}
        <Pagination.First onClick={() => setSelectedPageNum(1)} />
        <Pagination.Prev
          onClick={
            selectedPageNum > 1
              ? () => setSelectedPageNum(selectedPageNum - 1)
              : null
          }
        />
        {/* Ellipsis tile if more pages than can be shown in the lower range */}
        {selectedPageNum > maxTiles - numOfTiles && width > BSBreakpoints.sm ? (
          <Pagination.Ellipsis />
        ) : null}

        {/* Tiles to be shown in range below Active page */}
        {pageTiles().map((num) =>
          num >= selectedPageNum - numOfTiles && num < selectedPageNum ? (
            <Pagination.Item key={num} onClick={() => setSelectedPageNum(num)}>
              {num}
            </Pagination.Item>
          ) : numOfPages - selectedPageNum < numOfTiles &&
            num < selectedPageNum &&
            num > numOfPages - maxTiles ? (
            <Pagination.Item key={num} onClick={() => setSelectedPageNum(num)}>
              {num}
            </Pagination.Item>
          ) : null
        )}

        {/* Active page tile */}
        <Pagination.Item className={"active_page_tile"} active>
          {selectedPageNum}
        </Pagination.Item>

        {/* Tiles to be shown in range above Active page */}
        {pageTiles().map((num) =>
          selectedPageNum < maxTiles &&
          num > selectedPageNum &&
          num <= maxTiles ? (
            <Pagination.Item key={num} onClick={() => setSelectedPageNum(num)}>
              {num}
            </Pagination.Item>
          ) : num <= selectedPageNum + numOfTiles && num > selectedPageNum ? (
            <Pagination.Item key={num} onClick={() => setSelectedPageNum(num)}>
              {num}
            </Pagination.Item>
          ) : null
        )}

        {/* Ellipsis tile if more pages than can be shown in the upper range */}
        {selectedPageNum < numOfPages - numOfTiles &&
        width > BSBreakpoints.sm ? (
          <Pagination.Ellipsis />
        ) : null}

        {/* Next and last tiles */}
        <Pagination.Next
          onClick={
            selectedPageNum < numOfPages
              ? () => setSelectedPageNum(selectedPageNum + 1)
              : null
          }
        />
        <Pagination.Last onClick={() => setSelectedPageNum(numOfPages)} />
      </>
    );

    return (
      <Pagination
        className={`pagination_bar d-flex justify-content-center align-items-center mt-3 mb-0`}
      >
        {/* Determines layout to display depending on number of max tiles*/}
        {numOfPages <= maxTiles ? allTiles : truncatedTiles}
      </Pagination>
    );
  }

  //Extra feature that needs work, return you to your result when changing num of results
  /*
  const numOfPagesRef = useRef(numOfPages); // Holds previous state value of numOfPages, used for 

  // Set total number of pages when clients or numOfSearch results changes
  useEffect(() => {
    setNumOfPages((prev) => {
      numOfPagesRef.current = prev; // Set reference to previous state value
      return Math.floor(roomsList.length / numOfSearchResults) + 1; // Calculate total # of pages
    });
  }, [roomsList.length, numOfSearchResults]);

  useEffect(() => {
    setSelectedPageNum(1);
if (numOfPages > numOfPagesRef.current) {
      return setSelectedPageNum(
        Math.min(
          Math.ceil(selectedPageNum / (numOfSearchResults / 25)),
          numOfPages
        )
      );
    } else {
      return setSelectedPageNum(
        Math.min(
          Math.floor(
            selectedPageNum *
              (numOfSearchResultsRef.current / numOfSearchResults)
          ),
          numOfPages
        )
      );
    }
  }, [numOfSearchResults , customersList]); */

  function resultsSelector(className) {
    const dropDownOptions = [25, 50, 100];
    return (
      <Form.Group key="numSelector" className={`${className} ms-auto`}>
        <Form.Label className="m-0 ms-2 me-2">Results to Display</Form.Label>
        <Form.Select
          aria-label="Default select example"
          //className="mx-4"
          onChange={(e) => setNumResultsToDisplay(parseInt(e.target.value))}
        >
          {dropDownOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    );
  }

  return { displayIndexes, numOfPages, paginate, resultsSelector };
}
