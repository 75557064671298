import FormConstructor from "../../../../common/FormConstructor/FormConstructor";

function managerUsersFormLogic(logicMapProps) {
  const { selectedUser } = logicMapProps;
  return {
    modalTitles: {
      "edit-user": "Editing the user",
      "user-status": `${selectedUser ? (selectedUser.user_enabled ? "Disable" : "Enable") : null} the account for`,
      "resend-email": "Resend verification email to",
      "delete-user": "Deleting the user",
      "permission-denied": "Permission Denied- ",
    },
    "add-user": {
      modalTitle: "Add a user",
      initialFormValues: {
        first_name: "",
        last_name: "",
        email: "",
        user_dids: [],
        user_role: "user",
        user_enabled: 1,
      },
      fields: {
        first_name: {
          inputType: "text",
          formalName: "First Name",
          keyName: "first_name",
          placeHolder: "John",
          fieldComponent: (props, key) => <FormConstructor.TextInput {...props} key={key} />,
        },
        last_name: {
          inputType: "text",
          formalName: "Last Name",
          keyName: "last_name",
          placeHolder: "Doe",
          fieldComponent: (props, key) => <FormConstructor.TextInput {...props} key={key} />,
        },
        email: {
          inputType: "email",
          formalName: "email",
          keyName: "email",
          placeHolder: "fakeemail@mail.com",
          fieldComponent: (props, key) => <FormConstructor.TextInput {...props} key={key} />,
        },
        phone_number: {
          inputType: "tel",
          formalName: "Phone Number",
          keyName: "personal_phone",
          placeHolder: "(123)-456-7890",
          fieldComponent: (props, key) => <FormConstructor.PhoneInput {...props} key={key} />,
          required: false,
        },
        role: {
          formalName: "User Role",
          keyName: "user_role",
          fieldComponent: (props, key) => (
            <FormConstructor.DropdownSelect {...props} dataList={["User", "Manager"]} key={key} />
          ),
        },
        user_dids: {
          formalName: "DID Numbers",
          keyName: "user_dids",
          inputGroup: false,
          fieldComponent: (props, key) => <FormConstructor.DIDAssign {...props} key={key} />,
        },
        user_enabled: {
          formalName: "User is activated",
          keyName: "user_enabled",
          inputGroup: false,
          fieldComponent: (props, key) => <FormConstructor.Switch {...props} key={key} />,
        },
      },
    },
    "edit-user": {
      modalTitle: "Editing the user",
      buttonTitle: {
        title: "Submit",
        showOnModal: true,
      },
      initialFormValues: {
        first_name: "",
        last_name: "",
        email: "",
        user_dids: [],
        user_role: "",
      },
      fields: {
        first_name: {
          inputType: "text",
          formalName: "First Name",
          keyName: "first_name",
          placeHolder: "John",
          fieldComponent: (props, key) => <FormConstructor.TextInput {...props} key={key} />,
        },
        last_name: {
          inputType: "text",
          formalName: "Last Name",
          keyName: "last_name",
          placeHolder: "Doe",
          fieldComponent: (props, key) => <FormConstructor.TextInput {...props} key={key} />,
        },
        email: {
          inputType: "email",
          formalName: "Email",
          keyName: "email",
          placeHolder: "fakeemail@mail.com",
          fieldComponent: (props, key) => <FormConstructor.TextInput {...props} key={key} />,
        },
        phone_number: {
          inputType: "tel",
          formalName: "Phone Number",
          keyName: "personal_phone",
          placeHolder: "(123)-456-7890",
          fieldComponent: (props, key) => <FormConstructor.PhoneInput {...props} key={key} />,
          required: false,
        },
        role: {
          formalName: "User Role",
          keyName: "user_role",
          fieldComponent: (props, key) => (
            <FormConstructor.DropdownSelect {...props} dataList={["User", "Manager"]} key={key} />
          ),
        },
        user_dids: {
          formalName: "DID Numbers",
          keyName: "user_dids",
          inputGroup: false,
          fieldComponent: (props, key) => <FormConstructor.DIDAssign {...props} key={key} />,
        },
      },
    },
    "user-status": {
      modalTitle: `${selectedUser ? (selectedUser.user_enabled ? "Disable" : "Enable") : null} the account for`,
      submitType: "yes/no",
      submitLogic: logicMapProps.submitFunc,
      text: `Are you sure you want to ${
        selectedUser ? (selectedUser.user_enabled ? "disable" : "enable") : "enable/disable"
      } the account for ${selectedUser ? selectedUser.email : "this user"}?`,
    },
    "reset-password": {
      modalTitle: "Send Password Reset",
      text: `Do you want to send a password reset email to ${
        selectedUser ? selectedUser.email : "to the users email"
      }? `,
      submitType: "yes/no",
    },
    "resend-email": {
      modalTitle: "Resend verification email to",
      text: `Do you want to resend a verification email to ${
        selectedUser ? selectedUser.email : "to the users email"
      }? `,
      submitType: "yes/no",
    },
    "permission-denied": {
      buttonTitle: {
        title: "Resend",
        showOnModal: false,
      },
      submitType: "none",
      text: "You do not have the required permissions to do that",
      fields: {},
    },
    ////////////////////////////////////////////////////////////////////////////////////////////////
    // DELETE USER
    ////////////////////////////////////////////////////////////////////////////////////////////////
    "delete-user": {
      modalTitle: "Deleting the user",
      text: "You are about to permanently delete this record- are you sure?",
      submitType: "yes/no",
      submitLogic: logicMapProps.submitFunc,
    },
  };
}

export default managerUsersFormLogic;
