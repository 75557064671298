import { useEffect, createContext, useContext, useRef, useCallback } from "react";
import { useAuth } from "src/contexts";
import { ErrorBoundary } from "@sentry/react";
import { ErrorModal } from "../../common/commonViews.index";
import useGlobalSocketEvents from "src/socket/useGlobalSocketEvents";
import useAdminSocketEvents from "src/socket/useAdminSocketEvents";
import useManagerSocketEvents from "src/socket/useManagerSocketEvents";
import { useAdminStates, useSharedStates, useUserStates } from "./index";
import { useNotifications } from "src/hooks";
import useSocketIO from "src/socket";
import { usersAttributes } from "@tsmodels/users";

export function GetInitialContextValues() {
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // INITIALIZE APPLICATION STATES
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // !! This initialization structure is temporary until the handleChangeSetting is move from useUserStates
  const sharedAppStates = useSharedStates();
  const { socket } = useSocketIO(sharedAppStates);
  const userData = useUserStates(socket);
  const adminAppStates = useAdminStates();
  const { firebaseUser } = useAuth();
  
  const { setCurrentUser } = userData;
  const { error, setError } = sharedAppStates;
  
  const socketProps = {
    socket,
    userData,
    sharedAppStates,
  };
  
  const supplyBillCode = useCallback(() => {
    const adminBillCodeLogic = (currentUser: usersAttributes) => {
      // QOL Assignment
      const { selectedAdminBillCode } = adminAppStates;
      // Existance check
      if(selectedAdminBillCode) return selectedAdminBillCode;
      // Default
      return currentUser.billing_code;
    }
    
    // Null Guards
    if(!userData.currentUser) return null;
    if(!userData.currentUser.billing_code) return null;
    // QOL Assignment
    const { user_role } = userData.currentUser;
    // Admin selectedBillCode Check
    if(user_role && user_role === "admin") return adminBillCodeLogic(userData.currentUser);
    // Default if not an admin
    return userData.currentUser.billing_code;
  }, [userData.currentUser?.billing_code, adminAppStates.selectedAdminBillCode])

  const appSocketEvents = useGlobalSocketEvents(socketProps);
  const managerSocketEventsProps = { ...socketProps, supplyBillCode } as const;
  useManagerSocketEvents(managerSocketEventsProps);
  
  const adminSocketEventProps = (() => {
    return { ...socketProps, adminAppStates, supplyBillCode };
  })();

  useAdminSocketEvents(adminSocketEventProps);

  const selectedCodeRef = useRef(null);

  function handleSelectedCodeRef(code) {
    return (selectedCodeRef.current = code);
  }

  const getUserData = useCallback(() => {
    if(!firebaseUser) return;
    socket.emit("requestUserData", firebaseUser.fcm_token, (user) => {
      setCurrentUser(user);
    });
  }, [firebaseUser, socket]);
  
  useEffect(() => {
    if (firebaseUser) {
      getUserData();
    }
  }, [firebaseUser, socket, getUserData]);

  const {
    notificationsEnabled,
    notificationOverride,
    setNotificationOverride,
    requestNotificationsPermission,
  } = useNotifications();

  return {
    adminSocketEventProps,
    error,
    setError,
    userData,
    sharedAppStates,
    adminAppStates,
    appSocketEvents,
    handleSelectedCodeRef,
    selectedCodeRef,
    notifications: {
      notificationsEnabled,
      notificationOverride,
      setNotificationOverride,
      requestNotificationsPermission,
    },
    socket,
    supplyBillCode
  }
}

export type ApplicationStatesContextType = ReturnType<typeof GetInitialContextValues>;

export function ApplicationStatesProvider({ children }) {
  const AppStates = GetInitialContextValues();

  return (
    <ApplicationStatesContext.Provider
      value={AppStates}
    >
      <ErrorBoundary beforeCapture={(scope) => scope.setContext("Shared App State", AppStates)}>  
        <ErrorModal error={AppStates.error} setError={AppStates.setError} />
        {children}
      </ErrorBoundary>
    </ApplicationStatesContext.Provider>
  );
}

/////////////////////////////////////////////////////////////////////////////////////////////////
// Context Hook
/////////////////////////////////////////////////////////////////////////////////////////////////
// Declare DataStoreContext as a context component
export const ApplicationStatesContext = createContext<ApplicationStatesContextType>({} as ApplicationStatesContextType);

// Hook for app wide access to all states stored in hooks
export default function useApplicationStates() {
  return useContext(ApplicationStatesContext);
}