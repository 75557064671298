import {
  faBusinessTime,
  faChartBar,
  faClipboardList,
  faComments,
  faPersonBooth,
  faPhone,
  faServer,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { createBrowserRouter } from "react-router-dom";
import * as view from "src/views";
import type { FirebaseUser } from "src/services/firebase/useFirebase";
//!! Order of paths effect display in navbar
/////////////////////////////////////////////////////////////////////////////////////////////////
// USER PATHS
/////////////////////////////////////////////////////////////////////////////////////////////////
export const userPaths = [
  {
    path: "/user/contacts",
    element: <view.Contacts />,
    title: "Contacts",
    icon: faUsers,
  },
  {
    path: "/user/messages",
    element: <view.Messages />,
    title: "Messages",
    icon: faComments,
  },
  {
    path: "/user/rooms",
    element: <view.Rooms />,
    title: "Rooms",
    icon: faPersonBooth,
  },
  {
    path: "/user/stats",
    element: <view.UserStats />,
    title: "Stats",
    icon: faChartBar,
  },
  {
    path: "/user/settings",
    element: <view.Settings />,
    displayInNav: false,
  },
] as const;

/////////////////////////////////////////////////////////////////////////////////////////////////
// MANAGER PATHS
/////////////////////////////////////////////////////////////////////////////////////////////////
export const managerPaths = [
  {
    path: "/manager/users",
    element: <view.ManagerUsers />,
    title: "Users",
    icon: faUser,
  },
] as const;

/////////////////////////////////////////////////////////////////////////////////////////////////
// ADMIN PATHS
/////////////////////////////////////////////////////////////////////////////////////////////////
export const adminPaths = [
  {
    path: "/admin/stats",
    element: <view.AdminStats />,
    title: "Stats",
    icon: faChartBar,
  },
  {
    path: "/admin/companies",
    element: <view.AdminCompanies />,
    title: "Companies",
    icon: faBusinessTime,
  },
  {
    path: "/admin/codes",
    element: <view.AdminCodes />,
    title: "Billing Codes",
    icon: faServer,
  },
  {
    path: "/admin/users",
    element: <view.AdminUsers />,
    title: "Users",
    icon: faUser,
  },
  {
    path: "/admin/dids",
    element: <view.AdminDIDs />,
    title: "DIDs",
    icon: faPhone,
  },
  {
    path: "/admin/logs",
    element: <view.AdminLogs />,
    title: "Logs",
    icon: faClipboardList,
  }, 
] as const;

/////////////////////////////////////////////////////////////////////////////////////////////////
// SHARED PATHS
/////////////////////////////////////////////////////////////////////////////////////////////////
const sharedPaths = [
  {
    path: "/",
    element: <view.Login />,
  },
  {
    path: "/auth/action/*",
    element: <view.Login />,
  },
  {
    path: "*",
    element: <view.Error404 />,
  },
] as const;

/////////////////////////////////////////////////////////////////////////////////////////////////
// APP PATHS AND DEFAULTS
/////////////////////////////////////////////////////////////////////////////////////////////////
export const routesByRole = {
  admin: [...userPaths, ...adminPaths],
  manager: [...userPaths, ...managerPaths],
  user: userPaths,
} as const;

type Role = keyof typeof routesByRole
type RoleRoute = typeof routesByRole[Role];

const defaultRedirects = {
  admin: "user/messages",
  manager: "user/messages",
  user: "user/messages",
} as const;

export type DefaultRedirects = typeof defaultRedirects;

export function routes(firebaseUser: FirebaseUser | false | null) {
  const authorizedRoutes: RoleRoute = firebaseUser ? routesByRole[firebaseUser.role] : [];

  return createBrowserRouter([
    // General App Routes
    {
      path: "/",
      element: <view.RouteLayoutSwitch firebaseUser={firebaseUser} defaultRedirects={defaultRedirects} />,
      children: [ ...authorizedRoutes, ...sharedPaths],
    }
  ]);
}