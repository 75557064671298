import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Form, Button } from "react-bootstrap";

import styles from "../Settings.module.scss";

export default function SettingsForm({ children }) {
  return (
    <Form
      className={`${styles.settings_form} mt-3 mx-auto d-flex flex-column flex-grow-1`}>
      {children}
    </Form>
  );
}

function SettingSectionHeader({ headerText }) {
  return (
    <div className={`px-3`}>
      <h2>{headerText}</h2>
      <hr />
    </div>
  );
}

function SettingGroup({ setting, children }) {
  return (
    <Form.Group className="mb-3 d-flex justify-content-between align-items-center">
      <div>
        <h3>{setting.header}</h3>
        <p className="text-break">{setting.subtext}</p>
      </div>
      <div className="ms-2">{children}</div>
    </Form.Group>
  );
}

function Switch({ setting }) {
  return (
    <Form.Group key={setting.id} className="d-flex">
      <Form.Label className="me-2">Off</Form.Label>
      <div className="px-2">
        <Form.Check
          className={styles.setting_switch}
          type="switch"
          id={setting.id}
          onChange={setting.handleFunc}
          checked={setting.checked}
        />
      </div>
      <Form.Label>On</Form.Label>
    </Form.Group>
  );
}

function ButtonInput({ setting }) {
  return (
    <Button
      key={setting.buttonName}
      className={`${styles.settings_btn}`}
      onClick={setting.handleFunc}>
      {setting.buttonName}
    </Button>
  );
}

function SensitiveInfo({ sensitiveText }) {
  const [visible, setVisible] = useState(false);

  let modifiedText = "";

  if (visible) {
    modifiedText = sensitiveText;
  } else if (sensitiveText) {
    modifiedText = sensitiveText.replace(/./g, "•");
  }

  return (
    <div
      className={`${styles.sensitive_info_container} d-flex align-items-center`}>
      <p>{sensitiveText ? modifiedText : "No key"}</p>
      {modifiedText ? (
        <Button
          className={`${styles.sensitive_info_btn} ms-2`}
          onClick={() => setVisible((prev) => !prev)}>
          <FontAwesomeIcon
            icon={visible ? faEyeSlash : faEye}
            color={"black"}
          />
        </Button>
      ) : null}
    </div>
  );
}

/* function AvailableDIDNumbers({ setting }) {

} */

function ApiKey({ setting }) {
  return (
    <Form.Group key={setting.id} className={`d-flex my-2`}>
      {/*       <Form.Control
        //className={`position-relative`}
        type={setting.visibilityState ? "input" : "password"}
        value={setting.apiKey}
        onChange={(e) => setting.setKey(e.target.value)}
        id={setting.id}
        onChange={setting.handleFunc}
        checked={setting.checked}
      /> */}

      <Button
        className={`ms-2`}
        /* onClick={() => setting.onSubmit({ pushover_user_key: setting.apiKey })} */
      >
        {setting.buttonName}
      </Button>
    </Form.Group>
  );
}

SettingsForm.Switch = Switch;
SettingsForm.ApiKey = ApiKey;
SettingsForm.ButtonInput = ButtonInput;
SettingsForm.SettingSectionHeader = SettingSectionHeader;
SettingsForm.SettingGroup = SettingGroup;
SettingsForm.SensitiveInfo = SensitiveInfo;
