import { useEffect, useState } from "react";
import { useApplicationStates } from "src/contexts";
import styles from "./NewCustomerCounter.module.scss";

export default function NewCustomerCounter() {
  const { sharedAppStates } = useApplicationStates();
  const { unattendedCustomers } = sharedAppStates;
  const [showUnattendedCounter, setShowUnattendedCounter] = useState(false);

  useEffect(() => {
    if (unattendedCustomers && unattendedCustomers.length) {
      setShowUnattendedCounter(true);
    }
  }, [unattendedCustomers, setShowUnattendedCounter]);

  if (!showUnattendedCounter) return null;
  return <div className={`mx-1 ${styles.new_customer_counter}`}>{unattendedCustomers}</div>;
}
