import {
  faAlignLeft,
  faCheckSquare,
  faDisease,
  faFolderPlus,
  faMoneyBill,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import MobileViewSelect from "src/common/Mobile/MobileViewSelect";
import { convertDateFormat } from "src/helpers/helpers";
import { nanoid } from "nanoid";
export default function SelectedDID(props) {
  const selectedDIDBtns = [
    {
      btnText: "Enable/Disable",
      btnIcon: faDisease,
      btnOnclick: () => console.error("This button has not had it's onClick defined"),
      btnDisabled: /* foundRooms.length > 1 ? true :  */ false,
    },
    /*     {
      btnText: "View Rooms",
      btnIcon: faPersonBooth,
      btnOnclick: () => setSelectedView("room-select"),
      btnDisabled: foundRooms.length === 0 ? true : false,
    }, */
  ];

  return (
    <MobileViewSelect.SelectedRecordView
      handleResetView={props.handleResetView}
      recordTitle={props.recordTitle}
      selectedRecord={props.selectedRecord}
      viewButtons={[<MobileViewSelect.EditButton key={nanoid()} onClick={() => props.setSelectedView("edit-did")} />]}
    >
      <MobileViewSelect.ButtonBar>{selectedDIDBtns}</MobileViewSelect.ButtonBar>
      <div className="overflow-auto mb-4" style={{ paddingBottom: "4rem" }}>
        <div>
          <MobileViewSelect.InfoTileLayout icon={faMoneyBill}>
            <div className="d-flex justify-content-between">
              <div>
                <div>Billing Code:</div>
                <div>
                  {props.selectedRecord.did_billing_code ? props.selectedRecord.did_billing_code : "Unassigned"}
                </div>
              </div>
            </div>
          </MobileViewSelect.InfoTileLayout>
          <MobileViewSelect.InfoTileLayout icon={faAlignLeft}>
            <div className="d-flex justify-content-between">
              <div>
                <div>Description:</div>
                <div>{props.selectedRecord.did_description}</div>
              </div>
              <div className="text-end">{convertDateFormat(props.selectedRecord.customer_created_at)}</div>
            </div>
          </MobileViewSelect.InfoTileLayout>
          <MobileViewSelect.InfoTileLayout
            icon={props.selectedRecord.deliver_to_phonesystem ? faCheckSquare : faTimesCircle}
          >
            <div className="d-flex justify-content-between">
              <div>
                <div>Delivering To Phone System:</div>
                <div>{props.selectedRecord.deliver_to_phonesystem ? "Enabled" : "Disabled"}</div>
              </div>
            </div>
          </MobileViewSelect.InfoTileLayout>
          <MobileViewSelect.InfoTileLayout icon={faFolderPlus}>
            <div className="d-flex justify-content-between">
              <div>
                <div>Created At:</div>
                <div>{convertDateFormat(props.selectedRecord.didnum_created_at)}</div>
              </div>
            </div>
          </MobileViewSelect.InfoTileLayout>
        </div>
      </div>
    </MobileViewSelect.SelectedRecordView>
  );
}
