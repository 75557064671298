import { useEffect } from "react";
import { useApplicationStates } from "src/contexts";
import { Button } from "react-bootstrap";
import styles from "../RoomManagement.module.scss";
import { useRoomHandlers } from "src/hooks";

export default function JoinRoomConfirmation({ selectedRoom, roomFormData, setTitle, setSelectedView }) {
  const { userData } = useApplicationStates();
  const { currentUser } = userData;
  const { joinUsersToRoom } = useRoomHandlers();

  useEffect(() => {
    let title = "Confirm action: Join Room";

    if (roomFormData.submitType === "CREATE_NEW_ROOM") title = "Confirm New Room Details:";
    if (roomFormData.submitType === "ADD_USERS") title = "Confirm Updated Room Details:";
    setTitle(title);
  }, [setTitle, roomFormData.submitType]);

  function handleJoinRoom() {
    joinUsersToRoom(selectedRoom.room_id, [currentUser.user_id]);
    setSelectedView(null);
  }

  return (
    <div className="d-flex flex-column flex-grow-1 flex-shrink-1">
      <p className="mb-2">Are you sure you want to join this room? </p>

      <div className={`${styles.button_wrapper}`}>
        <Button className="me-2" onClick={() => setSelectedView(null)}>
          Cancel
        </Button>
        <Button className="ms-2" onClick={handleJoinRoom}>
          Yes
        </Button>
      </div>
    </div>
  );
}
