// Libraries
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
// Hooks
import { useApplicationStates } from "src/contexts";
// Components
import Modal from "src/common/Modal/Modal";
import { Form } from "src/common";
// Assets
import styles from "./NavigationBar.module.scss";
import { useLocation } from "react-router-dom";

export default function NavbarBillCodeSelect({ firebaseUser }) {
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // DATASTORE/HOOKS
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const { adminAppStates } = useApplicationStates();
  const { adminCodes, selectedAdminBillCode, setSelectedAdminBillCode } = adminAppStates;
  const { pathname } = useLocation();
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // STATES
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const [selectActive, setSelectActive] = useState(false);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // CONSTANTS
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const formLogic = {
    validationSchema: yup.object({
      billing_code: yup.string().default("").required(),
    }),
    fields: new Map([
      [
        "billing_code",
        {
          selectValues: adminCodes.map((code) => code.billing_code),
          component: (props, key) => <Form.FormSelect {...props} key={key} />,
        },
      ],
    ]),
  };

  const modalChildren = {
    title: "Change current billing code:",
    children: (
      <Form
        formLogic={formLogic}
        selectedRecord={{ billing_code: selectedAdminBillCode }}
        onSubmit={(val) => {
          setSelectedAdminBillCode(val.billing_code);
          sessionStorage.setItem("last_set_billcode", val.billing_code);
          setSelectActive(false);
        }}
      />
    ),
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // RENDER (Return component only if conditions are met)
  /////////////////////////////////////////////////////////////////////////////////////////////////
  if (!pathname.includes("admin") && firebaseUser.role === "admin" || (firebaseUser.role === "dev" && selectedAdminBillCode)) {
    return (
      <>
        <Modal
          show={selectActive}
          onHide={() => setSelectActive(null)}
          onExited={() => setSelectActive(null)}
          {...modalChildren}
        />
        <div
          role="button"
          className={`${styles.nav_bill_code_select} d-flex flex-wrap justify-content-center align-items-center me-4`}
          onClick={() => setSelectActive("billcode-select")}
        >
          <FontAwesomeIcon icon={faMoneyBill} />
          <p>
            Bill Code:
            <b className={`ps-2`}>{selectedAdminBillCode}</b>
          </p>
        </div>
      </>
    );
  }

  return null;
}
