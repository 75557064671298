// Libraries
import React, { useState, useEffect, useRef } from "react";
import { Offcanvas, Accordion } from "react-bootstrap";
// Components
import useWindowDimensions, { BSBreakpoints } from "src/hooks/useWindowDimensions";
// Styles
import styles from "../../Messages.module.scss";
import { useApplicationStates, useAuth } from "src/contexts";
import { RoomTile } from "src/common/commonViews.index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { sortByDate } from "src/helpers";
import { useRoomHandlers } from "src/hooks";

export default function SelectionPane({ handleChangeRoom, showContacts, selectedRoom, setShowContacts }) {
  const { userPermissionLevel } = useAuth();
  const { userData } = useApplicationStates();
  const { currentUser, contactPanePreferences, setContactPanePreferences } = userData;
  const { displayableRooms } = useRoomHandlers();
  const [roomsToDisplay, setRoomsToDisplay] = useState(displayableRooms);
  const [roomsPaneOpen, setRoomsPaneOpen] = useState(contactPanePreferences.roomsPaneOpen);
  const [personalRoomPaneOpen, setPersonalRoomPane] = useState(contactPanePreferences.personalRoomPaneOpen);
  const { width } = useWindowDimensions();

  const selectedRoomTileRef = useRef(null);
  /////////////////////////////////////////////////////////////////////////////////////////////////
  //useEffects
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Uses memoized value for rooms that are neither archived or disabled.
  useEffect(() => {
    if(displayableRooms) setRoomsToDisplay(displayableRooms);
  }, [displayableRooms]);

  // Open or close the accordions depending on which position it was left in.
  useEffect(() => {
    setContactPanePreferences((prev) => ({
      ...prev,
      roomsPaneOpen,
      personalRoomPaneOpen,
    }));
  }, [roomsPaneOpen, personalRoomPaneOpen, setContactPanePreferences]);

  useEffect(() => {
    const ref = selectedRoomTileRef.current;
    if (ref) {
      const bounding = ref.getBoundingClientRect();
      ref.scrollIntoView(false);
      if (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <= window.innerWidth &&
        bounding.bottom <= window.innerHeight
      ) {
        console.log("Element is in the viewport!");
      } else {
        console.log("Element is NOT in the viewport!");
      }
    }
  }, [selectedRoom]);

  const roomTile = (room) => (
    <RoomTile
      ref={selectedRoom && room.room_id === selectedRoom.room_id ? selectedRoomTileRef : null}
      key={room.room_id}
      room={room}
      onClick={() => handleChangeRoom(room)}
      active={selectedRoom && room.room_id === selectedRoom.room_id}
      className={"px-2 py-3"}
    />
  );

  const elevatedUserPermission = userPermissionLevel("mid");
  // LIST FILTERING
  const personalRooms = elevatedUserPermission
    ? roomsToDisplay.filter(
        (room) => room.room_users.some((user) => user.user_id === currentUser.user_id),
        "room_updated_at"
      )
    : roomsToDisplay;

  const sortedPersonalRoomTiles = sortByDate(personalRooms, "last_message_sent_at").map((room) => roomTile(room));

  const otherRooms = elevatedUserPermission
    ? roomsToDisplay.filter(
        (room) => !room.room_users.some((user) => user.user_id === currentUser.user_id),
        "room_updated_at"
      )
    : [];

  const sortedOtherRooms = sortByDate(otherRooms, "last_message_sent_at").map((room) => roomTile(room));
  
  // JSX ASSIGNMENTS 
  const accordion = (
    <Accordion className={`${styles.contacts_accordion} h-100 d-flex flex-column overflow-auto `}>
      <Accordion.Item
        className={`${styles.accordion_item} d-flex flex-column flex-grow-${personalRoomPaneOpen ? 1 : 0}`}
      >
        <div
          className={`${styles.accordion_header} d-flex align-items-center shadow-sm`}
          onClick={() => setPersonalRoomPane((prev) => !prev)}
        >
          <FontAwesomeIcon className={`mx-3`} icon={personalRoomPaneOpen ? faChevronDown : faChevronRight} />
          {elevatedUserPermission ? "Personal Rooms" : "Rooms"}
        </div>
        <div className={`${styles.overflow_container}  overflow-auto`}>
          {!sortedPersonalRoomTiles.length ? noRecordsDisplay : sortedPersonalRoomTiles}
        </div>
      </Accordion.Item>
      {elevatedUserPermission && (
        <Accordion.Item className={`${styles.accordion_item} d-flex flex-column flex-grow-${roomsPaneOpen ? 1 : 0}`}>
          <div
            className={`${styles.accordion_header} d-flex align-items-center shadow-sm`}
            onClick={() => setRoomsPaneOpen((prev) => !prev)}
          >
            <FontAwesomeIcon className={`mx-3`} icon={roomsPaneOpen ? faChevronDown : faChevronRight} />
            {"All other rooms"}
          </div>
          <div className={`${styles.overflow_container}  overflow-auto`}>
            {!otherRooms.length ? noRecordsDisplay : sortedOtherRooms}
          </div>
        </Accordion.Item>
      )}
    </Accordion>
  );

  const offCanvasWrapper = (children) => (
    <Offcanvas
      className={`${styles.contacts_offcanvas} h-100 bg-white `}
      show={showContacts}
      onHide={() => setShowContacts(false)}
    >
      <div
        className={`${styles.offcanvas_header} w-100 d-flex justify-content-end align-items-center position-absolute`}
      >
        <div
          onClick={() => setShowContacts(false)}
          className={`${styles.overlay_close_button_wrapper} h-100 d-flex align-items-center justify-content-center p-4`}
        >
          <FontAwesomeIcon className={`${styles.overlay_close_button}`} icon={faTimes} size="lg" />
        </div>
      </div>
      <Offcanvas.Body className={`h-100 d-flex flex-column p-0`}>{children}</Offcanvas.Body>
    </Offcanvas>
  );

  return width <= BSBreakpoints.md ? offCanvasWrapper(accordion) : accordion;
}

const noRecordsDisplay = (
  <p className="h-100 d-flex flex-column justify-content-center text-center">{`No rooms to display`}</p>
);
