import { firebaseAuth } from "./index";
import { applyActionCode } from "firebase/auth";

export async function verifyEmailWithFirebase(actionCode, uid) {
  try {
    //console.log(firebase.auth());
    await applyActionCode(firebaseAuth, actionCode);
    // Send POST Request to DB to update users email_verified flag
    await fetch(`${import.meta.env.VITE_BACKEND_URL}/${import.meta.env.VITE_BACKEND_AUTH_PATH}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "auth-type": "verify-email",
      },
      body: JSON.stringify({ uid: uid }),
    });
  } catch (err) {
    console.error(err);
  }
}
