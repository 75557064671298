import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { snakeToTitleCase } from "src/helpers/formattingHelpers";
import { LAYOUTS } from "src/constants/layout";

export default function ReadRecord({ record, recordMap, stripKeyString, buttonActions, layout }) {
  function mapRecordFields(children) {
    return [...recordMap.fields].map(([key, val]) => {
      const name = stripKeyString ? key.replace(stripKeyString, "") : key;

      return children(key, val, name);
    });
  }

  const mobileLayout = (
    <div className="mx-3">
      {/* <div className="mb-4"> */}
      {mapRecordFields((key, val, name) => (
        <div className="*row mb-3 my-3 d-flex align-items-center" key={name}>
          <div className="me-3">
            <FontAwesomeIcon icon={val.icon} size="xl" />
          </div>
          <div className="flex-grow-1">
            <div className="mb-1">{snakeToTitleCase(name)}</div>
            <div className="*col border-bottom px-0 *mx-2" style={{ minHeight: "25px" }}>
              {val.formatter ? val.formatter(record[key]) : record[key]}
            </div>
          </div>
        </div>
      ))}
      {/*  </div> */}
      <div className="d-flex justify-content-between">
        {buttonActions &&
          buttonActions.map((btn) => (
            <Button key={btn.text} onClick={btn.onClick}>
              {btn.text}
            </Button>
          ))}
      </div>
    </div>
  );

  const desktopLayout = (
    <div className="container">
      <div className="mb-4">
        {mapRecordFields((key, val, name) => (
          <div className="row my-3" key={name}>
            <div
              className="
                d-flex justify-content-end
                col-2
                "
            >
              <span>{snakeToTitleCase(name)}</span>
              <span className="ms-3">
                <FontAwesomeIcon icon={val.icon} />
              </span>
            </div>
            <span className="col border-bottom px-0 mx-2">
              {val.formatter ? val.formatter(record[key]) : record[key]}
            </span>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-between">
        {buttonActions &&
          buttonActions.map((btn) => (
            <Button key={btn.text} onClick={btn.onClick}>
              {btn.text}
            </Button>
          ))}
      </div>
    </div>
  );

  const layoutComponent = {
    [LAYOUTS.DESKTOP]: desktopLayout,
    [LAYOUTS.MOBILE]: mobileLayout,
  };

  return layoutComponent[layout];
}
