import MobileViewSelect from "src/common/Mobile/MobileViewSelect";
import { ContactTile } from "src/common/commonViews.index";
import { sortAlphabetically } from "src/helpers/formattingHelpers";

export default function AllUsers(props) {
  const formattedData =
    props.data.length > 0 ? sortAlphabetically(props.data, "last_name") : [];

  return (
    <>
      <MobileViewSelect.MobileSearchBar {...props} />
      <MobileViewSelect.GroupByLetterContainer
        data={formattedData}
        groupKey={"last_name"}
      >
        {(user) => (
          <ContactTile
            name={`${user.first_name} ${user.last_name}`}
            onClick={() => props.handleSelectRecord(user)}
          />
        )}
      </MobileViewSelect.GroupByLetterContainer>
      <MobileViewSelect.NewButton
        onClick={() => props.setSelectedView("add-user")}
      />
    </>
  );
}
