import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook, faUserPlus, faCommentAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import useWindowDimensions, { BSBreakpoints } from "src/hooks/useWindowDimensions";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useApplicationStates } from "src/contexts";

import styles from "./MessageTitleBar.module.scss";
import { combineFirstAndLastName } from "src/helpers/formattingHelpers";
import React, { Dispatch } from "react";
import { RoomInstanceWithMembers } from "@tscontrollers/rooms.controller";

type MessageTitleBarProps = {
  selectedView: string | null, //This is state it may need a different type
  selectedRoom: RoomInstanceWithMembers | null, //This is state it may need a different type
  setShowContacts: Dispatch<React.SetStateAction<boolean>>, //Change this to appropriate state setter type later
  setSelectedView: Dispatch<React.SetStateAction<string | null>> //Change this to appropriate state setter type later
}

// TODO: This entire thing needs to be redone
export default function MessageTitleBar({ selectedView, selectedRoom, setShowContacts, setSelectedView } : MessageTitleBarProps) {
  const { width } = useWindowDimensions();
  const { userData } = useApplicationStates();
  const { currentUser } = userData;

  function getRoomDIDDescription() {
    if(!selectedRoom) {
      return;
    }
    const foundDID = currentUser.user_dids.find((did) => did.did_number === selectedRoom.room_did_number);
    return foundDID ? <p>{` via ${foundDID.did_description}`}</p> : null;
  }

  function roomNames() {
    function nameLengthSwitch(memberArray, usingFilter) {
      const filteredMemberArray = usingFilter
        ? memberArray.filter((member) => member.user_id !== currentUser.user_id)
        : memberArray;

      if (filteredMemberArray?.length === 1) {
        return combineFirstAndLastName(memberArray[0]);
      } else {
        return filteredMemberArray.map((member) => member.first_name).join(", ");
      }
    }

    let names = "";

    if (selectedRoom) {
      // If no customers are found with room then display user names
      if (selectedRoom.room_customers?.length === 0) {
        names = nameLengthSwitch(selectedRoom.room_users, true);
      } else if(selectedRoom.room_customers) {
        names = nameLengthSwitch(selectedRoom.room_customers, false);
      }

      return names;
    } else {
      return null;
    }
  }

  return (
    <div
      className={`
        ${styles.message_header_bar}
        w-100 py-0 d-flex align-items-center
        `}
    >
      {/* If width is <= 764px display message button to activate contacts view*/}
      {width <= BSBreakpoints.md ? (
        <Button
          className={`${styles.room_button} ms-2 text-wrap `}
          variant="primary"
          onClick={() => setShowContacts(true)}
        >
          {/* If width is < 576px change text to icon */}
          {width < BSBreakpoints.sm ? <FontAwesomeIcon icon={faAddressBook} /> : "Contacts"}
        </Button>
      ) : null}

      <div className={"flex-grow-1 flex-nowrap px-2 text-center line-clamp-1"} style={{ minWidth: 0 }}>
        <div className="d-flex justify-content-center align-items-center">
          {roomNames()}
          <span className={` align-self-center ${width >= BSBreakpoints.sm ? "ps-2" : ""} fs-5`}>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id={`tooltip-bototm`}>{selectedRoom && selectedRoom.room_did_number}</Tooltip>}
            >
              <div>{selectedRoom && getRoomDIDDescription()}</div>
              {/*   */}
            </OverlayTrigger>
          </span>
        </div>
      </div>

      <Button
        className={`${styles.add_user_btn_wrapper} h-100 px-4 d-flex align-items-center`}
        onClick={() => (selectedView ? setSelectedView(null) : setSelectedView("CREATE_OR_MANAGE_ROOM"))}
        title="Create A Room"
      >
        <FontAwesomeIcon icon={!selectedRoom ? faCommentAlt : faUserPlus} />
        {!selectedRoom && <FontAwesomeIcon icon={faPlus} />}
      </Button>
    </div>
  );
}
