import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Modal, RoomInfo } from "src/common";
import { useApplicationStates } from "src/contexts";
import {
  SelectDIDNumber,
  SelectParticipants,
  ManageParticipants,
  ExistingRoomFound,
  OccupiedRoomFound,
  ActionConfirmation,
  LeaveRoomConfirmation,
  JoinRoomConfirmation,
} from "./index";

import styles from "./RoomManagement.module.scss";
import { usersAttributes } from "@tsmodels/users";

type SelectedRoomMembers = { 
  customer_ids: number[], 
  user_ids: string[]
}

type RoomFormInitValues = {
  room_did_number: string | null,
  selected_room_members: SelectedRoomMembers
}

const initialValues: RoomFormInitValues = {
  room_did_number: null,
  selected_room_members: {
    customer_ids: [],
    user_ids: [],
  },
};

const modalTitle = "Create or Manage Room";

/**
 * @typedef {Object} RoomManagementType
 * @property {Object} selectedRoom - The selected room in Messages.
 * @property {String} selectedView - The selected view in Messages.
 * @property {Function} setSelectedView - The selected view state setter in Messages.
 */

/**
 * Wrapper for multi-stage forms used to manage rooms from the Messages View.
 * @param {RoomManagementType} props - An object containing all of the components props
 * @param  props.selectedRoom - The currently selected room in the parent component
 * @param  props.selectedView - The currently selected view in the parent component
 * @param  props.setSelectedView - The selectedView state setter in the parent component
 * @returns Modal with mapped form component
 */

export default function RoomManagement({ selectedRoom, setSelectedRoom, selectedView, setSelectedView }) {
  const { sharedAppStates, userData } = useApplicationStates();
  const { currentMediaBreakpoint } = sharedAppStates;
  const [formStage, setFormStage] = useState<string | null>(null);
  const [title, setTitle] = useState(modalTitle);
  const [roomFormData, setRoomFormData] = useState(initialValues);

  useEffect(() => {
    if (selectedRoom && !formStage) {
      setRoomFormData((prev) => ({
        ...prev,
        room_did_number: selectedRoom.room_did_number,
        selected_room_members: {
          customer_ids: selectedRoom.room_customers.map(({ customer_id }) => customer_id.toString()),
          user_ids: selectedRoom.room_users.map(({ user_id }) => user_id),
          //.filter(({ user_id }) => user_id !== userData.currentUser.user_id),
        },
      }));
    }
  }, [formStage, selectedRoom /* , userData?.currentUser?.user_id */]);

  // If no room is selected and no formStage was triggered by other events, default to creating new room
  if (!selectedRoom && !formStage) {
    setFormStage("SELECT_DID_NUMBER");
  }

  // If a room is selected and has customers but no room_did_number, change the view to SELECT_DID_NUMBER if it is not
  // already
  if (
    formStage !== "SELECT_DID_NUMBER" &&
    selectedRoom &&
    selectedRoom.room_customers.length &&
    !selectedRoom.room_did_number
  ) {
    setFormStage("SELECT_DID_NUMBER");
  }

  let wrapper;

  // If mobile layout return content without modal wrapper
  if (currentMediaBreakpoint === "MOBILE") {
    wrapper = (children) => (
      <div className={"h-100 d-flex flex-column p-2 overflow-auto"} style={{ flex: "1 1 1px" }}>
        <h3 className="text-center mb-3 border-bottom pb-1">{title}</h3>
        {children}
      </div>
    );
  } else {
    wrapper = (children) => (
      <Modal show={selectedView} title={title} onExited={() => setSelectedView(null)} size="md">
        {children}
      </Modal>
    );
  }

  const sharedProps = {
    roomFormData,
    selectedRoom,
    setTitle,
    setFormStage,
    setRoomFormData,
    setSelectedView,
    setSelectedRoom,
  } as const;


  const map = {
    SELECT_DID_NUMBER: <SelectDIDNumber {...sharedProps} />,
    SELECT_ROOM_MEMBERS: <SelectParticipants {...sharedProps} />,
    EXISTING_ROOM_FOUND: <ExistingRoomFound {...sharedProps} />,
    OCCUPIED_ROOM_FOUND: <OccupiedRoomFound {...sharedProps} />,
    MANAGE_ROOM_MEMBERS: <ManageParticipants {...sharedProps} />,
    CONFIRM_ROOM_ACTION: <ActionConfirmation {...sharedProps} />,
    CONFIRM_LEAVE_ROOM_ACTION: <LeaveRoomConfirmation {...sharedProps} />, // TODO: Consolidate with join room later
    CONFIRM_JOIN_ROOM_ACTION: <JoinRoomConfirmation {...sharedProps} />,
  } as const;

  const joinLeaveBtn = () => {
    function isUserPresent(usersInRoom: usersAttributes[]) { 
      const { currentUser } = userData;
      if(!currentUser) return false;
      return usersInRoom.some(({ user_id }) => currentUser.user_id === user_id)
    }
    const userPresent = isUserPresent(selectedRoom.room_users);
    const btnText = userPresent ? "Leave Room" : "Join Room";
    const btnAction = userPresent ? "CONFIRM_LEAVE_ROOM_ACTION" : "CONFIRM_JOIN_ROOM_ACTION";
    return (
      <Button className="ms-2" onClick={() => setFormStage(btnAction)}>
        {btnText}
      </Button>
    );
  };

  return (
    <>
      {/* If a room is selected but no formStage has been triggered show information about the room */}
      {selectedRoom &&
        !formStage &&
        wrapper(
          <>
            <RoomInfo selectedRoom={selectedRoom} />
            <div className={`${styles.button_wrapper}`}>
              <Button
                className="me-2"
                onClick={() => {
                  const { currentUser } = userData;
                  if(!currentUser) return;
                  // navigate("/user/messages"); //TODO: Do we want to clear the room on this?
                  // setSelectedRoom(null);
                  setRoomFormData({
                    ...initialValues,
                    selected_room_members: {
                      ...initialValues.selected_room_members,
                      user_ids: [currentUser.user_id],
                    },
                  });
                  setFormStage("SELECT_DID_NUMBER");
                }}
              >
                New Room
              </Button>
              <Button className="mx-2" onClick={() => setFormStage("MANAGE_ROOM_MEMBERS")}>
                Manage Room
              </Button>
              {joinLeaveBtn()}
            </div>
          </>
        )}
      {formStage && wrapper(map[formStage])}
    </>
  );
}
