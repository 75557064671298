import { useApplicationStates } from "src/contexts";

export default function useFormatNames() {
  const { sharedAppStates, userData } = useApplicationStates();
  const { customersList, usersInCode } = sharedAppStates;
  const { currentUser } = userData;

  function formatNamesByCustomerIds(customerIds, options) {
    return customerIds.map((id) => {
      const customer = customersList.find(({ customer_id }) => customer_id === id);

      if (!customer) return "Unknown Customer";

      switch (true) {
        case options?.firstNameOnly:
          return `${customer.first_name}`;
        case options?.lastNameOnly:
          return `${customer.last_name}`;
        default:
          return `${customer.first_name} ${customer.last_name}`;
      }
    });
  }

  function formatNamesByUserIds(user_ids, options) {
    console.log(user_ids);
    return user_ids.map((id) => {
      const isCurrentUser = currentUser?.user_id === id;
      const user = isCurrentUser ? currentUser : usersInCode.find(({ user_id }) => user_id === id);

      if (!user) return "Unknown User";

      switch (true) {
        case options?.firstNameOnly:
          return `${user.first_name}`;
        case options?.lastNameOnly:
          return `${user.last_name}`;
        case options?.replaceCurrentUserWithYou && isCurrentUser:
          return "You";
        default:
          return `${user.first_name} ${user.last_name}`;
      }
    });
  }

  return {
    formatNamesByUserIds,
    formatNamesByCustomerIds,
  };
}
