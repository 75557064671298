import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useApplicationStates, useAuth } from "src/contexts";
import sortArrayOfObjects from "src/helpers/sort/sort.helpers";
import { useRoomHandlers } from "src/hooks";
import ParticipantSelectionInput from "./RoomParticipantSelectInput";
import styles from "../RoomManagement.module.scss";

/**
 * @typedef {Object} ManageRoomMembersProps
 * @property {Object} field - Object containing the logic for the supplied field.
 * @property {String} fieldKey - Key name of the supplied field from the logic map.
 * @property {ParentPropsTypes} parentProps - An object containing props passed down from the HOC
 */
/**
 * @typedef {Object} ParentPropsTypes
 * @property {Function} setSelectedView - State setter for selected view in Messages.
 */
/**
 * Form and UI for managing room members
 * @param {ManageRoomMembersProps} props - The props object for the component
 * @returns JSX html
 */
export default function ManageParticipants({
  selectedRoom,
  setSelectedRoom,
  setFormStage,
  roomFormData,
  setRoomFormData,
  setTitle,
}) {
  // TODO: Implement user removal for higher level roles
  ////////////////////////////////////////////////////////////////////////////////
  // HOOKS
  ////////////////////////////////////////////////////////////////////////////////
  const { userData } = useApplicationStates();
  const [manageType, setManageType] = useState<null | string>(null);
  const [usersOnly, setUsersOnly] = useState(false);
  const { sharedAppStates } = useApplicationStates();
  const { customersList, usersInCode } = sharedAppStates;
  const { userPermissionLevel } = useAuth();
  const { checkForExistingRoomInLocalState, checkCustomerRoomAvailability } = useRoomHandlers();

  const maxMMSParticipants = 9;
  const isUserRoom = !selectedRoom.room_customers.length;

  useEffect(() => {
    let title = "Manage Room Members";
    if (manageType === "NEW_MMS_ROOM") {
      title = "Add Participants (New MMS)";
    }
    setTitle(title);
  }, [manageType, setTitle]);

  ////////////////////////////////////////////////////////////////////////////////
  // CONSTS
  ////////////////////////////////////////////////////////////////////////////////
  /**
   * @typedef {Object} InitialFormValsType
   * @property {string[]} customer_ids
   * @property {string[]} user_ids
   */
  /**
   * Make copy of the initial members of the room, used to determine wether form next button should be disabled.
     Memoized since these values don't change and is used in useEffect below.
   * @type {InitialFormValsType}
   */

  const initialRoomMembers = (() => ({
    customer_ids: selectedRoom.room_customers.map(({ customer_id }) => customer_id.toString()),
    user_ids: selectedRoom.room_users.map(({ user_id }) => user_id),
  }))();

  const elevatedPermission = userPermissionLevel("mid");

  // TODO: Need to check with Joe about how we are handling cross DID behavior
  /*   useEffect(() => {
    socket.emit("requestGetAvailableUsers", selectedRoom.room_id, (availableUsers) =>
      setAvailableRoomUsers(availableUsers)
    );
  }, [socket, selectedRoom.room_id]); */

  const sortedOptionsCustomers = sortArrayOfObjects(customersList, "first_name").map((member) => ({
    value: member.customer_id.toString(),
    member_type: "customer_ids", // Additional meta property for value filtering
    label: `${member.first_name} ${member.last_name}`,
    isDisabled: initialRoomMembers.customer_ids.includes(member.customer_id.toString()),
  }));
  
  const sortedOptionsUsers = () => {
    // Guarding against a null currentUser
    const { currentUser } = userData;
    if(!currentUser) return [];
    return sortArrayOfObjects([...usersInCode, currentUser], "first_name").map(
      (member) => ({
        
        value: member.user_id.toString(),
        member_type: "user_ids", // Additional meta property for value filtering
        label: `${member.first_name} ${member.last_name}`,
        isDisabled: initialRoomMembers.user_ids.includes(member.user_id) && !elevatedPermission,
      })
    );
  };

  const options = [...sortedOptionsCustomers, ...sortedOptionsUsers()];

  /**
   * Disables the form submission until the requirements are met.
   * @type {Boolean}
   */

  const disabled = (() => {
    const noCustomers = !roomFormData.selected_room_members.customer_ids.length;
    const noUsers = !roomFormData.selected_room_members.user_ids.length;
    const roomIsEmpty = noCustomers && noUsers;
    const HasDIDnoCustomers = selectedRoom.room_did_number && !roomFormData.selected_room_members.customer_ids.length;
    const allCustomersInInit = roomFormData.selected_room_members.customer_ids.every((customerID) => initialRoomMembers.customer_ids.includes(customerID));
    const allUsersInInit = roomFormData.selected_room_members.user_ids.every((userID) => initialRoomMembers.user_ids.includes(userID));
    // If the room is empty or has a DID but no customers, OR the list is exactly the same as before
    return roomIsEmpty || HasDIDnoCustomers || (allCustomersInInit && allUsersInInit);
  })();
    

  const ManageTypeAccept = (
    <>
      {mainText}
      {selectedRoom.room_customers.length >= maxMMSParticipants && disabledWarningText}
      <div className={`${styles.button_wrapper}`}>
        <Button
          className="me-2"
          disabled={selectedRoom.room_customers.length >= maxMMSParticipants || isUserRoom}
          onClick={() => setManageType("NEW_MMS_ROOM")}
        >
          New MMS Room
        </Button>
        <Button
          className="ms-2"
          onClick={() => {
            setManageType(elevatedPermission ? "MANAGE_USERS" : "ADD_USERS_ONLY");
            setUsersOnly(true);
          }}
        >
          {elevatedPermission ? "Manage Users" : "Add Users"}
        </Button>
      </div>
    </>
  );

  function handleNewMMSRoom() {
    const existingRoom = checkForExistingRoomInLocalState({
      customer_ids: roomFormData.selected_room_members.customer_ids,
      room_did_number: roomFormData.room_did_number,
      // Insert current users ID into room_users array to match potential existing room
      user_ids: [],
    });

    if (existingRoom) {
      setSelectedRoom(existingRoom);
      setFormStage("EXISTING_ROOM_FOUND");
      return;
    }

    const { customer_ids } = roomFormData.selected_room_members;
    checkCustomerRoomAvailability({ customer_ids: customer_ids }, ([roomIsAvailable, occupiedRooms]) => {
      if (roomIsAvailable) {
        setFormStage("CONFIRM_ROOM_ACTION");
        setRoomFormData((prev) => ({ ...prev, submitType: "CREATE_NEW_ROOM" }));
      } else {
        setRoomFormData((prev) => ({ ...prev, occupied_rooms: occupiedRooms }));
        setFormStage("OCCUPIED_ROOM_FOUND");
      }
    });
  }

  function handleManageUsers() {
    setFormStage("CONFIRM_ROOM_ACTION");
    setRoomFormData((prev) => ({ ...prev, submitType: "REPLACE_USERS" }));
  }

  function handleAddUsersOnly() {
    setFormStage("CONFIRM_ROOM_ACTION");
    setRoomFormData((prev) => ({ ...prev, submitType: "ADD_USERS" }));
  }

  function handleNextAction() {
    if (manageType === "NEW_MMS_ROOM") {
      handleNewMMSRoom();
    }

    if (manageType === "MANAGE_USERS") {
      handleManageUsers();
    }

    if (manageType === "ADD_USERS_ONLY") {
      handleAddUsersOnly();
    }
  }

  function handleBack() {
    setRoomFormData((prev) => ({ ...prev, selected_room_members: initialRoomMembers }));
    setUsersOnly(false);
    setManageType(null);
  }
  
  type ParticipantInputProps = {
    options: typeof options,
    roomFormData: typeof roomFormData,
    setRoomFormData: typeof setRoomFormData,
    usersOnly: typeof usersOnly,
    disabledValues: null | typeof initialRoomMembers
  }

  const participantInputProps: ParticipantInputProps = {
    options,
    roomFormData,
    setRoomFormData,
    usersOnly,
    disabledValues: null
  };

  if (!elevatedPermission) {
    participantInputProps.disabledValues = initialRoomMembers;
  }

  return (
    <div className="overflow-auto d-flex flex-column flex-grow-1 flex-shrink-1">
      {manageType === null && ManageTypeAccept}
      {manageType && (
        <>
          <ParticipantSelectionInput {...participantInputProps} />
          <div className={`${styles.button_wrapper}`}>
            <Button className="me-2" onClick={handleBack}>
              Back
            </Button>
            <Button className="ms-2" disabled={disabled} onClick={handleNextAction}>
              Next
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

const mainText = (
  <p className="mb-3 p-2" style={{ "whiteSpace": "pre-line" }}>
    {`⚠️ Warning\n Adding any additional customers to a room will create a new MMS group chat. Creating a new MMS room here will retain all the original participants of this room plus any additional ones you select. If you wish to create an entirely new room, please reopen the room management pane and select new room.

        There is a limit of 9 customers to any room, however you are free to add as many users to a room as you wish (provided they have access to the rooms DID number).`}
  </p>
);

const disabledWarningText = <p className="mt-4 p-2 text-muted">* The max number of participants has been reached</p>;
