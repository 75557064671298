import { faAddressCard, faArrowLeft, faPencilAlt, faPlus, faSave, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { ContactCircle, FormConstructor, GroupByLetter } from "../commonViews.index";

export default function MobileViewSelect(props) {
  return props.mobileView(props); //Main Mobile Component
}

function SelectedRecordView(props) {
  function backPageSelector() {
    if (props.selectedView === "edit-customer") {
      props.setSelectedView(null);
    } else {
      props.handleResetView();
    }
  }

  return (
    <div
      className="w-100 h-100 mx-auto position-relative d-flex flex-column flex-grow-1"
      style={{ "maxWidth": "450px" }}
    >
      <div className="w-100">
        {/*   {!props.contactCircle === false ? ( */}
        <div className={` my-5 d-flex flex-column`}>
          <ContactCircle
            size="md"
            className={`  mx-auto`}
            circleNames={props.recordTitle}
            newContact={props.newContact}
          />
        </div>

        {props.selectedRecord ? (
          <>
            <div className={` w-100 d-flex mx-auto`} style={{ maxWidth: "450px" }}>
              <h2 className={`line-clamp-2 px-4 w-100 text-break  text-center`}>{props.recordTitle}</h2>
            </div>
          </>
        ) : null}
        <hr />
      </div>

      {props.children}
      <div className="d-flex justify-content-between mt-auto mx-4 mb-4">
        <Button onClick={backPageSelector}>
          <FontAwesomeIcon icon={faArrowLeft} size={"2x"} />
        </Button>
        {props?.viewButtons?.map((btn) => btn)}
      </div>
    </div>
  );
}

function ButtonBar({ children }) {
  return (
    <div className={` mx-auto mb-3 d-flex justify-content-around`} style={{ maxWidth: "450px" }}>
      {children.map((btn, index) => (
        <Button key={index} className="mx-4" onClick={btn.btnOnclick} style={{ minWidth: "125px" }} disabled={btn.btnDisabled}>
          <div>
            <FontAwesomeIcon icon={btn.btnIcon} />
          </div>
          <div>{btn.btnText}</div>
        </Button>
      ))}
    </div>
  );
}

function GroupByLetterContainer({ data, groupKey, children }) {
  return (
    <div className={`min-height-0 d-flex flex-grow-1`}>
      <div className={`w-100 px-3 py-4 pb-5 overflow-auto`}>
        <div /* className={`${styles.contacts_scroll_container}  mx-auto `}*/>
          <GroupByLetter data={data} groupKey={groupKey}>
            {(listItem) => children(listItem)}
          </GroupByLetter>
        </div>
      </div>
    </div>
  );
}

function NewButton({ onClick }) {
  return (
    <Button onClick={onClick} className={`position-fixed end-0 bottom-0 mb-4 me-4 shadow`} size="md">
      <FontAwesomeIcon icon={faPlus} size="2x" />
    </Button>
  );
}

function EditButton({ onClick }) {
  return (
    <Button onClick={onClick}>
      <FontAwesomeIcon icon={faPencilAlt} size="2x" />
    </Button>
  );
}

function SaveButton({ form_id }) {
  return (
    <Button form={form_id} type="submit">
      <FontAwesomeIcon icon={faSave} size="2x" />
    </Button>
  );
}

function InfoTileLayout({ icon, children }) {
  /* ${styles.add_contact_form_inner_wrapper} */
  return (
    <div className={`mx-auto  border-top border-bottom`}>
      <div className="px-4 d-flex align-items-center py-3">
        <div>
          <FontAwesomeIcon icon={icon} size="2x" />
        </div>
        <div className="ms-4 flex-grow-1">{children}</div>
      </div>
    </div>
  );
}

function MobileFormFieldLayout({ icon, fieldName, selectedView, fieldMap }) {
  function inputGroupSwitch(fieldName) {
    if (/edit*/.test(selectedView)) {
      const label = fieldMap[fieldName].formalName;
      return (
        <FormConstructor.FormGroup label={label}>
          <FormConstructor.TextInput {...fieldMap[fieldName]} />
        </FormConstructor.FormGroup>
      );
    }

    return <FormConstructor.TextInput {...fieldMap[fieldName]} />;
  }

  return (
    <div className="d-flex m-2 mb-4 align-items-center">
      <div className={`d-flex align-items-center justify-content-center me-2`} style={{ minWidth: "25px" }}>
        <FontAwesomeIcon icon={icon} />
      </div>

      <div className="d-flex flex-column flex-grow-1">{inputGroupSwitch(fieldName)}</div>
    </div>
  );
}

function MobileSearchBar({ searchBar, primaryKey, setView }) {
  const switchContactTypeBtn = (
    <div className="ms-2">
      <Button style={{ minWidth: "46px" }} onClick={setView}>
        <FontAwesomeIcon icon={primaryKey === "customer_id" ? faAddressCard : faUser} />
      </Button>
    </div>
  );

  return (
    <div className="d-flex mt-3 mx-2">
      {setView ? switchContactTypeBtn : null}
      <div className="flex-grow-1">{searchBar("shadow-sm")}</div>
    </div>
  );
}

MobileViewSelect.GroupByLetterContainer = GroupByLetterContainer;
MobileViewSelect.SelectedRecordView = SelectedRecordView;
MobileViewSelect.MobileSearchBar = MobileSearchBar;
MobileViewSelect.NewButton = NewButton;
MobileViewSelect.EditButton = EditButton;
MobileViewSelect.SaveButton = SaveButton;
MobileViewSelect.ButtonBar = ButtonBar;
MobileViewSelect.InfoTileLayout = InfoTileLayout;
MobileViewSelect.MobileFormFieldLayout = MobileFormFieldLayout;
