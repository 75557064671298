import { useApplicationStates } from "src/contexts";
import { useRoomHandlers } from "src/hooks";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "../RoomManagement.module.scss";
import { RoomInfo } from "src/common";
import { RoomInstanceWithMembers } from "@tscontrollers/rooms.controller";

export default function ActionConfirmation({ selectedRoom, roomFormData, setTitle, setSelectedView, setSelectedRoom }) {
  useEffect(() => {
    let title = "Confirm action:";

    if (roomFormData.submitType === "CREATE_NEW_ROOM") title = "Confirm New Room Details:";
    if (roomFormData.submitType === "REPLACE_USERS" || roomFormData.submitType === "ADD_USERS")
      title = "Confirm Updated Room Details:";
    setTitle(title);
  }, [setTitle, roomFormData.submitType]);

  const { userData } = useApplicationStates();
  const { createNewRoom, replaceRoomUsers, joinUsersToRoom } = useRoomHandlers();
  const navigate = useNavigate();

  function handleNewRoom() {
    const { customer_ids = [], user_ids = [] } = roomFormData.selected_room_members;
    const { room_did_number = null } = roomFormData;
    const { currentUser } = userData;
    const filteredUserIds = user_ids.filter((id) => currentUser && id !== currentUser.user_id);

    createNewRoom(
      {
        customer_ids,
        user_ids: filteredUserIds,
        room_did_number,
      },
      (newRoom) => {
        setSelectedView(null);
        navigate(`/user/messages?room_id=${newRoom.room_id}`);
      }
    );
  }

  function handleReplaceUsers() {
    replaceRoomUsers(
      { existing_room: selectedRoom, user_ids: roomFormData.selected_room_members.user_ids },
      (updatedRoom) => setSelectedRoom(updatedRoom)
    );
    setSelectedView(null);
  }

  function handleAddUsers() {
    // Filter out original users
    // TODO: See if the backend can just ignore duplicate ids
    const usersToJoin = roomFormData.selected_room_members.user_ids.filter(
      (id) => !selectedRoom.room_users.some(({ user_id }) => user_id === id)
    );

    joinUsersToRoom(selectedRoom.room_id, usersToJoin, (newRoom) => setSelectedRoom(newRoom));
    setSelectedView(null);
    return;
  }

  const submitHandlers = {
    CREATE_NEW_ROOM: handleNewRoom,
    REPLACE_USERS: handleReplaceUsers,
    ADD_USERS: handleAddUsers,
  };

  const formData = {
    room_did_number: roomFormData.room_did_number,
    room_customers: roomFormData.selected_room_members.customer_ids,
    room_users: roomFormData.selected_room_members.user_ids,
  };

  return (
    <div className="d-flex flex-column flex-grow-1 flex-shrink-1">
      <RoomInfo roomFormData={formData} selectedRoom={selectedRoom} isNewRoom={true} />

      <div className={`${styles.button_wrapper}`}>
        <Button className="me-2" onClick={() => setSelectedView(null)}>
          Cancel
        </Button>
        <Button className="ms-2" onClick={() => submitHandlers[roomFormData.submitType]()}>
          Yes
        </Button>
      </div>
    </div>
  );
}
