import oneSP from "src/assets/img/1sp.png";
import styles from "./Error404.module.scss";
export default function Error404() {
  return (
    <div
      className={`${styles.wrapper} h-100 d-flex flex-column justify-content-center align-items-center`}
    >
      <div className={styles.logo_wrapper}>
        <img src={oneSP} alt="1 Simple Phone" className="mx-auto" />
      </div>
      <h1 className="text-center text-size-lg align-self-center my-5 fs-1">
        404 - Page not found
      </h1>
    </div>
  );
}
