import { createColumnHelper } from "@tanstack/react-table";
import { ContactTile } from "src/common/commonViews.index";

const columnHelper = createColumnHelper();

export const companyColumns = [
  columnHelper.accessor("company_name", {
    header: () => "Name",
    /* cell: (info) => info.renderValue(), */
  }),
  columnHelper.accessor("company_phone", {
    header: () => "Phone #",
  }),
  columnHelper.accessor("company_email", {
    header: "Email",
  }),
  columnHelper.accessor("website", {
    header: "Website",
  }),
];

export const companyMobileColumns = [
  columnHelper.accessor("company_name", {
    cell: (info) => <ContactTile name={info.getValue()} />,
  }),
];
