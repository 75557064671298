import React from "react";

import { default as RbForm } from "react-bootstrap/Form";
import { Formik, getIn } from "formik";
import { Button } from "react-bootstrap";
import { FormInput, FormSelect } from "./FormInputs";

// TODO: selectedRecord values sometime come in as null and cause null input error (minor, noticed in company view)
// May need to convert null to empty strings and strip them on submit

export default function Form({ formLogic, selectedRecord, onSubmit }) {
  if (!formLogic) {
    console.error("FormLogic is required to render this form");
    return null;
  }

  const { fields, validationSchema } = formLogic;

  const isRequired = (name) =>
    validationSchema &&
    !!getIn(validationSchema.describe().fields, name)?.tests.find((test) => test.name === "required");

  return (
    <Formik
      // Formik passes helpers as its second argument, useful if form needs to be manipulated from parent component
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={selectedRecord ? validationSchema.cast(selectedRecord) : validationSchema.cast()}
    >
      {(formik) => (
        <RbForm noValidate>
          {/* Create an from the fields map and iterate over each field.
              field[0] = name of map key // field[1] = field properties
              If a key override is present take precedence over the map key
          */}
          {[...fields].map((field) => {
            const mapKey = field[0];
            const fieldData = field[1];
            const fieldKey = fieldData["keyOverride"] || mapKey;

            return (
              <React.Fragment key={field[0]}>
                {fieldData.component({
                  field: fieldData,
                  fieldKey: fieldKey,
                  formik,
                  isRequired: isRequired(fieldKey),
                })}
              </React.Fragment>
            );
          })}

          <div className="d-flex align-items-center mt-3">
            {/* TODO: Conditionally require */}
            {/*             {"("}
            <b>*</b>
            {" - Required)"} */}
            <Button
              type="submit"
              className="d-block ms-auto"
              onClick={formik.handleSubmit}
              disabled={formik.isSubmitting}
            >
              Submit form
            </Button>
          </div>
        </RbForm>
      )}
    </Formik>
  );
}

Form.FormInput = FormInput;
Form.FormSelect = FormSelect;
