import { Button } from "react-bootstrap";
import { RoomInfo } from "src/common";
import { useEffect } from "react";
import styles from "../RoomManagement.module.scss";

type ExistingRoomSelectProps = {
  selectedRoom,
  setSelectedView: React.Dispatch<React.SetStateAction<string | null>>,
  setFormStage: React.Dispatch<React.SetStateAction<string | null>>,
  setTitle: React.Dispatch<React.SetStateAction<string>>
}

export default function ExistingRoomSelect({ selectedRoom, setSelectedView, setFormStage, setTitle }: ExistingRoomSelectProps) {
  ////////////////////////////////////////////////////////////////////////////////
  // HOOKS
  ////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setTitle("Existing Room Found");
  }, [setTitle]);

  return (
    <div className="h-100 d-flex flex-column overflow-auto">
      <RoomInfo room={selectedRoom} />
      <div className={`${styles.button_wrapper}`}>
        <div className="w-100">
          <Button
            className="w-100 mb-3"
            onClick={() => {
              setSelectedView(null);
            }}
          >
            Go to Room
          </Button>

          <div className="d-flex">
            <Button className="w-100 me-2" onClick={() => setFormStage("SELECT_ROOM_MEMBERS")}>
              Back
            </Button>
            <Button
              className="ms-2"
              onClick={() => {
                setFormStage("MANAGE_ROOM_MEMBERS");
              }}
            >
              Manage Room
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
