const authFormLogic = {
  login: {
    text: "Login to see it in action",
    formLogic: {
      email: {
        inputType: "email",
        formalName: "Email",
        keyName: "email",
        placeholder: "Enter email",
      },
      password: {
        inputType: "password",
        formalName: "Password",
        keyName: "password",
        placeholder: "Password",
      },
    },
    buttonLogic: {
      login: {
        type: "submit",
        text: "Login",
        variant: "primary",
      },
      forgotPassword: {
        type: "link",
        link: "/auth/action?mode=forgotPassword",
        text: "Forgot Password?",
        variant: "outline-dark",
      },
    },
  },
  forgotPassword: {
    text: "Reset forgotten password",
    formLogic: {
      email: {
        inputType: "email",
        formalName: "Email",
        keyName: "email",
        placeholder: "Enter email",
      },
    },
    buttonLogic: {
      forgotPassword: {
        type: "submit",
        text: "Send email",
        variant: "primary",
      },
      login: {
        type: "link",
        link: "/",
        text: "Login",
        header: "Remember your password?",
        variant: "outline-dark",
      },
    },
  },
  newPassword: {
    text: "Please set a new password",
    formLogic: {
      password: {
        inputType: "password",
        formalName: "Password",
        keyName: "password",
        placeholder: "Password",
      },
      verifyPassword: {
        inputType: "password",
        formalName: "Password",
        keyName: "verifyPassword",
        placeholder: "Verify Password",
      },
    },
    buttonLogic: {
      forgotPassword: {
        type: "submit",
        text: "Submit",
        variant: "primary",
      },
    },
  },
  resetPassword: {
    text: "Reset your password",
    formLogic: {
      password: {
        inputType: "password",
        formalName: "Password",
        keyName: "password",
        placeholder: "Password",
      },
      verifyPassword: {
        inputType: "password",
        formalName: "Password",
        keyName: "verifyPassword",
        placeholder: "Verify Password",
      },
    },
    buttonLogic: {
      forgotPassword: {
        type: "submit",
        text: "Submit",
        variant: "primary",
      },
    },
  },
};

export default authFormLogic;
