// Libraries
import { useState, useEffect, useCallback } from "react";
//Contexts
import { useApplicationStates } from "src/contexts";
//Components
import { ResponsiveContainer, DataTableHeader, DateSearch, StatsTiles } from "src/common/commonViews.index.js";
import useWindowDimensions from "src/hooks/useWindowDimensions";

export default function Stats() {
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Component States
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const [dateRange, setDateRange] = useState({ start: "", end: "" });

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Contexts
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const { sharedAppStates, socket } = useApplicationStates();
  const { userStats, setUserStats } = sharedAppStates;

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Render effects
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Update stats when date range changes
  const handleUserStats = useCallback(
    (res) => {
      setUserStats(res);
    },
    [setUserStats]
  );

  useEffect(() => {
    socket.emit("requestStats", dateRange, (res) => handleUserStats(res));
  }, [dateRange, socket, handleUserStats]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Component props
  /////////////////////////////////////////////////////////////////////////////////////////////////

  //Information to map over when rendering tiles
  const tiles = [
    {
      title: "Customers",
      number: userStats ? userStats.total_customers : 0,
      desc: "Total Customers",
      labelColor: "#1c84c6",
    },
    {
      title: "Messages Sent",
      number: userStats ? userStats.total_sent : 0,
      desc: "Total messages sent",
      labelColor: "#23c6c8",
    },
    {
      title: "Messages Received",
      number: userStats ? userStats.total_received : 0,
      desc: "Total messages received",
      labelColor: "#7cc440",
    },
  ];

  const { width } = useWindowDimensions();

  const dateSearchProps = {
    dateRange,
    setDateRange,
  };

  const dataTableHeaderProps = {
    title: "Stats",
    firstRowChildren: (width) => <DateSearch {...dateSearchProps} width={width} />,
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Return
  /////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <ResponsiveContainer>
        <DataTableHeader {...dataTableHeaderProps} width={width} />
        <StatsTiles tiles={tiles} dateRange={dateRange} />
      </ResponsiveContainer>
    </>
  );
}
