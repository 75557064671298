// Libraries
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Contexts
import { useApplicationStates, useAuth } from "src/contexts";
// Components
import { ResponsiveContainer, DataViewSelect, Buttons } from "src/common/commonViews.index";
// Types
import type { CustomerInstance } from "@tscontrollers/customers.controller";
// Hooks
import {
  useRequestRoomAvailability,
  useCreateRoom,
  useOccupyRoom,
  useWindowDimensions,
  useSearchBar,
  useSendMessage,
  usePagination,
  useFindRooms,
  BSBreakpoints,
  useUpdateRoom,
  
} from "src/hooks/hooks.index.js";

import { useRoomHandlers } from "src/hooks"

import {
  contactsTableColumnMap,
  contactsFormLogic,
  ContactsMobileViews,
  useCustomerButtonConstructor,
  useUserButtonConstructor,
} from "./Contacts.index";
import { users } from "@tsmodels/users";
import { RoomInstanceWithMembers } from "@tscontrollers/rooms.controller";

export type Contact = CustomerInstance | users
export default function Contacts() {
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // DATASTORE
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const { userData, sharedAppStates, socket, supplyBillCode } = useApplicationStates();
  const { currentUser } = userData;
  const { serverResponse, billCodeDIDs, customersList } = sharedAppStates;
  const { userPermissionLevel } = useAuth();

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // COMPONENT STATES
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Record Display
  const [contactsList, setContactsList] = useState("customersList");
  const [selectedView, setSelectedView] = useState<null | string>(null);
  // Pagination
  const [selectedPageNum, setSelectedPageNum] = useState(1);
  // Record manipulation states
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [selectedRoom, setSelectedRoom] = useState<RoomInstanceWithMembers | null>(null);
  const [optInMode, setOptInMode] = useState<boolean | null>(null);
  const [disableOptInSend, setDisableOptInSend] = useState<boolean | null>(null);
  const [showDisabledRooms, setShowDisabledRooms] = useState(false) ;
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // VARIABLES
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Name of primary key identifying unique record
  const primaryKey = contactsList === "customersList" ? "customer_id" : "user_id";
  const renderMap = contactsTableColumnMap(contactsList, currentUser);
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Type Guards
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function isCustomer(contact: Contact) {
    return (contact as CustomerInstance).customer_id !== undefined
  }
  function isUser(contact: Contact) {
    return (contact as users).user_id !== undefined
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // HOOKS
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Get the browsers history object for pushing paths to browsers history, provided by react-router
  const navigate = useNavigate();
  // Search Bar and record filtering
  const { searchBar, filteredResults } = useSearchBar({
    list: contactsList,
    renderMap,
  });
  // Redeclare the filtered results as contactsToDisplay to increase code readability
  const contactsToDisplay = filteredResults;

  // Find the customers rooms and personal with custom hook
  const { foundRooms, personalRooms } = useFindRooms({
    selectedContact,
    primaryKey,
  });

  // Use pagination hook to determine indexes of contactsRecords to display and pass prop to DataTable
  const { displayIndexes, numOfPages, paginate, resultsSelector } = usePagination(
    contactsToDisplay.length,
    selectedPageNum,
    setSelectedPageNum
  );

  // Use custom hook to request room availability
  const { requestRoomAvailability } = useRequestRoomAvailability();

  // Use custom hook to create a room with a customer
  const { createCustomerRoom, createUserRoom } = useCreateRoom();

  // Use custom hook to occupy a room with a customer
  const { occupyRoom } = useOccupyRoom();

  // Use custom send message hook
  const { sendMessage, invalidSendMessageModal } = useSendMessage();

  const { updateCustomerRoomDID } = useUpdateRoom();

  const { joinCurrentUserToRoom } = useRoomHandlers();
  
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // RENDER METHODS
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Return selected page number to initial state (1) whenever the number of results to display changes
  useEffect(() => setSelectedPageNum(1), [contactsList]);

  // Subscribe and unsubscribe to disable/reopen events each time the selected contact changes
  // (used have disabled modal appear if user is actively editing a customer)
  useEffect(() => {
    const selectedContactID = selectedContact ? selectedContact[primaryKey] : null;

    function activateDisabledModel(res) {
      if (res.customer_id === selectedContactID) {
        setSelectedView("disabled-room");
      }
    }

    function clearDisableModel(res) {
      if (res.room_customer_id === selectedContactID) {
        setSelectedView(null);
      }
    }

    socket.on("responseDisableRoom", activateDisabledModel);

    socket.on("responseReopenRoom", clearDisableModel);

    return () => {
      socket.off("responseDisableRoom", activateDisabledModel);
      socket.off("responseReopenRoom", clearDisableModel);
    };
  }, [selectedContact, socket, primaryKey]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // OPT IN MODE USE EFFECT
  ////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    //If room is selected and the room's customer length is only one
    if(!selectedContact) {
      return;
    }

    if (primaryKey === "customer_id" && isCustomer(selectedContact)) {
      const customerContact = selectedContact as CustomerInstance;
      //Turn on opt in mode
      setOptInMode(true);
      //If they have been sent an opt in disable the send button
      customerContact.sent_opt_in ? setDisableOptInSend(true) : setDisableOptInSend(false);

      return;

    }
    setOptInMode(false);
    setDisableOptInSend(false);
  }, [selectedContact, primaryKey, contactsList, optInMode, disableOptInSend]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // RECORD HANDLING METHODS
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // Select a record
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function handleSelectRecord(record) {
    return setSelectedContact(record);
  }
  // Deselect a record () {
  function handleDeselectRecord() {
    return setSelectedContact(null);
  }


  type CustomerFormData = {
    first_name: string,
    last_name: string,
    phone_number: string,
    consent_bypass: boolean
  }
  // Add a customer
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function handleAddCustomer(newCustomer: CustomerFormData) {
    const billCode = supplyBillCode();
    if(!billCode) return console.error(`There isn't a user role currently!`);
    socket.emit("requestCreateCustomer", { billCode, ...newCustomer});
  }

  // Edit a customer
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function handleEditCustomer(editedRecord: CustomerFormData) {
    socket.emit("requestEditCustomer", editedRecord);
  }

  // Disable a customer
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function handleDeleteCustomer() {
    if(!selectedContact) return;
    socket.emit("requestDeleteCustomer", selectedContact[primaryKey]);
    setSelectedContact(null);
  }

  function handleSelectDID(formInput) {
    if(!selectedRoom) return;
    const selectedDID = formInput.selected_did;
    const roomID = selectedRoom.room_id;

    updateCustomerRoomDID({ selectedDID, roomID }, (updatedRoom) => {
      setSelectedRoom(updatedRoom);
      return navigate("/users/messages", {
        state: {
          room_id: updatedRoom.room_id,
        },
      });
    });
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Customer Helpers
  //////////////////////////////////////////////////////////////////////////////////////////////////
  function checkForCustomerInLocalState(customerRecord) {
    console.log(`Checking for customer in local state`)
    const { phone_number } = customerRecord;
    const existingCustomer = customersList.filter(customer => customer.phone_number === phone_number);
    if(existingCustomer.length) {
      if(existingCustomer.length > 1) {
        // Something went wrong if we found multiple customers with the same phonenumber
        console.error(`Something went wrong while checking for an existing customer`);
      }
      return existingCustomer[0];
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // ROOM AND MESSAGE HANDLING METHODS
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function checkForExistingRoom() {
    // If the Users view is selected use logic to send the message directly to that other user
    if(!personalRooms || !selectedContact) return;

    if (primaryKey === "user_id") {
      // If user has no rooms with selected user, create one
      if (!personalRooms.length) {
        return createUserRoom([selectedContact[primaryKey]], (newRoom) => {
          setSelectedRoom(newRoom);
          return setSelectedView("send-message");
        });
        // If user has a existing room set that as the selected room and activate send message modal
      } else if (personalRooms.length === 1) {
        setSelectedRoom(personalRooms[0]);
        return setSelectedView("send-message");
        //!! Test case for multiple returned personal rooms (shouldn't happen)
      } else if (personalRooms.length > 1) {
        return console.error("This user has multiple personal rooms, this should not have happened");
      }
    }
    // Else handle all other customer messages
    const personalRoomsWithCurrUser = personalRooms.filter((room) =>
      room.room_users.some(roomUser => currentUser && roomUser.user_id === currentUser.user_id)
    );
    //Does the User have any rooms with the customer?
    if (personalRoomsWithCurrUser.length) {
      //If there is only one
      if (personalRoomsWithCurrUser.length === 1) {
        const onlyRoom = personalRooms[0];
        //Make sure to assign a DID if it hasn't already
        if (onlyRoom.room_did_number === null) {
          return setSelectedRoom(onlyRoom);
        }
        //Set it as the selected Room
        setSelectedRoom(onlyRoom);
        //Redirect the User to the messages Screen
        return navigate(`/user/messages?room_id=${onlyRoom.room_id}`);
      } 

      else {
        return console.error(
          "multiple personal rooms found (customer has multiple rooms with different dids you are engaged on)"
        );
      }
    }
    // Hotfix for edge case, this will not work for customers with multiple room
    if(userPermissionLevel("mid")) {
      console.log(`Escalated Priveleges`)
      if(foundRooms.length === 1) {
        console.log(`Only one room`)
        const { room_id } = foundRooms[0];

        joinCurrentUserToRoom(room_id);
        return navigate(`/user/messages?room_id=${room_id}`);
      }
    }
    handleRequestRoomAvailability();
  }

  // SEND MESSAGE
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function handleSendMessage({ message_text }) {
    if (selectedRoom) {
      sendMessage({ message_text, room: selectedRoom });
    } else {
      return console.error("🛑  ERROR: NO ROOM SELECTED!");
    }
  }

  // SEND OPT IN MESSAGE
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function handleOptInMessage(room) {
    sendMessage({
      room,
      message_text: null,
      optIn: true,
    });
  }

  // Request if a room is available to create with the selected contact
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function handleRequestRoomAvailability() {
    if(!selectedContact) return;
    // Send the customerID and the did number selected to the server to attempt room creation
    const newRoomFunc = () => setSelectedView("create-room");
    const occupiedRoomFunc = () => setSelectedView("occupied-rooms");
    requestRoomAvailability([selectedContact[primaryKey]], {
      newRoomFunc,
      occupiedRoomFunc,
    });
  }

  // Create a new personal room with the selected contact
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function handleCreateRoom(formInput: { selected_did: string }) {
    if(!selectedContact) return;
    if (primaryKey === "customer_id") {
      return createCustomerRoom(
        {
          selected_customers: [selectedContact[primaryKey]],
          selected_did: formInput.selected_did,
        },
        (newRoom: RoomInstanceWithMembers) => {
          navigate(`/user/messages?room_id=${newRoom.room_id}`);
        }
      );
    }
  }

  // Let the user occupy an existing room
  /////////////////////////////////////////////////////////////////////////////////////////////////-
  function handleOccupyRoom({ selected_room }) {
    setSelectedRoom(selected_room);

    const handleFunc = (newRoom) =>
      navigate(`/user/messages?room_id=${newRoom.room_id}`);

    occupyRoom(handleFunc, selected_room.room_id);
  }

  function tempBypass({ selected_room }) {
    setSelectedRoom(selected_room);
    navigate(`/user/messages?room_id=${selected_room.record_id}`);
  }

  // Push user to the selected room in the messages view
  /////////////////////////////////////////////////////////////////////////////////////////////////-
  function handleRoomSelect({ selected_room }) {
    const { room_id } = selected_room;
    if(Array.isArray(selectedRoom?.room_users)) {
      //If the current user is not a member of the room
      const userIsInRoom = selected_room.room_users.some((user) => currentUser && user.user_id === currentUser.user_id);
      
      if (userIsInRoom) {
        //If the room has customers and lacks a did_number
        if (selected_room.room_customers.length && selected_room.room_did_number === null) {
          setSelectedView("select-did");
        }
        //Redirect to the room
        return navigate(`/user/messages?room_id=${room_id}`);
      }
    }
    joinCurrentUserToRoom(room_id, () => navigate(`/user/messages?room_id=${room_id}`));
    //See if the room is available
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Modal methods and props
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // Clear view once users know its disabled
  /////////////////////////////////////////////////////////////////////////////////////////////////-
  function handleDisabledRoom() {
    setSelectedView(null);
  }

  // Reset back to the main view component
  /////////////////////////////////////////////////////////////////////////////////////////////////-
  function handleResetView() {
    setSelectedView(null);
    setSelectedContact(null);
    setSelectedRoom(null);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Table Header props
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const newButtonProps = {
    onClickFunc: () => setSelectedView("add-customer"),
  };

  const tableHeaderProps = {
    newButtonProps,
    contactsList,
    setContactsList,
    setSelectedContact,
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Table props
  /////////////////////////////////////////////////////////////////////////////////////////////////

  const { customerButtonConstructor } = useCustomerButtonConstructor({
    foundRooms,
    setSelectedView,
    checkForExistingRoom,
    showDisabledRooms
  });

  const { userButtonConstructor } = useUserButtonConstructor({
    personalRooms,
    foundRooms,
    setSelectedView,
    checkForExistingRoom
  });

  const logicMapProps = {
    serverResponse,
    foundRooms,
    currentUser,
    personalRooms,
    selectedRoom,
    selectedContact,
    handleCreateRoom,
    handleOccupyRoom,
    userPermissionLevel,
    billCodeDIDs,
    tempBypass,
  };

  const { width } = useWindowDimensions();
  function tableHeader() {
    function searchBarAndResultsSelector() {
      if (width >= BSBreakpoints.lg) {
        return (
          <div className="w-100 mb-2 d-flex justify-content-between justify-content-lg-end">
            {searchBar()}
            {resultsSelector(`mx-2 ms-4 d-flex align-items-center flex-nowrap text-nowrap`)}
          </div>
        );
      } else {
        return searchBar();
      }
    }

    function breakpointSelect(width) {
      return (
        <div>
          <div key={"buttons"} className="d-flex flex-grow-1 flex-md-grow-0 justify-content-between">
            {
              <Buttons.ContactsSwitchView
                setSelectedContact={setSelectedContact}
                contactsList={contactsList}
                setContactsList={setContactsList}
              />
            }
            {width < BSBreakpoints.lg
              ? resultsSelector(`mx-2 ms-4 d-flex align-items-center flex-nowrap text-nowrap`)
              : null}

            {contactsList === "customersList" ? <Buttons.NewButton {...newButtonProps} /> : null}
          </div>
          {numOfPages > 1 && width >= BSBreakpoints.md ? paginate() : null}
        </div>
      );
    }

    return {
      title: "Contacts",
      firstRowChildren: searchBarAndResultsSelector,
      children: breakpointSelect,
    };
  }

  // Set a custom column map size for the table
  const columnSizeMap =
    contactsList === "customersList"
      ? "minmax(100px, 400px) minmax(100px, auto) minmax(100px, auto) minmax(100px, auto)"
      : null;

  const dataViewSelectProps = {
    //Data to render
    data: contactsToDisplay,
    // Map logic on how to render data
    renderMap,
    // The primary key to use when selecting records
    primaryKey,
    // Indexes to slice when using table layout
    displayIndexes,
    // Button logic to use when using table layout
    buttonConstructor: contactsList === "customersList" ? customerButtonConstructor : userButtonConstructor,

    // Logic map for forms and modal
    logicMap: contactsFormLogic(logicMapProps),
    //Header props
    headerProps: tableHeaderProps,
    // General handle functions and props
    contactsList,
    setContactsList,
    foundRooms,
    columnSizeMap,
    selectedRecord: selectedContact,
    selectedRoom,
    selectedView,
    setSelectedView,
    setSelectedRoom,
    handleResetView,
    handleSelectRecord,
    handleDeselectRecord,
    handleCreateRoom,
    checkForExistingRoom,
    createUserRoom,
    searchBar,
    personalRooms,
    /* handleCheckForRoom, */
    //Handle functions for selected view
    handleFunctions: {
      "add-customer": handleAddCustomer,
      "edit-customer": handleEditCustomer,
      "send-message": optInMode && !disableOptInSend ? handleOptInMessage : handleSendMessage,
      "delete-customer": handleDeleteCustomer,
      "create-room": handleCreateRoom,
      "occupied-rooms": handleOccupyRoom,
      "room-select": handleRoomSelect,
      "disabled-room": handleDisabledRoom,
      "no-available-dids": handleResetView,
      "select-did": handleSelectDID,
    },
    // The component to use when the viewport is smaller than 450px;
    mobileView: (props) => <ContactsMobileViews {...props} />,
    tableHeader: tableHeader,
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Return
  /////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <ResponsiveContainer>
      <DataViewSelect {...dataViewSelectProps} />
      {invalidSendMessageModal()}
    </ResponsiveContainer>
  );
}
