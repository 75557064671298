import FormConstructor from "../../../../common/FormConstructor/FormConstructor";
import AdminUsersMobileViews from "../subcomponents/AdminUsersMobileViews";

function adminUsersFormLogic(logicMapProps) {
  const currentUserID = logicMapProps.currentUser ? logicMapProps.currentUser.user_id : "";

  const selectedUser = logicMapProps.selectedUser;

  return {
    ////////////////////////////////////////////////////////////////////////////////////////////////
    // ADD USER
    ////////////////////////////////////////////////////////////////////////////////////////////////
    "add-user": {
      modalTitle: "Add a user",
      mobileView: (props, key) => <AdminUsersMobileViews.AddEditUser {...props} key={key} />,
      initialFormValues: {
        first_name: "",
        last_name: "",
        email: "",
        billing_code: "",
        user_dids: [],
        user_role: "user",
        user_enabled: 1,
      },
      fields: {
        first_name: {
          inputType: "text",
          formalName: "First Name",
          keyName: "first_name",
          placeHolder: "John",
          fieldComponent: (props, key) => <FormConstructor.TextInput {...props} key={key} />,
        },
        last_name: {
          inputType: "text",
          formalName: "Last Name",
          keyName: "last_name",
          placeHolder: "Doe",
          fieldComponent: (props, key) => <FormConstructor.TextInput {...props} key={key} />,
        },
        email: {
          inputType: "email",
          formalName: "Email",
          keyName: "email",
          placeHolder: "fakeemail@mail.com",
          fieldComponent: (props, key) => <FormConstructor.TextInput {...props} key={key} />,
        },
        phone_number: {
          inputType: "tel",
          formalName: "Phone Number",
          keyName: "personal_phone",
          placeHolder: "(123)-456-7890",
          fieldComponent: (props, key) => <FormConstructor.PhoneInput {...props} key={key} />,
          required: false,
        },
        billing_code: {
          formalName: "Billing Code",
          keyName: "billing_code",
          fieldComponent: (props, key) => (
            <FormConstructor.DropdownSelect
              {...props}
              dataList={logicMapProps.adminCodes.map((code) => code.billing_code)}
              key={key}
            />
          ),
        },
        role: {
          formalName: "User Role",
          keyName: "user_role",
          fieldComponent: (props, key) => (
            <FormConstructor.DropdownSelect {...props} dataList={["User", "Manager", "Admin"]} key={key} />
          ),
        },
        user_dids: {
          formalName: "DID Numbers",
          keyName: "user_dids",
          inputGroup: false,
          fieldComponent: (props, key) => (
            /* if (logicMapProps.currentUser.user_role === "admin") {
              return (
                <p className="w-100 text-center pt-2">
                  Admins have access to all DID numbers in billing code by
                  default
                </p>
              );
            } else { */
            <FormConstructor.DIDAssign {...props} key={key} />
          ),
        },
        user_enabled: {
          inputType: "switch",
          formalName: "User is activated",
          keyName: "user_enabled",
          inputGroup: false,
          fieldComponent: (props, key) => <FormConstructor.Switch {...props} key={key} />,
        },
      },
    },

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // EDIT USER
    ////////////////////////////////////////////////////////////////////////////////////////////////
    "edit-user": {
      modalTitle: "Editing the user",
      buttonTitle: {
        title: "Submit",
        showOnModal: true,
      },
      mobileView: (props, key) => <AdminUsersMobileViews.AddEditUser {...props} key={key} />,
      initialFormValues: {
        first_name: "",
        last_name: "",
        email: "",
        user_dids: [],
        user_role: "",
      },
      fields: {
        first_name: {
          inputType: "text",
          formalName: "First Name",
          keyName: "first_name",
          placeHolder: "John",
          fieldComponent: (props, key) => <FormConstructor.TextInput {...props} key={key} />,
        },
        last_name: {
          inputType: "text",
          formalName: "Last Name",
          keyName: "last_name",
          placeHolder: "Doe",
          fieldComponent: (props, key) => <FormConstructor.TextInput {...props} key={key} />,
        },
        email: {
          inputType: "email",
          formalName: "Email",
          keyName: "email",
          placeHolder: "fakeemail@mail.com",
          fieldComponent: (props, key) => <FormConstructor.TextInput {...props} key={key} />,
        },
        phone_number: {
          inputType: "tel",
          formalName: "Phone Number",
          keyName: "personal_phone",
          placeHolder: "(123)-456-7890",
          fieldComponent: (props, key) => <FormConstructor.PhoneInput {...props} key={key} />,
          required: false,
        },
        // If the selected user is the current user, prevent them from changing their role
        ...(selectedUser &&
          currentUserID &&
          selectedUser.user_id !== currentUserID && {
            role: {
              formalName: "User Role",
              keyName: "user_role",
              fieldComponent: (props, key) => (
                <FormConstructor.DropdownSelect {...props} dataList={["User", "Manager", "Admin"]} key={key} />
              ),
            },
          }),
        user_dids: {
          formalName: "DID Numbers",
          keyName: "user_dids",
          inputGroup: false,
          fieldComponent: (props, key) => {
            if (selectedUser.user_role === "admin") {
              return (
                <p className="w-100 text-center pt-2">
                  Admins have access to all DID numbers in billing code by default
                </p>
              );
            } else {
              return <FormConstructor.DIDAssign {...props} key={key} />;
            }
          },
        },
      },
    },

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // USER STATUS
    ////////////////////////////////////////////////////////////////////////////////////////////////
    "user-status": {
      modalTitle: `${selectedUser ? (selectedUser.user_enabled ? "Disable" : "Enable") : null} the account for`,
      submitType: "yes/no",
      submitLogic: logicMapProps.submitFunc,
      text: `Are you sure you want to ${
        selectedUser ? (selectedUser.user_enabled ? "disable" : "enable") : "enable/disable"
      } the account for ${selectedUser ? selectedUser.email : "this user"}?`,
    },

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // RESET PASSWORD
    ////////////////////////////////////////////////////////////////////////////////////////////////
    "reset-password": {
      modalTitle: "Send Password Reset",
      text: `Do you want to send a password reset email to ${
        selectedUser ? selectedUser.email : "to the users email"
      }? `,
      submitType: "yes/no",
    },

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // RESEND EMAIL
    ////////////////////////////////////////////////////////////////////////////////////////////////
    "resend-email": {
      modalTitle: "Resend verification email to",
      text: `Do you want to resend a verification email to ${
        selectedUser ? selectedUser.email : "to the users email"
      }? `,
      submitType: "yes/no",
      submitLogic: logicMapProps.submitFunc,
    },

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // DELETE USER
    ////////////////////////////////////////////////////////////////////////////////////////////////
    "delete-user": {
      modalTitle: "Deleting the user",
      text: "You are about to permanently delete this record- are you sure?",
      submitType: "yes/no",
      submitLogic: logicMapProps.submitFunc,
    },
  };
}

export default adminUsersFormLogic;
