import { Unsubscribe } from "firebase/auth";
import { useEffect } from "react";
import { onMessageListener } from "src/services/firebase";

// Listener for incoming Firebase Cloud Messenger foreground notifications
export default function useSendForegroundNotification(selectedMessageViewRoomID) {
  useEffect(() => {
    // Intitialize with a void function to specifiy type
    let unsubscribe: Unsubscribe | false;
    
    const setupListener = async () => {
      unsubscribe = await onMessageListener(selectedMessageViewRoomID);
    }

    setupListener();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [selectedMessageViewRoomID]);
}
