import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import useDebounce from "src/hooks/useDebounce";
import styles from "./Table.module.scss";

export default function TableSearchBar({ className, setGlobalFilter }) {
  const [searchInput, setSearchInput] = useState("");
  const debouncedValue = useDebounce(searchInput, 500);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setGlobalFilter(searchInput);
      return () => clearTimeout(timeout);
    });
  }, [debouncedValue]);

  return (
    <div className={`${styles.react_table_search_bar} d-flex align-items-center ${className}`}>
      <Form.Group /* className="w-100 position-relative d-flex" */ className="w-100 *position-relative d-flex">
        <Form.Control
          className="rounded-start"
          type="text"
          placeholder="Search all columns..."
          onChange={(e) => setSearchInput(e.target.value)}
          value={searchInput}
        />
        <Button
          //className={"*position-absolute h-100 end-0"}
          onClick={() => setSearchInput("")}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </Button>
      </Form.Group>
    </div>
  );
}
//className={`${styles.table_search} flex-grow-1 px-2 shadow-sm border rounded`}
