// Styles/Assets
import oneSP from "../../assets/img/1sp.png";
import useWindowDimensions, {
  BSBreakpoints,
} from "../../hooks/useWindowDimensions";
import styles from "./Footer.module.scss";

export default function Footer() {
  const { width } = useWindowDimensions();
  const date = new Date();
  // Hide the footer on devices with a width of less than 768px;
  return width > BSBreakpoints.md ? (
    <div className={`${styles.footer} my-1`}>
      {/* TODO This copyright script is in multiple places, condense to one */}
      <div className="d-flex justify-content-center align-items-center mx-auto">
        <div className="h-100 d-flex align-items-center">
          <img
            className={`${styles.footer_img} pe-3`}
            src={oneSP}
            alt="1 Simple Phone"
          />
        </div>
        <div>1SimplePhone &copy; {date.getFullYear()}</div>
      </div>
    </div>
  ) : null;
}
