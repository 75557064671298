// Libraries/Constants
import { RouterProvider } from "react-router-dom";
import { routes } from "src/routes/routes";
// Providers
import { useAuth } from "src/contexts";

// Wrap RouterProvider in another react component in order allow firebase user to be passed to routes compiler.
// Used to determine authorized paths
export default function Router() {
  const { firebaseUser } = useAuth();
  const authorizedRoutes = routes(firebaseUser);

  return <RouterProvider basename={import.meta.env.VITE_BASENAME_URL} router={authorizedRoutes} />;
}
