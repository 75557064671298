import { useApplicationStates, useAuth } from "src/contexts";
import styles from "./Tiles.module.scss";
import { combineFirstAndLastName, formatRoomNames, roomsViewTimestamp } from "src/helpers/formattingHelpers";
import { ContactCircle } from "src/common/commonViews.index";
import { useRef, useState, forwardRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faBuildingUser, faCommentSms  } from "@fortawesome/free-solid-svg-icons";

// Allows ref to be passed from parent component, to track scroll location
const RoomTile = forwardRef(({ room, onClick, active, className, mobile }, ref) => {
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // States
  /////////////////////////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Hooks
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const { sharedAppStates, userData } = useApplicationStates();
  const { currentUser } = userData;
  const { billCodeDIDs } = sharedAppStates;

  const { firebaseUser, userPermissionLevel } = useAuth();

  const { customerFirstNames, usersFirstNames, roomMembers, customerNames, userNames } = formatRoomNames(
    room.room_users,
    room.room_customers,
    firebaseUser
  );

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Formatters
  /////////////////////////////////////////////////////////////////////////////////////////////////

  function formatLastMessageText(room) {
    if (!room.last_message_text) {
      return (
        <div>
          <i>No messages</i>
        </div>
      );
    }

    let prefix = "";
    let classname = "line-clamp-1";

    if (room.last_message_from === currentUser.user_name) {
      prefix = "You: ";
      classname = "line-clamp-1";
    } else if (room.room_customers.length > 1 || room.room_users.length > 1) {
      if (room.last_message_from) {
        prefix = room.last_message_from.split(" ", 1)[0] + ": ";
      }
    }

    return (
      <div className={`${styles.mobile_room_last_text} ${classname}`} title={room.last_message_text}>
        {prefix + room.last_message_text}
      </div>
    );
  }

  function roomNameSelect(namesArray) {
    if (namesArray.length === 1) {
      return `${namesArray[0].first_name} ${namesArray[0].last_name}`;
    }

    const combinedNames = customerFirstNames.concat(usersFirstNames).sort();
    return combinedNames.join(", ");
  }

  const roomMembersRef = useRef(null);

  function addPaddingForDisabledBanner() {
    return !room.room_enabled ? "pt-4" : null;
  }

  // TODO: Did number overlay?
  const roomDIDDescription = () => {
    let didDescription = "No DID Found!";
    let didList = currentUser.user_dids;

    if (userPermissionLevel("mid")) {
      didList = billCodeDIDs;
    }

    const foundDID = didList.find((did) => did.did_number === room.room_did_number);

    if (foundDID) {
      didDescription = foundDID.did_description;
    }

    return didDescription;
  };

  const customerRow = () => {
    if (!room.room_customers.length) {
      return null;
    }

    return (
      <>
        <FontAwesomeIcon icon={faCommentSms} className="me-2" />
        {room.room_customers.length > 1
          ? customerFirstNames.sort().join(", ")
          : combineFirstAndLastName(room.room_customers[0])}
      </>
    );
  };

  const userRow = () => {
    // Remove the current user from the room users list
    const filteredRoomUsers = room.room_users.filter((user) => user.user_id !== currentUser.user_id);

    if (!filteredRoomUsers.length) {
      return null;
    }

    return (
      <div
        className="line-clamp-1"
        ref={roomMembersRef}
        title={userNames.length ? userNames.reduce((users, next) => `${users}, ${next}`) : null}
      >
        <FontAwesomeIcon icon={faBuildingUser} className="me-2" />
        {filteredRoomUsers.length > 1
          ? usersFirstNames.sort().join(", ")
          : combineFirstAndLastName(filteredRoomUsers[0])}

        {/*         <Overlay
          show={
            roomMembersHovered &&
            (room.room_customers.length > 1 || (room.room_customers.length <= 1 && room.room_users.length > 2))
          }
          placement={"right"}
          target={roomMembersRef.current}
        >
          {constructToolTip()}
        </Overlay> */}
      </div>
    );
  };

  const mobileTile = (
    <>
      <div
        key={room.room_id}
        className={`${active ? styles.active_item : null} d-flex ${className} ${addPaddingForDisabledBanner(room)}`}
        onClick={onClick}
        ref={ref}
      >
        <ContactCircle size="sm" circleNames={roomMembers} unreadMessageCount={room.unread_message_count} />
        <div className="w-100 ms-3">
          <div className="mb-1 d-flex justify-content-between">
            <div className="min-width-0 flex-grow-1">
              <div
                className="line-clamp-1"
                /*                 ref={roomMembersRef}
                onMouseEnter={() => setRoomMembersHovered(true)}
                onMouseLeave={() => setRoomMembersHovered(false)} */
              >
                {roomNameSelect(room.room_customers)}
              </div>
            </div>
            <div className=" ms-2 text-end text-nowrap">{roomsViewTimestamp(room.last_message_sent_at)}</div>
          </div>
          {formatLastMessageText(room)}
        </div>
      </div>
    </>
  );

  const webTile = (
    <>
      <div
        key={room.room_id}
        className={`${
          active ? styles.active_item : null
        } d-flex align-items-center ${className} ${addPaddingForDisabledBanner()}`}
        onClick={onClick}
        ref={ref}
      >
        <ContactCircle size="sm" circleNames={roomMembers} unreadMessageCount={room.unread_message_count} />
        <div className="w-100 ms-3">
          <div className="mb-1 d-flex justify-content-between">
            <div className="min-width-0 flex-grow-1">
              {room.room_did_number && (
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faBuilding} className="me-2" />
                  {<p>{roomDIDDescription()}</p>}
                </div>
              )}
              <div className="line-clamp-1" title={customerNames.length ? customerNames.reduce((member, next) => `${member}, ${next}`) : null}>{customerRow()}</div>
              {userRow()}
            </div>
            <div className=" ms-2 text-end text-nowrap">{roomsViewTimestamp(room.last_message_sent_at)}</div>
          </div>
          {formatLastMessageText(room)}
        </div>
      </div>
    </>
  );

  return mobile ? mobileTile : webTile;
});

RoomTile.displayName = "RoomTile";

export default RoomTile;
