// Libraries
import { useEffect, useState } from "react";
//Contexts
import { useApplicationStates } from "src/contexts";
//Components
import {
  DataViewSelect,
  ResponsiveContainer,
} from "src/common/commonViews.index.js";
import { DateSearch } from "src/common/commonViews.index";
import adminStatsColumnMap from "./adminStatsColumnMap";

//TODO pagination?
export default function AdminStats() {
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // States
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const [dateRange, setDateRange] = useState({ start: "", end: "" });

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Contexts
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const { adminAppStates, socket } = useApplicationStates();
  const { adminStats, setAdminStats } = adminAppStates;
  
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Component props
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // On component load
  useEffect(() => {
    socket.emit("requestAdminStats", dateRange, (res) => setAdminStats(res));
  }, [dateRange, socket, setAdminStats]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Component props
  /////////////////////////////////////////////////////////////////////////////////////////////////

  const dateSearchProps = {
    dateRange,
    setDateRange,
  };

  function adminStatsHeaderConstructor() {
    return {
      title: "Stats",
      firstRowChildren: (width) => (
        <DateSearch {...dateSearchProps} width={width} />
      ),
    };
  }

  const dataViewSelectProps = {
    data: adminStats,
    primaryKey: "billing_code",
    renderMap: adminStatsColumnMap,
    headerConstructor: adminStatsHeaderConstructor,
    headerProps: dateSearchProps,
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Return
  /////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <ResponsiveContainer fullHeight={true}>
      <DataViewSelect {...dataViewSelectProps} />
    </ResponsiveContainer>
  );
}
