import { getAuth, getIdToken } from "firebase/auth";

export async function getAuthToken() {
  return await getIdToken(getCurrentUser());
}

export function getCurrentUser() {
  const { currentUser } = getAuth();
  return currentUser;
}
