import { createContext, useContext } from "react";
import useFirebase from "src/services/firebase/useFirebase";
export type FirebaseAuth = ReturnType<typeof useFirebase>
// Declare AuthContext as a context component
export const AuthContext = createContext<FirebaseAuth>({} as FirebaseAuth);

// Then wrap the context in a provider function and use provide auth hook as its context value
export function AuthProvider({ children }) {
  const firebaseAuth = useFirebase();
  return <AuthContext.Provider value={firebaseAuth}>{ children }</AuthContext.Provider>;
}

// Export the hook separately so it can be used without needing to import useContext in components
export default function useAuth() {
  return useContext(AuthContext);
}
