// Libraries
import { Button, Toast, ToastContainer } from "react-bootstrap";
import { useApplicationStates } from "src/contexts";
import useWindowDimensions, {
  BSBreakpoints,
} from "../../hooks/useWindowDimensions";
export default function NotificationEnableToast() {
  const { notifications } = useApplicationStates();
  const { width } = useWindowDimensions();
  // TODO: We need a mobile friendly way to alert the user that they need to allow notificatons 
  // Don't render/obscure view if user is on a mobile display
  /* if(width < BSBreakpoints.md) {
    return null;
  } */
  const { notificationsEnabled, notificationOverride, setNotificationOverride, requestNotificationsPermission } =
    notifications;

  const permissionLevel = Notification.permission;

  const textMap = new Map([
    [
      "default",
      <p key="default" className="mb-3">
        Notifications are required for real time updates on new messages and events, please enable them if you wish to
        use these features.
      </p>,
    ],
    [
      "denied",
      <p key="denied" className="mb-3">
        Notifications are currently disabled by the browser. Please enable them manually if you wish to receive real
        time updates on new messages and events.
      </p>,
    ],
  ]);

  return (
    <ToastContainer className="p-3 fit-content" position="bottom-start">
      <Toast
        className="bg-white"
        show={!notificationsEnabled && !notificationOverride}
        onClose={() => setNotificationOverride(true)}
      >
        <Toast.Header>
          <strong className="me-auto">🔔 Enable Notifications</strong>
        </Toast.Header>
        <Toast.Body>
          {textMap.get(permissionLevel)}
          {permissionLevel !== "denied" && (
            <Button className="d-block text-nowrap mx-auto" variant="primary" onClick={requestNotificationsPermission}>
              Enable Notifications
            </Button>
          )}
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
}