import { Table } from "react-bootstrap";
import { convertTimestampToDate, formatPhoneNumber } from "src/helpers/formattingHelpers";
import { ContactCircle } from "src/common/commonViews.index";
import styles from "./RoomInfo.module.scss";
import { useDIDHandlers, useFormatNames } from "src/hooks";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { RoomInstanceWithMembers } from "@tscontrollers/rooms.controller";

type RoomFormData = {
  room_did_number: RoomInstanceWithMembers["room_did_number"];
  room_customers: RoomInstanceWithMembers["room_customers"];
  room_users: RoomInstanceWithMembers["room_users"];
}

type RoomInfoProps = {
  selectedRoom: RoomInstanceWithMembers
  roomFormData?: RoomFormData,
  isNewRoom?: boolean,
  compact?: boolean,
  children?: React.ReactNode
}

export default function RoomInfo({ selectedRoom, roomFormData, isNewRoom, compact, children }: RoomInfoProps) {
  const [show, setShow] = useState(false);
  const { getDIDDescription } = useDIDHandlers();
  const { formatNamesByCustomerIds, formatNamesByUserIds } = useFormatNames();
  const hasRoomFormData = roomFormData || selectedRoom;
  const customerIds = hasRoomFormData.room_customers.map((customer) => Number(customer.customer_id || customer));
  const userIds = hasRoomFormData.room_users.map((user) => user.user_id || user);
  const customerNames = formatNamesByCustomerIds(customerIds);
  const userNames = formatNamesByUserIds(userIds, { replaceCurrentUserWithYou: true });
  
  return (
    <div className="mb-3" onClick={() => setShow(!show)}>
      <div className={`d-flex ${compact ? "mb-1" : "mb-3"}`}>
        <ContactCircle circleNames={customerNames} className={"me-3"} size="sm" />
        <div className="d-flex flex-column justify-content-center">
          {!isNewRoom && (
            <div className="d-flex">
              <span className="fw-bold me-2 text-nowrap">Room ID:</span>
              <p className="mx-2">{selectedRoom.room_id}</p>
            </div>
          )}
          <div className="d-flex">
            <span className="fw-bold me-2 text-nowrap">DID #:</span>
            <p className="mx-2 ">
              {hasRoomFormData.room_did_number
                ? `${formatPhoneNumber(hasRoomFormData.room_did_number )} - ${getDIDDescription(hasRoomFormData.room_did_number)}`
                : "No DID - Web Room"}
            </p>
          </div>
        </div>
        {compact && (
          <div className="ms-auto d-flex justify-content-center align-items-center">
            <FontAwesomeIcon icon={show ? faChevronDown : faChevronUp} />
          </div>
        )}
      </div>

      <div className="overflow-auto" style={{ height: !compact ? "auto" : compact && show ? "auto" : 0 }}>
        <Table className={styles.table} size="sm">
          <tbody>
            {!isNewRoom && (
              <>
                <tr>
                  <th>Last Message:</th>
                  <td>{selectedRoom.last_message_text}</td>
                </tr>
                <tr>
                  <th>Last Updated:</th>
                  <td>{convertTimestampToDate(selectedRoom.room_updated_at)}</td>
                </tr>
              </>
            )}
            <tr>
              <th>Customers:</th>
              <td>
                <ul className="ps-2 mb-0">
                  {customerNames.map((customerName, i) => (
                    <li key={`${i}-${customerName}`}>{customerName}</li>
                  ))}
                </ul>
              </td>
            </tr>
            <tr>
              <th>Users:</th>
              <td>
                <ul className="ps-2  mb-0">
                  {userNames.map((userName, i) => (
                    <li key={`${i}-${userName}`}>{userName}</li>
                  ))}
                </ul>
              </td>
            </tr>
          </tbody>
        </Table>
        {children}
      </div>
    </div>
  );
}
