import {
  combineFirstAndLastName,
  convertTimestampToDate,
  formatPhoneNumber,
  formatRoomNames,
} from "src/helpers/formattingHelpers";
import { sortyByDate } from "src/helpers/helpers";
import { useApplicationStates } from "src/contexts";
import { RoomTile } from "src/common/commonViews.index";
import MobileViewSelect from "src/common/Mobile/MobileViewSelect";
import { faCalendarAlt, faEnvelope, faPersonBooth, faPhone, faSms, faUser } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export default function RoomsMobileViews(props) {
  const { userData } = useApplicationStates();
  const { currentUser } = userData;

  function roomNameSelect() {
    const { customerFirstNames, usersFirstNames } = formatRoomNames(
      props.selectedRecord.room_users,
      props.selectedRecord.room_customers,
      currentUser
    );

    if (props.selectedRecord.room_customers.length === 1 && props.selectedRecord.room_users.length === 1) {
      return `${props.selectedRecord.room_customers[0].first_name} ${props.selectedRecord.room_customers[0].last_name}`;
    }

    const combinedNames = customerFirstNames.concat(usersFirstNames);

    return combinedNames.join(", ");
  }

  const recordTitle = props.selectedRecord ? roomNameSelect() : null;

  if (props.selectedRecord && !props.selectedView) {
    return <SelectedRoomView {...props} primaryKey={props.primaryKey} recordTitle={recordTitle} />;
  }

  return <RoomsViewContainer {...props} />;
}

function RoomsViewContainer(props) {
  return (
    <>
      <MobileViewSelect.MobileSearchBar
        {...props}
        setView={() => {
          if (props.roomListType === "customers") {
            return props.setRoomListType("users");
          } else {
            return props.setRoomListType("customers");
          }
        }}
      />
      <AllRoomsView {...props} />
    </>
  );
}

function AllRoomsView({ data, handleSelectRecord }) {
  const { userData } = useApplicationStates();
  const { currentUser } = userData;

  const formattedData = data.length > 0 ? sortyByDate(data, "room_updated_at") : [];

  return (
    <>
      <div className={`min-height-0 d-flex flex-grow-1`}>
        <div className={`w-100 px-3 py-4 pb-5 overflow-auto`}>
          {formattedData.length === 0 ? (
            <div className="text-center">
              <p>No rooms to display</p>
            </div>
          ) : null}
          {formattedData.map((room) => (
            <div key={room.room_id} className="mb-4">
              <RoomTile room={room} currentUser={currentUser} onClick={() => handleSelectRecord(room)} mobile />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

function SelectedRoomView({ setSelectedRoom, selectedRecord, setSelectedView, handleResetView, recordTitle }) {
  const { userData } = useApplicationStates();
  const { currentUser } = userData;
  const navigate = useNavigate();

  const selectedRoomBtn = [
    {
      btnText: "Go To Room",
      btnIcon: faSms,
      btnOnclick: () => {
        if (selectedRecord.room_did_number === null) {
          //console.log(`selectedRoom`, selectedRecord)
          setSelectedRoom(selectedRecord);
          return setSelectedView("select-did");
        }
        navigate("/user/messages", { state: { room_id: selectedRecord.room_id } });
      },
    },
  ];

  //TODO DUPLICATE
  function formatLastMessageText(room) {
    let prefix;
    let classname;

    if (room.last_message_from === currentUser.user_name) {
      prefix = "You: ";
      classname = "line-clamp-1";
    }

    if (room.room_customers.length > 1 || room.room_users.length > 1) {
      prefix = room.last_message_from.split(" ", 1)[0] + ": ";
      classname = "line-clamp-3";
    }

    return (
      <div className={classname}>
        {prefix}
        {room.last_message_text}
      </div>
    );
  }

  return (
    <MobileViewSelect.SelectedRecordView
      handleResetView={handleResetView}
      recordTitle={recordTitle}
      selectedRecord={selectedRecord}
      square
    >
      <MobileViewSelect.ButtonBar>{selectedRoomBtn}</MobileViewSelect.ButtonBar>
      <div className="overflow-auto">
        <div style={{ paddingBottom: "6rem" }}>
          <MobileViewSelect.InfoTileLayout icon={faEnvelope}>
            <div className="w-100">
              <div>{formatLastMessageText(selectedRecord)}</div>
            </div>
          </MobileViewSelect.InfoTileLayout>

          <MobileViewSelect.InfoTileLayout icon={faCalendarAlt}>
            <div className="w-100">
              <div className="">
                <p>Sent At:</p>
                <div className="text-end">{convertTimestampToDate(selectedRecord.room_updated_at)}</div>
              </div>
            </div>
          </MobileViewSelect.InfoTileLayout>

          <MobileViewSelect.InfoTileLayout icon={faUser}>
            <div className="w-100">
              <div>
                <p>Users in room:</p>
              </div>
              <div>
                {selectedRecord.room_users.map((user, index) => (
                  <div key={index}>{combineFirstAndLastName(user)}</div>
                ))}
              </div>
            </div>
          </MobileViewSelect.InfoTileLayout>

          <MobileViewSelect.InfoTileLayout icon={faPhone}>
            <div className="w-100">
              <div>
                <p>Via DID:</p>
              </div>
              <div>{formatPhoneNumber(selectedRecord.room_did_number)}</div>
            </div>
          </MobileViewSelect.InfoTileLayout>

          <MobileViewSelect.InfoTileLayout icon={faPersonBooth}>
            <div>
              <div className="w-100">
                <p>Room ID:</p>
              </div>
              <div>{selectedRecord.room_id}</div>
            </div>
          </MobileViewSelect.InfoTileLayout>
        </div>
      </div>
    </MobileViewSelect.SelectedRecordView>
  );
}
