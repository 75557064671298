export default function GroupByLetter({ data, groupKey, children }) {
  const groupedNames = [];

  for (const item of data) {
    // Get first letter of contacts first name
    const itemFirstLetter = item[groupKey][0].toUpperCase();
    // Check if that group exists in groupedNames
    const foundGroup = groupedNames.find(
      (group) => group.letter === itemFirstLetter
    );

    if (foundGroup) {
      foundGroup.items = [...foundGroup.items, item];
    } else {
      groupedNames.push({
        letter: itemFirstLetter,
        items: [item],
      });
    }
  }

  return groupedNames.map( group => (
    <div className="w-100 d-flex " key={group.letter}>
      <div
        className={` d-flex justify-content-center position-sticky top-0`}
        style={{ minWidth: "25px", height: "min-content" }}
      >
        {group.letter}
        {/* I don't think this does anything <div className=" "></div> */}
      </div>
      <div className={`flex-grow-1`}>
        {group.items.map((item, index) => (
          <div className="ms-3 mb-4" key={`${group.letter}-${index}}`}>{children(item)}</div>
        ))}
      </div>
    </div>
  ));
}

/* ${styles.contact_tile_name_container}  */
