import Modal from "src/common/Modal/Modal";
import { LAYOUTS } from "src/constants/layout";
import { useEffect, useState } from "react";

export default function RecordWrapperDesktop({ record, selectedView, resetView }) {
  const [modalActive, setModalActive] = useState(false);

  useEffect(() => {
    if (!record && selectedViewKey !== "create") return setModalActive(false);
    setModalActive(true);
  }, [record]);

  const [selectedViewKey, selectedViewProps] = selectedView;

  const children =
    typeof selectedViewProps.children == "function"
      ? selectedViewProps.children({ layout: LAYOUTS.DESKTOP })
      : selectedViewProps.children;

  return (
    <Modal show={modalActive} onHide={() => setModalActive(null)} onExited={resetView} {...selectedViewProps}>
      {children}
    </Modal>
  );
}
