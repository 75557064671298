import { useEffect } from "react";
import { useApplicationStates } from "src/contexts";

// Collection of useEffects related to message/room socket events
export default function useSocketEffects_Messages({
  fetchRoomMessages,
  handleUpdateLastReadMessageID,
  setSelectedContact,
  setSelectedView,
  selectedRoom,
  setSelectedRoom,
  selectedRoomMessages,
  setSelectedRoomMessages,
  setUploadedFile,
}) {
  const { socket, sharedAppStates } = useApplicationStates();
  const { setSelectedMessageViewRoomID } = sharedAppStates;

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // FETCH ROOM MESSAGES ON ROOM CHANGE
  /////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (selectedRoom !== null) {
      fetchRoomMessages(selectedRoom.room_id);
    }
  }, [selectedRoom, fetchRoomMessages]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // HANDLE INCOMING MESSAGE
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function handleUpdateMessages(newMessage) {
      if (selectedRoom && newMessage.message_room_id === selectedRoom.room_id) {
        setSelectedRoomMessages((prev) => [...prev, newMessage]);
      }
    }

    socket.on("responseNewMessage", handleUpdateMessages);
    // If theres an attached image while changing rooms then remove it
    setUploadedFile(null);

    // Remove the socket for that room on room change
    return () => socket.off("responseNewMessage", handleUpdateMessages);
  }, [socket, selectedRoom, setSelectedRoomMessages, setUploadedFile]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // UPDATE MESSAGE DELIVERY STATUS
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function updateMessageDeliveryStatus(room_id, message_id, status) {
      if (room_id === selectedRoom.room_id)
        setSelectedRoomMessages((prev) => {
          const foundMessage = prev.find((message) => message.message_id === message_id);
          foundMessage.delivered_to_carrier = status;
          return [...prev];
        });
    }

    if (selectedRoom) {
      socket.on("responseMessageDeliveryStatus", updateMessageDeliveryStatus);
    }

    return () => socket.off("responseMessageDeliveryStatus", updateMessageDeliveryStatus);
  }, [socket, selectedRoom, setSelectedRoomMessages]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // UPDATE LAST MESSAGE READ ID
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // Whenever a room is selected and the messages are loaded/sent/received, update the last read message ID in the
  // room_user_lookup table
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (selectedRoom && Array.isArray(selectedRoomMessages) && selectedRoomMessages.length > 0) {
      handleUpdateLastReadMessageID(
        // Get the ID of the last message retrieved
        selectedRoomMessages.at(-1).message_id
      );
    }
  }, [selectedRoom, selectedRoomMessages, handleUpdateLastReadMessageID]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // TURN OFF NOTIFICATIONS WHEN ROOM IS IN VIEW
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (selectedRoom) {
      setSelectedMessageViewRoomID(selectedRoom.room_id);
    } else {
      setSelectedMessageViewRoomID(null);
    }

    return () => setSelectedMessageViewRoomID(null);
  }, [selectedRoom, setSelectedMessageViewRoomID]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // ENABLE/DISABLE ROOMS
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const selectedRoomID = selectedRoom ? selectedRoom.room_id : null;

    function activateDisabledModel(roomID) {
      if (roomID === selectedRoomID) {
        setSelectedRoom(null);
        setSelectedRoomMessages(null);
        setSelectedContact(null);
        setSelectedView("ROOM_DISABLED");
      }
    }

    function clearDisableModel(roomID) {
      if (roomID === selectedRoomID) {
        setSelectedView(null);
      }
    }

    socket.on("responseDisableRoom", activateDisabledModel);
    socket.on("responseReopenRoom", clearDisableModel);

    return () => {
      socket.off("responseDisableRoom", activateDisabledModel);
      socket.off("responseReopenRoom", clearDisableModel);
    };
  }, [socket, selectedRoom, setSelectedRoom, setSelectedRoomMessages, setSelectedContact, setSelectedView]);
}
