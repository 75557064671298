import { useApplicationStates } from "src/contexts";

export default function useSocketEvent() {
  const { socket } = useApplicationStates();

  function emitEvent(eventName, data, callback) {
    socket.emit(eventName, data, callback);
  }

  return {
    emitEvent,
  };
}
