import type { FCM_Notification_Payload } from "@sharedTypes/fcm_types";

type NotificationConstructorPayload = FCM_Notification_Payload & { messageId: string, room_id: string | number };

export function notificationConstructor({ body, icon, badge, room_id, messageId }: NotificationConstructorPayload) {
  return {
    body,
    icon,
    badge,
    tag: messageId, // Tag prevents multiple notifications when more than one browser tab is open
    data: {
      room_id,
    },
  };
}
