import { useCallback, useState, useEffect } from "react";
import { customersAttributes, eagerLoadedRoom, usersAttributes } from "src/types/models";
import { useApplicationStates } from "src/contexts";
import { rooms } from "@tsmodels/rooms";
import { RoomInstanceWithMembers } from "@tscontrollers/rooms.controller";

export function useFindRooms({ selectedContact, primaryKey }) {
  const { userData, sharedAppStates } = useApplicationStates();
  const { currentUser } = userData;
  const { roomsList } = sharedAppStates;

  const [foundRooms, setFoundRooms] = useState<RoomInstanceWithMembers[]>([]);
  const [personalRooms, setPersonalRooms] = useState<RoomInstanceWithMembers[] | null>(null);
  type Contact = customersAttributes | usersAttributes;
  
  const findPersonalRooms = useCallback(
    
    (contactRecord: Contact, primaryKey) => {
      if(!currentUser) return console.error(`No Current User`)
      if (primaryKey && contactRecord) {
        let foundPersonalRooms;

        if (primaryKey === "customer_id" && "customer_id" in contactRecord) {
          foundPersonalRooms = foundRooms.filter(
            (room) =>
              room?.room_customers?.length === 1 &&
              room?.room_customers[0].customer_id === contactRecord.customer_id
          );
        }

        if (primaryKey === "user_id") {
          foundPersonalRooms = foundRooms.filter(
            (room) =>
              room.room_users?.length === 2 &&
              room.room_users.some(
                (user) => user.user_id === currentUser.user_id
              )
          );
        }

        return setPersonalRooms(foundPersonalRooms);        
      } 
      
      else {
        return console.error(
          `An argument was invalid- \n primaryKey: ${primaryKey} \n contactRecord:`,
          contactRecord
        );
      }
    },
    [currentUser, foundRooms]
  );

  const findRooms = useCallback(
    (contactRecord, primaryKey) => {
      if (primaryKey && contactRecord) {
        let foundRooms: RoomInstanceWithMembers[] = [];

        switch (primaryKey) {
          case "customer_id":
            foundRooms = roomsList.filter((room) => {
              const hasCustomers = room.room_customers.some(
                roomcustomer => roomcustomer.customer_id === contactRecord.customer_id
              );
              return hasCustomers && room.room_enabled
            });

            setFoundRooms(foundRooms);
            break;

          case "user_id":
            foundRooms = roomsList.filter(
              (room) =>
                room.room_customers.length === 0 &&
                room.room_users.some(
                  (user) => user.user_id === contactRecord.user_id
                )
            );
            break;

          default:
            return console.error("No Key Found in useFindRooms!")
        }

        return setFoundRooms(foundRooms);
      } 
      
      else {
        return null;
      }
    },
    [roomsList]
  );

  useEffect(() => {
    if (selectedContact) {
      findRooms(selectedContact, primaryKey);
    }
  }, [selectedContact, primaryKey, findRooms]);

  useEffect(() => {
    if (selectedContact) {
      findPersonalRooms(selectedContact, primaryKey);
    }
  }, [selectedContact, primaryKey, findPersonalRooms]);

  return { findPersonalRooms, findRooms, foundRooms, personalRooms };
}
