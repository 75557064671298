// Libraries
import { useState } from "react";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
//Contexts
import { useApplicationStates } from "src/contexts";
//Components
import {
  ResponsiveContainer,
  DataViewSelect,
  Buttons,
} from "src/common/commonViews.index.js";

//Logic
import { adminCodesFormLogic } from "./AdminCodes.index";
import renderMap from "./logic/adminCodesColumnMap"
import AdminCodesMobileViews from "./subcomponents/AdminCodesMobileViews";
import { BSBreakpoints } from "src/hooks/useWindowDimensions";
import useSearchBar from "src/hooks/useSearchBar";
import usePagination from "src/hooks/usePagination";

export default function AdminCodes() {
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Contexts
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const { adminAppStates, socket } = useApplicationStates();
  const { adminCompanies } = adminAppStates;

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Admin Code states and variables
  /////////////////////////////////////////////////////////////////////////////////////////////////

  const [selectedCode, setSelectedCode] = useState(null);
  const [selectedView, setSelectedView] = useState(null);
  // Pagination
  const [selectedPageNum, setSelectedPageNum] = useState(1);

  // Name of primary key identifying unique record
  const primaryKey = "billing_code";

  const { searchBar, filteredResults } = useSearchBar({ list: "adminCodes", renderMap });
  // Redeclare for code readability
  const adminCodesToDisplay = filteredResults;

  const { displayIndexes, numOfPages, paginate, resultsSelector } =
    usePagination(
      adminCodesToDisplay.length,
      selectedPageNum,
      setSelectedPageNum
    );

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Record handling methods
  /////////////////////////////////////////////////////////////////////////////////////////////////

  function handleSelectRecord(record) {
    if (selectedCode && selectedCode === record) {
      return setSelectedCode(null);
    }

    setSelectedCode(record);
  }

  // Deselect a record
  function handleDeselectRecord() {
    return setSelectedCode(null);
  }

  function handleAddCode(newCode) {
    newCode.billcode_company_id = translateNameToCompanyID(newCode.billcode_company_name);
    socket.emit("requestAdminNewCode", newCode);
  }

  function handleEditCode(editedRecord) {
    //Handles the name change to an actual value change on the billcode's foreign key
    editedRecord.billcode_company_id = translateNameToCompanyID(editedRecord.billcode_company_name);
    //Emit the event to the socket
    socket.emit(
      "requestAdminEditCode",
      editedRecord,
      // Send previous code as well in case billing code (the primary key) has changed and to
      // check did numbers for change (and subsequently determine whether to send update or not)
      selectedCode
    );
  }

  // Don't need to worry about updating DID's as you can't delete a billing code if any are assigned
  function handleDeleteCode() {
    socket.emit("requestDeleteCode", selectedCode.billing_code);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Helper Functions
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function translateNameToCompanyID(companyName) {
    const companyID = adminCompanies
      .find(company => company.company_name === companyName)
      .company_id;
    return companyID;
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Modal methods and props
  /////////////////////////////////////////////////////////////////////////////////////////////////

  function handleResetView() {
    setSelectedView(null);
    setSelectedCode(null);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Component props
  /////////////////////////////////////////////////////////////////////////////////////////////////

  function buttonConstructor() {
    /*    const underXLBreakpoint = width < BSBreakpoints.xl ? true : false; */
    return [
      {
        label: "Edit",
        /*   className: `${styles.client_action_btn} ${
          underXLBreakpoint ? "mb-1" : "me-1"
        }`, */
        onClick: () => setSelectedView("edit-code"),
        faIcon: faPencilAlt,
      },
      {
        label: "Delete",
        /*   className: `${styles.client_action_btn} ${
          underXLBreakpoint ? "mb-1" : "me-1"
        }`, */
        onClick: () => setSelectedView("delete-code"),
        faIcon: faTrashAlt,
      },
    ];
  }

  function tableHeader() {
    function firstRowChildren(width) {
      return (
        <div
          className={`d-flex ${
            width < BSBreakpoints.lg
              ? "justify-content-center"
              : "justify-content-end"
          } flex-grow-1`}
        >
          {searchBar()}
          {resultsSelector(`mx-2 ms-4 d-flex align-items-center flex-nowrap
            text-nowrap`)}
        </div>
      );
    }

    function breakpointSelect(width) {
      return (
        <div>
          <div
            key={"buttons"}
            className="d-flex flex-grow-1 flex-md-grow-0 justify-content-between"
          >
            <Buttons.AddNew
              view={"add-code"}
              setSelectedView={setSelectedView}
              className="ms-auto"
            />
          </div>
          {numOfPages > 1 && width >= BSBreakpoints.md ? paginate() : null}
        </div>
      );
    }

    return {
      title: "Billing Codes",
      firstRowChildren,
      children: breakpointSelect,
    };
  }

  const dataViewSelectProps = {
    //Data to render
    data: adminCodesToDisplay,
    // Map logic on how to render data
    renderMap,
    // The primary key to use when selecting roomRecords
    primaryKey,
    // Indexes to slice when using table layout
    displayIndexes,
    // Button logic to use when using table layout
    buttonConstructor,
    // Logic map for forms and modal
    logicMap: adminCodesFormLogic(adminCompanies),
    //Header props
    headerProps: setSelectedView,

    // General handle functions and props
    selectedRecord: selectedCode,
    selectedView,
    setSelectedView,
    handleResetView,
    handleSelectRecord,
    handleDeselectRecord,
    //Handle functions for selected view
    handleFunctions: {
      "add-code": handleAddCode,
      "edit-code": handleEditCode,
      "delete-code": handleDeleteCode,
    },
    // The component to use when the viewport is smaller than 450px;
    mobileView: (props) => <AdminCodesMobileViews {...props} adminCompanies={adminCompanies} />,
    tableHeader,
    searchBar,
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Return
  /////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <ResponsiveContainer>
      <DataViewSelect {...dataViewSelectProps} />
    </ResponsiveContainer>
  );
}
