import { LAYOUTS } from "src/constants";
import { useMediaQueries } from "src/hooks";
import useSendForegroundNotification from "src/hooks/useFCMNotificationListener";
import { useEffect, useState } from "react";
import filterSoftRecords from "src/helpers/filterSoftRecords";
import { customersAttributes } from "@tsmodels/customers";
import { users, usersAttributes } from "@tsmodels/users";
import { RoomInstanceWithMembers } from "@tscontrollers/rooms.controller";
import { did_numbers } from "@tsmodels/did_numbers";

export default function useSharedStates() {
  const currentMediaBreakpoint = useMediaQueries(["(min-width: 576px)"], [LAYOUTS.DESKTOP], LAYOUTS.MOBILE);
  const [userStats, setUserStats] = useState(null);
  const [customersList, setCustomersList] = useState<customersAttributes[]>([]);
  const [fullCustomersList, setFullCustomersList] = useState<customersAttributes[]>([]);
  const [usersInCode, setUsersInCode] = useState<usersAttributes[]>([]);
  const [fullUsersInCode, setFullUsersInCode] = useState<usersAttributes[]>([]);
  const [deletedCustomersList, setDeletedCustomersList] = useState<customersAttributes[]>([])
  const [roomsList, setRoomsList] = useState<RoomInstanceWithMembers[]>([]);
  const [deletedRoomsList, setDeletedRoomsList] = useState<RoomInstanceWithMembers[]>([]);
  const [fullRoomsList, setFullRoomsList] = useState<RoomInstanceWithMembers[]>([]);
  const [deletedUsersInCode, setDeletedUsersInCode] = useState<users[]>([]);
  const [billCodeDIDs, setBillCodeDIDs] = useState<did_numbers[]>([]);
  const [selectedMessageViewRoomID, setSelectedMessageViewRoomID] = useState<null | string>(null);
  const [socketStatus, setSocketStatus] = useState(false);
  const [serverResponse, setServerResponse] = useState<null | string>(null);
  const [error, setError] = useState<null | string>(null);
  const [installingUpdate, setInstallingUpdate] = useState(false);
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [showReleaseNotes, setShowReleaseNotes] = useState<boolean>(false);
  const [unattendedCustomers, setUnattendedCustomers] = useState(null);

  useSendForegroundNotification(selectedMessageViewRoomID);
  
  useEffect(() => {
    const { existingRecords, deletedRecords } = filterSoftRecords(fullCustomersList);
    setCustomersList(existingRecords);
    setDeletedCustomersList(deletedRecords)
  }, [fullCustomersList]);

  useEffect(() => {
    const { existingRecords, deletedRecords } = filterSoftRecords(fullUsersInCode);
    setUsersInCode(existingRecords);
    setDeletedUsersInCode(deletedRecords);
  }, [fullUsersInCode])

  useEffect(() => {
    const { existingRecords, deletedRecords } = filterSoftRecords(fullRoomsList);
    setRoomsList(existingRecords);
    setDeletedRoomsList(deletedRecords);
  }, [fullRoomsList]);

  return {
    billCodeDIDs,
    currentMediaBreakpoint,
    customersList,
    deletedCustomersList,
    deletedRoomsList,
    deletedUsersInCode,
    error,
    fullCustomersList,
    installingUpdate,
    showReleaseNotes,
    roomsList,
    selectedMessageViewRoomID,
    serverResponse,
    setBillCodeDIDs,
    setError,
    setFullCustomersList,
    setFullRoomsList,
    setFullUsersInCode,
    setInstallingUpdate,
    setShowReleaseNotes,
    setSelectedMessageViewRoomID,
    setServerResponse,
    setSocketStatus,
    setUnattendedCustomers,
    setUpdateAvailable,
    setUserStats,
    socketStatus,
    unattendedCustomers,
    updateAvailable,
    usersInCode,
    userStats
  };
}
