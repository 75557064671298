import { useEffect, useState } from "react";
import { editRecord, deleteRecord } from "../helpers/helpers.index";
import { ApplicationStatesContextType } from "src/contexts/ApplicationStates/ApplicationStates.context";
import { BillingCodeWithOptions } from "@tscontrollers/billing_codes.controller";
import { ResponseDeletedDID } from "@tsSockets/admin/dids.admin";

type AdminSocketEventProps = ApplicationStatesContextType["adminSocketEventProps"];
export type ClientSideBillCodes = BillingCodeWithOptions & { billcode_company_name: string };

export default function useAdminSocketEvents({ 
  socket, 
  userData, 
  sharedAppStates, 
  adminAppStates, 
  supplyBillCode
}: AdminSocketEventProps) {
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const { currentUser } = userData;
  const { setFullCustomersList, setUnattendedCustomers, setFullRoomsList, setFullUsersInCode, billCodeDIDs, setBillCodeDIDs } =
    sharedAppStates;
  const { selectedAdminBillCode, setAdminCodes, setFullAdminCompanies, setAdminDIDs, setSelectedAdminBillCode } =
    adminAppStates;

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // On-load User Data Retrieval useEffects
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // Set current user role on retrieval of user data (if anything other than admin or dev then no useEffects in this file will fire)
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (currentUser && (currentUser.user_role === "admin" || currentUser.user_role === "dev")) {
      setUserIsAdmin(true);
    }
  }, [currentUser]);

  // If the admins selected billing code is null then set it to the admins default billing code
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function updateSelectedCode() {
      return socket.emit("requestUpdateSelectedAdminBillCode", selectedAdminBillCode);
    }
    // If there is no selectedAdminBillCode, set it to default on load
    if (userIsAdmin) {
      if (!selectedAdminBillCode && currentUser?.billing_code) {
        // Does not need to be updated on socket as users billing code is default
        return setSelectedAdminBillCode(currentUser.billing_code);
      }

      if (selectedAdminBillCode) {
        // Update selected code whenever it changes
        updateSelectedCode();
        // If selectedBillingCode is different than the users default on socket connection, update the selected code
        // (selectedAdminBillCode will reset  on socket disconnection, this reconnects to it)
        socket.on("connect", updateSelectedCode);
      }
    }

    return () => { socket.off("connect", updateSelectedCode) };
  }, [socket, userIsAdmin, currentUser, selectedAdminBillCode, setSelectedAdminBillCode]);

  // Update all information when an admin changes their selected bill code
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (userIsAdmin && selectedAdminBillCode) {
      const billing_code = supplyBillCode();
      if(!billing_code) return console.warn(`No billcode to tie to request!`)
      
      socket.emit("requestCustomersByCode", (customers, unattendedCount) => {
        setUnattendedCustomers(unattendedCount);
        return setFullCustomersList(customers);
      });
      
      socket.emit("requestAllRoomsInCode", billing_code, (rooms) => {
        // For each room in the returned list
        rooms.forEach(room => {
          // See if it has customers involved
          if(room.room_customers.length) {
            // Assign all the customer numbers to a const
            const customerPhones = room.room_customers.map(customer => customer.phone_number);
            // Create an key with the value of an array containing customer phones includind Room DID if it exists
            room.phones = room?.did_number 
              ? [...customerPhones, room.did_number] 
              : [...customerPhones];
          }
        })
        // Set the state of the resulting rooms
        setFullRoomsList(rooms);
      });
      
      
      socket.emit("requestAdminUsersInSelectedCode", (users) => setFullUsersInCode(users));
      socket.emit("requestBillCodeDIDs", billing_code, (users) => setBillCodeDIDs(users));
    }
  }, [
    userIsAdmin,
    socket,
    selectedAdminBillCode,
    setFullCustomersList,
    setFullRoomsList,
    setFullUsersInCode,
    setBillCodeDIDs,
    setUnattendedCustomers,
    supplyBillCode
  ]);

  // Request all billing codes
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (userIsAdmin) {
      socket.emit("requestAdminCodes", (response) => {
        response.forEach((record) => (record.billcode_company_name = record.billcode_company.company_name));
        setAdminCodes(response);
      });
    }

    return () => { socket.off("requestAdminCodes") };
  }, [userIsAdmin, socket, setAdminCodes]);

  // Request all companies
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (userIsAdmin) {
      socket.emit("requestAdminCompanies", (dids) => setFullAdminCompanies(dids));
    }

    return () => { socket.off("requestAdminCompanies") };
  }, [socket, userIsAdmin, setFullAdminCompanies]);

  // Request all DID numbers
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (userIsAdmin) {
      const billing_code = supplyBillCode();
      if(!billing_code) return console.warn(`There is no billcode to tie to request!`);
      socket.emit("requestAdminDIDs", billing_code, (dids) => setAdminDIDs(dids));
    }

    return () => { socket.off("requestAdminDIDs") };
  }, [socket, userIsAdmin, setAdminDIDs, supplyBillCode]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Billing Codes
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // Create new billing code
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function adminNewCode(newCode: BillingCodeWithOptions & { billcode_company_name: string }) {
      newCode.billcode_company_name = newCode.billcode_company.company_name;
      // Add new billing code
      setAdminCodes((prev) => [...prev, newCode]);

      // And update each did record assigned to code
      setAdminDIDs((prev) => {
        const prevDIDs = [...prev];
        newCode.did_numbers.forEach((did) => editRecord(did, "did_number", prevDIDs));
        return prevDIDs;
      });
    }

    if (userIsAdmin) {
      socket.on("responseAdminNewCode", adminNewCode);
    }

    return () => { socket.off("responseAdminNewCode", adminNewCode) };
  }, [userIsAdmin, socket, setAdminCodes, setAdminDIDs]);

  // Edit a billing code
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (userIsAdmin) {
      socket.on("responseAdminEditCode", (updatedBillCode, prevBillCode) => {
        //Flatten nested include value for logicMap keyName compatibility
        updatedBillCode.editedCode.billcode_company_name = updatedBillCode.editedCode.billcode_company.company_name;
        // Edit billing code
        setAdminCodes((prev) => editRecord(updatedBillCode.editedCode, "billing_code", prev, prevBillCode));

        // Update the available did state with the bill codes DIDS
        //TODO logic for changing bill code name on current user if changed
        if (updatedBillCode.editedCode.billing_code === selectedAdminBillCode) {
          setBillCodeDIDs(updatedBillCode.editedCode.did_numbers);
        }

        // Update each DID record that is now assigned to the edited bill code
        setAdminDIDs((prev) => {
          let prevDIDs = [...prev];

          updatedBillCode.editedCode.did_numbers.forEach((did) => (prevDIDs = editRecord(did, "did_number", prevDIDs)));

          return prevDIDs;
        });

        // And remove any association from DIDs previously associated with bill code
        if (updatedBillCode.removedDIDs.length) {
          setAdminDIDs((prev) => {
            let prevDIDs = [...prev];

            updatedBillCode.removedDIDs.forEach(
              (did) => (prevDIDs = editRecord({ ...did, did_billing_code: null }, "did_number", prevDIDs))
            );

            return prevDIDs;
          });
        }
      });
    }

    return () => { socket.off("responseAdminEditCode") };
  }, [userIsAdmin, socket, setAdminCodes, setAdminDIDs, setBillCodeDIDs, selectedAdminBillCode]);

  useEffect(() => {
    function adminDeleteCode(deletedCodeID) {
      setAdminCodes((prev) => deleteRecord(deletedCodeID, "billing_code", prev));
    }

    if (userIsAdmin) {
      socket.on("responseDeleteCode", adminDeleteCode);
    }

    return () => { socket.off("responseDeleteCode", adminDeleteCode) };
  }, [userIsAdmin, socket, setAdminCodes]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Companies
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Create new company
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function adminNewCompany(newCompany) {
      // Add new billing code
      setFullAdminCompanies((prev) => [...prev, newCompany]);
    }

    if (userIsAdmin) {
      socket.on("responseNewCompany", adminNewCompany);
    }

    return () => { socket.off("responseNewCompany", adminNewCompany) };
  }, [userIsAdmin, socket, setFullAdminCompanies]);

  // Edit a company
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function adminEditCompany(editedCompany) {
      setFullAdminCompanies((prev) => editRecord(editedCompany, "company_id", prev));
    }

    if (userIsAdmin) {
      socket.on("responseUpdateCompany", adminEditCompany);
    }

    return () => { socket.off("responseUpdateCompany", adminEditCompany) };
  }, [userIsAdmin, socket, setFullAdminCompanies]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // DID Numbers
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // New DID number
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function adminNewDID(newDID) {
      setAdminDIDs((prev) => [...prev, newDID]);

      // Add the new DID number if it matches the current admins selected bill code
      if (newDID.billing_code === selectedAdminBillCode) {
        setBillCodeDIDs((prev) => [...prev, newDID]);
      }

      // If a bill code is assigned to the did, add this new did to the bill code record
      if (newDID.did_billing_code) {
        setAdminCodes((prev) => {
          const foundCode = prev.find((code) => code.billing_code === newDID.did_billing_code);

          return editRecord(
            {
              ...foundCode,
              did_numbers: [...foundCode.did_numbers, { ...newDID }],
            },
            "did_number",
            prev
          );
        });
      }
    }

    if (userIsAdmin) {
      socket.on("responseNewDID", adminNewDID);
    }

    return () => { socket.off("responseNewDID", adminNewDID) };
  }, [userIsAdmin, socket, setAdminDIDs, setAdminCodes, setBillCodeDIDs, selectedAdminBillCode]);

  // Edit a DID number
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (userIsAdmin) {
      socket.on("responseEditedDID", (resEditedDID, prevBillCode) => {
        setAdminDIDs((prev) => editRecord(resEditedDID, "did_number", prev));

        // If the DID already exists in the billCodeDIDs array
        if (billCodeDIDs.some((did) => did.did_number === resEditedDID.did_number)) {
          // And bill code did not change, update DID
          if (resEditedDID.did_billing_code === selectedAdminBillCode) {
            setBillCodeDIDs((prev) => editRecord(resEditedDID, "did_number", prev));
          } else {
            // Otherwise remove it from array
            setBillCodeDIDs((prev) => deleteRecord(resEditedDID.did_number, "did_number", prev));
          }
        } else {
          // Bill code changed on existing DID so add it to array
          setBillCodeDIDs((prev) => [...prev, resEditedDID]);
        }

        // If the edited DIDs billing code does not match the previous one
        if (resEditedDID.did_billing_code !== prevBillCode) {
          setAdminCodes((prev) => {
            let foundCode;
            let updatedDIDs;

            if (prevBillCode !== null) {
              // Find the previous billing code the did was associated with
              foundCode = prev.find((code) => code.billing_code === prevBillCode);
              // Filter that found codes DIDs for all that are not the edited DID
              updatedDIDs = foundCode.did_numbers.filter((codeDID) => codeDID.did_number !== resEditedDID.did_number);
            } else {
              foundCode = prev.find((code) => code.billing_code === resEditedDID.did_billing_code);
              updatedDIDs = [...foundCode.did_numbers, resEditedDID];
            }

            // And update that record
            return editRecord({ ...foundCode, did_numbers: [...updatedDIDs] }, "billing_code", prev);
          });
        }
      });
    }

    return () => { socket.off("responseEditedDID") };
  }, [userIsAdmin, socket, setAdminDIDs, setAdminCodes, billCodeDIDs, setBillCodeDIDs, selectedAdminBillCode]);

  // Change DID phone status
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function adminDIDPhoneStatus(did) {
      setAdminDIDs((prev) => editRecord(did, "did_number", prev));
      setBillCodeDIDs((prev) => editRecord(did, "did_number", prev));
    }

    if (userIsAdmin) {
      socket.on("responseAdminDIDPhoneStatus", adminDIDPhoneStatus);
    }

    return () => { socket.off("responseAdminDIDPhoneStatus", adminDIDPhoneStatus) };
  }, [userIsAdmin, socket, setAdminDIDs, setBillCodeDIDs]);

  // Delete a DID number
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function adminDeleteDID(deletedDID: ResponseDeletedDID) {
      setAdminDIDs((prev) => deleteRecord(deletedDID, "did_number", prev));
    }

    if (userIsAdmin) {
      socket.on("responseDeleteDID", adminDeleteDID);
    }

    return () => { socket.off("responseDeleteDID", adminDeleteDID) };
  }, [userIsAdmin, socket, setAdminDIDs]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Users
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // Add a new user
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (userIsAdmin) {
      socket.on("responseNewUser", (newUser) => {
        if (newUser.billing_code === selectedAdminBillCode) {
          setFullUsersInCode((prev) => [...prev, newUser]);
        }
      });
    }

    return () => { socket.off("responseNewUser") };
  }, [userIsAdmin, socket, selectedAdminBillCode, setFullUsersInCode]);

  // Edit a user
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (userIsAdmin) {
      socket.on("responseEditUser", (editedUser) => {
        if (editedUser.billing_code === selectedAdminBillCode) {
          setFullUsersInCode((prev) => editRecord(editedUser, "user_id", prev));
        }
      });
    }

    return () => { socket.off("responseEditUser") };
  }, [userIsAdmin, socket, selectedAdminBillCode, setFullUsersInCode]);

  // Change a users status
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (userIsAdmin) {
      socket.on("responseAdminChangeUserStatus", (editedUser) => {
        if (editedUser.billing_code === selectedAdminBillCode) {
          setFullUsersInCode((prev) => editRecord(editedUser, "user_id", prev));
        }
      });
    }

    return () => { socket.off("responseAdminChangeUserStatus") };
  }, [userIsAdmin, socket, selectedAdminBillCode, setFullUsersInCode]);

  // Delete a user
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (userIsAdmin) {
      socket.on("responseDeleteUser", (res) => {
        if (res.billing_code === selectedAdminBillCode || !selectedAdminBillCode) {
          setFullUsersInCode((prev) => deleteRecord(res, "user_id", prev));
        }
      });
    }
    return () => { socket.off("responseDeleteUser") };
  }, [userIsAdmin, socket, selectedAdminBillCode, setFullUsersInCode]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Rooms
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // Join a customer room
  /////////////////////////////////////////////////////////////////////////////////////////////////
  /*   useEffect(() => {
    if (userIsAdmin) {
      socket.on(
        "responseCreateCustomerRoom",
        (newRoom) =>
          setFullRoomsList((prev) => {
            editOrAddRecord(newRoom, "room_id", prev);
          })
          const firstNewRoomCustomerID = newRoom.room_customer[0].user_id; //???
        const foundCustomer = customersList.find(
          (customer) => customer.customer_id === firstNewRoomCustomerID
        );
        if (foundCustomer) {
          setFullRoomsList((prev) => [...prev, newRoom]);
        }
      );
    }
    return () => socket.off("responseCreateCustomerRoom");
  }, [userIsAdmin, socket, setFullRoomsList]);
   */

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Logs
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // Retrieve admin logs
  /////////////////////////////////////////////////////////////////////////////////////////////////
  /*   useEffect(() => {
    if (userIsAdmin) {
      socket.on("responseNewUser", (newUser) => {
        if (newUser.billing_code === selectedAdminBillCode) {
          setFullUsersInCode((prev) => [...prev, newUser]);
        }
      });
    }
    return () => socket.off("responseNewUser");
  }, [userIsAdmin, socket, selectedAdminBillCode, setFullUsersInCode]); */
}
