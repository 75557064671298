import { Button } from "react-bootstrap";
import { useApplicationStates } from "src/contexts";
import { useRoomHandlers } from "src/hooks";

import sortArrayOfObjects from "src/helpers/sort/sort.helpers";
import { useNavigate } from "react-router-dom";
import ParticipantSelectionInput from "./RoomParticipantSelectInput";
import { useEffect } from "react";
import styles from "../RoomManagement.module.scss";
/**
 * UI and form for selecting room members.
 * @param {CommonProps} props - Object of props for the component
 * @returns Component
 */
export default function SelectParticipants({ setTitle, setFormStage, roomFormData, setRoomFormData, setSelectedRoom }) {
  ////////////////////////////////////////////////////////////////////////////////
  // HOOKS
  ////////////////////////////////////////////////////////////////////////////////
  const { sharedAppStates, userData } = useApplicationStates();
  const { customersList, usersInCode } = sharedAppStates;
  const { checkCustomerRoomAvailability, checkForExistingRoomInLocalState } = useRoomHandlers();

  // Set title for the wrapper on load
  useEffect(() => {
    setTitle("Select Room Participants");
  }, [setTitle]);

  const navigate = useNavigate();

  ////////////////////////////////////////////////////////////////////////////////
  // CONSTS
  ////////////////////////////////////////////////////////////////////////////////
  // Map customers for select options
  const sortedOptionsCustomers = sortArrayOfObjects(customersList, "first_name").map((member) => ({
    value: member.customer_id.toString(),
    member_type: "customer_ids", // Additional meta property for value filtering
    label: `${member.first_name} ${member.last_name}`,
  }));
  // Map users to for select options
  const sortedOptionsUsers = sortArrayOfObjects(usersInCode, "first_name").map((member) => ({
    value: member.user_id.toString(),
    member_type: "user_ids", // Additional meta property for value filtering
    label: `${member.first_name} ${member.last_name}`,
  }));

  // Combine sorted options
  const options = [...sortedOptionsCustomers, ...sortedOptionsUsers];

  ////////////////////////////////////////////////////////////////////////////////
  // HANDLERS
  ////////////////////////////////////////////////////////////////////////////////

  function handleNext() {
    // If an existing room was found
    const existingRoom = checkForExistingRoomInLocalState({
      customer_ids: roomFormData.selected_room_members.customer_ids,
      room_did_number: roomFormData.room_did_number,
      // Insert current users ID into room_users array to match potential existing room
      user_ids: roomFormData.room_did_number
        ? null
        : [userData.currentUser.user_id, ...roomFormData.selected_room_members.user_ids],
    });

    if (existingRoom) {
      setSelectedRoom(existingRoom);
      navigate(`/user/messages?room_id=${existingRoom.room_id}`);
      setFormStage("EXISTING_ROOM_FOUND");
      return;
    }

    const { customer_ids } = roomFormData.selected_room_members;

    // // If no customers just create a new user-only room
    if (!customer_ids.length) {
      setRoomFormData((prev) => ({ ...prev, submitType: "CREATE_NEW_ROOM" }));
      return setFormStage("CONFIRM_ROOM_ACTION");
    }

    checkCustomerRoomAvailability({ customer_ids: customer_ids }, ([roomIsAvailable, occupiedRooms]) => {
      if (roomIsAvailable) {
        setRoomFormData((prev) => ({ ...prev, submitType: "CREATE_NEW_ROOM" }));
        setFormStage("CONFIRM_ROOM_ACTION");
      } else {
        setRoomFormData((prev) => ({ ...prev, occupied_rooms: occupiedRooms }));
        setFormStage("OCCUPIED_ROOM_FOUND");
      }
    });
  }

  return (
    <div className="overflow-auto flex-grow-1 d-flex flex-column //p-2">
      {
        <p className="text-muted">{`You may add up to ${
          9 - roomFormData.selected_room_members.customer_ids.length
        } customers.`}</p>
      }
      <ParticipantSelectionInput
        options={options}
        roomFormData={roomFormData}
        setRoomFormData={setRoomFormData}
        usersOnly={!roomFormData.room_did_number}
      />
      <div className={`${styles.button_wrapper}`}>
        <Button className="me-2" onClick={() => setFormStage("SELECT_DID_NUMBER")}>
          Back
        </Button>
        <Button
          className="ms-2"
          // Disable button if no customers/users are selected
          disabled={
            !roomFormData.selected_room_members.customer_ids.length &&
            !roomFormData.selected_room_members.user_ids.length
          }
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
