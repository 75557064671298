// Libraries/Constants
import { useEffect } from "react";
import markerSDK from "@marker.io/browser";
import Router from "src/routes/Router.jsx";
// Providers
import { AuthProvider } from "src/contexts/Authentication";
import "src/scss/index.scss";
import * as Sentry from "@sentry/react";
import { ServiceWorkerProvider } from "./contexts/ServiceWorker/ServiceWorker.context";
export default function App() {  
  // Use Marker.IO in production environment
  ////////////////////////////////////////////////////////////
  useEffect(() => {
    if (import.meta.env.MODE === "production") getWidget();
    
    async function getWidget() {
      const widget = await markerSDK.loadWidget({
        destination: "62a5087eaf476bee70683f02",
      });
      widget.show();
    }
  }, []);
  //} Register service worker
  ////////////////////////////////////////////////////////////
  return (
    <>
      <Sentry.ErrorBoundary>         
        <ServiceWorkerProvider>
          {/* Authenticates user and allows access to users firebase information across app */}
          <AuthProvider>
              <Router />
          </AuthProvider>
        </ServiceWorkerProvider>
      </Sentry.ErrorBoundary>
    </>
  );
}
