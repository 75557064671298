import { Button, Form } from "react-bootstrap";
import { useEffect } from "react";
import { useDIDHandlers } from "src/hooks";
import { formatPhoneNumber } from "src/helpers/formattingHelpers";
import { ContactCircle } from "src/common/commonViews.index";
import styles from "../RoomManagement.module.scss";

/**
 * UI and form for selecting a rooms DID number.
 * @param {CommonProps} props - Object of props for the component
 * @returns Component
 */
export default function SelectDIDNumber({
  selectedRoom,
  setTitle,
  setFormStage,
  roomFormData,
  setRoomFormData,
  setSelectedView,
}) {
  const { currentUserDIDs, getDIDDescription, updateCustomerRoomDID } = useDIDHandlers();

  // Set title for the wrapper on load
  useEffect(() => {
    setTitle("Select Room DID Number");
  }, [setTitle]);

  ////////////////////////////////////////////////////////////////////////////////
  // CONSTS
  ////////////////////////////////////////////////////////////////////////////////
  // If a list of available dids has already been determined (such as creating a new room from an occupied room) use those
  const userDIDs = roomFormData.available_did_numbers || currentUserDIDs;
  // Boolean to determin wether to show default "No DID Number" option
  const showDefault = !roomFormData.available_did_numbers;
  // Boolean to determine whether room has a customer but no did number and needs assignment
  const didlessRoom = selectedRoom && selectedRoom.room_customers.length && !selectedRoom.room_did_number;

  // Default No DID for user only rooms
  const defaultValue = {
    value: "",
    label: "No DID - User only room",
  };

  let options = [
    ...userDIDs.map((did) => ({
      value: did.did_number,
      label: ` ${formatPhoneNumber(did.did_number)} - ${getDIDDescription(did.did_number)}`,
    })),
  ];

  // Include default if available did numbers are not available (available did numbers means the room has been processed
  // with users)
  if (showDefault) {
    !didlessRoom && options.unshift(defaultValue);
  }

  ////////////////////////////////////////////////////////////////////////////////
  // HANDLERS
  ////////////////////////////////////////////////////////////////////////////////
  // Assign a did to a room if didless otherwise advance to next form stage
  const submitHandler = didlessRoom
    ? () => {
        updateCustomerRoomDID({
          room_id: selectedRoom.room_id,
          selected_did: roomFormData.room_did_number,
        });

        setSelectedView(null);
      }
    : () => setFormStage("SELECT_ROOM_MEMBERS");

  return (
    <>
      <div className="overflow-auto px-4">
        {options.map(({ value, label }) => {
          return (
            <Form.Check
              key={`${value}-${label}`}
              id={`${"selected_room_did"}-${value}-${label}`}
              className={`${styles.form_check} m-0 py-3 border-bottom`}
              reverse
              name={"room_management_did_select_group"}
              required
            >
              <Form.Check.Input
                type="radio"
                onChange={() => setRoomFormData((prev) => ({ ...prev, room_did_number: value }))}
                value={value}
                checked={roomFormData?.room_did_number === value}
              />
              <span className="d-flex">
                {/* Only show contact circle if option is valid did number */}
                {value && <ContactCircle size="xxsm" circleNames={getDIDDescription(value)} className="me-2" />}
                <Form.Check.Label className="w-100 d-flex text-start" checked={roomFormData.room_did_number === value}>
                  {label}
                </Form.Check.Label>
              </span>
            </Form.Check>
          );
        })}
      </div>
      <div className={`${styles.button_wrapper}`}>
        <Button className="me-2" onClick={() => setSelectedView(null)}>
          Close
        </Button>
        <Button className="ms-2" onClick={submitHandler} disabled={!roomFormData.room_did_number}>
          {didlessRoom ? "Update Room" : "Next"}
        </Button>
      </div>
    </>
  );
}
