import FormConstructor from "../../../../common/FormConstructor/FormConstructor";


function roomsFormLogic({
  billCodeDIDs,
  currentUser,
  userPermissionLevel
}) {
  // Modal logic to use when rendering form fields
  return {
    "send-message": {
      modalTitle: "Send message to",
      initialFormValues: {
        message_text: "",
      },
      fields: {
        message_body: {
          formalName: "Send Message",
          keyName: "message_text",
          placeHolder: "Enter message here",
          inputGroup: false,
          fieldComponent: (props, key) => (
            <FormConstructor.TextArea {...props} key={key} />
          ),
        },
      },
    },
    /*   "archive-room": {
      modalTitle: "Archive room with",
      submitType: "yes/no",
      text: "Are you sure you wish to archive this room?",
      onSubmitOverride: () => {},
    }, */
    "disable-room": {
      modalTitle: "Are you sure you want to disable the room for...",
      submitType: "yes/no",
      text: "Are you sure you wish to disable this room?",
    },
    "reopen-room": {
      modalTitle: "Reopening room ...",
      submitType: "yes/no",
      text: "Are you sure you wish to reopen this room?",
    },
    "delete-room": {
      modalTitle: "Deleting the room for",
      /* submitType: "yes/no", */
      DeleteAlert: () => <p>You are about to disable this room for all users</p>,
    },
    "select-did": {
      modalTitle: "Select a DID Number for this room",
      submitType: (() => {
        if (userPermissionLevel("user")) {
          return currentUser && currentUser.user_dids.length
            ? "yes/no"
            : "none";
        }

        if (userPermissionLevel("mid")) {
          return billCodeDIDs.length ? "yes/no" : "none";
        }
      })(),
      initialFormValues: {
        selected_did: "",
      },
      text:
        currentUser &&
        currentUser.user_dids.length &&
        `You have no existing chat history with the customer in this room- Please select a DID to start a conversation.`,
      fields: {
        did_select: {
          formalName: "DID Numbers",
          keyName: "selected_did",
          inputGroup: false,
          fieldComponent: (props, key) => {
            // Convert to userPermission function
            if (userPermissionLevel("user") && !currentUser.user_dids.length) {
              return (
                <p>
                  You have no DID numbers assigned to your user, please contact
                  a Manager or Administrator if you believe this to be a
                  mistake.
                </p>
              );
            }

            return <FormConstructor.RoomDIDSelect {...props} key={key} />;
          },
        },
      },
    },
  };
}
/*  <div className="d-flex flex-column flex-grow-1">
   <div
     className={`${styles.add_contact_form_inner_wrapper} mx-auto d-flex justify-content-around`}
   >

   </div>
   <hr className="mb-0" />
   <div
     className={`${styles.room_select_list_container} pt-2 min-height-0 px-2 d-flex flex-grow-1 flex-shrink-1`}
   >
     <div className="w-100  overflow-auto ">
       <ListGroup as="ul" className={`${styles.room_select_list}  `}>


           <div className="d-flex">
             <div className={faIconClass}>
               <FontAwesomeIcon icon={faPhone} size="sm" />
             </div>

           </div>
         </ListGroup.Item>
         <ListGroup.Item as="li">
           <div className="d-flex">
             <div className={faIconClass}>
               <FontAwesomeIcon icon={faPersonBooth} size="sm" />
             </div>
             <div>
               <div className="w-100">
                 <p>Room ID:</p>
               </div>
               <div>{selectedRecord.room_id}</div>
             </div>
           </div>
         </ListGroup.Item>
       </ListGroup>
     </div>

   </div>
 </div>; */
export default roomsFormLogic;