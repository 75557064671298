import MobileViewSelect from "src/common/Mobile/MobileViewSelect";
import { nanoid } from "nanoid";
import { convertDateFormat } from "src/helpers/helpers";
import {
  faCalendar,
  faEnvelope,
  faIdBadge,
  faKey,
  faMoneyBill,
  faPhone,
  faUserTag,
} from "@fortawesome/free-solid-svg-icons";

export default function SelectedUser(props) {
  return (
    <MobileViewSelect.SelectedRecordView
      handleResetView={props.handleResetView}
      recordTitle={props.recordTitle}
      selectedRecord={props.selectedRecord}
      viewButtons={[<MobileViewSelect.EditButton key={nanoid()} onClick={() => props.setSelectedView("edit-user")} />]}
    >
      <div className="mb-4">
        <MobileViewSelect.InfoTileLayout icon={faIdBadge}>
          <div>User ID:</div>
          {props.selectedRecord.user_id}
        </MobileViewSelect.InfoTileLayout>

        <MobileViewSelect.InfoTileLayout icon={faEnvelope}>
          <div>Email:</div>
          {props.selectedRecord.email}
        </MobileViewSelect.InfoTileLayout>

        <MobileViewSelect.InfoTileLayout icon={faPhone}>
          <div>Phone:</div>
          {props.selectedRecord.personal_phone}
        </MobileViewSelect.InfoTileLayout>

        <MobileViewSelect.InfoTileLayout icon={faMoneyBill}>
          <div>Billing Code:</div>
          {props.selectedRecord.billing_code}
        </MobileViewSelect.InfoTileLayout>

        <MobileViewSelect.InfoTileLayout icon={faUserTag}>
          <div>Role:</div>
          {props.selectedRecord.user_role}
        </MobileViewSelect.InfoTileLayout>

        <MobileViewSelect.InfoTileLayout icon={faKey}>
          <div>Pushover Key:</div>
          {props.selectedRecord.pushover_user_key ? props.selectedRecord.pushover_user_key : "No Key"}
        </MobileViewSelect.InfoTileLayout>

        <MobileViewSelect.InfoTileLayout icon={faMoneyBill}>
          <div>User Enabled:</div>
          {props.selectedRecord.user_enabled ? "Enabled" : "Disabled"}
        </MobileViewSelect.InfoTileLayout>

        <MobileViewSelect.InfoTileLayout icon={faMoneyBill}>
          <div>Email Verified:</div>
          {props.selectedRecord.email_verified ? "Verified" : "Unverified"}
        </MobileViewSelect.InfoTileLayout>

        <MobileViewSelect.InfoTileLayout icon={faMoneyBill}>
          <div>Password Verified:</div>
          {props.selectedRecord.password_verified ? "Verified" : "Unverified"}
        </MobileViewSelect.InfoTileLayout>

        <MobileViewSelect.InfoTileLayout icon={faMoneyBill}>
          {props.selectedRecord.phone_ext}
        </MobileViewSelect.InfoTileLayout>

        <MobileViewSelect.InfoTileLayout icon={faCalendar}>
          <div>Created At:</div>
          {convertDateFormat(props.selectedRecord.user_created_at)}
        </MobileViewSelect.InfoTileLayout>

        <MobileViewSelect.InfoTileLayout icon={faCalendar}>
          <div>Last Login:</div>
          {convertDateFormat(props.selectedRecord.user_last_login)}
        </MobileViewSelect.InfoTileLayout>

        <MobileViewSelect.InfoTileLayout icon={faCalendar}>
          <div>Last Logout:</div>
          {convertDateFormat(props.selectedRecord.user_last_logout)}
        </MobileViewSelect.InfoTileLayout>
      </div>
    </MobileViewSelect.SelectedRecordView>
  );
}
