import { useApplicationStates, useAuth } from "src/contexts";
import { formatPhoneNumber } from "src/helpers/formattingHelpers";
import { useCallback, useContext, Fragment, useEffect } from "react";
import { Form } from "react-bootstrap";
import FormConstructor, { FormConstructorContext } from "../FormConstructor";

export default function RoomDIDSelect({ view }) {
  const { formInput, setFormInput } = useContext(FormConstructorContext);
  const { userPermissionLevel } = useAuth();
  const { userData, sharedAppStates } = useApplicationStates();
  const { currentUser } = userData;
  const { billCodeDIDs } = sharedAppStates;
  
  useEffect(() => {
    const availableDids = didsAvailable();
    if(availableDids.length === 1 && !formInput.selected_did) {
      setFormInput(prev => ({ ...prev, selected_did: availableDids[0].did_number }))
    }
  }, [ formInput.selected_did, setFormInput])

  const didsAvailable = useCallback(() => {
    if (currentUser) {
      if (userPermissionLevel("mid")) {
        return billCodeDIDs;
      } 
      return currentUser.user_dids;
    }

    return [];
  },[ currentUser, userPermissionLevel, billCodeDIDs ] );

  function handleOnChange(did) {
    setFormInput((prev) => ({ ...prev, selected_did: did.did_number }));
  }

  function handleIsChecked(did) {
    return formInput.selected_did === did.did_number;
  }
  
  function mobileView() {
    return (
      <div className="mx-auto" style={{ "max-width": "275px" }}>
        {
          didsAvailable().map((did) => (
            <>
              <div
                key={did.did_number}
                className="d-flex"
                onClick={() => handleOnChange(did)}
              >
                <Form.Check
                  aria-label={did.did_number}
                  className="me-4 d-flex justify-content-center align-items-center"
                  type="radio"
                  checked={formInput.selected_did === did.did_number}
                  onChange={() => handleOnChange(did)}
                />
                <div>
                  <div>{formatPhoneNumber(did.did_number)}</div>
                  <div>{did.did_description}</div>
                </div>
              </div>
              <hr />
            </>
          ))
        }
        <div style={{ height: "100px" }} />
      </div>
    );
  }

  function tableView() {
    return (
      <FormConstructor.SelectionTable
        list={didsAvailable()}
        headers={["Selected", "Number", "Description"]}
        onChange={handleOnChange}
        checked={handleIsChecked}
        key="did_number"
        radio
      >
        {(did) => (
          <Fragment key={did.did_number}>
            <td>{formatPhoneNumber(did.did_number)}</td>
            <td className="d-flex align-items-center">{did.did_description}</td>
          </Fragment>
        )}
      </FormConstructor.SelectionTable>
    );
  }

  return view === "mobile" 
    ? ( mobileView() ) 
    : didsAvailable() 
      ? ( tableView() ) 
      : (
        <p className="">
          Error- Cannot start a conversation as there are no DID numbers associated
          with this billing code. If you are an Admin you may assign them yourself,
          otherwise please contact support.
        </p>
      );
}