import { useQuery } from "@tanstack/react-query";
import { default as pkg } from "../../../package.json";
import { firebaseAuth } from "src/services/firebase";
import { useApplicationStates } from "src/contexts";
import { useServiceWorkerContext } from "src/contexts/ServiceWorker/ServiceWorker.context";
export type LatestVersionResponse = { LATEST_CLIENT_VERSION: string, releaseNotes: string[] | null };
// Query Hook
export default function useVersionCheckQuery() {
  const { sharedAppStates } = useApplicationStates();
  const serviceWorkerRegistration = useServiceWorkerContext();
  const { setShowReleaseNotes, setUpdateAvailable } = sharedAppStates;
  const { swRegistration } = serviceWorkerRegistration;
  // QOL Assign args
  const queryFn = reqLatestVersion;
  const enabled = !!firebaseAuth;
  // Activate query
  return useQuery<LatestVersionResponse>({ queryKey: ["LatestVersion"], queryFn, enabled });
  // Query Fetch Function
  async function reqLatestVersion() {
    const defaultReturn = { LATEST_CLIENT_VERSION: '', releaseNotes: [] }
    // Attempt to retrieve token
    const fireToken = await firebaseAuth.currentUser?.getIdToken();
    // Write error on failure
    if (!fireToken) { console.error(`Unable to retrieve token`); return defaultReturn }
    // Template the endpoint url
    const endpoint = `${import.meta.env.VITE_BACKEND_URL}/api/version`;
    console.log(`Requesting Release data...`);
    // Fetch the data via GET request
    const request = await fetch(endpoint, {
      method: 'GET',
      headers: {
        "Authorization": fireToken,
        "Accept": "application/json"
      }
    });
    // Parse the version and notes json
    const versionAndNotes = request.json() as Promise<LatestVersionResponse>;
    // QOL assign
    const { LATEST_CLIENT_VERSION } = await versionAndNotes;
    // Compare logic of versions
    if (pkg.version < LATEST_CLIENT_VERSION) {
      try {
        // Tell the current service worker to look for an update
        swRegistration && swRegistration.update();
        // TODO: ENV switch here? Or should this be moved to on routes
        setUpdateAvailable(true);
      }
      catch (err) {
        console.error(err);
      }
    }
    return versionAndNotes;
  }
}