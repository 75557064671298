//Base
import { useState, useEffect } from "react";
//Contexts
import { useApplicationStates } from "src/contexts";
//Hooks
import useWindowDimensions from "src/hooks/useWindowDimensions";
import {
  sortAlphabetically,
  sortByNumber,
  sortyByDate,
} from "src/helpers/helpers";
import {
  ResponsiveContainer,
  DataViewSelect,
} from "src/common/commonViews.index";
import { AdminLogsTableHeaderConstructor } from "./AdminLogs.index";

export default function AdminLogs() {
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // States
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const [selectedRecord, setSelectedRecord] = useState("");
  const [selectedView, setSelectedView] = useState(null);
  const [dateRange, setDateRange] = useState({ start: "", end: "" });

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Contexts
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const { adminAppStates, socket } = useApplicationStates();
  const { adminLogs } = adminAppStates;
  const primaryKey = "phone_number";

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Socket methods
  /////////////////////////////////////////////////////////////////////////////////////////////////use
  useEffect(() => {
    socket.emit(
      "requestAdminLogs",
      //TODO: Thise Needs a better callback
      () => null
    );
  }, [socket]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Render effects
  /////////////////////////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Handle methods
  /////////////////////////////////////////////////////////////////////////////////////////////////

  function handleResetView() {
    setSelectedView(null);
    setSelectedRecord("");
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Component props
  /////////////////////////////////////////////////////////////////////////////////////////////////

  //Get the viewport width for deciding responsive layouts
  const { width } = useWindowDimensions();

  //Maps table titles and data to show.
  //First element in array is table title while second is key to retreive.
  //Uses map to preserve order of insertion.
  const adminLogsColumnMap = new Map([
    [
      "Phone Number",
      {
        columnKey: "phone_number",
        sort: true,
        sortFunc: sortByNumber,
      },
    ],
    [
      "Message",
      {
        columnKey: "message",
        sort: true,
        sortFunc: sortAlphabetically,
      },
    ],
    [
      "Received Date",
      {
        columnKey: "received_date",
        sort: true,
        sortFunc: sortyByDate,
      },
    ],
  ]);

  /*   function buttonData(obj) {
    return [
      {
        label: "Edit",
        onClick: () => {
          setSelectedView("edit-log");
          onClickFunc(obj[mapKey]);
          document.body.click();
        },
        faIcon: faPencilAlt,
      },
      {
        label: "Activate",
        onClick: () => {
          onClickFunc(obj[mapKey]);
          setSelectedView("activate-did");
          document.body.click();
        },
        faIcon: faPencilAlt,
      },
      {
        label: "Delete",
        onClick: () => {
          setSelectedView("delete-did");
          onClickFunc(obj[mapKey]);
          document.body.click();
        },
        faIcon: faTrashAlt,
      },
    ];
  } */

  const dateSearchProps = {
    dateRange,
    setDateRange,
    width,
  };

  const dataViewSelectProps = {
    //Data to render
    data: adminLogs,
    // Map logic on how to render data
    renderMap: adminLogsColumnMap,
    // The primary key to use when selecting records
    primaryKey,
    // Indexes to slice when using table layout
    //displayIndexes,
    // Button logic to use when using table layout
    /*     buttonConstructor:
      contactsList === "customersList"
        ? customerButtonConstructor
        : userButtonConstructor,
 */
    // Logic map for forms and modal
    logicMap: null,
    //Header props
    headerConstructor: AdminLogsTableHeaderConstructor,
    headerProps: dateSearchProps,

    // General handle functions and props
    selectedRecord,
    selectedView,
    setSelectedView,
    handleResetView,
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Return
  /////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <ResponsiveContainer width={width}>
      <DataViewSelect {...dataViewSelectProps} />
      {/* <ModalMaker {...modalMakerProps} /> */}
      {/*       <DataTableHeader {...dataTableHeaderProps} />
      <DataTable {...dataTableProps} /> */}
    </ResponsiveContainer>
  );
}
