import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useApplicationStates } from "src/contexts";
import { useLocation } from "react-router-dom";


export default function useSearchBar({list, renderMap}) {
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Contexts and States
  const { sharedAppStates, userData, adminAppStates } = useApplicationStates();
  const [searchInput, setSearchInput] = useState("");
  const [debouncedSearchInput, setDebouncedSearchInput] = useState(searchInput) // || sessionStorage.getItem("searchInput"));
  const [selectedCategory,  setSelectedCategory] = useState("All");
  const [filteredResults, setFilteredResults] = useState([/*...sessionStorage.getItem("filteredResults")*/]);
  const [tempOverride, setTempOverride] = useState(null);
  const { currentUser } = userData;
  const { adminDIDs } = adminAppStates;

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // CATEGORY DECLARATIONS
  // Default value and assignment of categories
  const categories = [{ name: "All", recordKey: null }];
  // Create Array from the renderMap column data and
  const renderArr = Array.from( 
    renderMap, 
    (data, item) => categories.push({ name: data[0], recordKey: data[1].columnKey })
  ); 
  const { pathname } = useLocation();
  function getListData(list) {
    if(typeof list === "string") {
      if(pathname.includes("admin")) {
        return adminAppStates[list]
      }
      return sharedAppStates[list]
    }
    return list; 
  }
  // Execute and assign the list data
  const listData = getListData(list);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // EFFECTS
  //
  // DEBOUNCE INPUT EFFECT
  useEffect(() => { 
    const timer = setTimeout(() => {
      // Clear any temp override currently in place
      if(tempOverride) setTempOverride(null);
      setSearchInput(debouncedSearchInput)
      // Truthy values get set, falsy values get an empty string in session storage
      /* debouncedSearchInput 
        ? sessionStorage.setItem("searchInput", debouncedSearchInput) 
        : sessionStorage.setItem("searchInput", ""); */
    }, 600);
    return () => clearTimeout(timer);
  }, [debouncedSearchInput]);
  // FILTER RESULTS EFFECT
  useEffect(() => {
    if (searchInput) {
      const filteredResults = filterRecords(searchInput, listData);
      //sessionStorage.setItem("filteredResults", filteredResults)
      return setFilteredResults(filteredResults);

    }

    return setFilteredResults(listData);
  }, [searchInput, listData, selectedCategory]); //Adding filterRecords creates infiniteLoop!
  // TEMP OVERRIDE EFFECT
  useEffect(() => {
    if(tempOverride) setDebouncedSearchInput(tempOverride)
  }, [tempOverride])
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // FILTER FUNCTION
  function filterRecords(userInput, recordList, arrayOfExceptions = []) {
    // Escape user input
    const escapedString = userInput.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
    // Create Regular expression out of escaped string
    const searchRegex = new RegExp(escapedString, "gi");
    //CATEGORY IS SELECTED
      //Categories have edge cases that get their key names from the renderMap 
      //not the raw object record iteratively
    if(selectedCategory !== "All") {
      //Get the relevant object key based on the category name
      const translatedKey = categories.find(category => category.name === selectedCategory).recordKey;
      //Standardize that all keys will be arrays
      const keyArr = Array.isArray(translatedKey) ? translatedKey : [translatedKey];
      //Filter the records based on the selected key and regex
      return recordList.filter(record => {
        //Check the keylist if one of the keys returns a match
        return keyArr.some(key => {
          const isNestedKey = handleNestedKey(record, key, searchRegex)
          //Conditionally return a raw key Regex test depending if there was a nested key
          return arrayOfExceptions.indexOf(key) === -1 
            && isNestedKey 
              ? isNestedKey 
              : searchRegex.test(record[key]);
        })
      })
    }
    //NO CATEGORY SELECTED
    return recordList.filter(record => {
      // Check all object propeties on record...
      return Object.keys(record).some(
        (key) => {
          //See if the key value is nested edge case
          const isNestedKey = handleNestedKey(record, key, searchRegex);
          //Dynamically return value based on handleNestedKey return
          return arrayOfExceptions.indexOf(key) === -1 
          && isNestedKey 
            ? isNestedKey 
            : searchRegex.test(record[key]);
        }
      )
      //I don't think this ternary is necessary as .some() natively returns a boolean
        /* ? true
        : false */
      });
  }

  //NESTED KEY HANDLER
  function handleNestedKey(record, keyName, regExpression) {
    if(keyName === "room_customers" || keyName === "room_users") {
      //Create name concactentation from nested values
      const concatedNames = record[keyName].reduce(
        (baseStr, member) => `${baseStr} ${member.first_name} ${member.last_name} `,
        ``
      );
      //Perform Regex Test on it
      return regExpression.test(concatedNames);
    }
    //Handle the Contacts Name edge case
    if(keyName === "last_name") {
      const name = `${record.first_name} ${record.last_name}`;
      return regExpression.test(name);
    }

    if(keyName === "room_did_number") {
      const DIDname = record.room_customers.length ? `${record.room_did_number} ${handleDIDinfo(record[keyName])}` : `None`;

      return regExpression.test(DIDname);
    }

    return false;
  }
  //This returns a single string of both the DID number and description to parse
  function handleDIDinfo(didNum) {
    // Guard condition in case room has a customer but no DID number assigned.
    if(!didNum) {
      return "";
    }

    function availableDIDs() {
      if (currentUser) {
        if (currentUser.user_role === "user") {
          return currentUser.user_dids;
        }
  
        if (currentUser.user_role === "admin") {
          return adminDIDs;
        }
  
        return [];
      }
    }
    
    const foundDIDDesc = availableDIDs().find((did) => did.did_number === didNum);
    return foundDIDDesc?.did_description ?? "No Description";
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // SEARCH BAR COMPONENT
  function searchBar(className) {
    return (  
      <div
        className={` mx-2 d-flex flex-column justify-content-evenly flex-grow-1 flex-md-row`}
      >
        <Form.Group className="w-100 position-relative d-flex">
          <Form.Control
            type="text"
            placeholder="Search here"
            className={className}
            value={tempOverride ?? debouncedSearchInput}
            onChange={(e) => setDebouncedSearchInput(e.target.value)}
          />
          
          {/* 
            I think we should change this to a search button, 
            clearing text is inherent since you're already typing
            
            <Button
              className={"position-absolute h-100 end-0"}
              onClick={() => setDebouncedSearchInput("")}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </Button> 
          */}

          <Form.Select 
            className="w-auto mx-2"
            onChange={ (e) => setSelectedCategory(e.target.value) }
          >
          {
            categories.map((category, index) => (
              <option key={index}>
                { category.name }
              </option>
            ))
          }
        </Form.Select>
        </Form.Group>
      </div>
    );
  }
  // Finally if any properties match, include record in returned array
  return {
    filteredResults,
    searchBar,
    setTempOverride
  };
}
///////////////////////////////////////////////////////////////////////////////////////////////////
