import { BillingCodeWithOptions } from "@tscontrollers/billing_codes.controller";
import { useEffect, useState } from "react";
import filterSoftRecords from "src/helpers/filterSoftRecords";
export default function useAdminStates() {
  const [adminStats, setAdminStats] = useState<any>([]);
  const [adminCompanies, setAdminCompanies] = useState<any>([]);
  const [deletedAdminCompanies, setDeletedAdminCompanies] = useState<any>([]);
  const [fullAdminCompanies, setFullAdminCompanies] = useState<any>([]);
  const [adminCodes, setAdminCodes] = useState<any>([]);
  const [adminDIDs, setAdminDIDs] = useState<any>([]);
  // Shared by Admins and Managers
  const [selectedAdminBillCode, setSelectedAdminBillCode] = useState<string | null>(sessionStorage.getItem("last_set_billcode") ?? null);

  //!!!!TEST OBJECT
  const [adminLogs, setAdminLogs] = useState([
    {
      phone_number: "1234567890",
      message: "Test",
      received_date: "2021-09-19 16:09:26",
    },
  ]);

  useEffect(() => {
    const { existingRecords, deletedRecords } = filterSoftRecords(fullAdminCompanies);
    setAdminCompanies(existingRecords);
    setDeletedAdminCompanies(deletedRecords);
  }, [fullAdminCompanies])

  return {
    adminStats,
    setAdminStats,
    adminCodes,
    setAdminCodes,
    adminDIDs,
    setAdminDIDs,
    adminCompanies,
    deletedAdminCompanies,
    fullAdminCompanies,
    setFullAdminCompanies,
    selectedAdminBillCode,
    setSelectedAdminBillCode,
    adminLogs,
    setAdminLogs,
  };
}