import { Col, FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { snakeToTitleCase } from "src/helpers/formattingHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBusinessTime } from "@fortawesome/free-solid-svg-icons";

export function FormInput({ field, fieldKey, formik, isRequired }) {
  const { values, handleChange, touched, errors } = formik;
  const name = field.name || snakeToTitleCase(fieldKey);

  function inputProps() {
    switch (field.type) {
      case "tel":
        return { maxLength: 14, pattern: "[0-9\\s\\(\\)\\-]{14}" };
      default:
        return {};
    }
  }

  return (
    <Form.Group as={Col} md="5" controlId={`text-${fieldKey}`} className="position-relative my-2">
      {/* <InputGroup className="mb-3"> */}
      {/*         <InputGroup.Text id="basic-addon1">
          <FontAwesomeIcon icon={faBusinessTime} />
        </InputGroup.Text> */}
      {/* <FloatingLabel controlId={name} label={name} className="mb-3"> */}
      <Form.Label>
        {isRequired ? <b>*</b> : null} {name}
      </Form.Label>
      <Form.Control
        type={field.type || "text"}
        name={fieldKey}
        value={values[fieldKey]}
        onChange={handleChange}
        isValid={isRequired && touched[fieldKey] && !errors[fieldKey]}
        isInvalid={!!errors[fieldKey]}
        placeholder={name}
        {...inputProps()}
      />
      {/* </FloatingLabel> */}
      {/* </InputGroup> */}
    </Form.Group>
  );
}

export function FormSelect({ field, fieldKey, formik, isRequired }) {
  const { selectValues } = field;
  const { values, handleChange } = formik;

  // {
  //   label ? <Form.Label className="d-flex align-items-center me-2 mb-0">{label} </Form.Label> : null;
  // }
  return (
    <Form.Select aria-label="Default select example" name={fieldKey} value={values[fieldKey]} onChange={handleChange}>
      {selectValues.map((value) => (
        <option key={value} value={value} data-testid="select_option">
          {/* data[keyName] ? data[keyName] : */ value}
        </option>
      ))}
    </Form.Select>
  );
}
