import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faQuestionCircle, faTimesCircle, faFile } from "@fortawesome/free-solid-svg-icons";
import { nanoid } from "nanoid";
// Styles
import styles from "../../Messages.module.scss";
import { convertTimestampToDate } from "src/helpers/helpers";
import { useApplicationStates } from "src/contexts";
import { ContactCircle } from "src/common/commonViews.index";
export default function TextMessage({ message, selectedRoom, currentUser, handleRetryFailedMessage }) {
  const [showTimestamp, setShowTimestamp] = useState(false);
  const [showFailureMessage, setShowFailureMessage] = useState(false);
  const [showResendingMessage, setShowResendingMessage] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const { sharedAppStates } = useApplicationStates();
  const { customersList, usersInCode } = sharedAppStates;

  if (!selectedRoom) return null;

  function convertToURL(messageText) {
    //Create an array of all url matches in message
    const matchedURLs = [
      ...messageText.matchAll(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi
      ),
    ].map((match) => match[0]);

    function linkConstructor(url) {
      return [
        <a key={nanoid()} href={`//${url}`} target="_blank" rel="noreferrer noopener">
          {url}
        </a>,
        " ",
      ];
    }

    const replacedWithLinks = messageText
      .split(" ")
      .map((text) => (matchedURLs.includes(text) ? linkConstructor(text) : text));

    return replacedWithLinks.map((elem) => (typeof elem === "string" ? elem + " " : elem)).flat();
  }

  function deliveryStatus(delivered) {
    const [cursorStyle, setCursorStyle] = useState("default");
    const [isAnimating, setIsAnimating] = useState(false);
    // If the message is from a customer or the room is a user to user room (no did number) return early
    if (message.from_customer_id || selectedRoom.room_did_number === null) return;

    let icon;
    let color = "";
    let isClickable = false;

    useEffect(() => {
      if (isAnimating) {
        // Display the temp resending message
        setShowResendingMessage(true);
        // Run the event emitter function
        handleRetryFailedMessage(message.message_id, result => {
          // Turn of the the resending message
          setShowResendingMessage(false);
          // Change the message's delivered_to_carrier value if it was changed in the result
          if (result.delivered_to_carrier !== message.delivered_to_carrier) {
            message.delivered_to_carrier = result.delivered_to_carrier;
          }
        });
      }
    }, [isAnimating, message]);

    switch (delivered) {
      case true:
        icon = faCheckCircle;
        color = "green";
        break;
      case false:
        icon = faTimesCircle;
        color = "red";
        isClickable = true;
        break;
      default:
        icon = faQuestionCircle;
        color = "grey";
    }
    // Controls the cursor changing when hovered over
    const handleMouseEnter = () => {
      if (isClickable) setCursorStyle("pointer");
    };
    const handleMouseLeave = () => {
      setCursorStyle("default");
    };

    const retryMessage = () => {
      setIsAnimating(true);
    };

    const deliveryStatusProps = {
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
      className: isAnimating ? `animate-bounce` : ``,
      style: { cursor: cursorStyle },
      onClick: retryMessage,
      icon,
      color,
    };

    return <FontAwesomeIcon onClick={retryMessage} {...deliveryStatusProps} />;
  }

  function getSendersName(message) {
    // If there is only 1 customer and a user in a room, omit the sender names
    if (!selectedRoom || (selectedRoom.room_customers.length === 1 && selectedRoom.room_users.length === 1)) {
      return null;
    }

    let foundRecord;

    if (message.from_user_id === currentUser.user_id) {
      return "You";
    }

    if (message.from_user_id === "system_user") return "Automated System";

    if (message.from_customer_id !== null) {
      const foundCustomer = customersList.find((customer) => customer.customer_id === message.from_customer_id);

      foundRecord = foundCustomer;
    } else if (message.from_user_id !== null) {
      const foundUser = usersInCode.find((user) => user.user_id === message.from_user_id);

      foundRecord = foundUser;
    } else {
      console.error(`🛑 ERROR, THIS MESSAGE HAD NO CUSTOMER OR USER ID! MESSAGE_ID: ${message.message_id}`);
    }

    return foundRecord ? `${foundRecord.first_name} ${foundRecord.last_name}` : "Unknown Contact";
  }

  function MessageBubble(message) {
    let style;
    let orientRight;
    const senderName = getSendersName(message);

    function orientMessage() {
      // If message is from a customer, always align on left side
      if (message.from_customer_id !== null) {
        style = `${styles.message} pe-4 mb-2 me-4 align-self-start ${styles.mess_left}`;
        return (orientRight = false);
      }

      // If message is from a user, align on the right
      if (message.from_user_id === currentUser.user_id) {
        style = `${styles.message} ps-5 mb-2 align-self-end ${styles.mess_right}`;
        return (orientRight = true);
      }

      // If there are customers in them room, align all users to the right
      if (selectedRoom.room_customers.length) {
        style = `${styles.message} ps-5 mb-2 align-self-end ${styles.mess_right}`;
        return (orientRight = true);
      }
      // Else if there are no customers, align all other users other than current user to the left
      else {
        style = `${styles.message} pe-4 mb-2 me-4 align-self-start ${styles.mess_left}`;
        orientRight = false;
      }
    }

    function isValidImage(linkAndURL, index) {
      const { message_media, file_name, file_size } = linkAndURL;
      const parseLinkPattern = /\.\w{3,4}($|\?)/;
      const mediaExtensions = [".jpg", ".jpeg", ".gif", ".png"];
      const mediaExtension = message_media?.match(parseLinkPattern);
      const isValidExtension = mediaExtensions.some((extension) => extension === mediaExtension[0].toLowerCase());

      if (isValidExtension) {
        return (
          //Return an image thumbnail if isValidExtension came back as true
          <div
            key={`media-${index}`}
            className={`${styles.text_img_wrapper} d-flex ${
              orientRight ? "justify-content-end" : "justify-content-start"
            }`}
          >
            <img
              src={message_media}
              alt="Text message media file"
              className="rounded"
              style={{ height: "400px" }}
              onLoad={(elem) => {
                elem.target.style.height = "fit-content";
                elem.target.style.maxHeight = "400px";
              }}
            />
          </div>
        );
      }
      //If Message Media is not a valid image format return text link
      return (
        <div className={`d-flex ${orientRight && "justify-content-end"}`}>
          <p className={`${styles.message_text} px-3 py-2 position-relative`}>
            <a href={message_media} style={{ "text-decoration": "none" }} target="_blank" rel="noopener noreferrer">
              <div className="d-flex flex-row">
                <div style={{ "margin-right": "0.5rem" }}>
                  <FontAwesomeIcon icon={faFile} size="4x" />
                </div>
                <div className="d-flex flex-column">
                  <div className="line-clamp-1" style={{ "font-size": "1.2rem" }}>{`${
                    file_name ? file_name : `Download File`
                  }`}</div>
                  <div>{file_size ? file_size : <i>Unknown File Size</i>}</div>
                </div>
              </div>
            </a>
          </p>
          <div className="d-flex align-items-end">
            {orientRight && deliveryStatus(message.delivered_to_carrier)}
            {orientRight && contactCircle()}
          </div>
        </div>
      );
    }

    function isExpired(timeStamp) {
      //Source: https://stackoverflow.com/questions/16767301/calculate-difference-between-2-timestamps-using-javascript
      const currentDate = new Date();
      const timeStampDate = new Date(timeStamp);
      const differenceOfDays = Math.floor(currentDate.getTime() - timeStampDate.getTime()) / 1000 / 60 / 60 / 24;

      if (differenceOfDays > 30) {
        return (
          <div className={`d-flex ${orientRight && "justify-content-end"}`}>
            <p className={`${styles.message_text} px-3 py-2 position-relative`}>{`🚫 Media Content has expired.`}</p>
            <div className="d-flex align-items-end">
              {orientRight && deliveryStatus(message.delivered_to_carrier)}
              {orientRight && contactCircle()}
            </div>
          </div>
        );
      }
      return null;
    }

    function handleMedia(message) {
      //Does the message even have URLs in the key
      if (message?.messages_media?.length) {
        //See if the content is expired and generate the template
        const expiredContent = isExpired(message.sent_at);
        if (expiredContent) return expiredContent;
        //Otherwise return the thumbnails or links for each media item
        return message.messages_media.map((linkAndURL, index) => isValidImage(linkAndURL, index));
      }
      //Otherwise return nothing
      return null;
    }

    function handleText(message) {
      const failed = message.delivered_to_carrier !== undefined && !message.delivered_to_carrier;
      if (message.message_text)
        return (
          <div
            className={`d-flex ${orientRight && "justify-content-end"}`}
            title={failed ? `Message Failed, Click Red X to Attempt Resend` : ``}
          >
            <p onClick={handleClick} className={`${styles.message_text} px-3 py-2 position-relative text-break`}>
              {convertToURL(message.message_text)}
            </p>
            <div className="d-flex align-items-end">
              {orientRight && deliveryStatus(message.delivered_to_carrier)}
              {orientRight && contactCircle()}
            </div>
          </div>
        );

      return null;
    }

    function contactCircle() {
      if (
        (selectedRoom.room_customers.length === 1 && selectedRoom.room_users.length === 1) ||
        message.from_user_id === currentUser.user_id
      ) {
        return null;
      }

      return <ContactCircle circleNames={senderName} size="xsm" className={`${orientRight ? "ms-2" : "me-2"}`} />;
    }

    orientMessage();

    return (
      <div
        //Set side of chat depending on sender
        className={`d-flex flex-column ${style} w-100`}
        title={message.delivered_to_carrier ? `Click Red X to Attempt Resend` : null}
      >
        <div className={`w-100 d-flex ${orientRight && "justify-content-end"} align-items-end`}>
          {!orientRight && contactCircle()}
          <div className="d-flex flex-column" style={{ minWidth: "0px" }}>
            {senderName}
            {handleMedia(message)}
            {handleText(message)}
          </div>
        </div>
        {/* Show timestamp if message did not fail and is clicked */}
        {message.delivered_to_carrier && message.message_media && (
          <div className={`pt-1 ${orientRight && "text-end"}`}>
            <a href={message.message_media} target="_blank" rel="noreferrer">
              Open Attachment
            </a>
          </div>
        )}
        {showTimestamp && (
          <div className={`pt-1 ${orientRight && "text-end"}`}>{convertTimestampToDate(message.sent_at)}</div>
        )}
        {showResendingMessage && <div className={`pt-1 ${orientRight && "text-end"}`}>{`Resending Message...`}</div>}
      </div>
    );
  }
  const handleClick = () => {
    // Undefined guard
    if (message.delivered_to_carrier === undefined) return;
    // If message was delivered to carrier in some form
    if (message.delivered_to_carrier) {
      return setShowTimestamp((prev) => !prev);
    }
    if (!message.delivered_to_carrier) {
      return setShowFailureMessage((prev) => !prev);
    }
  };
  return (
    <div key={message.message_id} className={`${styles.message_wrapper} w-100 px-2 d-flex flex-column mb-2`}>
      {MessageBubble(message)}
    </div>
  );
}
