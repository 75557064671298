import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import seedrandom from "seedrandom";
import styles from "./ContactCircle.module.scss";

type ContactCircleProps = {
  circleNames: string[],
  className: string,
  size?: "xxsm" | "xsm" | "sm" | "md",
  square?: boolean,
  newContact?: boolean,
  unreadMessageCount?: number,
}

export default function ContactCircle({
  circleNames,
  className,
  size,
  square,
  newContact,
  unreadMessageCount,
}: ContactCircleProps) {
  //Generate seed random color pallet for room circles
  function generateColorFromString(str) {
    const PHI = 1.618033988749895;
    return `hsl(${Math.floor(
      ((seedrandom(str)() + 1 / PHI) % 1) * 360
    )}, 60%, 50%)`;
  }

  function roomCircleLetter() {
    if (!circleNames || circleNames.length === 0) {
      return "!";
    }

    if (Array.isArray(circleNames)) {
      const index = circleNames.indexOf("You");

      if (index > -1) {
        circleNames.splice(index, 1);
      }

      if (circleNames.length === 1) {
        return circleNames[0].toString()[0].toUpperCase();
      } else {
        return `+${circleNames.length}`;
      }
    }

    return circleNames[0];
  }

  function convertNamesToString(names) {
    if (Array.isArray(names)) {
      return names.join(",");
    }

    return names;
  }

  const circleStyle = {
    backgroundColor: generateColorFromString(convertNamesToString(circleNames)),
    height: "",
    width: "",
    fontSize: ""
  };

  switch (size) {
    case "xxsm":
      circleStyle.height = "25px";
      circleStyle.width = "25px";
      circleStyle.fontSize = ".75rem";
      break;
    case "xsm":
      circleStyle.height = "35px";
      circleStyle.width = "35px";
      circleStyle.fontSize = "1rem";
      break;
    case "sm":
      circleStyle.height = "50px";
      circleStyle.width = "50px";
      circleStyle.fontSize = "2rem";
      break;
    case "md":
      circleStyle.height = "120px";
      circleStyle.width = "120px";
      circleStyle.fontSize = "4.5rem";
      break;
    default:
      circleStyle.height = "50px";
      circleStyle.width = "50px";
      circleStyle.fontSize = "2rem";
  }

  if (newContact) {
    circleStyle.backgroundColor = "rgb(255, 180, 109)";
  }

  return (
    <div
      className={` ${styles.contact_circle} ${
        className
          ? `${className} ${square ? null : "rounded-circle"} `
          : ` ${square ? null : "rounded-circle"}`
      } flex-shrink-0 d-flex justify-content-center align-items-center`}
      style={circleStyle}
    >
      {newContact ? (
        <FontAwesomeIcon icon={faUserPlus} size="1x" />
      ) : (
        roomCircleLetter()
      )}
      {unreadMessageCount && unreadMessageCount > 0
        ? unreadMessageCircle(unreadMessageCount)
        : null}
    </div>
  );
}

const unreadMessageCircle = (unreadMessages) => (
  <div className={`${styles.unread_message_circle}`}>
    {unreadMessages}
  </div>
);

export { unreadMessageCircle };