/////////////////////////////////////////////////////////////////////////////////////////////////
// Sorting functions

/////////////////////////////////////////////////////////////////////////////////////////////////
export function sortAlphabetically(list, keyToSortBy, direction) {
  if (list.length > 1) {
    //TODO return empty array if invalid?
    return list.sort((a, b) => {
      if (a[keyToSortBy] === null) {
        return -1;
      }

      if (b[keyToSortBy] === null) {
        return 1;
      }

      const nameA = a[keyToSortBy].toLowerCase();
      const nameB = b[keyToSortBy].toLowerCase();
      // If name empty sort to back
      /*       if (nameA === "") {
        return 1;
      } else if (nameB === "") {
        return -1;
        // Otherwise sort
      } else { */
      if (direction === "descending") {
        return nameA > nameB ? -1 : nameA < nameB ? 1 : 0;
      } else {
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      }

      //}
    });
  } else {
    return list;
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////
// FORMAT USERNAMES
/////////////////////////////////////////////////////////////////////////////////////////////////
export function formatUsernames(roomUsers, firebaseUser) {
  if (!roomUsers.length) {
    return <i>No Users</i>;
  }
  const userNames = sortAlphabetically(roomUsers, "last_name")
    .filter((roomUser) => roomUser.user_id !== firebaseUser.uid)
    .map((roomUser) => `${roomUser.first_name} ${roomUser.last_name}`);

  if (firebaseUser.role !== "user" && roomUsers.some((roomUser) => roomUser.user_id === firebaseUser.uid)) {
    userNames.unshift("You ");
  }

  return roomUsers ? userNames : [];
}

/////////////////////////////////////////////////////////////////////////////////////////////////
// COMBINE FIRST AND LAST NAME
/////////////////////////////////////////////////////////////////////////////////////////////////
export function combineFirstAndLastName(record) {
  if (!record) {
    return "";
  }

  return `${record.first_name} ${record.last_name}`;
}

/////////////////////////////////////////////////////////////////////////////////////////////////
// JOIN ARRAY OF NAMES
/////////////////////////////////////////////////////////////////////////////////////////////////

export function joinArrayOfNames(arrayOfNames) {
  const fullNames = arrayOfNames.map((person) => `${person.first_name} ${person.last_name}`);

  if (fullNames.length === 2) {
    fullNames.splice(1, 0, "and");
    return fullNames.join(" ");
  } else if (fullNames.length > 2) {
    fullNames.splice(fullNames.length - 1, 0, "and");
    return fullNames.join(", ").replace("and,", "and");
  }
  return fullNames.join("");
}

/////////////////////////////////////////////////////////////////////////////////////////////////
// FORMAT PHONE NUMBERS
/////////////////////////////////////////////////////////////////////////////////////////////////
export function formatPhoneNumber(phoneNumberString) {
  //TODO Need to make more dynamic
  if (!phoneNumberString || phoneNumberString.length === 0) {
    return "";
  }
  const typeCheckedString = typeof phoneNumberString === "number" ? phoneNumberString.toString() : phoneNumberString

  var formatted = typeCheckedString.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (formatted) {
    return "(" + formatted[1] + ") " + formatted[2] + "-" + formatted[3];
  }
  return phoneNumberString;
}

/////////////////////////////////////////////////////////////////////////////////////////////////
// SNAKE AND KEBAB TO TITLE CASE
/////////////////////////////////////////////////////////////////////////////////////////////////
export function snakeToTitleCase(s) {
  return s
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
    .replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase()); // First char after each -/_
}

/////////////////////////////////////////////////////////////////////////////////////////////////
// TIMESTAMP CONVERSION
/////////////////////////////////////////////////////////////////////////////////////////////////

export function convertTimestampToDate(timestamp) {
  if (typeof timestamp === "undefined") {
    return;
  }

  const dateNow = new Date();
  const date = new Date(timestamp);

  // If timestamp is of the year 1969 it mean date was converted from a null value
  if (date.getFullYear() === 1969) {
    return "Never";
  }

  function dateTimeFormat(options) {
    return new Intl.DateTimeFormat("en-US", options);
  }

  //DOC: stackoverflow.com/questions/8888491/how-do-you-display-javascript-datetime-in-12-hour-am-pm-format
  //Convert timestamp to AM/PM
  //LINK: stackoverflow.com/questions/8888491/how-do-you-display-javascript-datetime-in-12-hour-am-pm-format
  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  let options;

  if (dateNow.toDateString() === date.toDateString()) {
    return `${formatAMPM(date)}`;
  } else if (new Date(dateNow.setDate(dateNow.getDate() - 1)).toDateString() === date.toDateString()) {
    return `Yesterday • ${formatAMPM(date)}`;
  } else if (date.setDate(date.getDate()) > dateNow.setDate(dateNow.getDate() - 6)) {
    options = {
      weekday: "short",
    };
  } else if (dateNow.getMonth() === date.getMonth() && date.getFullYear === dateNow.getFullYear) {
    options = {
      weekday: "short",
      month: "short",
      day: "numeric",
    };
  } else {
    options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
  }

  return `${dateTimeFormat(options).format(date)} • ${formatAMPM(date)}`;
}

// TODO: Rename after other components using roomsViewTimestamp have been moved over
export function newRoomsViewTimestamp(timestamp) {
  if (typeof timestamp === "undefined") {
    return;
  }

  const date = new Date(timestamp);
  return `${date.toDateString().split(" ").slice(1).join(" ")} ${formatAMPM(date)}`;
}

export function roomsViewTimestamp(timestamp) {
  if (typeof timestamp === "undefined") {
    return;
  }

  const dateNow = new Date();
  const date = new Date(timestamp);

  // If timestamp is of the year 1969 it mean date was converted from a null value
  if (date.getFullYear() === 1969) {
    return "Never";
  }

  function dateTimeFormat(options) {
    return new Intl.DateTimeFormat("en-US", options);
  }

  //DOC: stackoverflow.com/questions/8888491/how-do-you-display-javascript-datetime-in-12-hour-am-pm-format
  //Convert timestamp to AM/PM
  //LINK: stackoverflow.com/questions/8888491/how-do-you-display-javascript-datetime-in-12-hour-am-pm-format
  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  let options;

  if (dateNow.toDateString() === date.toDateString()) {
    return `${formatAMPM(date)}`;
  } else if (date.setDate(date.getDate()) > dateNow.setDate(dateNow.getDate() - 7)) {
    options = {
      weekday: "short",
    };
    return dateTimeFormat(options).format(date);
  } else if (dateNow.getMonth() === date.getMonth() && date.getFullYear() === dateNow.getFullYear()) {
    options = {
      month: "short",
      day: "numeric",
    };
    return dateTimeFormat(options).format(date);
  } else {
    const mmDDYYDate = date.toLocaleDateString().split("");
    mmDDYYDate.splice(mmDDYYDate.length - 4, 2);
    return mmDDYYDate.join("");
  }
}

export function formatRoomNames(roomUsers, roomCustomers, currentUser) {
  let customerFirstNames = [];
  let usersFirstNames = [];

  const customerNames = sortAlphabetically(roomCustomers, "last_name").map((roomCustomer) => {
    customerFirstNames.push(roomCustomer.first_name);
    return `${roomCustomer.first_name} ${roomCustomer.last_name}`;
  });

  const userNames = sortAlphabetically(roomUsers, "last_name")
    .filter((roomUser) => roomUser.user_id !== currentUser.uid)
    .map((roomUser) => {
      usersFirstNames.push(roomUser.first_name);
      return `${roomUser.first_name} ${roomUser.last_name}`;
    });

  /*     if (
      firebaseUser.role !== "user" &&
      roomUsers.some((roomUser) => roomUser.user_id === firebaseUser.uid)
    ) {
      customerNames.unshift("You");
    } */

  return roomUsers
    ? {
        customerFirstNames,
        usersFirstNames,
        customerNames,
        userNames,
        roomMembers: [...customerNames, ...userNames],
      }
    : null;
}

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}
