import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Form } from "react-bootstrap";
import useWindowDimensions, {
  BSBreakpoints,
} from "../../hooks/useWindowDimensions";
//TODO create new stylesheet
import styles from "./DateSearch.module.scss";

export default function DateSearch({ dateRange, setDateRange }) {
  const { width } = useWindowDimensions();

  function changeDateRange(direction) {
    const startDate = new Date(dateRange.start.replace(/-/g, "/"));
    const endDate = new Date(dateRange.end.replace(/-/g, "/"));

    const dateDirection =
      direction === "older" ? startDate - endDate : endDate - startDate;
    const differenceInDays = Math.round(dateDirection / (1000 * 60 * 60 * 24));

    const startOffset = (days) => startDate.setDate(startDate.getDate() + days);
    const endOffset = (days) => endDate.setDate(endDate.getDate() + days);

    //LINK: https://stackoverflow.com/questions/10589732/checking-if-a-date-is-valid-in-javascript
    function checkValidDate(date) {
      return date instanceof Date && !isNaN(date.valueOf());
    }

    if (checkValidDate(startDate) && checkValidDate(endDate)) {
      setDateRange({
        start: new Date(startOffset(differenceInDays))
          .toISOString()
          .split("T")[0],
        end: new Date(endOffset(differenceInDays)).toISOString().split("T")[0],
      });
    } else if (checkValidDate(startDate) && direction === "older") {
      return setDateRange({
        start: new Date(startOffset(-30)).toISOString().split("T")[0],
        end: "",
      });
    } else if (checkValidDate(startDate) && direction === "newer") {
      return setDateRange({
        start: new Date(startOffset(30)).toISOString().split("T")[0],
        end: "",
      });
    } else if (checkValidDate(endDate) && direction === "older") {
      return setDateRange({
        start: "",
        end: new Date(endOffset(-30)).toISOString().split("T")[0],
      });
    } else if (checkValidDate(endDate) && direction === "newer") {
      return setDateRange({
        start: "",
        end: new Date(endOffset(30)).toISOString().split("T")[0],
      });
    } else {
      return;
    }
  }

  const prevButton = (
    <Button
      onClick={() => changeDateRange("older")}
      className="me-4 d-flex align-items-center"
    >
      <FontAwesomeIcon icon={faChevronLeft} />
    </Button>
  );

  const nextButton = (
    <Button
      onClick={() => changeDateRange("newer")}
      className={`ms-4 d-flex align-items-center`}
    >
      <FontAwesomeIcon icon={faChevronRight} />
    </Button>
  );

  const clearButton = (
    <Button
      className={`${styles.clear_button} ${
        width < BSBreakpoints.md ? null : "ms-4"
      }`}
      variant="primary"
      type="submit"
      size={width < BSBreakpoints.md ? "sm" : null}
    >
      Clear
    </Button>
  );

  const groupedClear = (
    <div
      className={`${styles.date_search_grouped_clear} pt-2 d-flex w-75 justify-content-between`}
    >
      {prevButton}
      {clearButton}
      {nextButton}
    </div>
  );

  return (
    <Form
      className={` d-flex flex-column flex-md-row justify-content-center align-items-center text-nowrap w-100 mx-auto `}
      onSubmit={(e) => {
        e.preventDefault();
        setDateRange({ start: "", end: "" });
      }}
    >
      {/* <div className={`mx-auto d-flex align-items-center`}>
        <div className={`d-flex flex-column flex-md-row`}> */}

      {width >= BSBreakpoints.md ? prevButton : null}
      {/* {width > BSBreakpoints.md ? prevButton : null} */}
      {/* Map over form fields */}
      <div
        className={`${styles.stats_date_change_group} d-flex flex-wrap justify-content-center`}
      >
        <div
          className={`d-flex flex-column flex-md-row align-items-end justify-content-end`}
        >
          {[
            { label: "From Date:", position: "start" },
            { label: "To Date:", position: "end" },
          ].map((date) => {
            return (
              <Form.Group
                key={date.label}
                className={`d-flex ${width > BSBreakpoints.md ? "" : "pb-2"} `}
              >
                <Form.Label className="my-2 ms-3 me-2 text-end align-self-center">
                  {date.label}
                </Form.Label>
                <Form.Control
                  className="text-center"
                  type="date"
                  value={dateRange[date.position]}
                  style={{ width: "205px" }}
                  onChange={(e) =>
                    setDateRange({
                      ...dateRange,
                      [date.position]: e.target.value,
                    })
                  }
                />
              </Form.Group>
            );
          })}
        </div>
      </div>
      {width >= BSBreakpoints.md ? nextButton : null}
      {width >= BSBreakpoints.md ? clearButton : groupedClear}
      {/* {width <= BSBreakpoints.md ? clearButton : null} */}
    </Form>
  );
}
