import {  useAuth } from "src/contexts";
import { useState } from "react";
import { useServiceWorkerContext } from "src/contexts/ServiceWorker/ServiceWorker.context";

export default function useNotifications() {
  const serviceWorkerRegistration = useServiceWorkerContext();
  const { setFirebaseUser, getFCMToken } = useAuth();
  let initialNotificationEnabled = false,
    initialNotificationOverride = false;

  // If Notifications are not supported by browser or have previously denied set
  if (!("Notification" in window) || Notification.permission === "denied") {
    initialNotificationEnabled = false;
    initialNotificationOverride = true;
  } else if (Notification.permission === "granted") {
    initialNotificationEnabled = true;
  }

  const [notificationsEnabled, setNotificationsEnabled] = useState(initialNotificationEnabled);
  const [notificationOverride, setNotificationOverride] = useState(initialNotificationOverride);

  //Request notification permissions from browser
  async function requestNotificationsPermission() {
    // Check if the browser supports notifications
    if (!("Notification" in window)) return console.error("🔔⛔ This browser does not support notifications");
    // Check whether notification permissions have already been granted
    if (Notification.permission === "granted") return setNotificationsEnabled(true);
    if (Notification.permission === "denied" && notificationsEnabled) return setNotificationsEnabled(false);
    // Otherwise ask the user for permission
    console.log(`🔔❓Requesting Notification Permissions`);
    const permission = await Notification.requestPermission();
    // If the user accepts, create a notification
    if (permission === "granted") {
      setNotificationsEnabled(true);
      const { swRegistration } = serviceWorkerRegistration;
      if(swRegistration) {
        const fcm_token = await getFCMToken(swRegistration);
        setFirebaseUser((prev) => (prev ? {
          ...prev,
          fcm_token,
        } : prev));
      } 
      if (navigator.serviceWorker) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.showNotification("Notifications are now enabled!");
        });
      }
    }
    
    setNotificationOverride(true);
  }

  return {
    notificationsEnabled,
    notificationOverride,
    setNotificationOverride,
    requestNotificationsPermission,
  };
}
