import { useState, useEffect, useCallback } from "react";
import {
  onAuthStateChanged,
  setPersistence,
  getIdTokenResult,
  browserLocalPersistence,
  inMemoryPersistence,
  User,
} from "firebase/auth";
import { useIdleTimer } from "react-idle-timer";
import { firebaseAuth, getFCMToken, handleSignOut, handleUpdatePassword } from "./index";
import * as emailHandlers from "./email.firebase";
import * as authHelpers from "./helpers.firebase";
import * as messagingHandlers from "./messaging.firebase";
import * as passwordHandlers from "./password.firebase";
import * as signInOutHandlers from "./sign_in_out.firebase";
import { useServiceWorkerContext } from "src/contexts/ServiceWorker/ServiceWorker.context";

type Role = "admin" | "dev" | "manager" | "user";

export type FirebaseUser = {
  email: string | null,
  uid: string,
  displayName: string | null,
  role: Role,
  tempPassword: string,
  fcm_token: string | void
}

// Main authentication hook, authenticates FB token and defines firebase authentication methods.
export default function useFirebase() {
  // This generic and inital value is not a mistake, the application will have infinite loops
  // Without an initial null value that either gets set to the FirebaseUser object or false afterwards
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | false | null>(null);
  const [activity, setActivity] = useState('Active');
  const [maintenanceSignOut, setMaintenanceSignOut] = useState(() => {
    const { VITE_MAINTENANCE_OVERRIDE } = import.meta.env;
    const now = new Date();
    const endTime = new Date(VITE_MAINTENANCE_OVERRIDE);
    return now < endTime;
  })
  const serviceWorkerRegistration = useServiceWorkerContext();
  const { swRegistration } = serviceWorkerRegistration;
  //////////////////////////////////////////////////////////////////////
  // RENDER EFFECTS
  //////////////////////////////////////////////////////////////////////
  // On component load, attach authentication listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, async (user) => {
      if(!user) return setFirebaseUser(false);
      // Get user custom token claims and assign them to the firebaseUser state object
      const tokenResult = await getIdTokenResult(user);
      if (tokenResult) console.log(`🗝️ Token valid and user login check succeeded!`);
      // Destructure with a default case;
      const { claims = {} } = tokenResult;
      // QOL Destructure Assign
      const { tempPassword } = claims;
      // Null Guard for getFCMToken
      console.log(`swRegistration:`, JSON.stringify(swRegistration));
      const fcm_token = swRegistration ? await getFCMToken(swRegistration) : undefined;
      if(!fcm_token) console.warn(`No Service Worker Token`);
      // Define firebaseUser options object
      const firebaseUser = {
        email: user.email,
        uid: user.uid,
        displayName: user.displayName,
        role: claims.role,
        tempPassword,
        fcm_token,
      } satisfies FirebaseUser;
      // Error logging
      if(!firebaseUser) return console.error(`Something went wrong registering the user's session`);
      // Update State with the Firebase User
      setFirebaseUser(firebaseUser);
      // Log temporary password redirect
      if (tempPassword) console.log(`🔑 Temporary password detected, rerouting to new password form`);
    });

    // Clean up listener on unmount
    return () => unsubscribe();
  }, [swRegistration]);

  useEffect(() => {
    if (firebaseUser) {
      // If the user has a temporary password, set the session persistence to none
      // (prevents user from remaining stuck on password reset view when page is refreshed)
      if (firebaseUser && firebaseUser.tempPassword) {
        setPersistence(firebaseAuth, inMemoryPersistence);
      } else {
        setPersistence(firebaseAuth, browserLocalPersistence);
      }
    }
  }, [firebaseUser]);

  useEffect(() => {
    if(firebaseUser && maintenanceSignOut) handleSignOut(firebaseUser);
  })

  const onIdle = () => {
    setActivity("Idle");
    if(firebaseUser) handleSignOut(firebaseUser);
  }

  const onActive = () => {
    setActivity("Active");
  }

  useIdleTimer({
    onIdle,
    onActive,
    timeout: 1000 * 60 * 60 * 8,
    throttle: 500
  });

  function userPermissionLevel(level) {
    if (firebaseUser) {
      
      let authorizedRole: Role[] = [];
      switch (level) {
        case "full":
          authorizedRole = ["admin", "dev"];
          break;
        case "mid":
          authorizedRole = ["manager", "admin", "dev"];
          break;
        case "user":
          authorizedRole = ["user"];
          break;
        case "manager":
          authorizedRole = ["manager"];
          break;
        case "admin":
          authorizedRole = ["admin"];
          break;
        default:
          break;
      }

      return authorizedRole.includes(firebaseUser.role);
    }

    return "🛑 This is not a valid firebase user!!!";
  }

  return {
    firebaseUser,
    setFirebaseUser,
    ...authHelpers,
    ...emailHandlers,
    ...messagingHandlers,
    ...passwordHandlers,
    ...signInOutHandlers,
    userPermissionLevel,
    handleUpdatePassword: useCallback((password) => handleUpdatePassword(password, firebaseUser), [firebaseUser]),
    handleSignOut: useCallback(() => handleSignOut(firebaseUser), [firebaseUser]),
  };
}
