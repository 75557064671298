import { FormatColumns } from "../../../../common/DataTable/components/DataTable.index";
import { newRoomsViewTimestamp } from "../../../../helpers/formattingHelpers";
import {
  sortAlphabetically,
  sortByNumber,
  sortyByDate,
} from "../../../../helpers/helpers";
import { sortRoomNames } from "../../../../helpers/sortingHelpers";

export default function roomsDataMap(viewType) {
  const roomColumnMap = new Map([
    viewType === "customers"
      ? [
          "Customer Name",
          {
            columnKey: ["room_customers", "room_users"],
            sort: true,
            sortKey: "room_customers", // Overrides default sort key (columnKey)
            sortFunc: sortRoomNames,
            formatFunc: ({ room_users, room_customers }) => (
              <FormatColumns.NameCol
                room_users={room_users}
                room_customers={room_customers}
              />
            ),
          },
        ]
      : [
          "User Name",
          {
            columnKey: ["room_customers", "room_users"],
            sort: true,
            sortKey: "room_users",
            formatFunc: ({ room_users, room_customers }) => {
              return (
                <FormatColumns.NameCol
                  room_users={room_users}
                  room_customers={room_customers}
                />
              );
            },
            //sortFunc: sortFullNames,
          },
        ],
    [
      "Last Message",
      {
        columnKey: "last_message_text",
        sort: true,
        sortFunc: sortAlphabetically,
      },
    ],

    [
      "Sent By",
      {
        columnKey: "last_message_from",
        sort: true,
        sortFunc: sortAlphabetically,
      },
    ],
    [
      "DID Name",
      {
        columnKey: "room_did_number",
        sort: true,
        formatFunc: (did) => <FormatColumns.ViaDIDCol didNum={did} />,
        sortFunc: sortByNumber,
      },
    ],
    [
      "Room #",
      {
        columnKey: "room_id",
        sort: true,
        sortFunc: sortByNumber,
      },
    ],
    [
      "Update At",
      {
        columnKey: "room_updated_at",
        sort: true,
        formatFunc: newRoomsViewTimestamp,
        sortFunc: sortyByDate,
      },
    ],
  ]);

  if (viewType === "users") {
    roomColumnMap.delete("Via");
  }

  return roomColumnMap;
}
