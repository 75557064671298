// Libraries
import { useState, useEffect } from "react";
//Contexts
import { useApplicationStates } from "src/contexts";
// Components
import { Buttons, DataViewSelect, ResponsiveContainer } from "src/common/commonViews.index.js";
import { deleteRecord, editRecord, sortAlphabetically } from "src/helpers/helpers";
//Hooks
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { managerUsersColumnMap, managerUsersFormLogic, ManagerUsersTableHeaderConstructor } from "./ManagerUsers.index";
// Logic
import { handleForgotFirebasePassword } from "src/services/firebase";
import { usersAttributes } from "@tsmodels/users";

export default function ManagerUsers() {
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Contexts
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const { userData, sharedAppStates, socket, supplyBillCode } = useApplicationStates();
  const { currentUser, setCurrentUser } = userData;
  const { usersInCode, setFullUsersInCode, setServerResponse } = sharedAppStates;

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Admin Users States and variables
  /////////////////////////////////////////////////////////////////////////////////////////////////

  const [selectedUser, setSelectedUser] = useState<usersAttributes | null>(null);
  const [selectedCode] = useState(null);
  const [selectedView, setSelectedView] = useState<string | null>(null);

  // Name of primary key identifying unique record
  const primaryKey = "user_id";
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Render Effects
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // Get users in selected code

  /* useEffect(() => {

  if(currentUser) {
      const { bill_code } = currentUser;
      socket.emit(
        "requestManagerUsersInBillCode",
        bill_code,
        (res) => {
          return setFullUsersInCode(sortAlphabetically(res, "last_name"));
        }
      );
    }
  }, []); */

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Handle methods
  /////////////////////////////////////////////////////////////////////////////////////////////////

  //!!TODO ABSTRACT
  function handleSelectRecord(record) {
    if (/* !recordID ||  */ selectedUser && selectedUser === record) {
      return setSelectedUser(null);
    }
    setSelectedUser(record);
  }

  function handleAddUser(newUser) {
    const billCode = supplyBillCode();
    if(!billCode) return console.error(`Was not able to tie billcode to new user!`);
    socket.emit(
      "requestManagerNewUser", 
      newUser, 
      billCode, 
      (res) => {
        // This is probably checking if an admin is in their default billing code, bad logic probably applies to all user cases
        if (selectedCode === null || res.billing_code === selectedCode) setFullUsersInCode((prev) => [...prev, res]);
      }
    );
  }

  function handleEditUser(editedUser) {
    socket.emit("requestManagerEditUser", editedUser, (responseEditedUser: usersAttributes) => {
      setFullUsersInCode((prev) => editRecord(responseEditedUser, primaryKey, prev));
    });
  }

  function handleUserStatus() {
    if(!selectedUser) return;
    socket.emit(
      "requestManagerChangeUserStatus",
      selectedUser.user_id,
      // Send opposite of current record
      !selectedUser.user_enabled,
      (res) => setFullUsersInCode((prev) => editRecord(res, primaryKey, prev))
    );
  }

  async function handleSendPasswordReset() {
    if(!selectedUser) return;
    const response = await handleForgotFirebasePassword(selectedUser.email);
    setSelectedView(null);

    if (response.ok) {
      setServerResponse("Successfully sent password reset!");
    } else {
      setServerResponse("An error occurred sending a password reset");
    }
  }

  function handleResendVerification() {
    socket.emit("requestManagerResendVerification", selectedUser, () => {
      //TODO: response handling
    });
    setSelectedView(null);
  }

  function handleDeleteUser() {
    if(!selectedUser) return;
    socket.emit("requestManagerDeleteUser", selectedUser.user_id, (res) =>
      setFullUsersInCode((prev) => deleteRecord(res, primaryKey, prev))
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Component props
  /////////////////////////////////////////////////////////////////////////////////////////////////

  function buttonConstructor(record) {
    function userRoleCheck(userRecord) {
      if(!currentUser) return;
      if (
        userRecord.user_role === "admin"
        && userRecord.user_id !== currentUser.user_id
      ) {
        setSelectedView("permission-denied");
        return true;
      }
    }

    return [
      {
        label: "Edit",
        onClick: () => (userRoleCheck(record) ? null : setSelectedView("edit-user")),
        faIcon: faPencilAlt,
      },
      {
        label: record.user_enabled ? "Disable" : "Enable",
        onClick: () => (userRoleCheck(record) ? null : setSelectedView("user-status")),
        faIcon: faPencilAlt,
      },
      {
        label: "Send Password Reset",
        onClick: () => setSelectedView("reset-password"),

        faIcon: faPencilAlt,
      },
      record.email_verified
        ? null
        : {
            label: "Resend Verification Email",
            onClick: () => setSelectedView("resend-email"),

            faIcon: faPencilAlt,
          },
      {
        label: "Delete",
        onClick: () => (userRoleCheck(record) ? null : setSelectedView("delete-user")),
        faIcon: faTrashAlt,
      },
    ];
  }

  function tableHeader() {
    return {
      title: "Users",
      firstRowChildren: () => <Buttons.AddNew setSelectedView={setSelectedView} view={"add-user"} />,
      children: () => null,
    };
  }

  function handleResetView() {
    setSelectedView(null);
    setSelectedUser(null);
  }

  const logicMapProps = {
    selectedUser: selectedUser ? selectedUser : null,
    submitFunc: handleResendVerification
  };

  const dataViewSelectProps = {
    //Data to render
    data: usersInCode,
    // Map logic on how to render data
    renderMap: managerUsersColumnMap,
    // The primary key to use when selecting records
    primaryKey,
    // Indexes to slice when using table layout
    //displayIndexes,
    // Button logic to use when using table layout
    buttonConstructor,

    // Logic map for forms and modal
    logicMap: managerUsersFormLogic(logicMapProps),
    //Header props
    headerConstructor: ManagerUsersTableHeaderConstructor,
    headerProps: setSelectedView,

    // General handle functions and props
    selectedRecord: selectedUser,
    selectedView,
    setSelectedView,
    handleResetView,
    handleSelectRecord,
    //Handle functions for selected view
    handleFunctions: {
      "add-user": handleAddUser,
      "edit-user": handleEditUser,
      "user-status": handleUserStatus,
      "reset-password": handleSendPasswordReset,
      "resend-email": handleResendVerification,
      "delete-user": handleDeleteUser,
      "permission-denied": handleResetView,
    },
    tableHeader,
    // The component to use when the viewport is smaller than 450px;
    //mobileView: (props) => <ContactsMobileViews {...props} />,
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Return
  /////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <ResponsiveContainer>
      <DataViewSelect {...dataViewSelectProps} />
    </ResponsiveContainer>
  );
}
