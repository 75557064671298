import { Button, Modal } from "react-bootstrap";
import ReleaseNotes from "./subcomponents/ReleaseNotes";
import { useApplicationStates } from "src/contexts";
import useVersionCheckQuery from "src/hooks/queries/useVersionCheckQuery";
import { useEffect, useState } from "react";
import { default as pkg } from "../../../package.json";
import { useServiceWorkerContext } from "src/contexts/ServiceWorker/ServiceWorker.context";


export default function AppUpdateModal() {
  // Destructuring of needed data
  const [isLoading, setIsLoading] = useState(false);
  const { sharedAppStates } = useApplicationStates();
  const serviceWorkerRegistration = useServiceWorkerContext();
  const { data } = useVersionCheckQuery();
  const { showReleaseNotes, setShowReleaseNotes } = sharedAppStates;
  const { 
    needRefresh, 
    setNeedRefresh, 
    setOfflineReady, 
    swRegistration,
    updateServiceWorker 
  } = serviceWorkerRegistration;
  
  // Open the panel when a service worker needs to refresh
  useEffect(() => {
    // Callback for setting loading
    const checkForUpdate = async () => {
      // Null worker guard
      if (!swRegistration) return null;
      setIsLoading(true);
      // Check the service-worker's URL for a new service worker
      await swRegistration.update();
      // One second wait timer to allow the fastest loads to show that it's looking
      setTimeout(() => {
        null;
      }, 750);
      setIsLoading(false);
    };
    // If we are opening the release notes, and have a current registration, check for updates
    showReleaseNotes && checkForUpdate();
  }, [showReleaseNotes, swRegistration]);

  useEffect(() => {
    if (needRefresh) setShowReleaseNotes(true);
  }, [needRefresh, setShowReleaseNotes]);
  // Undefined Guard
  if (!data || !sharedAppStates) return null;
  const { LATEST_CLIENT_VERSION } = data;
  const currentVersion = pkg.version;
  const outOfDate = currentVersion < LATEST_CLIENT_VERSION;
  const updateTitle = needRefresh ? `🥳 New Update Ready!` : `✅ You're Running the Latest Version`;
  const updateDescription = `Please click the button to update the application to the newest version.`;
  // Callback for setting state to false and dismissing modal
  const close = () => {
    setOfflineReady(false);
    setNeedRefresh(false);
    setShowReleaseNotes(false);
  };
  // Define onClick handler
  const onClick = () => (needRefresh ? updateServiceWorker() : close());
  // JSX to render
  return (
    <Modal show={showReleaseNotes} backdrop="static" size={"lg"} scrollable={true} keyboard={false} centered>
      <Modal.Header>
        <Modal.Title className="text-center w-100">{isLoading || outOfDate ? `🤔 Checking for Update...` : updateTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {needRefresh ? updateDescription : null}
        <ReleaseNotes isLoading={isLoading} notesList={data} currentVersion={pkg.version} latestVersion={LATEST_CLIENT_VERSION} />
      </Modal.Body>
      <Modal.Footer>
        <Button className={"d-block w-100 fs-5"} variant="primary" onClick={onClick}>
          {needRefresh ? `Update` : `Dismiss`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
