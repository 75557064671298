import React, { useState, useEffect } from "react";
import { Container, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { ButtonBar } from "../commonViews.index";
import useWindowDimensions, {
  BSBreakpoints,
} from "../../hooks/useWindowDimensions";

import styles from "./DataTable.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import NoResults from "./components/NoResults";
import newCustomerLabel from "./components/NewCustomerLabel.jsx";

export default function DataTable({
  data,
  renderMap,
  buttonConstructor,
  selectedRecord,
  onClickFunc,
  primaryKey,
  columnSizeMap,
  handleSelectRecord,
  handleDeselectRecord,
  selectedView,
  displayIndexes, // Optional
}) {
  const { pathname } = useLocation();
  const [columnToSort, setColumnToSort] = useState(null);
  const [sortingMethod, setSortingMethod] = useState("ascending");
  const { width } = useWindowDimensions();

  useEffect(() => {
    //Guard Conditional if columnToSort is unitialized
    if (!columnToSort) {
      const [firstCol] = renderMap.keys();
      setColumnToSort(firstCol);
    }
  }, [renderMap, columnToSort]);

  function sortColumn(records) {
    //TODO need to figure out how to get last name record when sorting customer_name and room_users

    const sliceOfRecords = (records) => {
      if (displayIndexes) {
        const [start, end] = [
          displayIndexes.startIndex,
          displayIndexes.endIndex,
        ];
        return records.slice(start, end);
      }
      return records;
    };

    const foundMapObj = renderMap.get(columnToSort);

    if (foundMapObj && foundMapObj.sort === true && foundMapObj.sortFunc) {
      let sortKey = foundMapObj.columnKey;
      const sortingFunction = foundMapObj.sortFunc;

      // If override sortKey is found
      if (foundMapObj.sortKey) {
        sortKey = foundMapObj.sortKey;
      }

      const sortedResults = sortingFunction(records, sortKey, sortingMethod);
      return sliceOfRecords(sortedResults);
    }

    return sliceOfRecords(records);
  }

  function setNumOfCols(numOfCols, columnSizeMap) {
    const standardCol = `minmax(100px, auto)`;

    //If there is a custom column size object defined, use that instead of auto column widths
    if (columnSizeMap) {
      return {
        gridTemplateColumns: columnSizeMap,
      };
    }

    //TODO remove below when done converting other pages
    let style = "";

    for (let i = 1; i <= numOfCols; i++) {
      style += ` ${standardCol}`;
    }

    return {
      gridTemplateColumns: style,
    };
  }

  function compileRecordColumns(recordObj) {
    const compiledRows = [];

    renderMap.forEach((val, key) => {
      let formattedData;
      if (val.columnKey === "last_name") {
        formattedData = `${recordObj.first_name} ${recordObj.last_name}`;
      } else if (val.formatFunc) {
        if (Array.isArray(val.columnKey)) {
          const verifiedData = {};
          val.columnKey.forEach((key) => (verifiedData[key] = recordObj[key]));
          formattedData = val.formatFunc(verifiedData);
        } else {
          formattedData = val.formatFunc(
            recordObj[val.columnKey],
            val.columnKey
          );
        }
      } else {
        formattedData = recordObj[val.columnKey];
      }
      if (val.columnKey === "phone_number") {
        formattedData = `+${recordObj.phone_number_country} ${val.formatFunc(
          recordObj.phone_number
        )}`;
      }
      //Hotfix to inject None string in the admin DIDs view
      if(val.columnKey === "did_billing_code" && recordObj.did_billing_code === null) {
        formattedData = `None`
      }
      return compiledRows.push(
        <td
          key={key}
          className={`py-3 d-flex flex-column ${
            key !== "Name" && recordObj.acknowledged && "flex-column"
          }`}
        >
          <div>
            {formattedData}
            {pathname === "/user/contacts" &&
            key === "Name" &&
            !recordObj.acknowledged &&
            recordObj.added_by_user === "system user"
              ? newCustomerLabel()
              : null}
          </div>
        </td>
      );
    });

    return compiledRows;
  }

  function tableLayout() {
    return (
      <Table
        hover
        style={setNumOfCols(renderMap.size, columnSizeMap)}
        className={`${styles.table}  table-borderless`}
      >
        <thead className={`${styles.table_header}`}>
          {
            <tr>
              {Array.from(renderMap, ([key]) => key).map((title) => (
                <th
                  key={title}
                  className={`d-flex justify-content-between align-items-end `}
                  onClick={() => {
                    if (renderMap.get(title).sort) {
                      if (columnToSort === title) {
                        return sortingMethod === "ascending"
                          ? setSortingMethod("descending")
                          : setSortingMethod("ascending");
                      }
                      setColumnToSort(title);
                    }
                  }}
                >
                  <div>{title}</div>
                  {columnToSort === title && renderMap.get(title).sort ? (
                    <div className="ps-1">
                      <FontAwesomeIcon
                        icon={
                          sortingMethod === "ascending"
                            ? faChevronUp
                            : faChevronDown
                        }
                      />
                    </div>
                  ) : null}
                </th>
              ))}
            </tr>
          }
        </thead>
        <tbody className={`${styles.table_body}`}>
          {sortColumn(data).map((record, i) => (
            <React.Fragment key={record[primaryKey] || i}>
              <tr
                className={`${styles.table_row} px-2 text-break`}
                //Needs to be declared as anon func in order to not render infinite loop
                onMouseEnter={() =>
                  handleSelectRecord ? handleSelectRecord(record) : null
                }
                onMouseLeave={() =>
                  !selectedView && handleDeselectRecord
                    ? handleDeselectRecord()
                    : null
                }
              >
                {compileRecordColumns(record)}
                <td
                  style={{
                    gridColumnEnd: `span ${renderMap.size}`,
                  }}
                  className={`${styles.DataTable_btn_container} d-flex flex-column flex-shrink-1 flex-grow-1`}
                >
                  <div
                    className={`${styles.btn_row_transition_wrapper} d-flex w-100 flex-grow-1`}
                    style={
                      selectedRecord &&
                      selectedRecord[primaryKey] === record[primaryKey]
                        ? { height: "60px" }
                        : null
                    }
                  >
                    {buttonConstructor ? (
                      <ButtonBar
                        buttonConstructor={buttonConstructor(record)}
                        width={width}
                        className={`${styles.button_bar}`}
                        selectRecord={() => handleSelectRecord(record)}
                      />
                    ) : null}
                  </div>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    );
  }

  function compileCardRows(recordObj) {
    const classes = "p-2 d-flex flex-wrap align-items-center flex-grow-1";
    const formattedData = [];

    renderMap.forEach((val, key) => {
      let recordResult;
      if (val.columnKey === "last_name") {
        recordResult = `${recordObj.first_name} ${recordObj.last_name}`;
      } else if (val.formatFunc) {
        if (Array.isArray(val.columnKey)) {
          const verifiedData = {};
          val.columnKey.forEach((key) => (verifiedData[key] = recordObj[key]));
          recordResult = val.formatFunc(verifiedData);
        } else {
          recordResult = val.formatFunc(
            recordObj[val.columnKey],
            val.columnKey
          );
        }
      } else {
        recordResult = recordObj[val.columnKey];
      }

      return formattedData.push(
        <div key={key} className={`${classes} ${styles.record_data_container}`}>
          <div className="me-2 ">{key}:</div>
          <div className={`${styles.record_data} text-truncate flex-grow-1`}>
            {recordResult}
          </div>
        </div>
      );
    });

    return formattedData;
  }

  function cardLayout() {
    return data.map((record) => (
      <Container
        key={record.key}
        className={`${styles.record_tile_container_wrapper} bg-white  g-0  my-3 d-flex border shadow rounded-2`}
      >
        <div
          className={` ${styles.record_tile_container} bg-white p-1 flex-grow-1 justify-content-center rounded-2`}
        >
          {compileCardRows(record).map((tile) => tile)}
        </div>
        {buttonConstructor ? (
          <ButtonBar
            buttonConstructor={buttonConstructor(record)}
            width={width}
            className={`${styles.button_bar}`}
            selectRecord={() => onClickFunc(record)}
            clearSelectedRecord={() => onClickFunc(null)}
          />
        ) : null}
      </Container>
    ));
  }

  function layoutSwitch() {
    let layout;

    if (width >= BSBreakpoints.md) {
      layout = tableLayout();
    } else {
      layout = cardLayout();
    }

    return (
      <>
        {layout}
        {!data.length && <NoResults />}
      </>
    );
  }

  return (
    <>
      <div
        //TODO change padding and add stripes when in col layout
        /* If breakpoint is >= 762px display the background as white */
        className={`
        w-100 px-3 mx-auto overflow-auto 
        ${styles.table_container}
        ${width >= BSBreakpoints.md ? "bg-white" : null} 
        `}
      >
        {layoutSwitch()}
      </div>
    </>
    /*     <Container
      className={`${styles.table_main_container}  d-flex flex-grow-1 w-100 px-0 `}>
    </Container> */
  );
}

export const MemoDataTable = React.memo(DataTable);