
import { useEffect, useState } from "react";
import { useRegisterSW } from "virtual:pwa-register/react";
import { useSharedStates } from "src/contexts/ApplicationStates/index"
export const useServiceWorker = () => {
  const { setUpdateAvailable, setInstallingUpdate } =  useSharedStates();
  const [swRegistration, setSwRegistration] = useState<ServiceWorkerRegistration | null>(null);
  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swURL, registration) {
      // If we have a truthy registration set it in state
      registration && setSwRegistration(registration);
      setUpdateAvailable(true);
    },
    onRegisterError(error) {
      console.error(`Service Worker Registration failed`);
      setSwRegistration(null);
    },
  });
  
  useEffect(() => {
    if(swRegistration?.installing) setInstallingUpdate(true);
  }, [swRegistration?.installing, setInstallingUpdate])

  return {
    swRegistration,
    setSwRegistration,
    updateServiceWorker,
    needRefresh,
    setNeedRefresh,
    offlineReady,
    setOfflineReady,
  };
};
