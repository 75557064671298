import { useApplicationStates, useAuth } from "src/contexts";
import { editRecord } from "src/helpers/recordHelpers";

/**
 * Collection of handlers related to DID numbers.
 */
export default function useDIDHandlers() {
  const { userPermissionLevel } = useAuth();
  const { socket, userData, sharedAppStates } = useApplicationStates();
  const { currentUser } = userData;
  const { billCodeDIDs, setFullRoomsList } = sharedAppStates;

  /**
   * Retrieve the DID numbers available based on a users role.
   * @returns {Array<Object>}
   */
  function currentUserDIDs() {
    if (currentUser) {
      if (userPermissionLevel("mid")) {
        return billCodeDIDs;
      } else {
        return currentUser.user_dids;
      }
    }

    return [];
  }

  /**
   * Retrieve the description for a DID number.
   * @param {String} did_number
   * @returns {String} Description for DID number if found, otherwise returns "No Description Found".
   */
  function getDIDDescription(did_number) {
    const foundDID = currentUserDIDs().find(
      ({ did_number: searched_did_number }) => did_number === searched_did_number
    );

    if (foundDID) {
      return foundDID.did_description;
    } else {
      return "No Description Found";
    }
  }

  /**
   * Callback fired when a new room is returned from the server.
   * @callback updateCustomerRoomDIDCB
   * @param {object} newRoom - The new room returned by the parent function.
   */
  /**
   * @typedef {Object} RoomData
   * @property {string[]} selected_did - Array of customer IDs.
   * @property {string} room_id - ID of room.
   */
  /**
   * @param {RoomData} roomData - {@link RoomData} - Object containing all relevant room data.
   * @param roomData.selected_did - The DID number to update selected room with.
   * @param roomData.room_id - The id of the room to update.
   * @param {updateCustomerRoomDIDCB} [callback=null] - Callback fired after the room is updated.
   * Defaults to null
   */
  function updateCustomerRoomDID({ selected_did, room_id }, callback) {
    socket.emit("requestUpdateRoom", { selected_did, room_id }, (updatedRoom) => {
      setFullRoomsList((prev) => editRecord(updatedRoom, "room_id", prev));
      return callback && callback(updatedRoom);
    });
  }

  return { currentUserDIDs: currentUserDIDs(), getDIDDescription, updateCustomerRoomDID };
}
