import { XMarkIcon } from '@heroicons/react/20/solid'
import { useApplicationStates } from 'src/contexts'

type BannerProps = { show: boolean };
export default function UpdateBanner({ show }: BannerProps) {
  const { sharedAppStates } = useApplicationStates();
  const { setShowReleaseNotes, installingUpdate } = sharedAppStates;

  const onClick = () => installingUpdate ? null : setShowReleaseNotes(true);
  const banner = (
    <div className="flex items-center gap-x-6 bg-indigo-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1 justify-center">
      <p className="text-sm leading-6 text-white">
        <a href="#" onClick={onClick}>
          <strong className="font-semibold">{installingUpdate ? `Installing Update` : `Update Available`}</strong>
        </a>
      </p>
      <div className="flex flex-1 justify-end">
        <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
          <span className="sr-only">Dismiss</span>
          <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
        </button>
      </div>
    </div>
  );

  return show ? banner : null;
}