import { firebaseAuth } from "./index";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";

export async function handleSignIn({ email, password }) {
  // No need to set the state persistence here as the default is local, all
  // users without a temp flag on their claims will have their token persist in local storage.

  // Sign in the user and get their UID
  const { user } = await signInWithEmailAndPassword(firebaseAuth, email, password);

  // Then send post request to authentication route to handle updating DB login time
  await fetch(`${import.meta.env.VITE_BACKEND_URL}/${import.meta.env.VITE_BACKEND_AUTH_PATH}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Auth-Type": "login",
    },
    body: JSON.stringify({
      user_id: user.uid,
      user_agent: navigator.userAgent,
    }),
  });
}

export async function handleSignOut(firebaseUser) {
  try {
    if (firebaseUser.fcm_token) {
      // Send post request to DB updating logout time and fcmToken to remove
      fetch(`${import.meta.env.VITE_BACKEND_URL}/${import.meta.env.VITE_BACKEND_AUTH_PATH}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Auth-Type": "logout",
        },
        body: JSON.stringify({
          user_id: firebaseUser.uid,
          fcm_token: firebaseUser.fcm_token,
        }),
      });

      // Unsubscribe from push notifications if fcm token was assigned
      //await deleteFCMToken();
    }

    // Logout the user
    await signOut(firebaseAuth);
  } catch (err) {
    console.error(err);
  }
}
