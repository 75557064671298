import { usersAttributes } from '@tsmodels/users';
import {useState, useCallback } from 'react'

export default function useUserStates(socket) {
  const [currentUser, setCurrentUser] = useState<null | usersAttributes>(null);
  const [contactPanePreferences, setContactPanePreferences] = useState({
    contactsPaneOpen: true,
    roomsPaneOpen: true,
    personalRoomPaneOpen: true,
  });
  const [selectedMessageViewRoomID, setSelectedMessageViewRoomID] = useState(null);


  // TODO: Move into own hook
  const handleChangeSetting = useCallback(
    (newSetting) => {
      if(!currentUser) return null;
      // Check if new setting is actually a column and not the settings JSON object (personal_phone, pushover_keys, etc.)
      if (Object.keys(newSetting)[0] in currentUser) {
        socket.emit("requestUpdateUserAttribute", newSetting, (updatedAttribute) => {
          setCurrentUser((prev) => ({
            ...prev,
            ...updatedAttribute,
          }));
        });
      } 
      
      else {
        const newSettingsObject = {
          ...currentUser.user_settings,
          ...newSetting,
        };

        socket.emit("requestChangeSetting", newSettingsObject, (updatedSettings: UserSettings) => {
          setCurrentUser((prev) => {
            if(!prev) return null;
            return ({
              ...prev,
              user_settings: updatedSettings,
            })
          });
        })
      }
    },
    [socket, currentUser]
  );

  return {
    contactPanePreferences,
    setContactPanePreferences,
    selectedMessageViewRoomID,
    setSelectedMessageViewRoomID,
    currentUser,
    setCurrentUser,
    handleChangeSetting,
  };
}

export type UserSettings = {
  archivedRooms?: number[],
  email_notifications?: boolean,
  firebase_notifications?: boolean,
  pushover_notifications?: boolean
}