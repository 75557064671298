//Maps following values to an object key pairs to table.
//First element is table header name and second is key of object to map to table cell.

import { FormatColumns } from "../../../../common/DataTable/components/DataTable.index";
import {
  convertDateFormat,
  sortAlphabetically,
  sortyByDate,
} from "../../../../helpers/helpers";

//Follows insertion order.
const adminCodesColumnMap = new Map([
  [
    "Billing Code",
    {
      columnKey: "billing_code",
      sort: true,
      sortFunc: sortAlphabetically,
    },
  ],
  [
    "DID Numbers",
    {
      columnKey: "did_numbers",
      sort: false,
      //sortFunc: sortByNumber,
      formatFunc: (dids) => <FormatColumns.DIDNumberCol dids={dids} />,
    },
  ],
  [
    "Company",
    {
      columnKey: "billcode_company_name",
      sort: true,
      sortFunc: sortAlphabetically,
    },
  ],
  //["Sending To Phone System", "deliver_to_phonesystem"],
  [
    "Created",
    {
      columnKey: "billcode_created_at",
      sort: true,
      sortFunc: sortyByDate,
      formatFunc: convertDateFormat,
    },
  ],
]);

export default adminCodesColumnMap;
