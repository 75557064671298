import { useApplicationStates } from "src/contexts";
import { useCallback } from "react";

export default function useCheckIfRoomArchived() {
  const { userData } = useApplicationStates();
  const { currentUser } = userData;
  const checkIfRoomIsArchived = useCallback(
    (room) => {
      const archivedRooms = currentUser.user_settings.archived_rooms;

      return archivedRooms.some(
        (archivedRoom) => archivedRoom.room_id === room.room_id
      );
    },
    [currentUser]
  );

  return { checkIfRoomIsArchived };
}
