import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContactCircle } from "src/common/commonViews.index";
import { LAYOUTS } from "src/constants/layout";
import { Button } from "react-bootstrap";

export default function RecordWrapperMobile({ record, displayName, selectedView, resetView, buttonActions }) {
  const [, selectedViewProps] = selectedView;
  const children =
    typeof selectedViewProps.children == "function"
      ? selectedViewProps.children({ layout: LAYOUTS.MOBILE })
      : selectedViewProps.children;

  return (
    <div
      className="w-100 h-100 mx-auto position-relative d-flex flex-column flex-grow-1" /* style={{ maxWidth: "450px" }} */
    >
      <div className="w-100">
        {/*   {!props.contactCircle === false ? ( */}
        <div className={` my-5 d-flex flex-column`}>
          <ContactCircle
            size="md"
            className={`  mx-auto`}
            circleNames={displayName}
            /* newContact={props.newContact} */
          />
        </div>

        {record && (
          <>
            <div className={` w-100 d-flex mx-auto`} style={{ maxWidth: "450px" }}>
              <h2 className={`line-clamp-2 px-4 w-100 text-break  text-center`}>{displayName}</h2>
            </div>
          </>
        )}
        <hr />
      </div>
      {children}
      <div className="d-flex justify-content-between mt-auto mx-4 mb-4">
        <Button /* className={`position-absolute bottom-0 ms-4 mb-4`} */ onClick={resetView}>
          <FontAwesomeIcon icon={faArrowLeft} size={"2x"} />
        </Button>
        {buttonActions &&
          buttonActions.map((btn) => (
            <Button key={btn.text} onClick={btn.onClick}>
              {btn.text}
            </Button>
          ))}
      </div>
    </div>
  );
}
