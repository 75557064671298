import MobileViewSelect from "src/common/Mobile/MobileViewSelect";
import { faAlignLeft, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FormConstructor } from "src/common/commonViews.index";
import { nanoid } from "nanoid";
export default function AddEditDID(props) {
  return (
    <MobileViewSelect.SelectedRecordView
      newContact={!props.selectedRecord}
      handleResetView={props.handleResetView}
      recordTitle={props.recordTitle}
      selectedRecord={props.selectedRecord}
      viewButtons={[<MobileViewSelect.SaveButton key={nanoid()} form_id="admin_dids_form" />]}
    >
      <FormConstructor
        id="admin_dids_form"
        className={`w-100 px-2`}
        onSubmit={(formInput) => {
          props.handleFunctions[props.selectedView](formInput);
          props.handleResetView();
        }}
        initialFormValues={
          props.selectedRecord ? { ...props.selectedRecord } : props.logicMap[props.selectedView].initialFormValues
        }
      >
        <div className={`mx-auto pe-4 `}>
          {props.selectedView === "add-did" && (
            <MobileViewSelect.MobileFormFieldLayout
              icon={faPhone}
              fieldName="did_number"
              selectedView={props.selectedView}
              fieldMap={props.logicMap[props.selectedView].fields}
            />
          )}

          <MobileViewSelect.MobileFormFieldLayout
            icon={faAlignLeft}
            fieldName="description"
            selectedView={props.selectedView}
            fieldMap={props.logicMap[props.selectedView].fields}
          />
          <MobileViewSelect.MobileFormFieldLayout
            icon={faAlignLeft}
            fieldName="deliver_to_phonesystem"
            selectedView={props.selectedView}
            fieldMap={props.logicMap[props.selectedView].fields}
          />
        </div>
      </FormConstructor>
    </MobileViewSelect.SelectedRecordView>
  );
}
