import {
  faAddressCard,
  faPlus,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { SetStateAction } from "react";
import { Button } from "react-bootstrap";

export default function Buttons({ children }) {
  return <>{children}</>;
}

/* function SwitchContactType({ setSelectedContact, dataList, setContactsList }) {} */

function ContactsSwitchView({
  setSelectedContact,
  contactsList,
  setContactsList,
}) {
  return (
    <Button
      className={`mx-2 align-self-end`}
      //className={`position-absolute end-0 top-50`}
      onClick={() => {
        setSelectedContact(null);
        setContactsList((prev) =>
          prev === "customersList" ? "usersInCode" : "customersList"
        );
      }}
    >
      <FontAwesomeIcon
        className={`me-2`}
        icon={contactsList === "customersList" ? faAddressCard : faUser}
      />
      {contactsList === "customersList" ? "Users" : "Customers"}
    </Button>
  );
}

function RoomsSwitchView({
  setShowDisabledRooms,
  roomListType,
  setRoomListType,
}) {
  //TODO componetize
  return (
    <Button
      className={`mx-2`}
      onClick={() => {
        setShowDisabledRooms(false);
        setRoomListType((prev) =>
          prev === "customers" ? "users" : "customers"
        );
      }}
    >
      <FontAwesomeIcon
        className={`me-2`}
        icon={roomListType === "customers" ? faAddressCard : faUser}
      />
      {roomListType === "customers" ? "Customers" : "Users"}
    </Button>
  );
}

function AdminNewCodeButton({ setSelectedView }) {
  return (
    <Button
      variant="primary"
      type="button"
      onClick={() => setSelectedView("add-code")}
    >
      <FontAwesomeIcon icon={faPlus} className="me-1" />
      Add New
    </Button>
  );
}

function AdminNewUserButton({ setSelectedView }) {
  return (
    <Button className="ms-3" onClick={() => setSelectedView("add-user")}>
      <FontAwesomeIcon icon={faPlus} />
      Add New
    </Button>
  );
}

function AdminNewDIDButton({ setSelectedView }) {
  return (
    <Button
      variant="primary"
      type="button"
      onClick={() => setSelectedView("add-did")}
    >
      <FontAwesomeIcon icon={faPlus} className="me-1" />
      Add New
    </Button>
  );
}
type AddNewProps = { view: string, setSelectedView: React.Dispatch<SetStateAction<string | null>>, className?: string};
function AddNew({ view, setSelectedView, className }: AddNewProps) {
  return (
    <Button
      className={className}
      variant="primary"
      type="button"
      onClick={() => setSelectedView(view)}
    >
      <FontAwesomeIcon icon={faPlus} className="me-1" />
      Add New
    </Button>
  );
}

function NewButton({ onClickFunc }) {
  return (
    <Button
      className="mx-2 d-flex align-items-center text-nowrap"
      onClick={onClickFunc}
    >
      <FontAwesomeIcon icon={faPlus} className={"me-2"} />
      New Client
    </Button>
  );
}

function YesNo({ className, noBtnOnClick, size }) {
  const style = {
    minWidth: "100px",
  };
  
  return (
    <div className={className}>
      <Button
        variant="primary"
        type="submit"
        size={size ? size : null}
        style={style}
      >
        Yes
      </Button>
      <Button
        variant="secondary"
        type="button"
        className="ms-5"
        size={size ? size : null}
        onClick={noBtnOnClick}
        style={style}
      >
        No
      </Button>
    </div>
  );
}

function DuplicateContact({ className, noBtnOnClick, size, duplicateType }) {
  const style = {
    minWidth: "100px",
  };

  return (
    <div className={className}>
      <Button
        variant="primary"
        type="submit"
        size={size ? size : null}
        style={style}
      >
        { `Go To ${duplicateType ?? `Contact`}` }
      </Button>
      <Button
        variant="primary"
        type="button"
        className="ms-5"
        size={size ? size : null}
        onClick={noBtnOnClick}
        style={style}
      >
        Cancel
      </Button>
    </div>
  );
}

Buttons.ContactsSwitchView = ContactsSwitchView;
Buttons.RoomsSwitchView = RoomsSwitchView;
Buttons.AdminNewCodeButton = AdminNewCodeButton;
Buttons.AdminNewUserButton = AdminNewUserButton;
Buttons.AdminNewDIDButton = AdminNewDIDButton;
Buttons.AddNew = AddNew;
Buttons.NewButton = NewButton;
Buttons.YesNo = YesNo;
Buttons.DuplicateContact = DuplicateContact;
