import { flexRender } from "@tanstack/react-table";
import { Button, Table as RbTable } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import TableSearchBar from "./TableSearchBar";

export default function TableListLayout({ table, setGlobalFilter, onRowClick, createButton }) {
  function groupByFirstLetter() {
    const groups = new Map([]);
    // TODO: Harcoded
    table.getPrePaginationRowModel().rows.forEach((row) => {
      const firstLetter = row.getValue("company_name")[0].toUpperCase();
      groups.set(firstLetter, [...(groups.get(firstLetter) || []), row]);
    });

    return groups;
  }

  const createRecordBtn = (props) => (
    <Button onClick={() => props.handler()} className={`position-fixed end-0 bottom-0 mb-4 me-4 shadow`} size="md">
      <FontAwesomeIcon icon={faPlus} size="2x" />
    </Button>
  );

  return (
    <>
      <TableSearchBar setGlobalFilter={setGlobalFilter} className="m-2 mb-0 shadow-sm" />
      <div className="w-100 p-2 pt-0 d-flex flex-column flex-shrink-1 flex-basis-0 overflow-auto">
        <RbTable hover className="user-select-text  table-borderless" data-testid="mobile_data_table">
          {[...groupByFirstLetter()].map(([letter, rows]) => {
            return (
              <tbody key={letter} className="d-block w-100">
                <tr className="position-sticky top-0" style={{ minWidth: "25px", height: "min-content" }}>
                  <td>{letter}</td>
                </tr>
                {rows.map((row) => (
                  <tr
                    key={row.id}
                    /* scope="row" */
                    className="*mb-2 d-flex flex-grow-1 ms-4"
                    onClick={() => onRowClick(row.original)}
                  >
                    {row.getVisibleCells().map((cell) => (
                      // TOOD: padding should probably be a const
                      <td key={cell.id} className="px-xl-5 py-3 w-100">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            );
          })}
        </RbTable>
      </div>
      {createButton && createRecordBtn(createButton)}
    </>
  );
}
