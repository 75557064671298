import { faPencilAlt, faExternalLinkAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export function useCustomerButtonConstructor({ foundRooms, setSelectedView, checkForExistingRoom, showDisabledRooms }) {
  function customerButtonConstructor() {
    // Generates what the room button label will be
    function foundRoomLabel() {
      if (foundRooms.length) {
        if (foundRooms.length > 1) {
          return "Select Room";
        }
        return "Go To Room";
      }
      return "Create A Room";
    }

    const editBtn = {
      label: "Edit",
      onClick: () => setSelectedView("edit-customer"),
      faIcon: faPencilAlt,
    };

    const roomBtn = {
      label: foundRoomLabel(),
      onClick: () => {
        if (foundRooms.length > 1) {
          return setSelectedView("room-select");
        }
        return checkForExistingRoom();
      },
      faIcon: faExternalLinkAlt,
    };

    const deleteBtn = {
      label: "Delete",
      onClick: () => setSelectedView("delete-customer"),
      faIcon: faTrashAlt,
    };

    const buttonsArray = [editBtn, roomBtn, deleteBtn];
    return buttonsArray;
  }

  return { customerButtonConstructor };
}

export function useUserButtonConstructor({ personalRooms, foundRooms, checkForExistingRoom, setSelectedView }) {
  const navigate = useNavigate();
  const label = foundRooms.length ? "Go To Room" : "Create Room" 
  function userButtonConstructor() {
    return [
      /* {
        label: "Send Message",
        onClick: checkForExistingRoom,
        faIcon: faComment,
      }, */
      {
        label,
        // Does not fire if button is disabled, preventing a null router state
        onClick: checkForExistingRoom,
        faIcon: faExternalLinkAlt,
      },
    ];
  }

  return { userButtonConstructor };
}
