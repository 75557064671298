import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApplicationStates } from "src/contexts";
import {
  combineFirstAndLastName,
  formatPhoneNumber,
} from "src/helpers/formattingHelpers";
import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button, Form, Table } from "react-bootstrap";
import { FormConstructorContext } from "../FormConstructor";
import { joinArrayOfNames } from "src/helpers/helpers.index";

export default function OccupiedRoomDIDSelect({
  keyName,
  className,
  setModalActive,
  setSelectedView,
  tempBypass,
  occupyRoom,
  newRoom,
  view,
  selectedContact,
  selectedRoom,
}) {
  const { formInput, setFormInput } = useContext(FormConstructorContext);
  const { userData, sharedAppStates } = useApplicationStates();
  const { currentUser } = userData;
  const location = useLocation();
  const { serverResponse } = sharedAppStates;
  const [showDIDTable, setShowDIDTable] = useState(false);
  const occupiedRooms = serverResponse["occupied-rooms"];
  useEffect(() => {
    if (occupiedRooms.length === 1 && !showDIDTable) {
      setFormInput((prev) => ({
        ...prev,
        selected_room: occupiedRooms[0],
      }));
    }
  }, [occupiedRooms, showDIDTable, selectedRoom, setFormInput]);

  if (!serverResponse || !serverResponse["occupied-rooms"]) {
    console.error("🛑 ERROR: NO SERVER RESPONSE FOUND");
    return null;
  }

  if (!occupiedRooms) {
    return null;
  } /* else  */

  /*  const usersInRoom = ""; */ //joinArrayOfNames(occupiedRooms.room_users);

  const availableUserDids =
    (currentUser &&
      currentUser.user_dids.filter(
        (did) =>
          !occupiedRooms.some((room) => room.room_did_number === did.did_number)
      )) ||
    [];

  function handleSubmit(mobile) {
    if (showDIDTable) {
      newRoom(formInput);
    } else {
      mobile ? occupyRoom(formInput, "mobile") : occupyRoom(formInput);
    }
  }

  function handleTempBypass(mobile) {
    mobile ? tempBypass(formInput, "mobile") : handleTempBypass(formInput)
  } 

  const roomNames = () => {
    if (occupiedRooms.length === 1) {
      return joinArrayOfNames(occupiedRooms[0].room_users);
    }

    return "";
  };

  const introDisplayText = `This customer is already engaged in a conversation with the following users - ${roomNames()}. Would you like to join the room?`; //${usersInRoom}
  const emptyRoomText = `This customer has a room already on DID number ${formatPhoneNumber(occupiedRooms[0].room_did_number)} but no users are present- would you like to join the room?`
  const didNumSelectionText = `Please select the DID number you would like to use in order to start a new conversation with ${combineFirstAndLastName(
    selectedContact
  )}.`;
  const multipleRoomText = `This customer has multiple rooms- please select the room you wish to join:`;
  const selectedRoomText = `Are you sure you want to join this room? Your message will be sent on submit.`;

  const multipleDIDsAvailable = (
    <p key={"multipleDIDSAvailable"} className="my-4">
      You may also start a conversation on another DID number, however this is
      not recommended as it can possibly lead to customer confusion.
    </p>
  );

  const multipleRoomsTable = (
    <React.Fragment key={"multipleRoomsTable"}>
      <p>{multipleRoomText}</p>

      <Table
        className={`${className} mt-3`}
        //className={styles.DIDAssign_table}
        style={{
          gridTemplateColumns: `minmax(auto, 100px) minmax(auto, 100px) minmax(auto, auto) minmax(auto, auto) minmax(auto, auto)`,
        }}
        striped
        bordered
        hover
      >
        <thead>
          <tr>
            <th>Selected</th>
            <th>Room ID</th>
            <th>DID Number</th>
            <th>Customers</th>
            <th>Users</th>
          </tr>
        </thead>
        <tbody>
          {occupiedRooms.map((room) => {
            return (
              <tr
                key={room.room_id}
                onClick={() => {
                  setFormInput((prev) => ({
                    ...prev,
                    selected_room: room,
                  }));
                }}
              >
                <td>
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      className="d-flex justify-content-center"
                      type="radio"
                      onChange={() =>
                        setFormInput((prev) => ({
                          ...prev,
                          selected_room: room,
                        }))
                      }
                      checked={
                        formInput.selected_room &&
                        formInput.selected_room.room_id === room.room_id
                      }
                    />
                  </Form.Group>
                </td>
                <td>{room.room_id}</td>
                <td>{formatPhoneNumber(room.room_did_number)}</td>
                <td>
                  <div className="d-flex flex-column">
                    {room.room_customers.map((customer) => (
                      <p key={customer.customer_id}>
                        {combineFirstAndLastName(customer)}
                      </p>
                    ))}
                  </div>
                </td>

                <td>
                  <div className="d-flex flex-column">
                    {room.room_users.map((user) => (
                      <p key={user.user_id}>{combineFirstAndLastName(user)}</p>
                    ))}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </React.Fragment>
  );

  const occupiedRoomDIDMobileTable = (
    <div className="overflow-auto">
      <p>{didNumSelectionText}</p>
      <div className="mt-3 mx-auto" style={{ "max-width": "275px" }}>
        {availableUserDids.map((did) => (
          <>
            <div
              key={did.did_number}
              className="d-flex"
              onClick={() =>
                setFormInput((prev) => ({
                  ...prev,
                  selected_did: did.did_number,
                }))
              }
            >
              <Form.Check
                aria-label={did.did_number}
                className="me-4 d-flex justify-content-center align-items-center"
                type="radio"
                checked={formInput.selected_did === did.did_number}
                onChange={() =>
                  setFormInput((prev) => ({
                    ...prev,
                    selected_did: did.did_number,
                  }))
                }
              />
              <div>
                <div>{formatPhoneNumber(did.did_number)}</div>

                <div>{did.did_description}</div>
              </div>
            </div>
            <hr />
          </>
        ))}
        {formInput[keyName] ? (
          <div className="position-absolute bottom-0 end-0 mb-4 me-4">
            <Button type="submit">
              <FontAwesomeIcon icon={faArrowRight} size="2x" />
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );

  const occupiedRoomDIDTable = (
    <React.Fragment key={"occupiedRoomDIDTable"}>
      <Table
        className={`${className} mt-3`}
        //className={styles.DIDAssign_table}
        style={{
          gridTemplateColumns: `minmax(auto, auto) minmax(auto, auto) minmax(100px, 1fr)`,
        }}
        striped
        bordered
        hover
      >
        <thead>
          <tr>
            <th>Selected</th>
            <th>DID Number</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {availableUserDids.map((did) => {
            return (
              <tr
                key={did.did_number}
                onClick={() =>
                  setFormInput((prev) => ({
                    ...prev,
                    selected_did: did.did_number,
                  }))
                }
              >
                <td>
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      className="d-flex justify-content-center"
                      type="radio"
                      onChange={() =>
                        setFormInput((prev) => ({
                          ...prev,
                          selected_did: did.did_number,
                        }))
                      }
                      checked={formInput.selected_did === did.did_number}
                    />
                  </Form.Group>
                </td>
                <td>{formatPhoneNumber(did.did_number)}</td>
                <td>{did.did_description}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </React.Fragment>
  );

  const mobileView = (
    <div className="">
      {!showDIDTable ? (
        <>
          <p className="mb-4">{introDisplayText}</p>
          <div>
            {/* <Buttons.YesNo
              size="lg"
              className="px-4 mb-4 d-flex justify-content-between"
              noBtnOnClick={() => setSelectedView(null)}
            /> */}
            { submitButton(true) }
          </div>
        </>
      ) : null}
      {availableUserDids.length > 0 ? (
        <div className="w-100">
          {!showDIDTable ? (
            <p className="mb-4">
              You may also start a conversation on another DID number, however
              this is not recommended as it can possibly lead to customer
              confusion.
            </p>
          ) : null}
          <div className="d-flex justify-content-center">
            <Button
              className="mb-3"
              size="lg"
              /*   onClick={() => {
                setShowDIDTable((prev) => !prev);
                setFormInput({ ...formInput, [keyName]: "" });
              }} */
            >
              {!showDIDTable ? "New Room" : "Occupy Room"}
            </Button>
          </div>
        </div>
      ) : null}
      {showDIDTable ? occupiedRoomDIDMobileTable : null}
    </div>
  );

  function tableSelect() {
    let components = [];
    if (!selectedContact && selectedRoom) {
      components.push(selectedRoomText);
    } else if (occupiedRooms.length === 1 && occupiedRooms[0].room_users.length === 0) {
      components.push(emptyRoomText);
    } else if (occupiedRooms.length === 1) {
      components.push(introDisplayText);
    }

    if (occupiedRooms.length > 1 && !showDIDTable) {
      components.push(multipleRoomsTable);
    }

    if (showDIDTable) {
      components.shift();
      components.push(didNumSelectionText);
      components.push(occupiedRoomDIDTable);
    }

    if (availableUserDids.length && selectedContact && !showDIDTable) {
      components.push(multipleDIDsAvailable);
    }

    return (
      <>
        {components.map((component) => component)}
        <div className="d-flex justify-content-between align-items-end mt-4">
          {/* Show new room button if a contact is selected and the user has DID numbers available */}
          {!!availableUserDids.length && selectedContact && newRoomToggleBtn}
          {submitButton()}
        </div>
      </>
    );
  }

  function submitButton(mobile = false) {
    const minWidth = mobile ? "100px" : "80px"
    const submitBtn = (
      <Button
        variant="primary"
        type="button"
        className="d-block ms-auto"
        style={{ minWidth }}
        //size={width < BSBreakpoints.bp675 ? "sm" : null}
        onClick={() => {
          handleSubmit();
          //setFormInput({ ...formInput, [keyName]: "" });
        }}
       size={mobile ? "lg" : null}
      >
        {occupiedRooms.length <= 1 ? "Yes" : "Submit"}
      </Button>
    );

    const tempBtn = (
      <Button
        variant="outline-warning"
        type="button"
        className="d-block ms-auto"
        style={{ minWidth}}
        onClick={() => handleTempBypass() }
          
        
        title="You Will Not Be Able To Send Messages"
        size={mobile ? "lg" : null}
      >
        Temp Join
      </Button>
    )

    const noBtn = (
      <Button
        variant="primary"
        type="button"
        className="d-block ms-auto"
        style={{ minWidth }}
        //size={width < BSBreakpoints.bp675 ? "sm" : null}
        onClick={() => mobile ? setSelectedView(null) : setModalActive(false)}
        size={mobile ? "lg" : null}
      >
        No
      </Button>
    );

    if (occupiedRooms.length <= 1) {
      return (
        
        <div
          className="ms-auto w-100 d-flex justify-content-between"
          style={ mobile ? { minWith: "100px" } : { maxWidth: "300px" } }
        >
          {submitBtn}
          {mobile && location.pathname !== "/user/messages" && currentUser.user_role === "admin" ? tempBtn : null}
          {noBtn}

          {!mobile && location.pathname !== "/user/messages" && currentUser.user_role === "admin" ? tempBtn : null}
        </div>
      );
    } else {
      return submitBtn;
    }
  }

  const newRoomToggleBtn = (
    <Button
      variant="primary"
      type="button"
      //size={width < BSBreakpoints.bp675 ? "sm" : null}
      onClick={() => {
        setShowDIDTable((prev) => !prev);
        setFormInput((prev) => {
          if (showDIDTable) {
            return { ...prev, selected_did: "" };
          } else {
            return { ...prev, selected_room: "" };
          }
        });
        //setFormInput({ ...formInput, [keyName]: "" });
      }}
    >
      New Room
    </Button>
  );

  return view === "mobile" ? mobileView : <div>{tableSelect()}</div>;
}
