import AddEditDID from "../mobile/AddEditDID";
import AllDIDs from "../mobile/AllDIDs";
import SelectedDID from "../mobile/SelectedDID";

export default function AdminDIDsMobileViews(props) {
  const recordTitle = props.selectedRecord
    ? props.selectedRecord.did_number
    : null;

  if (props.selectedRecord && !props.selectedView) {
    return <SelectedDID {...props} recordTitle={recordTitle} />;
  }

  if (props.selectedView && props.logicMap[props.selectedView].mobileView) {
    return props.logicMap[props.selectedView].mobileView({
      ...props,
      recordTitle,
    });
  }

  /*   

  if (props.selectedView && props.logicMap[props.selectedView].mobileView) {
    return props.logicMap[props.selectedView].mobileView({
      ...props,
      recordTitle,
    });
  } */

  return <AllDIDs {...props} />;
}

AdminDIDsMobileViews.AddEditDID = AddEditDID;
