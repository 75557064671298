import styles from "../../Messages.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { BSBreakpoints } from "../../../../../hooks/useWindowDimensions";

export default function ImagePreview({ imageSrc, handlePreview, width }) {
  return (
    <div
      className={`${styles.preview_image_main_container} ${
        width < BSBreakpoints.sm ? "mx-auto" : "me-5"
      } d-flex justify-content-end position-sticky bottom-0 align-self-end`}
    >
      <div className={` p-2 bg-white shadow position-relative rounded`}>
        <div
          className={`${styles.image_preview_remove} d-flex justify-content-center align-items-center `}
          onClick={() => handlePreview(null)}
        >
          <FontAwesomeIcon
            icon={faTimes}
            className={`${styles.image_preview_remove_icon} `}
          />
        </div>
        <img
          src={imageSrc}
          alt="Upload Preview"
          className={`${styles.image_preview} rounded`}
        />
      </div>
    </div>
  );
}
