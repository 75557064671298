import useWindowDimensions, { BSBreakpoints } from "../../hooks/useWindowDimensions";

export default function ResponsiveContainer({ children }) {
  const { width } = useWindowDimensions();

  const topMargin = width < BSBreakpoints.md ? "mt-0" : "mt-3";

  return (
    <div className={`d-flex flex-column ${topMargin} p-0 flex-grow-1 overflow-auto`} style={{ minHeight: 0 }}>
      {children}
    </div>
  );
}
