import { useApplicationStates } from "src/contexts";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { customersAttributes } from "src/types/models";
export default function useSendMessage(setMessages = null, savedMessage = {}) {
  const { userData, sharedAppStates, socket } = useApplicationStates();
  const { currentUser } = userData;
  const { customersList, setFullRoomsList } = sharedAppStates;

  const [modalActive, setModalActive] = useState(false);
  const [notOptedInCustomer, setNotOptedInCustomer] = useState<customersAttributes | null>(null);

  function invalidSendMessageModal() {
    const customerName =
      notOptedInCustomer &&
      `${notOptedInCustomer.first_name} ${notOptedInCustomer.last_name}`;

    return (
      <Modal
        contentClassName={"modal_main_style"}
        dialogClassName={"modal_dialog_style"}
        show={modalActive}
        onHide={() => {
          setModalActive(false);
          setNotOptedInCustomer(null);
        }}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            An Opt In Message Has Been Sent
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-4">
          {`A Opt In Message was dispatched to ${customerName}, you will be able to message them once they have replied with consent.`}
        </Modal.Body>
      </Modal>
    );
  }

  function sendMessage({ message_text, room, uploadedFile = null, optIn = false }) {
    // Display no-opt in modal if sms and customer hasn't opted in
    if (room.room_customers.length === 1) {
      const foundCustomer = customersList.find(
        (customer) =>
          customer.customer_id === room.room_customers[0].customer_id
      );

      if (!foundCustomer) {
        console.error("ERROR: Unknown customer-", room.room_customers[0]);
        console.warn(
          "This customer most likely has been engaged with a DID you did not have access to but were added to a room with that DID by an admin/manager"
        );
        //TODO: Should customers actually disappear from the contacts list if engaged with a DID the user doesn't have access to?
      }
      //If the customer isn't opted in display warning modal
      if (foundCustomer && !foundCustomer.sms_opt_in) {
        setNotOptedInCustomer(foundCustomer);
        setModalActive(true);
      }
    }

    const roomCustomerIDs = room.room_customers.map(
      (customer) => customer.customer_id
    );
    const customerRecords = customersList.filter((customer) =>
      roomCustomerIDs.includes(customer.customer_id)
    );
    const customerPhones = customerRecords.map(
      (customer) => `${customer.phone_number_country}${customer.phone_number}`
    );
    
    //Can be utilized for requring opt ins on future group messages
    /* const customerOptIns = customerRecords.map(
      (customer) => customer.sms_opt_in
    ); */

    const messageObject = {
      from_user_id: currentUser.user_id,
      message_room_id: room.room_id,
      message_text: message_text,
      media: uploadedFile
        ? [
            {
              fileName: uploadedFile.name,
              data: uploadedFile,
            },
          ]
        : [],
      to_phone: customerPhones,
      from_phone: room.room_did_number ? room.room_did_number : null,
      misc_sms: "",
      customer_id_list: room.room_customers.map(
        (customer) => customer.customer_id
      ),
      opt_in: optIn,
      senderName: `${currentUser.first_name} ${currentUser.last_name}`,
    };

    socket.emit(optIn ? `newOptIn` : `newWebMessage`, messageObject, (newMessage) => {
      // If a set state function was passed to hook, use it to update that components state as well.
      // In this case the Messages view also needs the new message appended to its local state.

      if (setMessages) setMessages((prev) => (prev ? [...prev, newMessage] : [newMessage]));
      

      setFullRoomsList((prev) => {
        const foundRoom = prev.find(
          (room) => room.room_id === newMessage.message_room_id
        );

        foundRoom.last_message_text = newMessage.message_text;
        foundRoom.last_message_from = newMessage.last_message_from;
        foundRoom.room_updated_at = newMessage.sent_at;
        foundRoom.last_message_time = newMessage.sent_at;
        return [...prev];
      });
    });
    return true;
  }

  return { sendMessage, invalidSendMessageModal };
}
