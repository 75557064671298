import { useDIDHandlers, useRoomHandlers } from "src/hooks";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { RoomInfo } from "src/common";
import styles from "../RoomManagement.module.scss";
import { useApplicationStates } from "src/contexts";

export default function OccupiedRoomFound({ roomFormData, setRoomFormData, setSelectedView, setFormStage, setTitle }) {
  ////////////////////////////////////////////////////////////////////////////////
  // HOOKS
  ////////////////////////////////////////////////////////////////////////////////
  const { userData } = useApplicationStates();
  const { joinCurrentUserToRoom } = useRoomHandlers();
  const { currentUserDIDs } = useDIDHandlers();
  const navigate = useNavigate();

  useEffect(() => {
    setTitle("Occupied Room(s) found");
  }, [setTitle]);

  ////////////////////////////////////////////////////////////////////////////////
  // CONSTS
  ////////////////////////////////////////////////////////////////////////////////
  const { occupied_rooms } = roomFormData;

  /**
   * An array of did number strings
   * @const {string[]}
   */
  const availableDIDNumbers = currentUserDIDs.filter(
    // If current user did is found in any room filter it out
    ({ did_number }) => !occupied_rooms.some(({ room_did_number }) => did_number === room_did_number)
  );

  function handleNewRoom() {
    // If none of the occupied rooms are using the previously selected DID then skip did selection
    if (!occupied_rooms.some(({ room_did_number }) => room_did_number === roomFormData.room_did_number)) {
      setRoomFormData((prev) => ({ ...prev, submitType: "CREATE_NEW_ROOM" }));
      setFormStage("CONFIRM_ROOM_ACTION");
    } else {
      // Else remove all occupied room did numbers from the did list
      setRoomFormData((prev) => ({
        ...prev,
        available_did_numbers: availableDIDNumbers.filter(
          (did) => !occupied_rooms.some(({ room_did_number }) => did === room_did_number)
        ),
      }));
      setFormStage("SELECT_DID_NUMBER");
    }
  }

  function handleJoinRoom(room) {
    joinCurrentUserToRoom(room.room_id, (joinedRoom) => {
      setSelectedView(null);
      navigate(`/user/messages?room_id=${joinedRoom.room_id}`);
    });
  }

  const cancelBtn = (
    <Button className={occupied_rooms.length === 1 ? "" : "me-2"} onClick={() => setSelectedView(null)}>
      Cancel
    </Button>
  );

  const newRoomBtn = (
    <Button
      className={occupied_rooms.length === 1 ? "mx-2" : "ms-2"}
      disabled={availableDIDNumbers.length === 0}
      onClick={handleNewRoom}
    >
      New Room
    </Button>
  );

  const joinRoomBtn = (room) => (
    <Button className="me-2" onClick={() => handleJoinRoom(room)}>
      Join
    </Button>
  );

  const goToRoomBtn = (room) => (
    <Button
      onClick={() => {
        setSelectedView(null);
        navigate(`/user/messages?room_id=${room.room_id}`);
      }}
    >
      Go To Room
    </Button>
  );

  const currentUserIsParticipant = (room) =>
    room.room_users.some(({ user_id }) => userData.currentUser.user_id === user_id);

  //TODO: Handle multiple rooms
  if (occupied_rooms.length === 1) {
    const occupiedRoom = occupied_rooms[0];
    const isParticipant = currentUserIsParticipant(occupiedRoom);

    return (
      <>
        {isParticipant ? existingConvoDifferentDidSingle : mainTextSingle}
        <RoomInfo room={occupiedRoom} />
        {newRoomWarningtext}
        <div className={`${styles.button_wrapper}`}>
          {cancelBtn}
          {newRoomBtn}
          {isParticipant ? goToRoomBtn(occupiedRoom) : joinRoomBtn(occupiedRoom)}
        </div>
      </>
    );
  }

  return (
    <div className="d-flex flex-column flex-grow-1">
      {mainTextPlural}
      {occupied_rooms.map((room) => {
        const isParticipant = currentUserIsParticipant(room);
        return (
          <>
            <RoomInfo room={room} compact>
              <div className={`${styles.button_wrapper}`}>{isParticipant ? goToRoomBtn(room) : joinRoomBtn(room)}</div>
            </RoomInfo>
          </>
        );
      })}
      {newRoomWarningtext}
      <div className={`${styles.button_wrapper}`}>
        {cancelBtn}
        {newRoomBtn}
      </div>
    </div>
  );
}

const mainTextSingle = (
  <p className="mb-3">
    This customer is currently already engaged the following conversation- Would you like to join this room instead?
  </p>
);

const mainTextPlural = (
  <p className="mb-3">
    This customer is currently already engaged the following conversations- Would you like to join one of these rooms
    instead?
  </p>
);

const existingConvoDifferentDidSingle = (
  <p className="mb-3">
    You currently have an ongoing conversation with these participants via another DID # - Would you like to use that
    room instead?
  </p>
);

/* const existingConvoDifferentDidPlural = (
  <p className="mb-3">
    {`You currently have multiple ongoing conversations with these participants via other DID #'s - Would you like to use one of those
    rooms instead?`}
  </p>
); */

const newRoomWarningtext = (
  <p className="mt-auto">
    You may create a new room on another DID, however this is not recommended as it may lead to possible customer
    confusion.
  </p>
);
