import { useApplicationStates } from "src/contexts";
import StagingBanner from "./StagingBanner";
import UpdateBanner from "./UpdateBanner";

export default function Banner() {
  const { sharedAppStates } = useApplicationStates();
  const { updateAvailable } = sharedAppStates;
  const isStaging = import.meta.env.MODE === "staging";
  return (
    <>
      {
        updateAvailable 
          ? <UpdateBanner show={updateAvailable} /> 
          : <StagingBanner show={isStaging} />
      }
    </>
  );
}
