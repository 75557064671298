import { useRef, useState } from "react";
import { Button, Popover, Overlay } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { BSBreakpoints } from "../../../../hooks/useWindowDimensions";

import styles from "./ButtonBar.module.scss";

export default function ButtonBar({ buttonConstructor, width, selectRecord, clearSelectedRecord }) {
  const [showPopover, setShowPopover] = useState(false);

  const popOverRef = useRef(null);

  function buttonBar() {
    return (
      <div className={`${styles.btn_container} mx-auto w-100 pb-3 pt-2 d-flex justify-content-between `}>
        {buttonConstructor.map(
          (buttonObject, i) =>
            buttonObject && (
              <Button
                style={{ minWidth: "150px" }}
                disabled={buttonObject.disabled === true ? true : null}
                key={`${buttonObject.label}-${i}`}
                variant={buttonObject.label === "Delete" ? "danger" : "primary"}
                size="sm"
                className={`${buttonObject.className} border btn-secondary mx-1 shadow-sm`}
                onClick={buttonObject.onClick}
              >
                <FontAwesomeIcon icon={buttonObject.faIcon} className="me-1" />
                {buttonObject.label}
              </Button>
            )
        )}
      </div>
    );
  }

  function buttonHamburger() {
    return (
      <div>
        <Button className="h-100" variant="secondary" ref={popOverRef} onClick={() => setShowPopover((prev) => !prev)}>
          <FontAwesomeIcon icon={faBars} />
        </Button>
        {/* //TODO examine the props for this component */}
        <Overlay
          target={popOverRef}
          rootClose={true}
          trigger="click"
          placement="left"
          onHide={() => {
            clearSelectedRecord();
            setShowPopover(false);
          }}
          onEnter={selectRecord}
          show={showPopover}
        >
          <Popover id="popover-basic" className={`${styles.action_popover}`}>
            <Popover.Header as="h3" className={`text-center`}>
              Actions
            </Popover.Header>
            <Popover.Body className="d-flex flex-column ">
              {buttonConstructor
                .filter((button) => button)
                .map((buttonObject, i) => (
                  <Button
                    key={`${buttonObject.label}-${i}`}
                    variant={buttonObject.label === "Delete" ? "danger" : "secondary"}
                    size="sm"
                    className={`
                    ${buttonObject.className}
                    ${buttonObject.label === "Delete" ? "mb-0" : "mb-3"}
                    btn-secondary my-1`}
                    disabled={buttonObject.disabled}
                    onClick={() => {
                      buttonObject.onClick();
                      setShowPopover(false);
                    }}
                  >
                    <FontAwesomeIcon icon={buttonObject.faIcon} className="me-1" />
                    {buttonObject.label}
                  </Button>
                ))}
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
    );
  }

  return width >= BSBreakpoints.md ? buttonBar() : width < BSBreakpoints.md ? buttonHamburger() : null;
}
