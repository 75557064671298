//DOC: https://stackoverflow.com/questions/39924309/javascript-iso-date-to-human-readable
export function convertDateFormat(timestamp) {
  if (!timestamp) {
    return "";
  }

  const date = new Date(timestamp);
  const dateTimeFormat = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  try {
    return dateTimeFormat.format(date);
  } catch (err) {
    return null;
  }
}

export function filterRecords(userInput, listToFilter, arrayOfExceptions = []) {
  // Escape user input
  const escapedString = userInput.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
  // Create Regular expression out of escaped string
  const searchRegex = new RegExp(escapedString, "gi");
  // Filter records for requested list- for each record...
  return listToFilter.filter((record) =>
    // Check all object propeties on record...
    Object.keys(record).some(
      (key) =>
        // That are not included on arrayOfExceptions
        arrayOfExceptions.indexOf(key) === -1 &&
        // And match the searchRegex
        searchRegex.test(record[key])
      // Finally if any properties match, include record in returned array
    )
      ? true
      : false
  );
}

export function convertTimestampToDate(timestamp) {
  if (typeof timestamp === "undefined") {
    return;
  }

  const dateNow = new Date();
  const date = new Date(timestamp);

  // If timestamp is of the year 1969 it mean date was converted from a null value
  if (date.getFullYear() === 1969) {
    return "Never";
  }

  function dateTimeFormat(options) {
    return new Intl.DateTimeFormat("en-US", options);
  }

  //DOC: stackoverflow.com/questions/8888491/how-do-you-display-javascript-datetime-in-12-hour-am-pm-format
  //Convert timestamp to AM/PM
  //LINK: stackoverflow.com/questions/8888491/how-do-you-display-javascript-datetime-in-12-hour-am-pm-format
  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  let options;

  if (dateNow.toDateString() === date.toDateString()) {
    return `${formatAMPM(date)}`;
  } else if (new Date(dateNow.setDate(dateNow.getDate() - 1)).toDateString() === date.toDateString()) {
    return `Yesterday • ${formatAMPM(date)}`;
  } else if (date.setDate(date.getDate()) > dateNow.setDate(dateNow.getDate() - 6)) {
    options = {
      weekday: "short",
    };
  } else if (dateNow.getMonth() === date.getMonth() && date.getFullYear === dateNow.getFullYear) {
    options = {
      weekday: "long",
      month: "short",
      day: "numeric",
    };
  } else {
    options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
  }

  return `${dateTimeFormat(options).format(date)} ${formatAMPM(date)}`;
}

// add, splice, or remove element from record array.
// Uses the prev state from the set state callback to ensure data integrity
// editType is type of edit ('edit' or 'delete'), record is record to be modified,
// recordKey is the key to search by and prevState is the states previous state fed by the setState callback.
// Last argument is optional, only used when changing the primary key

export function editRecord(record, recordKey, prevState, prevPrimaryKey) {
  const newRecordArray = [...prevState];
  const findValue = prevPrimaryKey ? prevPrimaryKey : record[recordKey];
  const index = newRecordArray.findIndex((record) => record[recordKey] === findValue);

  newRecordArray.splice(index, 1, record);
  return newRecordArray;
}

export function deleteRecord(recordID, recordKey, prevState) {
  const newRecordArray = [...prevState];
  const index = newRecordArray.findIndex((record) => record === recordID || record[recordKey] === recordID);

  if (index > -1) {
    newRecordArray.splice(index, 1);
    return newRecordArray;
  } else {
    console.error(`🚫 No Record Found! recordID: ${recordID} recordKey: ${recordKey}`);
    return newRecordArray;
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////
// Sorting functions
/////////////////////////////////////////////////////////////////////////////////////////////////
export function sortAlphabetically(list, keyToSortBy, direction) {
  if (list.length > 1) {
    //TODO return empty array if invalid?
    return list.sort((a, b) => {
      if (a[keyToSortBy] === null) {
        return -1;
      }

      if (b[keyToSortBy] === null) {
        return 1;
      }

      const nameA = a[keyToSortBy].toLowerCase();
      const nameB = b[keyToSortBy].toLowerCase();
      // If name empty sort to back
      /*       if (nameA === "") {
        return 1;
      } else if (nameB === "") {
        return -1;
        // Otherwise sort
      } else { */
      if (direction === "descending") {
        return nameA > nameB ? -1 : nameA < nameB ? 1 : 0;
      } else {
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      }

      //}
    });
  } else {
    return list;
  }
}

export function sortFullNames(list, keyToSortBy, direction) {
  if (list.length > 1) {
    //TODO return empty array if invalid?
    return list.sort((a, b) => {
      if (a[keyToSortBy] === null) {
        return -1;
      }

      if (b[keyToSortBy] === null) {
        return 1;
      }

      const nameA = a[keyToSortBy].split(" ").at(-1).toLowerCase();
      const nameB = b[keyToSortBy].split(" ").at(-1).toLowerCase();
      // If name empty sort to back
      /*       if (nameA === "") {
        return 1;
      } else if (nameB === "") {
        return -1;
        // Otherwise sort
      } else { */
      if (direction === "descending") {
        return nameA > nameB ? -1 : nameA < nameB ? 1 : 0;
      } else {
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      }

      //}
    });
  } else {
    return list;
  }
}

export function sortByNumber(list, keyToSortBy, direction) {
  if (list.length > 1) {
    return list.sort((a, b) => {
      if (a[keyToSortBy] === null) {
        return -1;
      }

      if (b[keyToSortBy] === null) {
        return 1;
      }

      const numA = a[keyToSortBy];
      const numB = b[keyToSortBy];

      if (direction === "descending") {
        return numA > numB ? -1 : numA < numB ? 1 : 0;
      } else {
        return numA < numB ? -1 : numA > numB ? 1 : 0;
      }
    });
  } else {
    return list;
  }
}

export function sortyByDate(list, keyToSortBy, direction) {
  if (list.length > 1) {
    return list.sort((a, b) => {
      const dateA = new Date(a[keyToSortBy]); //.getTime();
      const dateB = new Date(b[keyToSortBy]); //.getTime();
      if (direction === "descending") {
        return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
      } else {
        return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
      }
    });
  } else {
    return list;
  }
}

export function formatNames(ids, listOfIds, key) {
  const arrayOfNames = ids.map((id) => {
    const foundID = listOfIds.find((listID) => listID[key] === id);
    return `${foundID.first_name} ${foundID.last_name}`;
  });

  if (arrayOfNames.length === 2) {
    arrayOfNames.splice(1, 0, "and");
    return arrayOfNames.join(" ");
  } else if (arrayOfNames.length > 2) {
    arrayOfNames.splice(arrayOfNames.length - 1, 0, "and");
    return arrayOfNames.join(", ").replace("and,", "and");
  }
  return arrayOfNames.join("");
}

export function formatBooleans(val, columnKey) {
  switch (columnKey) {
    case "Status":
      return val ? "Enabled" : "Disabled";
    case "Verified":
    case "Sending To Phone System":
      return val ? "Yes" : "No";
    default:
      break;
  }
}

export function arrayEquals(a, b) {
  if (Array.isArray(a) && Array.isArray(b) && a.length === b.length) {
    const sortedA = a.sort((a, b) => (a > b ? -1 : a < b ? 1 : 0));
    const sortedB = b.sort((a, b) => (a > b ? -1 : a < b ? 1 : 0));

    return sortedA.every((val, index) => val === sortedB[index]);
  } else {
    return false;
  }
}

// Parse phone numbers before submission
export function parsePhoneNumbers(formInput) {
  const formattedFields = {};

  Object.keys(formInput).forEach((field) => {
    if (formInput[field] && (field === "phone_number" || field === "did_number" || field === "personal_phone")) {
      return (formattedFields[field] = `${formInput[field].replace(/[(),\-,\s]/g, "")}`);
    } else {
      return (formattedFields[field] = formInput[field]);
    }
  });

  return formattedFields;
}
