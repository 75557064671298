/*** Filters Soft Deleted Records
 * @typedef {Object} SoftPartionedLists
 * @property {records[]} deletedRecords
 * @property {records[]} existingRecords
 * @param {records[]} recordList
 * @returns {SoftPartionedLists}
 * 
*/
export default function filterSoftRecords(recordList) {
  return recordList.reduce((filteredLists, record) => {
    filteredLists[record.deleted_at ? "deletedRecords" : "existingRecords"].push(record);
    return filteredLists;
  }, { existingRecords: [], deletedRecords: [] });
}