import { formatPhoneNumber } from "../../../../helpers/formattingHelpers";
import SettingsForm from "../subcomponents/SettingsForm";

export function userSettingsLogic({
  currentUser,
  settingHandlers,
  userPermissionLevel,
  billCodeDIDs,
  pushoverValidated
}) {
  const currentUserCheck = (val) => (currentUser ? currentUser[val] : "");

  const settings = {
    changeEmail: {
      header: "Email address",
      subtext: currentUserCheck("email"),
      id: "change-email",
      buttonName: "Change",
      handleFunc: settingHandlers.changeEmail,
      component: (setting) => <SettingsForm.ButtonInput setting={setting} />,
    },
    changePhoneNumber: {
      header: "Phone Number",
      subtext:
        formatPhoneNumber(currentUserCheck("personal_phone")) ||
        "No phone number",
      id: "change-phone_number",
      buttonName: "Change",
      handleFunc: settingHandlers.changePhoneNumber,
      component: (setting) => <SettingsForm.ButtonInput setting={setting} />,
    },
    changePassword: {
      header: "Change Password",
      subtext: `Password must be at least 8 characters long`,
      id: "reset-password",
      buttonName: "Change",
      handleFunc: settingHandlers.changePassword,
      component: (setting) => <SettingsForm.ButtonInput setting={setting} />,
    },
    pushoverUserKey: {
      header: "Pushover User Key",
      id: "pushover-user-key",
      subtext: (
        <SettingsForm.SensitiveInfo
          sensitiveText={currentUserCheck("pushover_user_key")}
        />
      ),

      buttonName: "Change",
      handleFunc: settingHandlers.changePushoverUserKey,
      component: (setting) => <SettingsForm.ButtonInput setting={setting} />,
    },
    pushoverAppKey: {
      header: "Pushover App Key",
      id: "pushover-app-key",
      subtext: (
        <SettingsForm.SensitiveInfo
          sensitiveText={currentUserCheck("pushover_app_key")}
        />
      ),
      buttonName: "Change",
      handleFunc: settingHandlers.changePushoverAppKey,
      component: (setting) => <SettingsForm.ButtonInput setting={setting} />,
    },
    validatePushoverKeys: {
      header: (
        <>
          <span>Validate Pushover Keys</span>
          <br />
          {
            pushoverValidated != null 
            ? pushoverValidated 
              ? <span className="success">Keys Validated! ✅</span> 
              : <span>Keys Invalid! ❌</span>
            : null
          }
        </>
      ),
      id: "pushover-validate",
      buttonName: "Validate",
      handleFunc: settingHandlers.handlePushoverValidation,
      component: setting => <SettingsForm.ButtonInput setting={setting} />,
    },
    availableDIDs: {
      header: "DID Numbers Available:",
      id: "available-dids",
      subtext: (() => {
        let user_dids = currentUserCheck("user_dids") || [];
        let textInfo = null;

        if (userPermissionLevel("mid")) {
          user_dids = billCodeDIDs;
          textInfo =
            "Managers and Admins have access to all DIDs in billing code";
        }

        return (
          <>
            {textInfo}
            {user_dids.map((lookup, compKey) => (
              <div className="my-2 d-flex align-items-center" key={ compKey }>
                <div className="me-3">•</div>
                <div>
                  <div>
                    {
                      formatPhoneNumber(lookup.did_number)
                    }
                  </div>
                  {lookup.did_description}
                </div>
              </div>
            ))}
          </>
        );
      })(),

      buttonName: "",
      handleFunc: () => null,
    },
  };

  return settings;
}

export function notificationSettingsLogic({ currentUser, settingHandlers, pushoverValidated }) {
  const userSettingsCheck = (val) =>
    currentUser ? currentUser.user_settings[val] : "";

  const hasPushoverKeyPair = currentUser?.pushover_api_key && currentUser?.pushover_user_key; 

  const settings = {
    emailNotifications: {
      header: `Email Notifications for messages unread for more than 10 minutes`,
      //subtext:
      id: "email-notifications",
      handleFunc: settingHandlers.emailNotifications,
      checked: userSettingsCheck("email_notifications") ? true : false,
      component: (setting) => <SettingsForm.Switch setting={setting} />,
    },
    firebaseNotifications: {
      header: "Web Push Notifications (Chrome & Firefox Only)",
      id: "firebase-notifications",
      handleFunc: settingHandlers.handleFirebaseToggle,
      checked: userSettingsCheck("firebase_notifications") ? true : false,
      component: (setting) => <SettingsForm.Switch setting={setting} />
    },
    pushoverNotifications: {
      header: (
        <>
          {/* Standardized Label */}
          <span>Pushover Notifications</span> 
          { 
            /*Conditional Warning*/
            hasPushoverKeyPair 
              ? <span className="text-warning">
                  <br />
                  ⚠️ Keys needed before enabling notifications
                </span> 
              : null
          }
        </>
      ),
      id: "pushover-notifications",
      handleFunc: settingHandlers.handlePushoverToggle,
      checked: userSettingsCheck("pushover_notifications") ? true : false,
      component: (setting) => <SettingsForm.Switch setting={setting} disabled={!pushoverValidated} />
    }
  };

  return settings;
}
