import { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";

export default function LoginForm({ authFormToMap, handleFormSubmit }) {
  const [formInput, setFormInput] = useState({});

  ////////////////////////////////////////////////////////////////////////////////
  // Input Component Declarations
  ////////////////////////////////////////////////////////////////////////////////
  function fieldConstructor(field) {
    return (
      <Form.Group
        key={field.keyName}
        className="mb-3"
        controlId={`form${field.formalName}`}
      >
        <Form.Control
          name={field.keyName}
          type={field.inputType}
          placeholder={field.placeholder}
          value={formInput[field.keyName]}
          onChange={(e) =>
            setFormInput({ ...formInput, [field.keyName]: e.target.value })
          }
        />
      </Form.Group>
    );
  }

  function buttonConstructor(button) {
    const buttonHeader = button.header ? (
      <p key={button.header}>{button.header}</p>
    ) : null;

    const newButton = (
      <Button
        key={button.text}
        variant={button.variant}
        className="w-100 my-2"
        type={button.type === "submit" ? "submit" : null}
      >
        {button.text}
      </Button>
    );

    if (button.type === "link") {
      return (
        <>
          {buttonHeader}
          <Link to={button.link} className="w-100 py-2">
            {newButton}
          </Link>
        </>
      );
    } else {
      return (
        <>
          {buttonHeader}
          {newButton}
        </>
      );
    }
  }

  return (
    <>
      <p className=" fs-5">{authFormToMap.text}</p>
      <Form
        className={`pt-3`}
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit(formInput);
        }}
      >
        {Object.keys(authFormToMap.formLogic).map((field) =>
          fieldConstructor(authFormToMap.formLogic[field])
        )}
        {Object.keys(authFormToMap.buttonLogic).map((button) =>
          buttonConstructor(authFormToMap.buttonLogic[button])
        )}
      </Form>
    </>
  );
}
