// Libraries
import { useState, useEffect, useRef, useCallback } from "react";
import ImagePreview from "./ImagePreview";
import MessageInput from "./MessageInput";
// Components
import useWindowDimensions, { BSBreakpoints } from "src/hooks/useWindowDimensions";
// Styles
import styles from "../../Messages.module.scss";
import { useApplicationStates } from "src/contexts";
import TextMessage from "./TextMessage";
import { Loading } from "src/common/commonViews.index";

//TODO Feature- Simulate behavior of discord (click notification) instead of auto jumping
//TODO Feature- Yesterday and today timestamps like google messages
//TODO Upload image needs to only allow images
//TODO Explore if being able to copy and paste from the desktop is worth it - https://www.lucidchart.com/techblog/2014/12/02/definitive-guide-copying-pasting-javascript/
//TODO urls will use websites protocol with current convertToURL implementation, need to verify if is a problem

export default function MessageContainer({
  messages,
  handleSendMessage,
  handleOptInMessage,
  handleRetryFailedMessage,
  selectedRoom,
  uploadedFile,
  setUploadedFile,
  roomLoadedRef,
  optInMode,
  disableOptInSend
}) {
  // States
  ////////////////////////////////////////////////////////////////////////////
  const [imagePreview, setImagePreview] = useState(null);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Hooks
  ////////////////////////////////////////////////////////////////////////////
  const { userData } = useApplicationStates();
  const { currentUser } = userData;
  const { width } = useWindowDimensions();
  //Set dummy div at bottom of chat as ref for scroll down
  const messagesEndRef = useRef(null);

  // UseCallbacks
  ////////////////////////////////////////////////////////////////////////////
  const handlePreview = useCallback(
    (file) => {
      //If file is undefined or falsey
      if (!file) {
        return setUploadedFile(null);
      }
      //If the selected room is a customer room and the file is oversized
      if(
        selectedRoom && 
        selectedRoom.room_customers.length && 
        file.size > 1782579 // About ~1.7MB
      ) { 
        //Cease execution and alert that the file is too large
        return alert("File Size Too Large, please limit your file upload to 1.7MB") 
      }
      
      if (file && selectedRoom) {
        setUploadedFile(file);
        setImagePreview(URL.createObjectURL(file));
        return;
      }
    },
    [selectedRoom, setUploadedFile]
  );

  ////////////////////////////////////////////////////////////////////////////
  // UseEffects
  ////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (!selectedRoom) {
      setIsLoading(false);
    }
    if (isLoading && selectedRoom && messages) {
      setIsLoading(false);
    }
  }, [isLoading, selectedRoom, messages]);

  useEffect(() => {
    if (!uploadedFile) {
      setImagePreview(null);
    }
  }, [uploadedFile]);

  useEffect(() => {
    window.addEventListener("paste", (e) => {
      if (
        e.clipboardData.files[0] &&
        /^image\/./i.test(e.clipboardData.files[0].type)
      ) {
        handlePreview(e.clipboardData.files[0]);
        setIsDraggingOver(false);
      }
    });
  }, [handlePreview]);

  //Scroll to bottom when message received
  // TODO Feature: Simulate behavior of discord (cick notification) instead of auto jumping?
  // TODO https://stackoverflow.com/questions/18614301/keep-overflow-div-scrolled-to-bottom-unless-user-scrolls-up
  // TODO Get rid of flickering, maybe have view start at bottom then switch?
  useEffect(() => {
    if (!isLoading) {
      messages &&
        messagesEndRef.current.scrollIntoView({
          behavior: roomLoadedRef.current ? "smooth" : "auto",
        });

      messages && messages.length > 0
        ? (roomLoadedRef.current = true)
        : (roomLoadedRef.current = false);
    }
  }, [messages, roomLoadedRef, isLoading]);

  function dropHandler(e) {
    e.preventDefault();
    if (
      e.dataTransfer.items[0].kind === "file" &&
      /image\/./.test(e.dataTransfer.items[0].type)
    ) {
      handlePreview(e.dataTransfer.items[0].getAsFile());
    }
    setIsDraggingOver(false);
  }

  function dragOverHandler(e) {
    e.preventDefault();
    setIsDraggingOver(true);
  }

  function mapTextMessages() {
    return messages.map((message) => (
      <TextMessage
        key={message.message_id}
        message={message}
        selectedRoom={selectedRoom}
        currentUser={currentUser}
        handleRetryFailedMessage={handleRetryFailedMessage}
      />
    ));
  }

  function noRoomOrMessages() {
    if(optInMode) {
      return (
        <div className={`h-100 d-flex justify-content-center align-items-center`}>
          { disableOptInSend ? "🤔 Opt In Sent, Waiting On Customer" : "Send an Opt In Message with the Send Message Button 👇" }
        </div>
      );
    }
    
    if (selectedRoom && selectedRoom.last_message_from !== null && !messages) {
      setIsLoading(true);
    }

    return (
      <div className={`h-100 d-flex justify-content-center align-items-center`}>
        {selectedRoom ? "No Messages" : "No room selected"}
      </div>
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Return
  /////////////////////////////////////////////////////////////////////////////////////////////////
  if (isLoading) {
    return <Loading />;
  }

  const messageContainerPadding = width > BSBreakpoints.sm ? "px-5" : "";
  return (
    <div
      className={`
      ${styles.message_main_container}
      ${isDraggingOver ? styles.drop_zone : null}
      ${width < BSBreakpoints.sm ? "px-0" : null}
      w-100 d-flex flex-column position-relative 
      `}
      onDrop={dropHandler}
      onDragEnd={() => {
        setIsDraggingOver(false);
      }}
      onDragOver={dragOverHandler}
      onDragExit={() => setIsDraggingOver(false)}
    >
      <div
        className={`${styles.chat_overflow_container} w-100 d-flex flex-column align-items-center flex-grow-1 overflow-auto position-relative`}
      >
        <div
          className={`${styles.chat_message_container} w-100 mx-auto py-4 ${messageContainerPadding} d-flex flex-column justify-content-end w-100`}
        >
          {messages && messages.length > 0 && !optInMode
            ? mapTextMessages()
            : noRoomOrMessages()}
          {/* Dummy ref for scroll reference */}
          <div ref={messagesEndRef} />
        </div>
        {uploadedFile ? (
          <ImagePreview
            imageSrc={imagePreview}
            handlePreview={handlePreview}
            width={width}
          />
        ) : null}
      </div>
      <MessageInput
        threadId={selectedRoom ? selectedRoom.room_id : null}
        handleSendMessage={handleSendMessage}
        handleOptInMessage={handleOptInMessage}
        handlePreview={handlePreview}
        uploadedFile={uploadedFile}
        setUploadedFile={setUploadedFile}
        optInMode={optInMode}
        disableOptInSend={disableOptInSend}
      />
    </div>
  );
}
