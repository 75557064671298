import { LAYOUTS } from "src/constants/layout";
import { Table } from "src/common";
import { RecordWrapperDesktop, RecordWrapperMobile } from "src/layout";

export default function ViewLayoutSwitch({ layout, selectedRecordProps, tableProps }) {
  // If layout is desktop show the modal when a record is interacted with and use the full table
  if (layout === LAYOUTS.DESKTOP)
    return (
      <>
        {selectedRecordProps.selectedView && <RecordWrapperDesktop {...selectedRecordProps} layout={layout} />}
        <Table {...tableProps} />
      </>
    );

  // Otherwise if a record is selected, use the wrapper else show the mobile table
  return selectedRecordProps.selectedView ? (
    <RecordWrapperMobile {...selectedRecordProps} layout={layout} />
  ) : (
    <Table {...tableProps} />
  );
}
