/////////////////////////////////////////////////////////////////////////////////////////////////
// Edit Record In State
/////////////////////////////////////////////////////////////////////////////////////////////////
export function editRecord<T>(record: T, recordKey: keyof T, prevState: T[], prevPrimaryKey?: keyof T) {
  const newRecordArray = [...prevState];
  const findValue = prevPrimaryKey ? prevPrimaryKey : record[recordKey];
  const index = newRecordArray.findIndex(
    (findRec) => findRec[recordKey] === findValue
  );

  newRecordArray.splice(index, 1, record);
  return newRecordArray;
}

export function editOrAddRecord<T>(record: T, recordKey: keyof T, prevState: T[], prevPrimaryKey?: keyof T) {
  const newRecordArray = [...prevState];
  const findValue = prevPrimaryKey ? prevPrimaryKey : record[recordKey];
  const index = newRecordArray.findIndex(
    (findRec) => findRec[recordKey] === findValue
  );

  if (index === -1) {
    return [...newRecordArray, record];
  }

  newRecordArray.splice(index, 1, record);
  return newRecordArray;
}

/////////////////////////////////////////////////////////////////////////////////////////////////
// Delete Record In State
/////////////////////////////////////////////////////////////////////////////////////////////////
export function deleteRecord(recordId, recordKey, prevState) {
  const newRecordArray = [...prevState];
  const index = newRecordArray.findIndex(
    (record) => record === recordId || record[recordKey] === recordId
  );

  if (index > -1) {
    newRecordArray.splice(index, 1);
    return newRecordArray;
  } else {
    return newRecordArray;
  }
}
