import {
  faSignOutAlt,
  faAddressCard,
  faToolbox,
  faCog,
  faUsersCog,
  faBell,
  faBellSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApplicationStates, useAuth } from "src/contexts";
import { NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// TODO: Fix top level import paths
import { default as pkg } from "../../../package.json";
import VersionButton from "./VersionButton";

export default function NavbarDropdown({ pathname, firebaseUser }) {
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // DATASTORE/HOOKS
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const { sharedAppStates, notifications } = useApplicationStates();
  const { socketStatus } = sharedAppStates;
  const { handleSignOut } = useAuth();
  const navigate = useNavigate();
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // CONSTANTS
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const adminRegex = /^\/*admin/i;
  const managerRegex = /^\/*manager/i;
  const { setNotificationOverride } = notifications;
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // HANDLERS
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function handleSwitchRoleView() {
    switch (true) {
      case firebaseUser.role === "admin" || firebaseUser.role === "dev":
        if (adminRegex.test(pathname)) {
          return navigate("/user/contacts");
        } else {
          return navigate("/admin/stats");
        }
      case firebaseUser.role === "manager":
        if (managerRegex.test(pathname)) {
          return navigate("/user/contacts");
        } else {
          return navigate("/manager/users");
        }
      default:
        null;
    }
  }

  function switchRoleViewContent() {
    if (firebaseUser.role === "user") return;

    const adminView = adminRegex.test(pathname) ? true : false;
    const managerView = managerRegex.test(pathname) ? true : false;

    let icon;
    let text;

    switch (true) {
      case (firebaseUser.role === "admin" || firebaseUser.role === "dev") && !adminView:
        icon = faToolbox;
        text = "Admin";
        break;
      case firebaseUser.role === "manager" && !managerView:
        icon = faUsersCog;
        text = "Manager";
        break;
      default:
        icon = faAddressCard;
        text = "User";
    }

    return (
      <>
        <FontAwesomeIcon icon={icon} />
        {text} Panel
      </>
    );
  }

  function roleTitle(firebaseRole) {
    switch(firebaseRole) {
      case("admin"):
        return "Admin"
      case("manager"):
        return "manager";
      default:
        return "user"
    }
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // RENDER
  /////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="d-flex align-items-center">
      <NavDropdown
        align={{ lg: "end" }}
        className="fw-bold me-3"
        title={`${firebaseUser?.displayName} (${roleTitle(firebaseUser?.role)})`}
        id="basic-nav-dropdown"
        data-testid="nav_dropdown"
      >
        {/* Switch between user and manager/admin if not a user role */}
        {firebaseUser.role !== "user" && (
          <NavDropdown.Item onClick={handleSwitchRoleView}>{switchRoleViewContent()}</NavDropdown.Item>
        )}
        {/* *** Settings *** */}
        <div onClick={() => navigate("user/settings")}>
          <NavDropdown.Item>
            <FontAwesomeIcon icon={faCog} />
            Settings
          </NavDropdown.Item>
        </div>
        {/* *** Logout *** */}
        <div onClick={handleSignOut}>
          <NavDropdown.Item>
            <FontAwesomeIcon icon={faSignOutAlt} />
            Logout
          </NavDropdown.Item>
        </div>
      </NavDropdown>
      <div className="d-flex ms-auto">
        <div className="d-flex flex-column justify-content-center ms-auto">
          <FontAwesomeIcon
            icon={notifications.notificationsEnabled ? faBell : faBellSlash}
            color={notifications.notificationsEnabled ? "#7cc440" : "red"}
            title={notifications.notificationsEnabled ? "Notifications Allowed" : "Notifications Blocked"}
            onClick={notifications.notificationsEnabled ? null : () => setNotificationOverride(false)}
          />
        </div>
        <VersionButton socketStatus={socketStatus} />
      </div>
    </div>
  );
}
