import ReactMarkdown from "react-markdown";
import { Spinner } from "react-bootstrap";
import { LatestVersionResponse } from "src/hooks/queries/useVersionCheckQuery";

type ReleaseNotesProps = {
  notesList: LatestVersionResponse;
  currentVersion: string,
  latestVersion: string,
  isLoading: boolean
};

export default function ReleaseNotes({ isLoading, notesList, currentVersion, latestVersion }: ReleaseNotesProps) {
  // Null Guard
  if (!notesList) return null;
  // Destructure data
  const { releaseNotes } = notesList;
  // Ensure the array is defined and has indexes
  const hasNotes = releaseNotes && releaseNotes.length;
  // QOL Conditional Assignment
  const outOfDate = currentVersion < latestVersion;
  // Render
  return isLoading || outOfDate
    ? <Spinner />
    : (
      <>
        <section className="container border-t-alphaGrey">
          <h3 className="font-bold font-">Release Notes</h3>
          <h4>Version: {latestVersion}</h4>
          { outOfDate && <h4>{ `Current Version: ${currentVersion}` }</h4> }
          {
            hasNotes 
              ? <ul className="grid justify-items-start">
                  {releaseNotes.map((note, index) => (
                    <li key={index}>
                      <ReactMarkdown>{note}</ReactMarkdown>
                    </li>
                  ))}
                </ul>
              : <p>No release notes available</p>
          }
        </section>
      </>
    );
}