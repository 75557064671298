// Libraries
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { faDoorOpen, faExternalLinkAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
//Contexts
import { useApplicationStates, useAuth } from "src/contexts";
// Components
import { ResponsiveContainer, DataViewSelect, Buttons } from "src/common/commonViews.index";

import { deleteRecord } from "src/helpers/helpers";
// Styles
import styles from "./Rooms.module.scss";
import roomsColumnMap from "./logic/roomsColumnMap";
import { usePagination, useSendMessage, BSBreakpoints, useSearchBar, useUpdateRoom } from "src/hooks/hooks.index";
import { roomsFormLogic } from "./Rooms.index";
import RoomsMobileViews from "./subcomponents/RoomsMobileView";
import { Form } from "react-bootstrap";
import useCheckIfRoomArchived from "src/hooks/useCheckIfRoomArchived";

export default function Rooms() {
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Contexts & Refs
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const { userData, sharedAppStates, adminAppStates, socket } = useApplicationStates();
  const { currentUser, handleChangeSetting } = userData;
  const { setFullRoomsList, billCodeDIDs, setFullCustomersList } = sharedAppStates;
  const { selectedAdminBillCode } = adminAppStates;
  const { updateCustomerRoomDID } = useUpdateRoom();
  const { userPermissionLevel } = useAuth();
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Rooms States
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Room record display states
  const [roomListType, setRoomListType] = useState("customers");
  const [roomsToDisplay, setRoomsToDisplay] = useState([]);
  const [showDisabledRooms, setShowDisabledRooms] = useState(false);

  // Room record Manipulation States
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedView, setSelectedView] = useState(null);
  const [selectedPageNum, setSelectedPageNum] = useState(1);

  const [archivedRoomViewActive, setArchivedRoomViewActive] = useState(false);

  // Name of primary key identifying unique record
  const primaryKey = "room_id";
  const renderMap = roomsColumnMap(roomListType);
  const { searchBar, filteredResults } = useSearchBar({
    list: "roomsList",
    renderMap,
  });

  const navigate = useNavigate();
  const { sendMessage } = useSendMessage();
  const { checkIfRoomIsArchived } = useCheckIfRoomArchived();

  // Use pagination hook to determine indexes of contactsRecords to display and pass prop to DataTable
  const { displayIndexes, numOfPages, paginate, resultsSelector } = usePagination(
    roomsToDisplay.length,
    selectedPageNum,
    setSelectedPageNum
  );

  useEffect(() => setSelectedPageNum(1), [roomListType]);

  useEffect(() => {
    const checkByViewType = (val) => (roomListType === "users" ? !val : val > 0);

    const archiveRoomCheck = (room) =>
      archivedRoomViewActive ? checkIfRoomIsArchived(room) : !checkIfRoomIsArchived(room);

    const disabledRoomCheck = (room) => (showDisabledRooms ? !room.room_enabled : room.room_enabled);

    //TODO might be able to condense this more, also it the behavior as expected?
    return setRoomsToDisplay(
      filteredResults.filter((room) => {
        if (archivedRoomViewActive) {
          return checkByViewType(room.room_customers.length) && archiveRoomCheck(room);
        }

        return checkByViewType(room.room_customers.length) && disabledRoomCheck(room);
      })
    );
  }, [
    currentUser,
    socket,
    checkIfRoomIsArchived,
    archivedRoomViewActive,
    showDisabledRooms,
    selectedAdminBillCode,
    roomListType,
    filteredResults,
  ]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Record handling methods
  /////////////////////////////////////////////////////////////////////////////////////////////////

  //!!TODO ABSTRACT?
  function handleSelectRecord(record) {
    if (showDisabledRooms) {
      if (selectedRoom && selectedRoom === record) {
        return setSelectedRoom(null);
        /*     } else {
        return setSelectedRoom({ room_id: recordID });*/
      }
    }

    if (selectedRoom && selectedRoom === record) {
      return setSelectedRoom(null);
    }

    //const foundRoom = roomsList.find((room) => room[primaryKey] === recordID);
    setSelectedRoom(record);
  }

  // Deselect a record
  function handleDeselectRecord() {
    return setSelectedRoom(null);
  }

  function handleSendMessage({ message_text }) {
    if (selectedRoom) {
      sendMessage({ message_text, room: selectedRoom });
    } else {
      return console.error("🛑  ERROR: NO ROOM SELECTED!");
    }
  }

  function handleArchiveRoom() {
    const archivedRooms = currentUser.user_settings.archived_rooms;
    let setting = [
      {
        room_id: selectedRoom[primaryKey],
        last_updated: selectedRoom.room_updated_at,
      },
    ];

    if (archivedRooms) {
      setting = [...archivedRooms, ...setting];
    }

    return handleChangeSetting({ archived_rooms: setting });
  }

  function handleDeleteRoom() {
    // Request to disable room and update db states with response (the room_id and customer_id)
    socket.emit("requestDisableRoom", selectedRoom[primaryKey], (res) => {
      setFullRoomsList((prev) => deleteRecord(res[primaryKey], primaryKey, prev));
      setFullCustomersList((prev) => {
        const foundCustomer = prev.find((customer) => customer.customer_id === res.customer_id);
        foundCustomer.room_enabled = 0;
        return [...prev];
      });
    });
  }

  function handleDisableRoom() {
    socket.emit("requestDisableRoom", selectedRoom[primaryKey]);
  }
  function handleReopenRoom() {
    socket.emit("requestReopenRoom", selectedRoom[primaryKey], (res) => {
      setRoomsToDisplay((prev) => deleteRecord(res[primaryKey], primaryKey, prev));
      setFullRoomsList((prev) => [...prev, res]);
    });
  }

  function handleSelectDID(formInput) {
    const selectedDID = formInput.selected_did;
    const roomID = selectedRoom.room_id;

    updateCustomerRoomDID({ selectedDID, roomID }, (updatedRoom) => {
      return navigate("/users/messages", {
        state: { room_id: updatedRoom.room_id },
      });
    });
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Modal methods and props
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function handleResetView() {
    setSelectedView(null);
    setSelectedRoom(null);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Table Header props
  /////////////////////////////////////////////////////////////////////////////////////////////////

  function tableHeader() {
    function firstRowChildren(width) {
      if (width > BSBreakpoints.lg) {
        return (
          <div className="w-100 mb-2 d-flex justify-content-between justify-content-lg-end">
            {searchBar()}
            {resultsSelector(`mx-2 ms-4  d-flex align-items-center flex-nowrap text-nowrap`)}
          </div>
        );
      } else {
        return searchBar();
      }
    }

    function breakpointSelect(width) {
      return (
        <div>
          <div key={"buttons"} className="d-flex flex-grow-1 flex-md-grow-0 justify-content-between">
            <Buttons.RoomsSwitchView {...roomListTypeSwitchProps} />
            {currentUser && (currentUser.user_role === "admin" || currentUser.user_role === "manager") ? (
              <Form className={`d-flex align-items-center`}>
                <Form.Group className="ms-3" controlId="viewDisabledRooms">
                  <Form.Check
                    type="checkbox"
                    label="View Disabled Rooms"
                    onChange={() => {
                      setArchivedRoomViewActive(false);
                      setShowDisabledRooms((prev) => !prev);
                    }}
                    checked={showDisabledRooms}
                  />
                </Form.Group>
              </Form>
            ) : null}
            {width < BSBreakpoints.lg && (
              <div className="d-flex">
                <div className="d-flex align-items-center me-4">
                  <p>Archived Rooms</p>
                  <Form.Check
                    checked={archivedRoomViewActive}
                    onChange={() => {
                      setShowDisabledRooms(false);
                      setArchivedRoomViewActive((prev) => !prev);
                    }}
                    className="mx-2"
                  />
                </div>

                {resultsSelector("ms-auto mx-2 d-flex align-items-center flex-nowrap text-nowrap")}
              </div>
            )}
          </div>
          {numOfPages > 1 && width >= BSBreakpoints.md ? paginate() : null}
        </div>
      );
    }

    return {
      title: "Rooms",
      firstRowChildren,
      children: breakpointSelect,
    };
  }

  const roomListTypeSwitchProps = {
    roomListType,
    setRoomListType,
    setShowDisabledRooms,
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Table props
  /////////////////////////////////////////////////////////////////////////////////////////////////

  function buttonConstructor(room) {
    const buttons = [
      {
        label: "Go To Room",
        className: `${styles.action_btn}`,
        onClick: () => {
          if (room.room_customers.length && room.room_did_number === null) {
            return setSelectedView("select-did");
          }
          navigate(`/user/messages?room_id=${room.room_id}`);
        },

        faIcon: faExternalLinkAlt,
      },
      /*       {
        label: "Archive",
        className: `${styles.action_btn}`,
        onClick: () => handleArchiveRoom(),
        faIcon: faArchive,
      }, */
    ];

    if (
      (currentUser.user_role === "admin" || currentUser.user_role === "manager") &&
      //TODO let user rooms be disabled
      roomListType !== "users"
    ) {
      const roomStatusBtn = {
        label: room.room_enabled ? "Disable" : "Reopen",
        className: `${styles.action_btn}`,
        onClick: () => (room.room_enabled ? setSelectedView("disable-room") : setSelectedView("reopen-room")),
        faIcon: room.room_enabled ? faTrashAlt : faDoorOpen,
      };

      if (showDisabledRooms) {
        return [roomStatusBtn];
      } else {
        buttons.push(roomStatusBtn);
      }
    }

    return buttons;
  }
  const logicMapProps = {
    billCodeDIDs,
    currentUser,
    userPermissionLevel,
  };
  const columnSizeMap =
    roomListType === "customers"
      ? "minmax(100px, auto) minmax(100px, auto) minmax(100px, auto) minmax(100px, auto) minmax(100px, auto) minmax(100px, auto)"
      : "minmax(100px, 400px) minmax(100px, 400px) minmax(100px, auto) minmax(100px, auto) minmax(100px, auto)";
  const dataViewSelectProps = {
    //Data to render
    data: roomsToDisplay,
    // Map logic on how to render data
    renderMap,
    // The primary key to use when selecting roomRecords
    primaryKey,
    columnSizeMap,
    // Indexes to slice when using table layout
    displayIndexes,
    // Button logic to use when using table layout
    buttonConstructor,
    // Logic map for forms and modal
    logicMap: roomsFormLogic(logicMapProps),
    //Header props

    // General handle functions and props
    selectedRecord: selectedRoom,
    selectedView,
    setSelectedView,
    selectedRoom,
    setSelectedRoom,
    handleResetView,
    handleSelectRecord,
    handleDeselectRecord,
    searchBar,
    roomListType,
    setRoomListType,
    //Handle functions for selected view
    handleFunctions: {
      "send-message": handleSendMessage,
      "archive-room": handleArchiveRoom,
      "delete-room": handleDeleteRoom,
      "disable-room": handleDisableRoom,
      "reopen-room": handleReopenRoom,
      "select-did": handleSelectDID,
    },
    // The component to use when the viewport is smaller than 450px;
    mobileView: (props) => <RoomsMobileViews {...props} />,
    tableHeader,
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Return
  /////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <ResponsiveContainer>
      <DataViewSelect {...dataViewSelectProps} />
    </ResponsiveContainer>
  );
}
