import { useEffect } from "react";
import { useApplicationStates } from "src/contexts";
import { Button } from "react-bootstrap";
import styles from "../RoomManagement.module.scss";
import { useNavigate } from "react-router-dom";

export default function LeaveRoomConfirmation({
  selectedRoom,
  roomFormData,
  setFormStage,
  setTitle,
  setSelectedView,
  setSelectedRoom,
}) {
  useEffect(() => {
    let title = "Confirm action: Leave Room";

    if (roomFormData.submitType === "CREATE_NEW_ROOM") title = "Confirm New Room Details:";
    if (roomFormData.submitType === "ADD_USERS") title = "Confirm Updated Room Details:";
    setTitle(title);
  }, [setTitle, roomFormData.submitType]);

  const navigate = useNavigate();
  const { socket, userData } = useApplicationStates();

  function handleRemoveUser() {
    socket.emit("requestRemoveUsersFromRoom", selectedRoom.room_id, [userData.currentUser.user_id]);
    navigate(`/user/messages`); // Prevent old room from being grabbed by url
    setSelectedRoom(null);
    setSelectedView(null);
  }

  const userOnlyRoom = selectedRoom.room_customers.length === 0;

  const singleUserRoom = selectedRoom.room_users.length === 1 || (selectedRoom.room_users.length === 2 && userOnlyRoom);
  const leaveText = `Customer rooms cannot be left unattended- please add or assign another user before leaving this room.`;

  return (
    <div className="d-flex flex-column flex-grow-1 flex-shrink-1">
      <p className="mb-2"> {singleUserRoom ? leaveText : "Are you sure you want to leave this room?"}</p>
      <div className={`${styles.button_wrapper}`}>
        <Button className="me-2" onClick={() => setFormStage(null)}>
          Cancel
        </Button>
        <Button className="ms-2" onClick={handleRemoveUser} disabled={singleUserRoom}>
          Yes
        </Button>
      </div>
    </div>
  );
}
