import { createContext, useContext } from "react";
import { useServiceWorker } from "src/hooks/useServiceWorker";

export type ServiceWorkerHook = ReturnType<typeof useServiceWorker>

export const ServiceWorkerContext = createContext<ServiceWorkerHook>({} as ServiceWorkerHook);

export function ServiceWorkerProvider({ children }) {
  const serviceWorker = useServiceWorker();
  return (
    <ServiceWorkerContext.Provider value={serviceWorker}>
      { children }
    </ServiceWorkerContext.Provider>
  );
}

export function useServiceWorkerContext() {
  return useContext(ServiceWorkerContext);
}