import { FormConstructor } from "src/common/commonViews.index";
import MobileViewSelect from "src/common/Mobile/MobileViewSelect";

import styles from "../Contacts.module.scss";

export default function OccupiedRoom({
  logicMap,
  handleFunctions,
  selectedView,
  setSelectedView,
  foundRooms,
  selectedRecord,
  handleResetView,
  recordTitle,
  tempBypass,
  occupyRoom
}) {
  return (
    <MobileViewSelect.SelectedRecordView
      handleResetView={handleResetView}
      recordTitle={recordTitle}
      selectedRecord={selectedRecord}
    >
      <div className={`${styles.occupied_room_mobile_view_wrapper} pt-3 px-3 `}>
        <FormConstructor
          initialFormValues={logicMap[selectedView].initialFormValues}
          onSubmit={(formInput) => handleFunctions[selectedView](formInput)}
        >
          <FormConstructor.OccupiedRoomDIDSelect
            view="mobile"
            foundRooms={foundRooms}
            setSelectedView={setSelectedView}
            selectedContact={selectedRecord}
            tempBypass={tempBypass}
            occupyRoom={occupyRoom}
          />
        </FormConstructor>
      </div>
    </MobileViewSelect.SelectedRecordView>
  );
}
