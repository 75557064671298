import { sortAlphabetically, sortByNumber } from "../../../helpers/helpers";

const adminStatsColumnMap = new Map([
  [
    "Billing Code",
    {
      columnKey: "billing_code",
      sort: true,
      sortFunc: sortAlphabetically,
    },
  ],
  ["Customers", { columnKey: "customers_in_code", sort: true, sortFunc: sortByNumber }],
  ["SMS Sent", { columnKey: "total_sent_sms", sort: true, sortFunc: sortByNumber }],
  ["MMS Sent", { columnKey: "total_sent_mms", sort: true, sortFunc: sortByNumber }],
  [
    "SMS Received",
    {
      columnKey: "total_received_sms",
      sort: true,
      sortFunc: sortByNumber,
    },
  ],
  [
    "MMS Received",
    {
      columnKey: "total_received_mms",
      sort: true,
      sortFunc: sortByNumber,
    },
  ],
]);

export default adminStatsColumnMap;
