export function sortRoomNames(roomsList, keyToSortBy, direction) {
  if (roomsList.length > 1) {
    return roomsList.sort((roomA, roomB) => {
      const sortedRoomMembersA =
        roomA[keyToSortBy].length > 1
          ? roomA[keyToSortBy].sort((memberA, memberB) => {
              const nameA = memberA?.last_name.toLowerCase();
              const nameB = memberB?.last_name.toLowerCase();

              return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
            })
          : roomA[keyToSortBy][0]?.last_name.toLowerCase();

      const sortedRoomMembersB =
        roomB[keyToSortBy].length > 1
          ? roomB[keyToSortBy].sort((memberA, memberB) => {
              const nameA = memberA?.last_name.toLowerCase();
              const nameB = memberB?.last_name.toLowerCase();

              return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
            })
          : roomB[keyToSortBy][0]?.last_name.toLowerCase();

      if (direction === "descending") {
        return sortedRoomMembersA > sortedRoomMembersB
          ? -1
          : sortedRoomMembersA < sortedRoomMembersB
          ? 1
          : 0;
      } else {
        return sortedRoomMembersA < sortedRoomMembersB
          ? -1
          : sortedRoomMembersA > sortedRoomMembersB
          ? 1
          : 0;
      }
    });
  }

  return roomsList;
}
