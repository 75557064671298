import { FormConstructor } from "src/common/commonViews.index";
import MobileViewSelect from "src/common/Mobile/MobileViewSelect";

export default function SelectRoom({
  logicMap,
  handleFunctions,
  selectedView,
  setSelectedView,
  foundRooms,
  handleResetView,
  recordTitle,
  selectedRecord,
  setSelectedRoom
}) {
  
  return (
    <MobileViewSelect.SelectedRecordView
      handleResetView={handleResetView}
      recordTitle={recordTitle}
      selectedRecord={selectedRecord}
    >
      <FormConstructor
        initialFormValues={logicMap[selectedView].initialFormValues}
      >
        <FormConstructor.RoomSelect
          view="mobile"
          foundRooms={foundRooms}
          changeRoom={(room) => {
            if(room.room_did_number === null) {
              setSelectedRoom(room);
              return setSelectedView("select-did");
            }
            setSelectedRoom(room);
            handleFunctions[selectedView](room)
            setSelectedView(null);
          }}
        />
      </FormConstructor>
    </MobileViewSelect.SelectedRecordView>
  );
}
