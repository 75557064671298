import { firebaseAuth } from "./index";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  verifyPasswordResetCode,
  confirmPasswordReset,
  signOut,
} from "firebase/auth";
import { getCurrentUser } from "./helpers.firebase";

export async function handleCheckPassword(password) {
  const user = getCurrentUser();
  const cred = EmailAuthProvider.credential(user.email, password);

  return await reauthenticateWithCredential(user, cred);
}

export async function handleUpdatePassword(password, firebaseUser) {
  // Update the users password with firebase
  const currentUser = getCurrentUser();
  await updatePassword(currentUser, password);

  // Then post verified password update to DB
  if (firebaseUser.tempPassword) {
    await fetch(`${import.meta.env.VITE_BACKEND_URL}/${import.meta.env.VITE_BACKEND_AUTH_PATH}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Auth-Type": "verify-password",
      },
      body: JSON.stringify({ uid: firebaseUser.uid }),
    });

    // Logout the user
    signOut(firebaseAuth);
  }
}

export async function handleForgotFirebasePassword(email) {
  //Use backend adminFunction instead
  const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}/${import.meta.env.VITE_BACKEND_AUTH_PATH}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Auth-Type": "reset-password",
    },
    body: JSON.stringify({ email }),
  });

  return response;
  //await firebase.auth().sendPasswordResetEmail(email);
}

export async function handleResetPasswordByEmail(actionCode, newPassword) {
  // Verify the password reset code is valid.
  await verifyPasswordResetCode(firebaseAuth, actionCode);
  // Confirm new password with firebase
  await confirmPasswordReset(firebaseAuth, actionCode, newPassword);
}
