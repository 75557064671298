import { FormConstructor } from "src/common/commonViews.index";

function settingsFormLogic({ currentUser }) {
  return {
    "setting-change-password": {
      initialFormValues: {
        old_password: "",
        new_password: "",
        verify_password: "",
      },
      modalTitle: "Update your Password",
      /* text: `Please enter your previous password to  ${
        currentUser ? currentUser.email : "your email address"
      } ?` */
      /* onSubmitOverride: (formInput, e) => handleChangePassword(formInput, e), */
      fields: {
        old_password: {
          formalName: "Old Password",
          keyName: "old_password",
          fieldComponent: (props) => (
            <FormConstructor.PasswordField {...props} />
          ),
          isInvalid: (formInput) => {
            if (!formInput.old_password.length) {
              return "Enter your old password";
            }
          },
        },
        new_password: {
          formalName: "New Password",
          keyName: "new_password",
          minLength: 8,
          fieldComponent: (props) => (
            <FormConstructor.PasswordField {...props} />
          ),
          isInvalid: (formInput) => {
            /* return true; */
            if (!formInput.new_password.length) {
              return "Enter a new password";
            }

            if (formInput.new_password.length < 8) {
              return "Password must be at least 8 characters long";
            }

            if (formInput.new_password !== formInput.verify_password) {
              return "Password must match";
            }

            return "";
          },
        },
        verify_password: {
          formalName: "Verify Password",
          keyName: "verify_password",
          minLength: 8,
          pattern: ({ new_password }) => new_password,
          fieldComponent: (props) => (
            <FormConstructor.PasswordField {...props} />
          ),
          isInvalid: (formInput) => {
            /* return true; */
            if (!formInput.verify_password.length) {
              return "Verify your new password";
            }

            if (formInput.new_password !== formInput.verify_password) {
              return "Password must match";
            }

            return "";
          },
        },
      },
    },
    "setting-change-email": {
      modalTitle: "Request email change",
      text: `Current Email Address: ${currentUser && currentUser.email}`,
      type: "form",
      initialFormValues: {
        new_email: "",
      },
      fields: {
        email: {
          formalName: "New Email",
          keyName: "new_email",
          placeHolder: "email@email.com",
          fieldComponent: (props) => <FormConstructor.TextInput {...props} />,
        },
      },
    },
    "setting-change-phone-number": {
      modalTitle: "Change Personal Phone Number",
      type: "form",
      initialFormValues: {
        personal_phone: "",
      },
      fields: {
        personal_phone: {
          inputType: "tel",
          formalName: "Personal Phone",
          keyName: "personal_phone",
          placeHolder: "(123) 456-7890",
          fieldComponent: (props) => <FormConstructor.PhoneInput {...props} />,
        },
      },
    },
    "setting-change-pushover-key-user": {
      initialFormValues: {
        pushover_user_key: "",
      },
      modalTitle: "Change Pushover User Key",
      type: "form",
      fields: {
        pushover_user_key: {
          inputType: "text",
          formalName: "Pushover User Key",
          keyName: "pushover_user_key",
          placeHolder: "Enter user key here",
          fieldComponent: (props) => <FormConstructor.TextInput {...props} />,
        },
      },
    },
    "setting-change-pushover-key-app": {
      initialFormValues: {
        pushover_app_key: "",
      },
      modalTitle: "Change Pushover App Key",
      type: "form",
      fields: {
        pushover_app_key: {
          inputType: "text",
          formalName: "Pushover App Key",
          keyName: "pushover_app_key",
          placeHolder: "Enter app key here",
          fieldComponent: (props) => <FormConstructor.TextInput {...props} />,
        },
      },
    },
  };
}

export default settingsFormLogic;
