import { useEffect, useState } from "react";
import { editRecord, deleteRecord } from "src/helpers/helpers";

export default function useManagerSocketEvents({
  socket,
  userData,
  sharedAppStates,
  supplyBillCode
}) {
  const [userIsManager, setUserIsManager] = useState<boolean | null>(null);
  const { currentUser } = userData;
  const { setFullRoomsList, setFullUsersInCode, setBillCodeDIDs, billCodeDIDs } =
    sharedAppStates;

  // Set current user role on retrieval of user data (if anything other than admin or dev then no useEffects in this file will fire)
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (currentUser && currentUser.user_role === "manager") {
      setUserIsManager(true);
    }
  }, [currentUser]);

  // Use this user-specific socket for retrieving rooms depending on user role
  useEffect(() => {
    if (userIsManager) {
      const billing_code = supplyBillCode();
      if(!billing_code) return console.warn('No billing code to tie to request!');
      socket.emit("requestAllRoomsInCode", billing_code, (rooms) => setFullRoomsList(rooms));
    }
  }, [userIsManager, socket, setFullRoomsList, supplyBillCode]);

  // If user is a manager, get all the DIDS for their billing code
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const billing_code = supplyBillCode();
    if (userIsManager) {
      if(!billing_code) return console.error(`Unable to tie billcode to request!`);
      socket.emit("requestBillCodeDIDs", billing_code, (dids) => setBillCodeDIDs(dids));
    }
  }, [userIsManager, socket, setBillCodeDIDs, supplyBillCode]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // DID useEffects
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // Respond to new DID numbers added by Admins
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function managerNewDID(newDID) {
      if (newDID.did_billing_code === currentUser.billing_code) {
        setBillCodeDIDs((prev) => [...prev, newDID]);
      }
    }

    if (userIsManager) {
      socket.on("responseNewDID", managerNewDID);
    }

    return () => socket.off("responseNewDID", managerNewDID);
  }, [socket, userIsManager, currentUser, billCodeDIDs, setBillCodeDIDs]);

  // Respond to DID numbers edited by Admins
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function managerEditDID(editedDID) {
      // If DID exists in the managers dids list
      if (billCodeDIDs.some((did) => did.did_number === editedDID.did_number)) {
        // And bill code did not change, update DID
        if (editedDID.did_billing_code === currentUser.billing_code) {
          setBillCodeDIDs((prev) => editRecord(editedDID, "did_number", prev));
        } else {
          // Otherwise remove it from array
          setBillCodeDIDs((prev) =>
            deleteRecord(editedDID.did_number, "did_number", prev)
          );
        }
      } else {
        // Bill code changed on existing DID so add it to array
        setBillCodeDIDs((prev) => [...prev, editedDID]);
      }
    }

    if (userIsManager) {
      socket.on("responseEditedDID", managerEditDID);
    }

    return () => socket.off("responseEditedDID", managerEditDID);
  }, [socket, userIsManager, currentUser, billCodeDIDs, setBillCodeDIDs]);

  // Change DID phone status
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function managerDIDPhoneStatus(did) {
      setBillCodeDIDs((prev) => editRecord(did, "did_number", prev));
    }

    if (userIsManager) {
      socket.on("responseAdminDIDPhoneStatus", managerDIDPhoneStatus);
    }

    return () =>
      socket.off("responseAdminDIDPhoneStatus", managerDIDPhoneStatus);
  }, [socket, userIsManager, setBillCodeDIDs]);

  // Delete a DID number
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function managerDeleteDID(deletedDID) {
      setBillCodeDIDs((prev) => deleteRecord(deletedDID, "did_number", prev));
    }

    if (userIsManager) {
      socket.on("responseDeleteDID", managerDeleteDID);
    }

    return () => socket.off("responseDeleteDID", managerDeleteDID);
  }, [socket, userIsManager, setBillCodeDIDs]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Billing Code useEffects
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // Respond to DID numbers added or removed from a billing code
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (userIsManager) {
      socket.on("responseAdminEditCode", (updatedBillCode) => {
        //TODO logic for changing bill code name on current user if changed
        setBillCodeDIDs(updatedBillCode.editedCode.did_numbers);
      });
    }

    return () => socket.off("responseAdminEditCode");
  }, [socket, userIsManager, setBillCodeDIDs]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // User useEffects
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // Update state when a new user is added
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function managerNewUser(newUser) {
      setFullUsersInCode((prev) => [...prev, newUser]);
    }
    // If current user is a user only,
    if (userIsManager) {
      socket.on("responseNewUser", managerNewUser);
    }

    return () => socket.off("responseNewUser", managerNewUser);
  }, [userIsManager, socket, setFullUsersInCode]);

  // Update state when a user is edited
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function managerEditUser(editedUser) {
      setFullUsersInCode((prev) => [...prev, editedUser]);
    }

    if (userIsManager) {
      socket.on("responseEditUser", managerEditUser);
    }

    return () => socket.off("responseEditUser", managerEditUser);
  }, [userIsManager, socket, setFullUsersInCode]);
  
  
  // Update state when a user verifies their email
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function handleUpdateEmailVerified(uid) {
      setFullUsersInCode((prev) => {
        const foundUser = prev.find(({ user_id }) => user_id === uid);
        if (!foundUser) return prev;

        foundUser.email_verified = true;

        return editRecord(foundUser, "user_id", prev);
      });
    }
    // If current user is a user only,
    if (userIsManager) {
      socket.on("responseEmailVerified", handleUpdateEmailVerified);
    }

    return () => socket.off("responseEmailVerified", handleUpdateEmailVerified);
  }, [userIsManager, socket, setFullUsersInCode]);

  // Update state when a user verifies their password
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function handleUpdatePasswordVerified(uid) {
      setFullUsersInCode((prev) => {
        const foundUser = prev.find(({ user_id }) => user_id === uid);
        if (!foundUser) return prev;

        foundUser.password_verified = true;

        return editRecord(foundUser, "user_id", prev);
      });
    }
    // If current user is a user only,
    if (userIsManager) {
      socket.on("responsePasswordVerified", handleUpdatePasswordVerified);
    }

    return () => socket.off("responsePasswordVerified", handleUpdatePasswordVerified);
  }, [userIsManager, socket, setFullUsersInCode]);
}
