import { flexRender } from "@tanstack/react-table";
import { Button, Table as RbTable } from "react-bootstrap";
import TableSearchBar from "./TableSearchBar";
import TablePaginateGroup from "./TablePaginationGroup";
import styles from "./Table.module.scss";

export default function TableDesktopLayout({
  table,
  tableTitle,
  setGlobalFilter,
  paginateOptions,
  onRowClick,
  createButton,
}) {
  const createRecordBtn = (props) => (
    <Button className="*mx-5 shadow-sm" onClick={() => props.handler()}>
      {props.buttonTitle}
    </Button>
  );

  return (
    <div className={`${styles.main_wrapper} m-4 mx-auto p-4 d-flex flex-column flex-shrink-1 flex-basis-0 shadow `}>
      {/* *** Table Header *** */}
      <div className={`${styles.table_page_header} d-flex mb-4`}>
        <h1 className="*mx-5">{tableTitle}</h1>
        <TableSearchBar setGlobalFilter={setGlobalFilter} className="mx-4" />
        {createButton && createRecordBtn(createButton)}
      </div>

      {/* *** Table *** */}
      <div className={`${styles.table_wrapper} overflow-auto`}>
        <RbTable
          hover
          striped
          //bordered
          className={`${styles.table} *table *table-striped *table-hover *flex-grow-1 user-select-text`}
          data-testid="data_table"
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} className="px-5">
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}

                    {/* TODO: Implement sorting */}
                    {/* {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: " 🔼",
                          desc: " 🔽",
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    )} */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody data-testid="data_table_body">
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                /* scope="row" */
                //className="my-4"
                onClick={() => onRowClick(row.original)}
              >
                {row.getVisibleCells().map((cell) => (
                  // TOOD: padding should probably be a const
                  <td key={cell.id} className="px-xl-5 py-3">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </RbTable>
      </div>

      {/* *** Table Pagination *** */}
      <div className="">
        <TablePaginateGroup paginateOptions={paginateOptions} table={table} />
      </div>
    </div>
  );
}
