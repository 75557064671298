import styles from "../Contacts.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { FormConstructor } from "src/common/commonViews.index";
import MobileViewSelect from "src/common/Mobile/MobileViewSelect";
import { useNavigate } from "react-router-dom";

export default function DIDselect({
  handleFunctions,
  selectedView,
  logicMap,
  selectedRecord,
  selectedRoom,
  handleResetView,
  recordTitle,
}) {
  const navigate = useNavigate();

  return (
    <MobileViewSelect.SelectedRecordView
      newContact={!selectedRecord}
      handleResetView={handleResetView}
      recordTitle={recordTitle}
      selectedRecord={selectedRecord}
    >
      <div
        className={`${styles.createRoomView_container} px-3 overflow-auto d-flex flex-column flex-grow-1`}
      >
        <p>Please select a DID Number to start a conversation:</p>
        <hr className="w-100 my-2 flex-shrink-0" />
        <FormConstructor
          className="pt-2"
          onSubmit={(formInput) => {
            handleFunctions[selectedView](formInput, "mobile");
            navigate({
              to: "/user/messages",
              options: { state: { room_id: selectedRoom.room_id } },
            });
          }}
          initialFormValues={logicMap[selectedView].initialFormValues}
        >
          <FormConstructor.RoomDIDSelect view="mobile" />
          <Button
            className={`position-absolute bottom-0 end-0 mb-4 me-4`}
            type="submit"
          >
            <FontAwesomeIcon icon={faCheck} size="2x" />
          </Button>
        </FormConstructor>
      </div>
    </MobileViewSelect.SelectedRecordView>
  );
}
