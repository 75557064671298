/**
 * Sorts an array of objects by a defined key value
 * @param {Object[]} array The array to be sorted
 * @param {String} sortKey The key used to determine the value to sort by
 * @param {Boolean} descending Provide true to sort list in descending order. Defaults to ascending.
 * @returns The original array sorted
 */
export default function sortArrayOfObjects<T>(array: T[], sortKey: string, descending?: boolean) {
  if (array.length === 1) return array;

  return array.sort((a, b) => {
    let nameA = a[sortKey];
    let nameB = b[sortKey];

    if (typeof nameA === "string") nameA = nameA.toLowerCase();
    if (typeof nameB === "string") nameB = nameB.toLowerCase();

    if (descending) {
      return nameA > nameB ? -1 : nameA < nameB ? 1 : 0;
    } else {
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    }
  });
}

/**
 * Sorts an array of objects by a defined key value
 * @param {Object[]} array The array to be sorted
 * @param {String} keyToSortBy The key used to determine the value to sort by
 * @param {Boolean} descending Provide true to sort list in descending order. Defaults to ascending.
 * @returns The original array sorted
 */
export function sortByDate(array, keyToSortBy, descending) {
  if (array.length > 1) {
    return array.sort((a, b) => {
      const dateA = new Date(a[keyToSortBy]); //.getTime();
      const dateB = new Date(b[keyToSortBy]); //.getTime();

      if (descending) {
        return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
      } else {
        return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
      }
    });
  } else {
    return array;
  }
}
