import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

import { FIREBASE_CREDENTIALS } from "src/constants";
import { getMessaging, isSupported } from "firebase/messaging";

//////////////////////////////////////////////////////////////////////
// CREATE A SHARED INSTANCE OF FIREBASE WITH THE DEFAULT APPLICATION
//////////////////////////////////////////////////////////////////////
export const firebaseApp = initializeApp(FIREBASE_CREDENTIALS);
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseMessaging = (async () => {
  try {
    if (await isSupported()) return getMessaging(firebaseApp);
    console.info("Messaging notifications are not supported by this browser");
  } 
  catch (err) {
    console.error(err);
  }
})();
