// Libraries
import { useEffect, useState } from "react";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
//Components
import {
  ResponsiveContainer,
  DataViewSelect,
  Buttons,
} from "src/common/commonViews.index";
//Hooks
import useWindowDimensions, {
  BSBreakpoints,
} from "src/hooks/useWindowDimensions";
import renderMap from "./logic/adminDIDsColumnMap"
import adminDIDsFormLogic from "./logic/adminDIDsFormLogic";
import { useApplicationStates } from "src/contexts";
import AdminDIDsMobileViews from "./subcomponents/AdminDIDsMobileViews";
import useSearchBar from "src/hooks/useSearchBar";
import usePagination from "src/hooks/usePagination";
import { did_numbersAttributes } from "@tsmodels/did_numbers";
// Logic

export default function AdminDIDs() {
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Contexts
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const { adminAppStates, socket, userData } = useApplicationStates();
  const { setAdminDIDs, adminCodes } = adminAppStates;

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // States
  /////////////////////////////////////////////////////////////////////////////////////////////////

  const [selectedDID, setSelectedDID] = useState<did_numbersAttributes | null>(null);
  const [selectedView, setSelectedView] = useState<string | null>(null);
  // Pagination
  const [selectedPageNum, setSelectedPageNum] = useState(1);

  // Name of primary key identifying unique record
  const primaryKey = "did_number";

  const { searchBar, filteredResults } = useSearchBar({ list: "adminDIDs", renderMap });
  const adminDIDsToDisplay = filteredResults;

  const { displayIndexes, numOfPages, paginate, resultsSelector } =
    usePagination(
      adminDIDsToDisplay.length,
      selectedPageNum,
      setSelectedPageNum
    );

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Record handling methods
  /////////////////////////////////////////////////////////////////////////////////////////////////

  //TODO Abstract
  function handleSelectRecord(record) {
    if (selectedDID && selectedDID === record) {
      return setSelectedDID(null);
    }

    setSelectedDID(record);
  }

  // Deselect a record
  function handleDeselectRecord() {
    return setSelectedDID(null);
  }

  function handleAddDID(newDID) {
    if(!userData.currentUser) return console.error(`Current User is null!`);
    socket.emit("requestAdminNewDID", newDID, userData.currentUser.user_id);
  }

  function handleEditDID(editedDID) {
    if(!selectedDID) return console.error(`No DID Selected!`);
    //Normalize the 'None' string to a null data type
    editedDID.did_billing_code === "None" ? editedDID.did_billing_code = null : null;
    const prevBillCode = selectedDID.did_billing_code;
    socket.emit("requestAdminEditDID", editedDID, prevBillCode);
  }

  function handleDIDPhoneStatus() {
    if(!selectedDID) return console.error(`No DID Selected!`);
    socket.emit("requestAdminDIDPhoneStatus", {
      did_number: selectedDID.did_number,
      // Send opposite of what is on record currently (to toggle enable/disable)
      deliver_to_phonesystem: !selectedDID.deliver_to_phonesystem,
    });
  }

  function handleDeleteDID() {
    if(!selectedDID) return console.error(`No DID Selected!`);
    socket.emit("requestDeleteDID", selectedDID.did_number);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Modal methods and props
  /////////////////////////////////////////////////////////////////////////////////////////////////

  function handleResetView() {
    setSelectedView(null);
    setSelectedDID(null);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Viewport props
  /////////////////////////////////////////////////////////////////////////////////////////////////

  //Get the viewport width for deciding responsive layouts
  const { width } = useWindowDimensions();

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Component props
  /////////////////////////////////////////////////////////////////////////////////////////////////

  function buttonConstructor() {
    /*    const underXLBreakpoint = width < BSBreakpoints.xl ? true : false; */
    return [
      {
        label: "Edit",
        /*   className: `${styles.client_action_btn} ${
          underXLBreakpoint ? "mb-1" : "me-1"
        }`, */
        onClick: () => setSelectedView("edit-did"),
        faIcon: faPencilAlt,
      },
      {
        label:
          selectedDID && selectedDID.deliver_to_phonesystem
            ? "Disable"
            : "Enable",
        /*  className: `${styles.client_action_btn} ${
          underXLBreakpoint ? "mb-1" : "me-1"
        }`, */
        onClick: () => setSelectedView("activate-did"),
        faIcon: faPencilAlt,
      },
      {
        label: "Delete",
        /*   className: `${styles.client_action_btn} ${
          underXLBreakpoint ? "mb-1" : "me-1"
        }`, */
        onClick: () => setSelectedView("delete-did"),

        faIcon: faTrashAlt,
      },
    ];
  }

  function tableHeader() {
    function firstRowChildren(width) {
      return (
        <div
          className={`d-flex ${
            width < BSBreakpoints.lg
              ? "justify-content-center"
              : "justify-content-end"
          } flex-grow-1`}
        >
          {searchBar()}
          {resultsSelector(`mx-2 ms-4 d-flex align-items-center flex-nowrap
            text-nowrap`)}
          <Buttons.AddNew setSelectedView={setSelectedView} view={"add-did"} />
        </div>
      );
    }

    function breakpointSelect(width) {
      return (
        <div>
          {numOfPages > 1 && width >= BSBreakpoints.md ? paginate() : null}
        </div>
      );
    }

    return {
      title: "DIDs",
      firstRowChildren,
      children: breakpointSelect,
    };
  }

  const logicMapProps = {
    adminCodes,
    selectedDID,
  };

  //Maps table titles and data to show.
  //First element in array is table title while second is key to retreive.
  //Uses map to preserve order of insertion.

  const dataViewSelectProps = {
    //Data to render
    data: adminDIDsToDisplay,
    // Map logic on how to render data
    renderMap,
    // The primary key to use when selecting records
    primaryKey,
    // Indexes to slice when using table layout
    displayIndexes,
    // Button logic to use when using table layout
    buttonConstructor,
    // Logic map for forms and modal
    logicMap: adminDIDsFormLogic({ ...logicMapProps }),
    //Header props
    headerProps: setSelectedView,

    // General handle functions and props
    selectedRecord: selectedDID,
    selectedView,
    setSelectedView,
    handleResetView,
    handleSelectRecord,
    handleDeselectRecord,
    //Handle functions for selected view
    handleFunctions: {
      "add-did": handleAddDID,
      "edit-did": handleEditDID,
      "activate-did": handleDIDPhoneStatus,
      "delete-did": handleDeleteDID,
    },
    // Actually uses records list here
    recordsList: {
      lastSelected: selectedDID,
      list: adminCodes,
      key: "billing_code",
    },
    // The component to use when the viewport is smaller than 450px;
    mobileView: (props) => <AdminDIDsMobileViews {...props} />,
    searchBar,
    tableHeader,
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Return
  /////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <ResponsiveContainer>
      <DataViewSelect {...dataViewSelectProps} />
    </ResponsiveContainer>
  );
}
