import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  formatPhoneNumber,
  sortAlphabetically,
  formatUsernames
} from "src/helpers/formattingHelpers";
import styles from "../DataTable.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useApplicationStates, useAuth } from "src/contexts";
import { ContactCircle } from "src/common/commonViews.index";

export default function FormatColumns({ children }) {
  return <>{children}</>;
}

export function ViaDIDCol({ didNum }) {
  //TODO might need to make this switch depending on user or admin
  const { userData, adminAppStates } = useApplicationStates();
  const { currentUser } = userData;
  const { adminDIDs } = adminAppStates;

  function availableDIDs() {
    if (currentUser) {
      if (currentUser.user_role === "user") {
        return currentUser.user_dids;
      }

      if (currentUser.user_role === "admin") {
        return adminDIDs;
      }

      return [];
    }
  }

  const foundDIDDesc = availableDIDs().find((did) => did.did_number === didNum);
  if (foundDIDDesc) {
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip id="button-tooltip">{didNum}</Tooltip>}
      >
        {/* TODO  Tooltip on mobile view too far to right */}
        <div>{foundDIDDesc.did_description}</div>
      </OverlayTrigger>
    );
  } else {
    return <div>No DID</div>;
  }
}

export function NameCol({ room_customers, room_users }, contactCircle) {
  const { firebaseUser } = useAuth();
  const userNames = formatUsernames(room_users, firebaseUser);

  if (room_customers.length === 0) {
    return (
      <div>
        {userNames.map((username) => (
          <div key={username}>{username}</div>
        ))}
      </div>
    );
  } else {
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip /* id="button-tooltip text-left" */ /* className={`d-flex`} */
          >
            <div>Users In Room:</div>
            <div /* className={`d-flex flex-column align-items-start`} */>
              {
                Array.isArray(userNames) 
                  
                ? userNames.map((username) => (
                    <div key={username}>{username}</div>
                  )) 
                  
                  : <i>No Users</i>}
            </div>
          </Tooltip>
        }
      >
        {/* TODO  Tooltip on mobile view too far to right */}
        <div>
          {room_customers.map((customer) => (
            <div
              key={customer.customer_id}
              className={"d-flex align-items-center mb-1"}
            >
              {contactCircle && (
                <ContactCircle
                  circleNames={`${customer.first_name} ${customer.last_name}`}
                  size="xxsm"
                />
              )}
              <span className="ps-2">
                {
                  `${customer.first_name} ${customer.last_name}`
                }
              </span>
            </div>
          ))}
        </div>
      </OverlayTrigger>
    );
  }
}

export function DIDNumberCol({ dids }) {
  if (dids.length > 0) {
    const sortedDIDS = sortAlphabetically(dids, "did_description");

    return sortedDIDS.map((did) => (
      <div key={did.did_number}>
        <div className={`mb-2`}>
          {did.did_description}:{" "}
          <div /* className={`fw-bolder`} */>
            {formatPhoneNumber(did.did_number)}
          </div>
        </div>
        {/* <div>{`Description: `}</div> */}
      </div>
    ));
  } else {
    return <div>- No Assigned DIDs</div>;
  }
}

export function VerifiedCol({ verifiedData }) {
  const xSVG = <FontAwesomeIcon icon={faTimes} size="xs" />;
  const checkSVG = <FontAwesomeIcon icon={faCheck} size="xs" />;

  const emailFlag = verifiedData.email_verified ? checkSVG : xSVG;
  const passFlag = verifiedData.password_verified ? checkSVG : xSVG;
  const style = { backgroundColor: "#ed5565" };

  return (
    <div>
      <div
        className={` py-1 d-flex justify-content-between align-items-center`}
      >
        <div>Email: </div>
        <div
          className={`${styles.verified_flag} d-flex justify-content-center align-items-center rounded`}
          style={emailFlag === xSVG ? style : null}
        >
          {emailFlag}
        </div>
      </div>
      <div className={`d-flex justify-content-between`}>
        <div>Password: </div>
        <div
          className={`${styles.verified_flag} ms-2 d-flex justify-content-center align-items-center rounded`}
          style={passFlag === xSVG ? style : null}
        >
          {passFlag}
        </div>
      </div>
    </div>
  );
}

export function EnabledCol({ userEnabled }) {
  let string = "";
  let style = {};

  if (userEnabled) {
    string = "Enabled";
  } else {
    string = "Disabled";
    style.backgroundColor = "#ed5565";
  }

  return (
    <div>
      <div
        style={style}
        className={`${styles.enabled_flag} d-flex justify-content-center align-items-center rounded`}
      >
        {string}
      </div>
    </div>
  );
}

export function SendToPhoneSystemCol({ enabled }) {
  let string = enabled ? "Sending" : "Disabled";
  let style = { backgroundColor: "#ed5565" };

  return (
    <div>
      <div
        style={enabled ? null : style}
        className={`${styles.enabled_flag} d-flex justify-content-center align-items-center rounded`}
      >
        {string}
      </div>
    </div>
  );
}

function UserRoleCol({ role }) {
  let string = "";

  if (role) {
    string = role.charAt(0).toUpperCase() + role.slice(1);
  } else {
    // This should never fire
    return "No role assigned";
  }

  return (
    <div>
      <div
      /*  className={`d-flex justify-content-center align-items-center rounded`} */
      >
        {string}
      </div>
    </div>
  );
}

FormatColumns.ViaDIDCol = ViaDIDCol;
FormatColumns.NameCol = NameCol;
FormatColumns.DIDNumberCol = DIDNumberCol;
FormatColumns.VerifiedCol = VerifiedCol;
FormatColumns.EnabledCol = EnabledCol;
FormatColumns.SendToPhoneSystemCol = SendToPhoneSystemCol;
FormatColumns.UserRoleCol = UserRoleCol;
