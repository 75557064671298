import { useState, useEffect, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import { useAuth } from "src/contexts";
import LoginForm from "./LoginForm";

export default function LoginAuthActions({ authFormLogic, setAlertResponse }) {
  const [authState, setAuthState] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const navigate = useNavigate();
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Component props
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const query = new URLSearchParams(useLocation().search);

  const mode = query.get("mode");
  const actionCode = query.get("oobCode");
  /*   const continueUrl = query.get("continueUrl");
  const lang = query.get("lang") || "en"; */
  const id = query.get("id");

  const {
    firebaseUser,
    handleUpdatePassword,
    handleForgotFirebasePassword,
    handleResetPasswordByEmail,
    verifyEmailWithFirebase,
    handleSignOut,
  } = useAuth();

  const selectedAuthForm = authFormLogic ? authFormLogic[mode] : null;

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Component props
  /////////////////////////////////////////////////////////////////////////////////////////////////

  const handleVerifyEmail = useCallback(async () => {
    try {
      await verifyEmailWithFirebase(actionCode, id);
      setAuthState(true);
    } catch (err) {
      setAuthState(false);
      console.error(err);
    }
  }, [actionCode, verifyEmailWithFirebase, id]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // RENDER EFFECTS
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // Render effect for email verification check
  useEffect(() => {
    // If a user is currently logged in, set the userLoggedInState to true to show warning modal
    if (firebaseUser && actionCode && mode === "verifyEmail") {
      setUserLoggedIn(true);
    } else if (firebaseUser === false && mode === "verifyEmail") {
      // Otherwise verify the email
      handleVerifyEmail();
    }
  }, [firebaseUser, actionCode, mode, handleVerifyEmail]);

  // If there is no logged in firebase user when attempting to submit new password, push user back to homepage
  useEffect(() => {
    if (mode === "newPassword" && !firebaseUser) {
      navigate("/");
    }
  }, [firebaseUser, mode]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Handle methods
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function checkPassword(password, verifyPassword) {
    if (password === verifyPassword) {
      return password;
    } else {
      throw new Error("Passwords do not match!");
    }
  }

  // Forgot Password
  //!! Default firebase forgot password email handler
  async function handleForgotPassword({ email }) {
    try {
      const serverResponse = await handleForgotFirebasePassword(email);
      if(serverResponse.status === 200) return "Password reset email sent successfully!";
      throw serverResponse;
    }
    catch(err) {
      return Error(`STATUS ${err.status}: Email not sent, please check your email address or contact an administrator`);
    }
  }

  // New Password
  async function handleNewPassword({ password, verifyPassword }) {
    // Verify passwords against each other
    const newPassword = checkPassword(password, verifyPassword);
    await handleUpdatePassword(newPassword);
  }

  // Reset Password
  async function handleResetPassword({ password, verifyPassword }) {
    const newPassword = checkPassword(password, verifyPassword);
    await handleResetPasswordByEmail(actionCode, newPassword);
    navigate("/");
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Layout components
  /////////////////////////////////////////////////////////////////////////////////////////////////

  function verifyEmail() {
    let verificationMessage;
    if (authState === null) {
      verificationMessage = "Verifying email...";
    } else if (authState) verificationMessage = "Congrats, your account has been verified!";
    else {
      verificationMessage = "Sorry, we couldn't verify this email address.";
    }
    return (
      <>
        <div>{verificationMessage}</div>
      </>
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Component props
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function formSwitch() {
    let handleSubmitForm;

    switch (mode) {
      case "forgotPassword":
        handleSubmitForm = handleForgotPassword;
        break;
      //return forgotPassword();
      case "newPassword":
        handleSubmitForm = handleNewPassword;
        break;
      //return newPassword();
      case "resetPassword":
        // Display reset password handler and UI.
        handleSubmitForm = handleResetPassword;
        break;
      //return passwordReset();
      /*       case "recoverEmail":
        // Display email recovery handler and UI.
        return handleRecoverEmail(auth, actionCode, lang); */
      case "verifyEmail":
        // Display email verification handler and UI.
        return verifyEmail();
      default:
        return <div>No action code detected</div>;
      // Error: invalid mode.
    }

    return (
      <LoginForm
        authFormToMap={selectedAuthForm}
        handleFormSubmit={async (formInput) => {
          try {
            const res = await handleSubmitForm(formInput);
            setAlertResponse(res);
          } catch (err) {
            setAlertResponse(err);
          }
        }}
      />
    );
  }

  function userLoggedInModal() {
    return (
      <Modal
        show={userLoggedIn}
        onHide={() => setUserLoggedIn(false)}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName={` mx-auto p-2`}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title className={`mx-auto`} id="contain ed-modal-title-vcenter">
            <div className={`d-flex align-items-center`}>
              <FontAwesomeIcon className={`mt-1 me-2`} icon={faExclamationTriangle} />
              <h1>Warning</h1>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2 className={`text-center fs-4 mb-2`}>Another user is logged in!</h2>
          <p className={`text-center`}>Verifying your email will log out current user- Proceed with action?</p>
        </Modal.Body>
        <Modal.Footer className={`d-flex justify-content-center`}>
          <Button className={`fs-5 flex-grow-1`} onClick={handleSignOut}>
            Yes
          </Button>
          <Button
            className={` fs-5 flex-grow-1`}
            onClick={() => {
              setAuthState(false);
              setUserLoggedIn(false);
            }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Return
  /////////////////////////////////////////////////////////////////////////////////////////////////
  return userLoggedIn ? (
    userLoggedInModal()
  ) : authState ? (
    <>
      <h2 className="pb-2">Success!</h2>
      <Link to={"/"}>
        <Button>Login</Button>
      </Link>
    </>
  ) : (
    formSwitch()
  );
}

/*     async function handleRecoverEmail(auth, actionCode, lang) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    let restoredEmail = null;
    try {
      // Confirm the action code is valid.
      const info = await auth.checkActionCode(actionCode);
      // Get the restored email address.
      restoredEmail = info["data"]["email"]; 
    } catch (err) {
      throw err;
    }
  } */
