import { useState, useEffect } from "react";
import { io } from "socket.io-client";
import { useAuth } from "src/contexts";

// Socket.io includes an old window method (setImmediate) that is non-standard, requires polyfill to prevent
// console/unit testing errors. Solution taken from https://github.com/expo/expo/issues/7996
/* import "setimmediate";

if (typeof global !== "undefined"  && !global.setImmediate) {
  global.setImmediate = setTimeout;
}
 */
/**
 * Initializes and returns a socketIO client
 * @returns A socketIO client instance
 */
export default function useSocketIO({ setSocketStatus }) {
  //////////////////////////////////////////////////////////////////////
  // CREATE SOCKET INSTANCE
  //////////////////////////////////////////////////////////////////////
  /**
   * Generates a new client socket instance for the application with predetermined settings. Uses Firebase token
   * for auth.
   * @returns Socket Instance in local state
   */
  const generateSocketInstance = () =>
    io(import.meta.env.VITE_BACKEND_URL, {
      autoConnect: false,
      auth: async (cb) => {
        cb({
          token: await getAuthToken(),
        });
      },
    });
  
  const [socket] = useState(generateSocketInstance());
  const { firebaseUser, getAuthToken } = useAuth();
  //////////////////////////////////////////////////////////////////////
  // INITIALIZE AND CONNECT SOCKET
  //////////////////////////////////////////////////////////////////////
  useEffect(() => {
    // If a user is signed in and the socket is disconnected, connect to server
    if (firebaseUser && !firebaseUser.tempPassword && !socket.connected) {
      socket.connect();

      // Socket Events
      //////////////////////////////////////////////////////////////////////
      socket.on("connect", () => {
        setSocketStatus(true);
        console.log(`🤝 Client Side Web Socket Connected!`);
        socket.emit("connected", "👍 Client-Side can hear you!");

        // Checks package versions on connect to detect out of sync versions. Fires service worker update call if
        // mismatch found.
        
      });

      socket.on("disconnect", () => {
        setSocketStatus(false);
        console.log(`🚫 Client Side Web Socket Disconnected`);
      });

      socket.on("connect_error", (error) => {
        console.error(`🛑 ERROR: ${error.message}`);
      });

      // If no firebase user is found disconnect socket
    } else if (!firebaseUser) {
      socket.disconnect();
    }

    // Remove all socket listeners on unmount
    return () => {
      socket.offAny();
    };
  }, [firebaseUser, socket, setSocketStatus]);

  return { socket };
}
