import { FormatColumns } from "../../../../common/DataTable/components/DataTable.index";
import {
  convertDateFormat,
  sortAlphabetically,
  sortByNumber,
  sortyByDate,
} from "../../../../helpers/helpers";
import { formatPhoneNumber } from "src/helpers/formattingHelpers";

const adminDIDsColumnMap = new Map([
  [
    "DID Number",
    {
      columnKey: "did_number",
      sort: true,
      sortFunc: sortByNumber,
      formatFunc: formatPhoneNumber,
    },
  ],
  [
    "DID Description",
    {
      columnKey: "did_description",
      sort: true,
      sortFunc: sortAlphabetically,
    },
  ],
  [
    "Billing Code",
    {
      columnKey: "did_billing_code",
      sort: true,
      sortFunc: sortAlphabetically,
    },
  ],
  [
    "Sending To Phone",
    {
      columnKey: "deliver_to_phonesystem",
      sort: false,
      formatFunc: (enabled) => (
        <FormatColumns.SendToPhoneSystemCol enabled={enabled} />
      ),
      //sortFunc: sortAlphabetically,
    },
  ],
  [
    "SMS In",
    {
      columnKey: "total_received_sms",
      sort: true,
      sortFunc: sortByNumber,
    },
  ],
  [
    "SMS Out",
    {
      columnKey: "total_sent_sms",
      sort: true,
      sortFunc: sortByNumber,
    },
  ],
  [
    "MMS In",
    {
      columnKey: "total_received_mms",
      sort: true,
      sortFunc: sortByNumber,
    },
  ],
  [
    "MMS Out",
    {
      columnKey: "total_sent_mms",
      sort: true,
      sortFunc: sortByNumber,
    },
  ],
  [
    "Created",
    {
      columnKey: "didnum_created_at",
      sort: true,
      sortFunc: sortyByDate,
      formatFunc: convertDateFormat,
    },
  ],
]);

export default adminDIDsColumnMap;
