import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlug, faPlugCircleXmark, faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";
import { default as pkg } from "../../../package.json";
import { useApplicationStates } from "src/contexts";
import { useState } from "react";
import { useServiceWorkerContext } from "src/contexts/ServiceWorker/ServiceWorker.context";

export default function VersionButton({ socketStatus }) {
  const [isHovering, setIsHovering] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const { sharedAppStates } = useApplicationStates();
  const serviceWorkerRegistration  = useServiceWorkerContext();
  const { setShowReleaseNotes, updateAvailable } = sharedAppStates;
  const { needRefresh } = serviceWorkerRegistration;
  const currentColor = !isHovering ? `` : isPressed ? "text-orange-500" : `text-orange-400`;

  const handleClick = () => {
    setShowReleaseNotes((prev) => !prev);
  };
  const handleChangeColor = () => {
    setIsHovering((prev) => !prev);
  };
  const handleBorderHighlight = () => {
    setIsPressed((prev) => !prev);
  };
  const plugColor = () => {
    if (isPressed && socketStatus) return "#71ba36";
    if (socketStatus) return "#7cc440";
    return "red";
  };

  return (
    <div
      onClick={handleClick}
      className={`d-flex flex-column ms-auto cursor-pointer ${isPressed ? `scale-105` : ``}`}
      onMouseEnter={handleChangeColor}
      onMouseLeave={handleChangeColor}
      onMouseDown={handleBorderHighlight}
      onMouseUp={handleBorderHighlight}
    >
      <FontAwesomeIcon
        icon={
          plugColor() === "red" 
            ? faPlugCircleXmark
            : needRefresh || updateAvailable  
                ? faArrowCircleDown 
                : faPlug
        }
        color={plugColor()}
        title={pkg.version}
        data-testid="socket_indicator"
      />
      <div className={currentColor}>{pkg.version}</div>
    </div>
  );
}
