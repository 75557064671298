import { useState, useRef } from "react";
import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import TextareaAutosize from "react-textarea-autosize";

// Styles
import styles from "../../Messages.module.scss";
import useWindowDimensions, { BSBreakpoints } from "src/hooks/useWindowDimensions";

//TODO do we need multiline input for messages?
//DOCS: https://medium.com/@650egor/react-30-day-challenge-day-2-image-upload-preview-2d534f8eaaa

export default function MessageInput({
  handleSendMessage,
  handleOptInMessage,
  threadId,
  handlePreview,
  uploadedFile,
  setUploadedFile,
  optInMode,
  disableOptInSend
}) {
  const [text, setText] = useState("");
  
  const fileInput = useRef(null);
  const messageInputRef = useRef(null);

  function handleText() {
    if ((text || uploadedFile) && threadId) {
      handleSendMessage(text, uploadedFile);
      setUploadedFile(null);
      setText("");
    }
    messageInputRef.current.focus();
  }

  function handleFileUpload(e) {
    // Not sure this conditional does anything
    if (e.target.files.length > 0) {
      handlePreview(e.target.files[0]);
      //User now gets an alert if the file size exceeds Bandwidth's transmission ability

    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Components
  /////////////////////////////////////////////////////////////////////////////////////////////////

  const fileUploadButton = (
    <Button
      className={`${styles.file_upload_button}`}
      type="button"
      onClick={() => fileInput.current.click()}
      disabled={optInMode ? true : false}
      title={optInMode ? null : "Upload a file"}
    >
      <FontAwesomeIcon icon={faImage} />
    </Button>
  );

  const sendMessageBtn = (
    <Button
      className={`${styles.send_message_button}`}
      type="submit"
      onClick={optInMode ? handleOptInMessage : handleText}
      title={optInMode ? "Send Opt In Message" : "Send Message"}
      disabled={disableOptInSend ? true : false}
    >
      <FontAwesomeIcon icon={faPaperPlane} />
    </Button>
  );

  const textAreaInput = (
    <TextareaAutosize
      className={`${styles.send_message_input} form-control w-100 text-break ${optInMode ? "text-center" : null}`}
      onKeyPress={(e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          e.preventDefault();
          return disableOptInSend ? null : handleText();
        }
      }}
      onChange={(e) => setText(e.target.value)}
      placeholder={optInMode ? `Disabled Until Customer "Opts In" to Messaging` : "Enter Message..."}
      value={text}
      ref={messageInputRef}
      maxRows={4}
      disabled={optInMode ? true : false}
    />
  );

  const { width } = useWindowDimensions();

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Return
  /////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className={`${styles.inputForm_container} p-2`}>
      <Form className="d-flex" onSubmit={(e) => e.preventDefault()}>
        {/* Declare a file upload field with a class of display none, will be in use but referenced by file upload button*/}
        <Form.Control
          className="d-none"
          type="file"
          ref={fileInput}
          onChange={handleFileUpload}
          accept=".jpg, .jpeg, .png"
          /* isInvalid={!!errors.file} */
          // multiple={false}
        />
        {/* Message Input */}
        {width < BSBreakpoints.sm ? fileUploadButton : null}
        {/* <div className={`flex-grow-1 align-self-end`}></div> */}
        {textAreaInput}
        {width > BSBreakpoints.sm ? fileUploadButton : null}
        {sendMessageBtn}
        {/* Attached File Notification */}
      </Form>
    </div>
  );
}
