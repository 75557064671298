import { useContext } from "react";
import { Form } from "react-bootstrap";
import { FormConstructorContext } from "../FormConstructor";

export default function TextInput({
  keyName,
  inputType,
  placeHolder,
  className,
  isInvalid,
  validated,
}) {
  const { formInput, setFormInput } = useContext(FormConstructorContext);

  return (
    <Form.Control
      className={className}
      type={inputType}
      placeholder={placeHolder}
      value={formInput[keyName]}
      onChange={(e) =>
        setFormInput({
          ...formInput,
          [keyName]: e.target.value,
        })
      }
      isInvalid={
        validated &&
        (typeof isInvalid === "function" ? isInvalid(formInput) : isInvalid)
      }
      required
    />
  );
}
