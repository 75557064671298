import { editOrAddRecord } from "src/helpers/recordHelpers";
import { useApplicationStates } from "src/contexts";

export default function useOccupyRoom() {
  const { sharedAppStates, socket } = useApplicationStates();
  const { setFullRoomsList } = sharedAppStates;

  function occupyRoom(handleFunction, room_id, selected_users = []) {
    if (room_id) {
      // Send server selected contacts ID to request to join room
      socket.emit(
        "requestOccupyRoom",
        room_id,
        selected_users,
        (joinedRoom) => {
          // Update DB state with the new record
          setFullRoomsList((prev) => editOrAddRecord(joinedRoom, "room_id", prev));

          return handleFunction
            ? handleFunction(joinedRoom)
            : console.error("No handle function given");
        }
      );
    } else {
      console.error("🛑 NO ROOM ID FOUND");
    }
  }

  return { occupyRoom };
}
