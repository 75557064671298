import { BSBreakpoints } from "../../hooks/useWindowDimensions";
import styles from "./DataTableHeader.module.scss";

export default function DataTableHeader({
  title,
  firstRowChildren,
  children,
  width,
}) {
  //console.table({title, firstRowChildren, children, width})
  //If viewport is >= 992px display title in header
  function tableTitle() {
    return width >= BSBreakpoints.lg ? (
      <div className="flex-grow-0">
        <h1 className="fs-2 pe-5 fw-bold">{title}</h1>
      </div>
    ) : null;
  }
  return (
    <div
      className={`${styles.table_header_container} ${
        width < BSBreakpoints.md ? "p-2" : "p-3"
      } flex-grow-0 mx-auto w-100 bg-white shadow-sm`}
    >
      <div className="d-flex p-2 mb-2">
        {tableTitle()}
        {firstRowChildren ? firstRowChildren(width) : null}
      </div>
      {children ? children(width) : null}
    </div>
  );
}
