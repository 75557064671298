// Libraries
import { useEffect, useState } from "react";
import { Tab, Tabs, ToastContainer, Toast } from "react-bootstrap";
import useWindowDimensions from "src/hooks/useWindowDimensions";
// Contexts
import { useApplicationStates, useAuth } from "src/contexts";

import { ModalMaker, ResponsiveContainer } from "src/common/commonViews.index";
// Styles
import styles from "./Settings.module.scss";

import {
  SettingsForm,
  settingsFormLogic,
  notificationSettingsLogic,
  userSettingsLogic,
} from "./Settings.index";

//TODO: Settings appears to revert you to client view, double check

export default function Settings() {
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Contexts
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const { sharedAppStates, userData, socket } = useApplicationStates();
  const { billCodeDIDs } = sharedAppStates;
  const { currentUser, handleChangeSetting } = userData;
  const { handleCheckPassword, handleUpdatePassword, userPermissionLevel } = useAuth();
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // States
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const [selectedTab, setSelectedTab] = useState("account");
  const [selectedView, setSelectedView] = useState(null);
  const [modalTextResponse, setModalTextResponse] = useState(null);
  const [setPushoverUserKeyVisibile] = useState(false);
  const [pushoverValidated, setPushoverValidated] = useState(null);
  const [toastContent, setToastContent] = useState(null);
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Viewport layout components
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const currentUserSettings = currentUser ? currentUser.user_settings : null;

  const { width } = useWindowDimensions();

  useEffect(() => {
    if(currentUser) {
      console.log(currentUser)
      setPushoverValidated(currentUser.pushover_key_validated)
    }
    
  }, [currentUser])

  async function handleChangePassword(formInput) {
    try {
      await handleCheckPassword(formInput.old_password);
      await handleUpdatePassword(formInput.new_password);
      setToastContent({ title: "✅ Success!", message: "Your password was changed successfully." });
    } catch (err) {
      console.error(err);
      setToastContent({ title: "⛔ Error!", message: "There was an error changing your password." });
    }
  }

  function handleChangeEmail() {
    socket.emit("newSystemMessage", {}, () => null);
  }

  function handleChangePhoneNumber(phoneNum) {
    socket.emit("requestUpdateuser");
    handleChangeSetting(phoneNum);
  }

  function handlePushoverValidation() {
    const {pushover_user_key, pushover_app_key} = currentUser;
    socket.emit("requestPushoverValidation", { pushover_user_key, pushover_app_key }, response => {
      if(response.status === 1) {
        setPushoverValidated(true);
        socket.emit("requestUpdateUserUpdate", )
        return;
      } //Success Modal
      setPushoverValidated(false);
    });
  }

  const settingsDynamicData = {
    currentUser: currentUser ? currentUser : null,
    settingHandlers: {
      emailNotifications: () =>
        handleChangeSetting({
          email_notifications: !currentUserSettings.email_notifications,
        }),
      handlePushoverToggle: () => handleChangeSetting({ pushover_notifications: !currentUserSettings.
        pushover_notifications}),
      handleFirebaseToggle: () => handleChangeSetting({ firebase_notifications: !currentUserSettings.firebase_notifications}),
      changePushoverUserKey: () =>
        setSelectedView("setting-change-pushover-key-user"),
      changePushoverAppKey: () =>
        setSelectedView("setting-change-pushover-key-app"),
      changePassword: () => setSelectedView("setting-change-password"),
      changeEmail: () => setSelectedView("setting-change-email"),
      changePhoneNumber: () => setSelectedView("setting-change-phone-number"),
      showPushoverUserKey: () => setPushoverUserKeyVisibile((prev) => !prev),

      handlePushoverValidation,
     
    },
    userPermissionLevel,
    billCodeDIDs,
    pushoverValidated
  };

  const modalMakerProps = {
    selectedView: selectedView,
    handleResetView: () => setSelectedView(null),
    handleFunctions: {
      "setting-change-password": handleChangePassword,
      "setting-change-email": handleChangeEmail,
      "setting-change-phone-number": handleChangePhoneNumber,
      "setting-change-pushover-key-user": (formInput) =>
        handleChangeSetting(formInput),
      "setting-change-pushover-key-app": (formInput) =>
        handleChangeSetting(formInput),
    },
    logicMap: settingsFormLogic({
      currentUser,
      handleChangePassword,
      handleCheckPassword,
    }),
    modalTextResponse,
    setModalTextResponse,
    pushoverValidated
  };

  function settingConstructor(settingsToMap) {
    return Object.keys(settingsToMap).map((settingKey) => (
      <SettingsForm.SettingGroup
        key={settingKey}
        setting={settingsToMap[settingKey]}
      >
        {
          settingsToMap[settingKey].component &&
          settingsToMap[settingKey].component(settingsToMap[settingKey])
        }
      </SettingsForm.SettingGroup>
    ));
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Return
  /////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <ModalMaker {...modalMakerProps} />
      <ResponsiveContainer width={width} fullHeight={true}>
        <div
          className={`${styles.settings_tabs_wrapper} mt-3 px-3 mx-auto w-100`}
        >
          {/* <div className={`d-flex justify-content-center`}> */}
          <Tabs
            id="controlled-tab-example"
            activeKey={selectedTab}
            onSelect={(selectedTab) => setSelectedTab(selectedTab)}
            className="w-100 mb-4"
          >
            <Tab eventKey="account" title="Account">
              <SettingsForm>
                {settingConstructor(userSettingsLogic(settingsDynamicData))}
              </SettingsForm>
            </Tab>
            <Tab eventKey="profile" title="Notifications">
              <SettingsForm
              /* className={`${styles.settings_form} mt-3 d-flex flex-column flex-grow-1`} */
              >
                {settingConstructor(
                  notificationSettingsLogic(settingsDynamicData)
                )}
              </SettingsForm>
            </Tab>
          </Tabs>
          {/*  </div> */}
        </div>
      </ResponsiveContainer>
      <div className="position-relative">
        <ToastContainer className="p-3 fit-content" position="bottom-start">
          <Toast className="bg-white" show={toastContent} delay={3000} autohide>
            <Toast.Header>
              <strong className="me-auto">{toastContent?.title}</strong>
            </Toast.Header>
            <Toast.Body>{toastContent?.message}</Toast.Body>
          </Toast>
        </ToastContainer>
      </div>
    </>
  );
}