import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./NavigationBar.module.scss";
import { NewCustomerCounter } from "src/common";

export default function NavLink({ link }) {
  return (
    // Link containers from react-router-bootstrap are required for functioning routing through react-router.
    <LinkContainer to={link.path}>
      <Nav.Link
        className={`w-fit position-relative d-flex flex-wrap justify-content-center align-items-center mb-2 mb-lg-0 py-lg-0  ${styles.nav_link}`}
        active={false}
      >
        {link.icon && <FontAwesomeIcon icon={link.icon} />}
        <p>{link.title}</p>
        {link.title === "Contacts" && <NewCustomerCounter />}
      </Nav.Link>
    </LinkContainer>
  );
}
