// Declared as shared object due to both fields sharing same properties

import FormConstructor from "../../../../common/FormConstructor/FormConstructor";
import AdminDIDsMobileViews from "../subcomponents/AdminDIDsMobileViews";

// Modal logic to use when rendering form fields
function adminDIDsFormLogic({ adminCodes, selectedDID }) {
  return {
    "add-did": {
      mobileView: (props, key) => (
        <AdminDIDsMobileViews.AddEditDID {...props} key={key} />
      ),
      modalTitle: "Add new DID Number",
      initialFormValues: {
        did_number: "",
        did_description: "",
        did_billing_code: "",
        deliver_to_phonesystem: true,
      },
      fields: {
        did_number: {
          inputType: "tel",
          formalName: "DID Number",
          keyName: "did_number",
          placeHolder: "800-123-4567",
          fieldComponent: (props, key) => (
            <FormConstructor.PhoneInput {...props} key={key} />
          ),
        },
        did_description: {
          inputType: "text",
          formalName: "Description",
          keyName: "did_description",
          placeHolder: "Main Office",
          fieldComponent: (props, key) => (
            <FormConstructor.TextInput {...props} key={key} />
          ),
        },
        billing_code: {
          inputType: "select",
          formalName: "Billing Code",
          keyName: "did_billing_code",
          fieldComponent: (props, key) => (
            <FormConstructor.DropdownSelect
              {...props}
              dataList={["None", ...adminCodes.map((code) => code.billing_code)]}
              key={key}
            />
          ),
        },
        deliver_to_phonesystem: {
          inputType: "switch",
          formalName: "Deliver to Phone System?",
          keyName: "deliver_to_phonesystem",
          inputGroup: false,
          fieldComponent: (props, key) => (
            <FormConstructor.Switch {...props} key={key} />
          ),
        },
      },
    },
    "edit-did": {
      mobileView: (props, key) => (
        <AdminDIDsMobileViews.AddEditDID {...props} key={key} />
      ),
      modalTitle: "Editing DID Number",
      initialFormValues: {
        did_description: "",
        did_billing_code: "",
        deliver_to_phonesystem: true,
      },
      fields: {
        description: {
          inputType: "text",
          formalName: "Description",
          keyName: "did_description",
          placeHolder: "Main Office",
          fieldComponent: (props, key) => (
            <FormConstructor.TextInput {...props} key={key} />
          ),
        },
        billing_code: {
          inputType: "select",
          formalName: "Billing Code",
          keyName: "did_billing_code",
          fieldComponent: (props, key) => (
            <FormConstructor.DropdownSelect
              {...props}
              dataList={["None", ...adminCodes.map((code) => code.billing_code)]}
              key={key}
            />
          ),
        },
        deliver_to_phonesystem: {
          inputType: "switch",
          formalName: "Deliver to Phone System?",
          keyName: "deliver_to_phonesystem",
          inputGroup: false,
          fieldComponent: (props, key) => (
            <FormConstructor.Switch {...props} key={key} />
          ),
        },
      },
    },
    "activate-did": {
      modalTitle: "Send DID to Phone System",
      btnText:
        selectedDID && selectedDID.deliver_to_phonesystem
          ? "Disable"
          : "Enable",
      text:
        selectedDID && selectedDID.deliver_to_phonesystem
          ? "Warning: You are about to disable this DID and stop it from delivering to the phone system- Are you sure?"
          : "You are about to enable this DID and allow delivering to the phone system- Are you sure?",
      initialFormValues: {
        deliver_to_phonesystem: true,
      },
      fields: {
        status: {
          inputType: "switch",
          formalName: "Sending to Phone System",
          keyName: "deliver_to_phonesystem",
          /*  formCompone */
        },
      },
    },
    "delete-did": {
      modalTitle: "Deleting the record for",
      submitType: "yes/no",
      text: "You are about to permanently delete this record- are you sure?",
    },
  };
}

export default adminDIDsFormLogic;
