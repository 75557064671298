import { faBars, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Table.module.scss";

export default function TablePaginationGroup({ table, paginateOptions }) {
  const { selectableRows } = paginateOptions;
  const paginateBtnClass = `${styles.paginate_btns} btn-primary border rounded p-1 mx-2`;

  const paginateBtnGrp = (
    <>
      <button className={paginateBtnClass} onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
        <FontAwesomeIcon icon={faChevronLeft} />
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <button className={paginateBtnClass} onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <button className={paginateBtnClass} onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
      <button
        className={paginateBtnClass}
        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
        disabled={!table.getCanNextPage()}
      >
        <FontAwesomeIcon icon={faChevronRight} />
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </>
  );

  return (
    //className="d-flex justify-content-between align-items-center gap-2 mt-1"
    <div className="container mt-2">
      <div className="row">
        <div className="col d-flex align-items-center">
          <div className="me-4">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
            </strong>
          </div>
        </div>
        {/* <span className="flex items-center gap-1">
        | Go to page:
        <input
          type="number"
          defaultValue={table.getState().pagination.pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            table.setPageIndex(page);
          }}
          className="border p-1 rounded w-16"
        />
      </span> */}

        <div className="col d-flex justify-content-center align-items-center">{paginateBtnGrp}</div>
        <div className="col d-flex justify-content-end">
          {/*           <div>
            <FontAwesomeIcon icon={faBars} />
          </div> */}
          <div className={`${styles.row_total_group} d-flex flex-column justify-content-center align-items-end`}>
            <select
              className="rounded mb-2"
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {selectableRows.map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
            <div className="col d-flex justify-content-end align-items-center">
              {table.getRowModel().rows.length} Rows
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
