import { Form } from "react-bootstrap";
import Select from "react-select";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard, faUser } from "@fortawesome/free-solid-svg-icons";
import { ContactCircle } from "src/common/commonViews.index";
/**
 * UI and form for selecting room members.
 * @param {CommonProps} props - Object of props for the component
 * @returns Component
 */
export default function RoomParticipantSelectInput({
  options,
  roomFormData,
  setRoomFormData,
  usersOnly,
  disabledValues,
}) {
  ////////////////////////////////////////////////////////////////////////////////
  // HOOKS
  ////////////////////////////////////////////////////////////////////////////////
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  function handleSelectOnChange(_option, e) {
    const { customer_ids, user_ids } = roomFormData.selected_room_members;
    switch (e.action) {
      case "clear":
        setRoomFormData((prev) => ({
          ...prev,
          selected_room_members: {
            ...prev.selected_room_members,
            customer_ids: disabledValues ? customer_ids.filter((id) => disabledValues.customer_ids.includes(id)) : [],
          },
        }));
        setRoomFormData((prev) => ({
          ...prev,
          selected_room_members: {
            ...prev.selected_room_members,
            user_ids: disabledValues ? user_ids.filter((id) => disabledValues.user_ids.includes(id)) : [],
          },
        }));
        break;
      case "remove-value":
        if (e.removedValue.member_type === "user_ids") {
          setRoomFormData((prev) => ({
            ...prev,
            selected_room_members: {
              ...prev.selected_room_members,
              user_ids: user_ids.filter((id) => id !== e.removedValue.value),
            },
          }));
        }

        if (e.removedValue.member_type === "customer_ids") {
          setRoomFormData((prev) => ({
            ...prev,
            selected_room_members: {
              ...prev.selected_room_members,
              customer_ids: customer_ids.filter((id) => id !== e.removedValue.value),
            },
          }));
        }
        break;
      default:
        break;
    }
  }

  function handleSelectValues() {
    const { customer_ids, user_ids } = roomFormData.selected_room_members;

    return [...customer_ids, ...user_ids].map((value) => {
      const foundVal = options.find((option) => option.value == value);

      if (!foundVal) return console.error("NO FOUND VAL", value, filteredOptions);
      return {
        value: foundVal.value,
        member_type: foundVal.member_type,
        label: foundVal.label,
        isDisabled: foundVal?.isDisabled,
      };
    });
  }

  function handleOnInputChange(input, reason) {
    const filteredOptions = [
      ...options.filter(
        (option) => option.member_type === "customer_ids" && option.label.toLowerCase().includes(input.toLowerCase())
      ),
      ...options.filter(
        (option) => option.member_type === "user_ids" && option.label.toLowerCase().includes(input.toLowerCase())
      ),
    ];

    if (reason.action !== "input-blur" && reason.action !== "menu-close") {
      setInputValue(input);
    }

    if (reason.action === "input-change" || reason.action === "set-value") {
      setFilteredOptions(filteredOptions);
    }
  }

  function handleCheckboxOnChange(value, member_type) {
    setInputValue("");
    setFilteredOptions(options);
    //TODO: see about passing down function that can handle this and other children
    setRoomFormData((prev) => {
      const prevRoomMembers = [...prev.selected_room_members[member_type]];
      const newRoomMembers = (() => {
        // Find index of selected customer/user
        const prevValIndex = prevRoomMembers.indexOf(value);

        if (prevValIndex > -1) {
          prevRoomMembers.splice(prevValIndex, 1);
          return prevRoomMembers;
        } else {
          return [...prevRoomMembers, value];
        }
      })();

      return {
        ...prev,
        selected_room_members: {
          ...prev.selected_room_members,
          [member_type]: newRoomMembers,
        },
      };
    });
  }

  function memberCheckboxGroup(list) {
    return list.map(({ value, member_type, isDisabled, label }) => {
      return (
        <Form.Check key={`${member_type}-${value}`} id={`${member_type}-${value}`} reverse className={"my-4"}>
          <Form.Check.Input
            /*         type={field.type} */
            onChange={(e) => handleCheckboxOnChange(e.target.value, member_type)}
            value={value}
            checked={roomFormData.selected_room_members[member_type].includes(value)}
            disabled={
              (roomFormData.selected_room_members.customer_ids.length > 8 &&
                ![...roomFormData.selected_room_members.customer_ids].includes(value) &&
                member_type === "customer_ids") ||
              isDisabled
            }
          />
          <Form.Check.Label className="d-flex text-start">
            <ContactCircle size="xxsm" circleNames={label} />
            <span className="ms-3 me-2">{label}</span>
          </Form.Check.Label>
        </Form.Check>
      );
    });
  }

  return (
    <>
      <Select
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, Menu: () => null }}
        isMulti
        onChange={handleSelectOnChange}
        onInputChange={handleOnInputChange}
        onKeyDown={(e) => e.key === "Enter" && e.preventDefault()} // Dumb react-select bug, prevents submit on enter press
        options={options}
        placeholder="Search..."
        value={handleSelectValues()}
        inputValue={inputValue}
        className={"mb-2"}
        isOptionDisabled={(option) => option.isDisabled}
        styles={{
          multiValue: (styles, { data }) => ({
            ...styles,
            paddingRight: data.isDisabled ? "3px" : styles.paddingRight,
          }),
          multiValueRemove: (styles, { data }) => ({
            ...styles,
            display: data.isDisabled ? "none" : styles.display,
          }),
        }}
      />
      <div className={"overflow-auto p-2 px-4 //mx-auto/ //d-flex //flex-column //align-items-center"}>
        <div>
          {!usersOnly && (
            <div>
              <p className="border-bottom mb-2 d-flex justify-content-center">
                <span>
                  <FontAwesomeIcon icon={faUser} className="me-2" />
                </span>
                Customers
              </p>
              {memberCheckboxGroup(filteredOptions.filter((option) => option.member_type === "customer_ids"))}
            </div>
          )}
          <div>
            <p className="border-bottom border-top mb-2 d-flex justify-content-center">
              <span>
                <FontAwesomeIcon icon={faIdCard} className="me-2" />
              </span>
              Users
            </p>
            {memberCheckboxGroup(filteredOptions.filter((option) => option.member_type === "user_ids"))}
          </div>
        </div>
      </div>
    </>
  );
}
