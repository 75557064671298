import { ContactTile } from "src/common/commonViews.index";
import MobileViewSelect from "src/common/Mobile/MobileViewSelect";
import { formatPhoneNumber } from "src/helpers/formattingHelpers";
import { sortByNumber } from "src/helpers/helpers";

export default function AllDIDs(props) {
  const formattedData =
    props.data.length > 0 ? sortByNumber(props.data, "did_number") : [];

  return (
    <>
      <MobileViewSelect.MobileSearchBar {...props} />
      <MobileViewSelect.GroupByLetterContainer
        data={formattedData}
        groupKey={"did_number"}
      >
        {(did) => (
          <ContactTile
            name={did.did_number}
            onClick={() => props.handleSelectRecord(did)}
            square
            formatHelper={formatPhoneNumber}
          />
        )}
      </MobileViewSelect.GroupByLetterContainer>
      <MobileViewSelect.NewButton
        onClick={() => props.setSelectedView("add-did")}
      />
    </>
  );
}
