import { faBuilding, faCalendarAlt, faMoneyBill, faPhone, faBusinessTime } from "@fortawesome/free-solid-svg-icons";

import { sortAlphabetically } from "../../../../helpers/formattingHelpers";
import { nanoid } from "nanoid";
import FormConstructor from "../../../../common/FormConstructor/FormConstructor";
import { convertDateFormat } from "../../../../helpers/helpers";
import MobileViewSelect from "../../../../common/Mobile/MobileViewSelect";
import { ContactTile } from "../../../../common/commonViews.index";

export default function AdminCodesMobileViews(props) {
  const recordTitle = props.selectedRecord ? props.selectedRecord.billing_code : null;

  if (props.selectedRecord && !props.selectedView) {
    return <SelectedCode {...props} recordTitle={recordTitle} />;
  }

  if (props.selectedView && props.logicMap[props.selectedView].mobileView) {
    return props.logicMap[props.selectedView].mobileView({
      ...props,
      recordTitle,
    });
  }

  return <AllCodesView {...props} />;
}

function AllCodesView(props) {
  const formattedData = props.data.length > 0 ? sortAlphabetically(props.data, "billing_code") : [];

  return (
    <>
      <MobileViewSelect.MobileSearchBar {...props} />
      <MobileViewSelect.GroupByLetterContainer data={formattedData} groupKey={"billing_code"}>
        {(code) => <ContactTile name={code.billing_code} onClick={() => props.handleSelectRecord(code)} square />}
      </MobileViewSelect.GroupByLetterContainer>
      <MobileViewSelect.NewButton onClick={() => props.setSelectedView("add-code")} />
    </>
  );
}

function SelectedCode(props) {
  return (
    <MobileViewSelect.SelectedRecordView
      handleResetView={props.handleResetView}
      recordTitle={props.recordTitle}
      selectedRecord={props.selectedRecord}
      viewButtons={[<MobileViewSelect.EditButton key={nanoid()} onClick={() => props.setSelectedView("edit-code")} />]}
    >
      <div className="">
        <MobileViewSelect.InfoTileLayout icon={faBuilding}>
          {props.selectedRecord.description}
        </MobileViewSelect.InfoTileLayout>

        <MobileViewSelect.InfoTileLayout icon={faCalendarAlt}>
          {convertDateFormat(props.selectedRecord.billcode_created_at)}
        </MobileViewSelect.InfoTileLayout>

        <MobileViewSelect.InfoTileLayout icon={faPhone}>
          <div>
            <div>Available DIDs</div>
            <div>
              {props.selectedRecord.did_numbers.map((did) => (
                <div key={did.did_number}>
                  <div>{did.did_number}</div>
                  <div>{did.did_description}</div>
                </div>
              ))}
            </div>
          </div>
        </MobileViewSelect.InfoTileLayout>
      </div>
    </MobileViewSelect.SelectedRecordView>
  );
}

function AddEditCodeView(props) {
  // If selected modal is edit customer then display labels above
  return (
    <MobileViewSelect.SelectedRecordView
      newContact={!props.selectedRecord}
      handleResetView={props.handleResetView}
      recordTitle={props.recordTitle}
      selectedRecord={props.selectedRecord}
      viewButtons={[<MobileViewSelect.SaveButton key={nanoid()} form_id="admin_codes_form" />]}
    >
      <FormConstructor
        id="admin_codes_form"
        className={`w-100 px-2`}
        onSubmit={(formInput) => {
          props.handleFunctions[props.selectedView](formInput);
          props.handleResetView();
        }}
        initialFormValues={
          props.selectedRecord ? { ...props.selectedRecord } : props.logicMap[props.selectedView].initialFormValues
        }
      >
        <div className={`mx-auto pe-4 `}>
          <MobileViewSelect.MobileFormFieldLayout
            icon={faMoneyBill}
            fieldName="billing_code"
            selectedView={props.selectedView}
            fieldMap={props.logicMap[props.selectedView].fields}
          />
          <MobileViewSelect.MobileFormFieldLayout
            icon={faBusinessTime}
            fieldName="company"
            selectedView={props.selectedView}
            fieldMap={props.logicMap[props.selectedView].fields}
            adminCompanies={props.adminCompanies}
          />
          <MobileViewSelect.MobileFormFieldLayout
            icon={faBuilding}
            fieldName="description"
            selectedView={props.selectedView}
            fieldMap={props.logicMap[props.selectedView].fields}
          />
        </div>
      </FormConstructor>
    </MobileViewSelect.SelectedRecordView>
  );
}

AdminCodesMobileViews.AddEditCodeView = AddEditCodeView;
