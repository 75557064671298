import AddEditUser from "../mobile/AddEditUser";
import AllUsers from "../mobile/AllUsers";
import SelectedUser from "../mobile/SelectedUser";

export default function AdminUsersMobileViews(props) {
  const { selectedRecord, selectedView, logicMap } = props;
  const recordTitle = props.selectedRecord
    ? `${selectedRecord.first_name} ${selectedRecord.last_name}`
    : null;

  if (selectedRecord && !selectedView) {
    return <SelectedUser {...props} recordTitle={recordTitle} />;
  }
  
  if (selectedView && logicMap[selectedView].mobileView) {
    return logicMap[selectedView].mobileView({
      ...props,
      recordTitle,
    });
  }

  return <AllUsers {...props} recordTitle={recordTitle} />;
}

AdminUsersMobileViews.AddEditUser = AddEditUser;
