import { useApplicationStates } from "src/contexts";
import { editOrAddRecord, editRecord } from "src/helpers/recordHelpers";
import { useMemo } from "react";
import { RoomInstanceWithMembers } from "@tscontrollers/rooms.controller";
import { customers } from "@tsmodels/customers";
import { rooms } from "@tsmodels/rooms";
import { users } from "@tsmodels/users";
/**
 * @typedef {Object} RoomData
 * @property {string} room_id - ID of room
 * @property {string[]} customer_ids - Array of customer IDs
 * @property {string[]} user_ids - Array of user IDs
 * @property {string} room_did_number - Room DID number
 */

/**
 * Collection of hook functions relating to rooms
 */
export default function useRoomHandlers() {
  const { userData, sharedAppStates, socket } = useApplicationStates();
  const { roomsList, setFullRoomsList, usersInCode, setError } = sharedAppStates;

  ////////////////////////////////////////////////////////////////////////////////
  // CHECK FOR AN EXISTING ROOM IN LOCAL STATE
  ////////////////////////////////////////////////////////////////////////////////
  /*
   * Checks for an existing room with the provided user/customer IDs.
  */
  function checkForExistingRoomInLocalState({ customer_ids, user_ids, room_did_number }: {
    customer_ids: customers["customer_id"][],
    user_ids: users["user_id"][],
    room_did_number: rooms["room_did_number"]
  }) {
    return roomsList.find(({ room_customers, room_users, room_did_number: searched_room_did }) => {
      // Reject rooms when provided DID numbers do not match or number of members is unequal
      if (
        // DID Doesn't Match
        (room_did_number && searched_room_did !== room_did_number) ||
        // Or room's customers is not the same length as passed value
        room_customers.length !== customer_ids.length ||
        // Or room's users is not the same length as passed value
        // TODO: This logic may be flawed, does user_ids match
        (user_ids && room_users.length !== user_ids.length)
      )
        return false;

      // With equal array lengths every id in the customer/user id array should be present in the found room
      const allCustomerIdsPresent = customer_ids.every((new_customer_id) =>
        room_customers.some(({ customer_id }) => new_customer_id === customer_id)
      );

      const allUserIdsPresent = user_ids
        ? user_ids.every((new_user_id) => room_users.some(({ user_id }) => new_user_id === user_id))
        : true;
        allCustomerIdsPresent && allUserIdsPresent
      return ;
    });
  }

  const displayableRooms = useMemo(() => {
      return roomsList.filter((room) => room.room_enabled);
  }, [ roomsList ]);

  ////////////////////////////////////////////////////////////////////////////////
  // CREATE A ROOM
  ////////////////////////////////////////////////////////////////////////////////
  /**
   * Callback fired when a new room is returned from the server
   * @callback NewRoomCallback
   * @param {object} newRoom - The new room returned by the parent function // TODO create shared room object
   */
  /**
   * @param {RoomData} roomData - {@link RoomData} - Object containing all relevant room data
   * @param roomData.customer_ids - Array of customer IDs to create room with
   * @param roomData.user_ids - Array of user IDs to create room with
   * @param roomData.room_did_number - The DID number to create new room with
   * @param {NewRoomCallback} [newRoomCallback=null] - Callback fired when a new room is returned from the server.
   * Defaults to null
   */

  function createNewRoom({ customer_ids, user_ids, room_did_number }, newRoomCallback: any = null) {
    // Throw error if attempting to create a customer room with no DID number
    if (customer_ids.length > 0 && !room_did_number)
      return console.error("CANNOT CREATE CUSTOMER ROOM WITHOUT DID NUMBER");

    if (!customer_ids.length && !user_ids.length)
      return console.error("CANNOT CREATE NEW ROOM WITHOUT ANY ROOM MEMBERS");

    // Request customer room creation if customer ids are present
    if (customer_ids.length === 0) {
      // Otherwise this is a user room, create one.
      socket.emit("requestNewUserRoom", user_ids, (newRoom) => {
        setFullRoomsList((prev) => editOrAddRecord(newRoom, "room_id", prev));
        newRoomCallback && newRoomCallback(newRoom);
      });
      return;
    }

    socket.emit(
      "requestCreateCustomerRoom",
      // TODO change backend params to match
      {
        selected_customers: customer_ids,
        selected_users: user_ids,
        selected_did: room_did_number,
      },
      (newRoom) => {
        setFullRoomsList((prev) => [...prev, newRoom]);
        newRoomCallback && newRoomCallback(newRoom);
      }
    );
  }

  ////////////////////////////////////////////////////////////////////////////////
  // CHECK IF CUSTOMER ROOM IS AVAILABLE TO CREATE
  ////////////////////////////////////////////////////////////////////////////////
  /**
   * @callback AvailabilityCallback
   * @param {[roomAvailable: Boolean, availableRooms: Object[]]} roomAvailability - An array returned from the server with two
   values- [0]: Room availability, [1]: An array of available rooms if [0] is false
   */
  /**
   * Check wether a room is available to create, or if it already exists and is occupied. Fires callback provided when
   * room availability is returned from server.
   * @param {RoomData} roomData - An object containing the rooms data
   * @param {string[]} roomData.customer_ids - An array of customer IDs
   * @param {AvailabilityCallback} availabilityCallback - The callback to be used when the rooms availability is returned
   */

  function checkCustomerRoomAvailability({ customer_ids }, callback) {
    socket.emit("requestRoomAvailabilityByCustomer", customer_ids, callback);
  }

  ////////////////////////////////////////////////////////////////////////////////
  // GET AVAILABLE USERS TO JOIN TO ROOM
  ////////////////////////////////////////////////////////////////////////////////
  function getAvailableUsersForRoom(room_id, callback) {
    socket.emit("requestGetAvailableUsers", room_id, (res) => {
      callback(res);
    });
  }

  ////////////////////////////////////////////////////////////////////////////////
  // JOIN CURRENT USER TO A EXISTING ROOM
  ////////////////////////////////////////////////////////////////////////////////
  function joinCurrentUserToRoom(room_id: number, callback?: CallbackFunc) {
    const { currentUser } = userData;
    if(!currentUser) return [];
    joinUsersToRoom(room_id, [currentUser.user_id], callback)
  }

  ////////////////////////////////////////////////////////////////////////////////
  // JOIN MEMBERS TO AN EXISTING ROOM
  ////////////////////////////////////////////////////////////////////////////////
  /**
   * 
   * Join the user_ids to room
  */
  type CallbackFunc = (room: RoomInstanceWithMembers) => void;
  
  function joinUsersToRoom(room_id: number, user_ids: string[], callback?: CallbackFunc) {
    const { currentUser } = userData;
    if(!currentUser) return;
    // Shallow copy user IDs and typecast if not already an array
    const usersToJoin = [...user_ids];
    // Return a console error if no values were passed
    if (!usersToJoin.length) return console.error("NO USERS PROVIDED TO JOIN TO ROOM");
    // Get Room State Object
    const selectedRoom = roomsList.find(room => room.room_id === room_id);
    // Return error if no room was found
    if(!selectedRoom) return console.error("NO ROOM IS SELECTED");
    // Review user_ids to see if anyone is not allowed to join this room
    const userNotAllowed = usersNotAllowed(selectedRoom, usersToJoin);
    // In the event a user is not allowed
    if (userNotAllowed) {
      // Get the user's name a implantable string
      const user_name = userNotAllowed.first_name && userNotAllowed.last_name 
        ? `${userNotAllowed.first_name} ${userNotAllowed.last_name}`
        : ``;
      const baseMessage = `A user ${user_name} is not assigned the room's DID number: ${selectedRoom.room_did_number}.`;
      const userMessage = `${baseMessage} Please ask a manager to give them access`;
      const elevatedMessage = `
      ${baseMessage}
      To add this DID to the user:
      1. Click the Manager Panel in the top right navbar drop down menu.
      2. Find the user record in the table
      3. Click Edit
      4. Check the DID number you want to add
      5. Click Submit`;
      const bannedUserMessage = currentUser.user_role === "user" ? userMessage : elevatedMessage;
      // Set the error modal to alert user that one of the member's does not have access to the DID
      return setError(bannedUserMessage);
    }
    // With no users banned from access go ahead and emit the socket request
    socket.emit("requestJoinUsersToRoom", { room_id, user_ids: usersToJoin }, (joinedRoom: RoomInstanceWithMembers) => {
      // Update the full room list (soft deletes and existing) with returned room
      setFullRoomsList((prev) => editRecord(joinedRoom, "room_id", prev));
      // If a callback was passed run it on the new room
      callback && callback(joinedRoom);
    });
  }
  // USERSNOTALLOWED higher order filter function for users
  function usersNotAllowed(roomState, user_ids) {
    // Shallow copy arrow
    const usersToCheck = [...user_ids];
    // Gets full user objects from app state via their UIDs
    function lookupUsers(user_ids) {
      // Spread the passed user_id string array
      const userRecords = [...user_ids
        // To create a new array of userInCode records 
        .map(user_id => usersInCode
          // That matches the passed user_id to the record
          .find(userInCode => userInCode.user_id === user_id)
        )
        // Remove any find results that returned undefined
        .filter(userRecord => userRecord !== undefined)
      ];
      if(userRecords.length !== user_ids.length) {
        console.error(`Something went wrong, found user count from userIDs does not match`);
      }
      return userRecords;
    }
    // Main Execution
    // Gather the user records
    const users = lookupUsers(usersToCheck);
    // Search for any users
    const bannedUser = users
      // Who do not have a did number access
      .find(user => {
        return !user.user_dids
        // To the rooms assigned DID number
        .some((did_record) => did_record.did_number === roomState.room_did_number)
      });
    // If a bannedUser was found return their record otherwise return false
    return bannedUser ? bannedUser : false
  }

  function replaceRoomUsers({ existing_room, user_ids }, callback) {
    // Shallow copy user IDs and typecast if not already an array
    if (!user_ids.length) return console.error("NO USERS PROVIDED TO JOIN TO ROOM");
    socket.emit("requestReplaceRoomUsers", { room_id: existing_room.room_id, user_ids }, (updatedRoom) => {
      setFullRoomsList((prev) => editRecord(updatedRoom, "room_id", prev));

      callback && callback(updatedRoom);
    });
  }

  return {
    createNewRoom,
    checkForExistingRoomInLocalState,
    checkCustomerRoomAvailability,
    joinCurrentUserToRoom,
    joinUsersToRoom,
    getAvailableUsersForRoom,
    displayableRooms,
    replaceRoomUsers,
  };
}
