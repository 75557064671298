//Libraries
import { useState, useEffect, useRef, useCallback } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Container } from "react-bootstrap";

// Hooks
import { useApplicationStates, useAuth } from "src/contexts";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { editRecord } from "src/helpers/helpers";
import useSendMessage from "src/hooks/useSendMessage";

//Components
import { Modal, RoomManagement } from "src/common";
import { MessageContainer, MessageTitleBar, SelectionPane } from "./children";
import { useSocketEffects_Messages } from "./index";
//Styleslers
import styles from "./Messages.module.scss";
import { useRoomHandlers } from "src/hooks";

// TODO New message bar
export default function Messages() {
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // HOOKS
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const { userData, sharedAppStates, adminAppStates, socket } = useApplicationStates();

  const { width } = useWindowDimensions();
  // Declare the location object for access to the router state
  const location = useLocation();
  const navigate = useNavigate();
  const { joinCurrentUserToRoom, checkForExistingRoomInLocalState } = useRoomHandlers();
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // STATES
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedRoomMessages, setSelectedRoomMessages] = useState(null);
  // Determines modal or page to show depending on user actions
  const [selectedView, setSelectedView] = useState(null);
  // Store any file uploaded by the user for sending later
  const [uploadedFile, setUploadedFile] = useState(null);
  // For mobile Contacts pane
  const [showContacts, setShowContacts] = useState(false);
  // For users with elevated roles, will save the message and return after selection of whether to join room
  const [savedMessage, setSavedMessage] = useState(null);
  // Sets the Messages Component to Opt In Controls
  const [optInMode, setOptInMode] = useState(false);
  const [disableOptInSend, setDisableOptInSend] = useState(false);
  // Datastore states
  const { currentUser } = userData;
  const { selectedAdminBillCode } = adminAppStates;
  const { customersList, roomsList, setFullRoomsList, setServerResponse } = sharedAppStates;

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // REFS
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Declare empty ref for later determining when to activate smooth scroll
  const roomLoadedRef = useRef(null);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // CONSTANTS AND HOOK FUNCTIONS
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const [searchParams] = useSearchParams();
  // Use sendMessage hook
  const { sendMessage, invalidSendMessageModal } = useSendMessage(setSelectedRoomMessages, {
    message: savedMessage,
    setMessage: (message) => {
      // Set server response using the selected room instead of querying
      setServerResponse({ "occupied-rooms": [selectedRoom] });
      setSelectedView("occupied-rooms");
      setSavedMessage(message);
    },
  });
  const { userPermissionLevel } = useAuth();

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // USECALLBACKS
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // TODO: Find more efficient way to do this
  // Updates selected room on change
  useEffect(() => {
    const foundRoom = roomsList.find(({ room_id }) => room_id === selectedRoom?.room_id);
    const roomsMatch = JSON.stringify(foundRoom) === JSON.stringify(selectedRoom);
    if (foundRoom && !roomsMatch) setSelectedRoom(foundRoom);
  }, [roomsList, selectedRoom]);

  const fetchRoomMessages = useCallback(
    (roomID) => {
      socket.emit("requestRoomMessages", roomID, (res) => {
        res.sort((roomA, roomB) => new Date(roomA.sent_at) - new Date(roomB.sent_at))
        setSelectedRoomMessages(res);
      });
    },
    [socket, setSelectedRoomMessages]
  );

  const handleUpdateLastReadMessageID = useCallback(
    (message_id) => {
      // Update the last_read_message_id on room_user_lookup table to the most
      // current message_id for the selected room and current user
      socket.emit(
        "requestUpdateLastMessageReadID",
        selectedRoom.room_id,
        message_id,
        currentUser.user_id,
        (success) => {
          if (success) {
            setFullRoomsList((prev) => {
              const foundRoom = prev.find((room) => room.room_id === selectedRoom.room_id);
              // Update the selected rooms last message read id
              return editRecord(
                {
                  ...foundRoom,
                  unread_message_count: 0,
                },
                "room_id",
                prev
              );
            });
          }
        }
      );
    },
    [socket, currentUser, selectedRoom, setFullRoomsList]
  );

  useSocketEffects_Messages({
    fetchRoomMessages,
    handleUpdateLastReadMessageID,
    selectedRoom,
    setSelectedRoom,
    selectedRoomMessages,
    setSelectedRoomMessages,
    setUploadedFile,
    setSelectedContact,
    setSelectedView,
  });

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // RENDER EFFECTS
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // Reset room and messages if selected admin bill code changes
  useEffect(() => {
    setSelectedRoom(null);
    setSelectedRoomMessages(null);
  }, [selectedAdminBillCode]);

  // Whenever a room is selected and the messages are loaded/sent/received, update the last read message ID in the
  // room_user_lookup table
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (selectedRoom && Array.isArray(selectedRoomMessages) && selectedRoomMessages.length > 0) {
      handleUpdateLastReadMessageID(
        // Get the ID of the last message retrieved
        selectedRoomMessages.at(-1).message_id
      );
    }
  }, [selectedRoom, selectedRoomMessages, handleUpdateLastReadMessageID]);

  //TODO setup up to carry room id through app so if user goes back to messages they are on same room again
  // If location object has a state value, set roomID to it. Enables go to room button action from client and rooms view.
  // Get room_id from React Router location state if:
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    let roomID = location.state?.room_id || searchParams.get("room_id");

    // Needs to be weak equality for string to int match
    if (selectedRoom?.room_id != roomID && roomsList.length) {
      const foundRoom = roomsList.find(({ room_id }) => room_id == roomID);

      if (foundRoom) {
        setSelectedRoom(foundRoom);
        if (foundRoom.room_customers.length && foundRoom.room_did_number === null) {
          setSelectedRoom(foundRoom);
          setSelectedView("CREATE_OR_MANAGE_ROOM");
        }
        //if(optInMode) return null;
        fetchRoomMessages(foundRoom.room_id);
        location.state = null;
      }
    }
  }, [selectedRoom, searchParams, location, roomsList, fetchRoomMessages]);

  // Opt-in mode
  ////////////////////////////////////////////////////////////////////////////////////////////////
  //TODO: Get Help from Derek
  useEffect(() => {
    if(!selectedRoom) return;
    //If room is selected and the room's customer length is only one
    if (selectedRoom.room_customers && selectedRoom.room_customers.length === 1) {
      //Get that customer's ID
      const currentCustomer = selectedRoom.room_customers[0].customer_id;
      //Use the ID to get their record
      const customerRecord = customersList.find((customer) => customer.customer_id === currentCustomer);
      //If the customer record exists and it's sms_opt_in is false
      if (customerRecord && !customerRecord.sms_opt_in) {
        //Turn on opt in mode
        setOptInMode(true);
        //If they have been sent an opt in disable the send button
        customerRecord.sent_opt_in ? setDisableOptInSend(true) : setDisableOptInSend(false);
        return;
      }
    }
    setOptInMode(false);
    setDisableOptInSend(false);
  }, [selectedRoom, customersList, disableOptInSend]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // HANDLERS
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // Change selected room
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function handleChangeRoom(roomRecord) {
    //If selectedRoom does not exist or the roomRecord is not using the same ID as selectedRoom
    if (!selectedRoom || roomRecord.room_id !== selectedRoom.room_id) {
      //Nullifyh the current room messages
      setSelectedRoomMessages(null);
      //Reset ref value
      roomLoadedRef.current = false;
      //Find room that has a matching room ID out of the room list
      const foundRoom = roomsList.find((room) => room.room_id === roomRecord.room_id);
      //If that room was found
      if (foundRoom) {
        //If the room has customers and but no DID number
        if (foundRoom.room_customers.length && foundRoom.room_did_number === null) {
          // Set the found room to be seleccted and change the view
          setSelectedRoom(foundRoom);
          setSelectedView("CREATE_OR_MANAGE_ROOM");
        }
        // Set the found room to be selected
        setSelectedRoom(foundRoom);
        // Redirect to the room ID
        navigate(`/user/messages?room_id=${foundRoom.room_id}`);

        // ABANDONED FOR NOW
        //Do not fetch messages if Opt In Mode is on
        //if(optInMode) return null;

        // Request messages for room from the server
        fetchRoomMessages(foundRoom.room_id);
      }
      // If no room was found
      else {
        // Nullify selection and console log an error
        setSelectedRoom(null);
        console.error("⛔ NO ROOM FOUND!");
      }
      // Finally
      setShowContacts(false);
    }
  }

  // SEND MESSAGE
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function handleSendMessage(message_text, uploadedFile) {
    let message = {
      message_text,
      room: selectedRoom,
      uploadedFile,
    };
    const senderIsMemberOfRoom = selectedRoom.room_users.some((roomUser) => roomUser.user_id === currentUser.user_id);
    //User isn't a member of the room
    if (!senderIsMemberOfRoom) {
      // If User has greater than base level permissions they can join rooms freely
      if (userPermissionLevel("mid")) {
        // Destructure data from selected room
        const { room_customers, room_users, room_did_number } = selectedRoom;
        let room_id = selectedRoom.room_id;
        // Create a list of customer IDs
        const customer_ids = room_customers.map((customer) => customer.customer_id);
        // Make sure to add the user themself to user_ids
        const user_ids = [currentUser.user_id, ...room_users.map((user) => user.user_id)];
        // If there's customers present in this room for additional handling
        if (customer_ids.length) {
          // See if room exists in local state
          const roomExists = checkForExistingRoomInLocalState({ customer_ids, user_ids, room_did_number });
          // Return existing room to enforce SMS/MMS unique recipients compatibility
          if (roomExists) {
            message.room = roomExists;
            setSelectedRoom(roomExists);
            room_id = roomExists.room_id;
          }
          joinCurrentUserToRoom(room_id);
        } else {
          // Otherwise if there's no customers go ahead and join the user to the room
          joinCurrentUserToRoom(room_id);
        }
      }
    }
    sendMessage(message);
  }

  function handleRetryFailedMessage(messageID, cb) {
    socket.emit(`requestRetryFailedMessage`, messageID, cb);
  }

  // SEND OPT IN MESSAGE
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function handleOptInMessage() {
    sendMessage({
      room: selectedRoom,
      optIn: true,
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Component props
  /////////////////////////////////////////////////////////////////////////////////////////////////

  const contactContainerProps = {
    customersList,
    showContacts,
    location,
    handleChangeRoom,
    handleRetryFailedMessage,
    selectedRoom,
    setSelectedRoom,
    selectedContact,
    setSelectedContact,
    setShowContacts,
  };

  const MessageTitleBarProps = {
    selectedRoom,
    setShowContacts,
    selectedView,
    setSelectedView,
    width,
  };

  const sharedViewProps = {
    selectedRoom,
    selectedView,
    setSelectedView,
    setSelectedRoom,
  };

  const MESSAGES_VIEWS = new Map([
    [
      "CREATE_OR_MANAGE_ROOM",
      {
        children: <RoomManagement {...sharedViewProps} />,
      },
    ],
    [
      "ROOM_DISABLED",
      {
        children: (
          <Modal
            show={selectedView === "ROOM_DISABLED"}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
            title={"This contact and its room have been disabled"}
            onExited={() => setSelectedView(null)}
          >
            <p>
              This contact and their room has been disabled by an administrator- please contact support if you believe
              this was an error
            </p>
          </Modal>
        ),
      },
    ],
  ]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // RENDER
  /////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      {invalidSendMessageModal()}
      <Container fluid className={`w-100 d-flex flex-column flex-grow-1 px-0`}>
        <MessageTitleBar {...MessageTitleBarProps} />
        <div className={`${styles.main_container} h-100 d-flex flex-shrink-1`}>
          <div className="h-100 d-flex flex-grow-1 flex-shrink-1">
            <SelectionPane {...contactContainerProps} />
            <div className="h-100 d-flex flex-grow-1 flex-shrink-1 flex-column" style={{ flex: "1 1 1px" }}>
              {MESSAGES_VIEWS.get(selectedView)?.children}
              {!selectedView && (
                <MessageContainer
                  messages={selectedRoomMessages}
                  handleSendMessage={handleSendMessage}
                  handleOptInMessage={handleOptInMessage}
                  handleRetryFailedMessage={handleRetryFailedMessage}
                  uploadedFile={uploadedFile}
                  setUploadedFile={setUploadedFile}
                  roomLoadedRef={roomLoadedRef}
                  selectedRoom={selectedRoom}
                  optInMode={optInMode}
                  disableOptInSend={disableOptInSend}
                />
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
