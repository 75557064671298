// Constructor component for Alert and Success responses, I.E. reset passwords and email, server responses, etc.
import { Alert } from "react-bootstrap";

export default function Alerts({ children }) {
  return <>{children}</>;
}

export function ErrorAlert({ error }) {
  return error ? (
    <div>
      <Alert className="my-2" variant="">
        {error}
      </Alert>
    </div>
  ) : (
    <div></div>
  );
}

export function ResponseAlert({ response }) {
  const variant = response instanceof Error ? "danger" : "success";
  const text = response instanceof Error ? response.message : response;

  if (response) {
    return (
      <div>
        <Alert className="my-2" variant={variant}>
          {text}
        </Alert>
      </div>
    );
  } else {
    return null;
  }
}

Alerts.ErrorAlert = ErrorAlert;
Alerts.ResponseAlert = ResponseAlert;
