import { useNavigate } from "react-router-dom";
import { faPersonBooth, faPhone, faSms, faUser } from "@fortawesome/free-solid-svg-icons";
import MobileViewSelect from "src/common/Mobile/MobileViewSelect";
import { formatPhoneNumber } from "src/helpers/formattingHelpers";
import { convertDateFormat } from "src/helpers/helpers";

export default function SelectedContact({
  foundRooms,
  selectedRecord,
  setSelectedView,
  createUserRoom,
  primaryKey,
  handleRequestRoomAvailability,
  handleResetView,
  recordTitle,
  personalRoom,
}) {
  const navigate = useNavigate();

  function handleSMSButton() {
    if (foundRooms.length === 1) {
      return navigate("/user/messages", { state: { room_id: foundRooms[0].room_id } });
    }

    if (primaryKey === "user_id") {
      if (personalRoom || foundRooms.length === 1) {
        return navigate("/user/messages", { state: { room_id: personalRoom.room_id || foundRooms[0].room_id } });
      }

      return createUserRoom({ contactID: selectedRecord[primaryKey] }, (newRoom) =>
        navigate("/user/messages", { state: { room_id: newRoom.room_id } })
      );
    }

    handleRequestRoomAvailability();
  }

  const selectedContactBtns = [
    {
      btnText: primaryKey === "customer_id" ? "New Text" : "Message",
      btnIcon: faSms,
      btnOnclick: () => handleSMSButton(),
      btnDisabled: foundRooms.length > 1 ? true : false,
    },
    {
      btnText: foundRooms.length ? "View Rooms" : "Create Room",
      btnIcon: faPersonBooth,
      btnOnclick: () => (foundRooms.length ? setSelectedView("room-select") : setSelectedView("create-room")),
      btnDisabled: false,
    },
  ];

  return (
    <MobileViewSelect.SelectedRecordView
      handleResetView={handleResetView}
      recordTitle={recordTitle}
      selectedRecord={selectedRecord}
      viewButtons={[
        primaryKey === "customer_id" ? (
          <MobileViewSelect.EditButton onClick={() => setSelectedView("edit-customer")} />
        ) : null,
      ]}
    >
      <MobileViewSelect.ButtonBar>{selectedContactBtns}</MobileViewSelect.ButtonBar>
      <div className="mt-4">
        <MobileViewSelect.InfoTileLayout icon={primaryKey === "customer_id" ? faPhone : faUser}>
          {primaryKey === "customer_id"
            ? `+${selectedRecord.phone_number_country} ${formatPhoneNumber(selectedRecord.phone_number)}`
            : "User in your organization"}
        </MobileViewSelect.InfoTileLayout>

        {primaryKey === "customer_id" ? (
          <MobileViewSelect.InfoTileLayout icon={faUser}>
            <div className="d-flex justify-content-between">
              <div>
                <div>Added By: </div>
                <div>{selectedRecord.added_by_user}</div>
              </div>
              <div className="text-end">{convertDateFormat(selectedRecord.customer_created_at)}</div>
            </div>
          </MobileViewSelect.InfoTileLayout>
        ) : null}
      </div>
    </MobileViewSelect.SelectedRecordView>
  );
}
