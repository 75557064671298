// Libraries
import { useState, useEffect, useCallback } from "react";
//Contexts
import { useApplicationStates } from "src/contexts";

// Components
import { ResponsiveContainer, DataViewSelect, Buttons, FormConstructor } from "src/common/commonViews.index";

import { deleteRecord, editRecord, sortAlphabetically } from "../../../helpers/helpers";

import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
// Logic
import { adminUsersColumnMap, adminUsersFormLogic } from "./AdminUsers.index";
import { useSearchBar, usePagination, BSBreakpoints } from "../../../hooks/hooks.index";
import AdminUsersMobileViews from "./subcomponents/AdminUsersMobileViews";
import { handleForgotFirebasePassword } from "src/services/firebase";

export default function AdminUsers() {
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Contexts
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const { userData, sharedAppStates, adminAppStates, handleSelectedCodeRef, socket } = useApplicationStates();
  const { currentUser } = userData;
  const { setServerResponse } = sharedAppStates;
  const { adminCodes } = adminAppStates;

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Admin Users States and variables
  /////////////////////////////////////////////////////////////////////////////////////////////////

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedCode, setSelectedCode] = useState(null);
  const [usersInSelectedCode, setUsersInSelectedCode] = useState([]);
  const [selectedView, setSelectedView] = useState(null);
  // Pagination
  const [selectedPageNum, setSelectedPageNum] = useState(1);
  // Name of primary key identifying unique record
  const primaryKey = "user_id";
  const renderMap = adminUsersColumnMap;
  const { searchBar, filteredResults } = useSearchBar({
    list: usersInSelectedCode,
    renderMap,
  });
  const usersToDisplay = filteredResults;

  // Use pagination hook to determine indexes of contactsRecords to display and pass prop to DataTable
  const { displayIndexes, numOfPages, paginate, resultsSelector } = usePagination(
    usersToDisplay.length,
    selectedPageNum,
    setSelectedPageNum
  );
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Render Effects
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Helper method to check users billing code to determine whether to update list
  const addToUserListUseCB = useCallback(
    (user, setter) => {
      if (selectedCode === null || user.billing_code === selectedCode) {
        return setter;
      }
    },
    [selectedCode]
  );

  // Get users in selected code
  useEffect(() => {
    socket.emit("requestAdminUsersByCode", selectedCode, (res) =>
      setUsersInSelectedCode(sortAlphabetically(res, "last_name"))
    );
  }, [socket, selectedCode]);
  // Update User's Logout
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const updateUserLogin = (updatedUser) => {
      addToUserListUseCB(
        updatedUser,
        setUsersInSelectedCode((prev) => editRecord(updatedUser, "user_id", prev))
      );
    }

    socket.on("responseUpdatedUserLogin", updateUserLogin);

    return () => { socket.off("responseUpdatedUserLogin", updateUserLogin) };
  }, [socket, addToUserListUseCB]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const usersViewAddUser = (newUser) => {
      addToUserListUseCB(
        newUser,
        setUsersInSelectedCode((prev) => [...prev, newUser])
      );
    };

    socket.on("responseNewUser", usersViewAddUser);

    return () => { socket.off("responseNewUser", usersViewAddUser) };
  }, [socket, addToUserListUseCB]);

  // When a users is edited by another user, update list
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const usersViewEditUser = (editedUser) => {
      addToUserListUseCB(
        editedUser,
        setUsersInSelectedCode((prev) => editRecord(editedUser, "user_id", prev))
      );
    };

    socket.on("responseEditUser", usersViewEditUser);

    return () => { socket.off("responseEditUser", usersViewEditUser) };
  }, [socket, addToUserListUseCB]);

  // When a users status is changed by another user, update list
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const usersViewChangeUserStatus = (editedUser) => {
      addToUserListUseCB(
        editedUser,
        setUsersInSelectedCode((prev) => editRecord(editedUser, "user_id", prev))
      );
    };

    socket.on("responseAdminChangeUserStatus", usersViewChangeUserStatus);

    return () => { socket.off("responseAdminChangeUserStatus", usersViewChangeUserStatus) };
  }, [socket, addToUserListUseCB]);

  // When a user is deleted by another user, update list
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const usersViewDeleteUser = (deletedUser) => {
      addToUserListUseCB(
        deletedUser,
        setUsersInSelectedCode((prev) => deleteRecord(deletedUser, "user_id", prev))
      );
    };

    socket.on("responseDeleteUser", usersViewDeleteUser);

    return () => { socket.off("responseDeleteUser") };
  }, [socket, addToUserListUseCB]);

  // Update state when a user verifies their email
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function handleUpdateEmailVerified(uid) {
      setUsersInSelectedCode((prev) => {
        const foundUser = prev.find(({ user_id }) => user_id === uid);
        if (!foundUser) return prev;

        foundUser.email_verified = 1;

        return editRecord(foundUser, "user_id", prev);
      });
    }

    socket.on("responseEmailVerified", handleUpdateEmailVerified);

    return () => { socket.off("responseEmailVerified", handleUpdateEmailVerified) };
  }, [socket, setUsersInSelectedCode]);

  // Update state when a user verifies their password
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function handleUpdatePasswordVerified(uid) {
      setUsersInSelectedCode((prev) => {
        const foundUser = prev.find(({ user_id }) => user_id === uid);
        if (!foundUser) return prev;

        foundUser.password_verified = 1;

        return editRecord(foundUser, "user_id", prev);
      });
    }

    socket.on("responsePasswordVerified", handleUpdatePasswordVerified);

    return () => { socket.off("responsePasswordVerified", handleUpdatePasswordVerified) };
  }, [socket, setUsersInSelectedCode]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Handle methods
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function selectedBillCodeCheck(user) {
    if (selectedCode === null || user.billing_code === selectedCode) {
      return true;
    }
    return false;
  }

  //!!TODO ABSTRACT
  function handleSelectRecord(record) {
    if (selectedUser && selectedUser === record) {
      return setSelectedUser(null);
    }

    setSelectedUser(record);
  }

  // Deselect a record
  function handleDeselectRecord() {
    return setSelectedUser(null);
  }

  function handleAddUser(newUser) {
    socket.emit("requestAdminNewUser", newUser, (newUser) => {
      if (selectedBillCodeCheck(newUser)) {
        return setUsersInSelectedCode((prev) => [...prev, { ...newUser, user_dids: newUser.user_dids }]);
      }
    });
  }

  function handleEditUser(editedUser) {
    socket.emit("requestAdminEditUser", editedUser, (resEditedUser) => {
      if (selectedBillCodeCheck(resEditedUser)) {
        setUsersInSelectedCode((prev) => editRecord(editedUser, primaryKey, prev));

        //TODO figure out handling edit your own record
        /*         if (currentUser.user_id === resEditedUser.user_id) {
          setCurrentUser((prev) => ({ ...prev, ...updatedCurrentUser }));
        } */
      }
    });
  }

  function handleUserStatus() {
    selectedUser && socket.emit(
      "requestAdminChangeUserStatus",
      selectedUser.user_id,
      // Send opposite of current record
      !selectedUser.user_enabled,
      (res) => setUsersInSelectedCode((prev) => editRecord(res, primaryKey, prev))
    );
  }

  async function handleSendPasswordReset() {
    if(!selectedUser) return;
    const response = await handleForgotFirebasePassword(selectedUser.email);
    setSelectedView(null);

    if (response.ok) {
      setServerResponse("Successfully sent password reset!");
    } else {
      setServerResponse("An error occurred sending a password reset");
    }
  }

  function handleResendVerification() {
    socket.emit("requestAdminResendVerification", selectedUser, () => {
      //TODO: response handling
    });
  }

  function handleDeleteUser() {
    if(!selectedUser) return;
    socket.emit("requestAdminDeleteUser", selectedUser.user_id, (res) =>
      setUsersInSelectedCode((prev) => deleteRecord(res, primaryKey, prev))
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Viewport props
  /////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Modal methods and props
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function tableHeader() {
    function firstRowChildren() {
      return (
        <div className="d-flex w-100">
          <FormConstructor
            //className="w-100"
            initialFormValues={{ selected_users_bill_code: "" }}
          >
            <FormConstructor.FormGroup className="d-flex text-nowrap mx-auto">
              <FormConstructor.DropdownSelect
                dataList={[ "All", ...adminCodes.map((code) => code.billing_code)]}
                label="Billing Code:"
                keyName="selected_users_bill_code"
                onChange={(e) => {
                  handleSelectedCodeRef(e.target.value);
                  setSelectedCode(e.target.value);
                }}
              />
            </FormConstructor.FormGroup>
          </FormConstructor>
          {searchBar()}
        </div>
      );
    }

    function breakpointSelect(width) {
      return (
        <div>
          <div key={"buttons"} className="d-flex flex-grow-1 flex-md-grow-0 justify-content-between">
            {resultsSelector(`mx-2 ms-4 d-flex align-items-center flex-nowrap text-nowrap`)}

            <Buttons.AddNew className="ms-auto" setSelectedView={setSelectedView} view={"add-user"} />
          </div>
          {numOfPages > 1 && width >= BSBreakpoints.md ? paginate() : null}
        </div>
      );
    }

    return {
      title: "Users",
      firstRowChildren,
      children: breakpointSelect,
    };
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Component props
  /////////////////////////////////////////////////////////////////////////////////////////////////

  function buttonConstructor(record) {
    const editBtn = {
      label: "Edit",
      onClick: () => setSelectedView("edit-user"),
      faIcon: faPencilAlt,
    };

    const statusBtn = {
      label: record.user_enabled ? "Disable" : "Enable",
      onClick: () => setSelectedView("user-status"),
      faIcon: faPencilAlt,
    };

    const passwordResetBtn = {
      label: "Send Password Reset",
      onClick: () => setSelectedView("reset-password"),

      faIcon: faPencilAlt,
    };

    const verifyEmailBtn = record.email_verified
      ? null
      : {
          label: "Resend Email Verification",
          onClick: () => setSelectedView("resend-email"),
          faIcon: faPencilAlt,
        };

    const deleteBtn = {
      label: "Delete",
      onClick: () => {
        /*    if (obj.id.pn_editable) { */ //TODO get client object
        setSelectedView("delete-user");
        /*           } else {
            alert("This phone number is marked as uneditable!"); //TODO style and turn into modal
          } */
      },
      faIcon: faTrashAlt,
    };

    if (record.user_id === currentUser?.user_id) {
      return [editBtn];
    }
    // document.body.click() is used to close popover after selection
    return [editBtn, statusBtn, passwordResetBtn, verifyEmailBtn, deleteBtn];
  }

  function handleResetView() {
    setSelectedView(null);
    setSelectedUser(null);
  }

  const logicMapProps = {
    selectedUser: selectedUser ? selectedUser : null,
    submitFunc: handleResendVerification,
    adminCodes,
    currentUser,
  };

  const dataTableHeaderProps = {
    setSelectedView,
    selectedCode,
    setSelectedCode,
    handleSelectedCodeRef,
    adminCodes,
  };

  const columnSizeMap =
    "minmax(100px, auto) minmax(100px, auto) minmax(100px, auto) minmax(90px, 90px) minmax(100px, auto) minmax(100px, auto) minmax(80px, 100px)  minmax(60px, auto)";

  //Handle functions for selected modals

  const dataViewSelectProps = {
    //Data to render
    data: usersToDisplay,
    // Map logic on how to render data
    renderMap,
    // The primary key to use when selecting records
    primaryKey,
    // Indexes to slice when using table layout
    displayIndexes,
    // Button logic to use when using table layout
    buttonConstructor,
    // Logic map for forms and modal
    logicMap: adminUsersFormLogic(logicMapProps),
    //Header props
    headerProps: { ...dataTableHeaderProps },

    // General handle functions and props
    columnSizeMap,
    selectedView,
    setSelectedView,
    handleResetView,
    handleSelectRecord,
    handleDeselectRecord,
    selectedRecord: selectedUser,
    recordsList: {
      lastSelected: selectedCode,
      list: adminCodes,
      key: "billing_code",
    },
    //Handle functions for selected view
    handleFunctions: {
      "add-user": handleAddUser,
      "edit-user": handleEditUser,
      "user-status": handleUserStatus,
      "reset-password": handleSendPasswordReset,
      "resend-email": handleResendVerification,
      "delete-user": handleDeleteUser,
    },
    tableHeader,
    // The component to use when the viewport is smaller than 450px;
    mobileView: (props) => <AdminUsersMobileViews {...props} />,
    searchBar,
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Return
  /////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <ResponsiveContainer>
      <DataViewSelect {...dataViewSelectProps} />
    </ResponsiveContainer>
  );
}
