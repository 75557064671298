import { editOrAddRecord } from "src/helpers/recordHelpers";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "src/contexts";
import { arrayEquals } from "src/helpers/helpers";
import { editRecord, deleteRecord } from "src/helpers/helpers.index";
import { sortAlphabetically } from "src/helpers/formattingHelpers";
import { rooms } from "@tsmodels/rooms";
import { useSharedStates } from "src/contexts/ApplicationStates";
import { customersAttributes } from "@tsmodels/customers";
import { RoomInstanceWithMembers } from "@tscontrollers/rooms.controller";
import { useQueryClient } from "@tanstack/react-query";

export default function useGlobalSocketEvents({ socket, userData, sharedAppStates }) {
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const { handleSignOut, firebaseUser } = useAuth();
  const queryClient = useQueryClient();
  const { currentUser, setCurrentUser, handleChangeSetting } = userData;
  const {
    fullCustomersList,
    setFullCustomersList,
    unattendedCustomers,
    setUnattendedCustomers,
    roomsList,
    setFullRoomsList,
    setFullUsersInCode,
    setSelectedMessageViewRoomID,
    setSocketStatus,
    setError,
    setBillCodeDIDs
  } = sharedAppStates as ReturnType<typeof useSharedStates>;
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // useCallback Helpers
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // Sign out a user
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const handleSignOutUseCB = useCallback(() => handleSignOut(), [handleSignOut]);

  // Unhide room if archived upon room update or new message
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const removeRoomFromArchiveList = useCallback(
    (roomID) => {
      const archivedRooms = currentUser.user_settings.archived_rooms;
      const newArchivedRooms = archivedRooms.filter(room => room.room_id !== roomID);

      handleChangeSetting({ archived_rooms: archivedRooms });

      /* setCurrentUser((prev) => {
        prev.user_settings.archived_rooms = [...archivedRooms];
        return { ...prev };
      }); */

      setCurrentUser(prev => ({
        ...prev,
        user_settings: {
          ...prev.user_settings,
          archived_rooms: newArchivedRooms
        }
      }));
    },
    [currentUser, handleChangeSetting]
  );
  
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Socket Status
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const socketStatusHandler = (bool) => setSocketStatus(bool);
    socket.on("connect", () => {
      queryClient.invalidateQueries({ queryKey: [ "LatestVersion" ] })
      return setSocketStatus(true);
    });

    socket.on("disconnect", () => socketStatusHandler(false));

    return () => {
      socket.off("connect", () => socketStatusHandler);
      socket.off("disconnect", () => socketStatusHandler);
    };
  }, [socket, setSocketStatus]);

  useEffect(() => {
    const newUpdateHandler = () => {
      console.log("New Server Update Available");
      queryClient.invalidateQueries({ queryKey: [ "LatestVersion" ] })
    }
    socket.on("updateAvailable", newUpdateHandler);
    return () => socket.off("updateAvailable", () => newUpdateHandler);
  }, [socket]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // On-load User Data Retrieval useEffects
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // Set current user role on retrieval of user data
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (currentUser) {
      setCurrentUserRole(currentUser.user_role);
    }
  }, [currentUser]);

  // Retrieve customer records by user code on load
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (currentUserRole) {
      socket.emit("requestCustomersByCode", (customers, unattendedCount) => {
        setFullCustomersList(customers);
        setUnattendedCustomers(unattendedCount);
      });
    }
  }, [currentUserRole, socket, setFullCustomersList, setUnattendedCustomers]);
  // On a newUnknownCustomerNotification set unattendedCount and add the new customer record
  useEffect(() => {
    function globalSetUnattendedCount({ newUnknownCustomer, unattendedCount }) {
      setUnattendedCustomers(unattendedCount);
      setFullCustomersList((prev) => [...prev, newUnknownCustomer]);
    }

    socket.on("newUnknownCustomerNotification", globalSetUnattendedCount);
    return () => socket.off("newUnknownCustomerNotification", globalSetUnattendedCount);
  }, [socket, setUnattendedCustomers, setFullCustomersList]);

  // Retrieve other fellow user records on load
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if(currentUser) {
      if(
        currentUser.user_role === "manager" || 
        currentUser.user_role === "admin" || 
        currentUser.user_role === "dev"
      ) {
        console.log(`Requesting Elevated User List`);
        const { bill_code } = currentUser;
        socket.emit(
          "requestManagerUsersInBillCode",
          bill_code,
          (res) => {
            setFullUsersInCode(sortAlphabetically(res, "last_name"));
          }
        );
        return;
      }
      if (currentUserRole) {
        socket.emit("requestUsersInCode", (users) => setFullUsersInCode(users));
      }
    }
  }, [currentUserRole, socket, setFullUsersInCode]);

  // Use this user-specific socket for retrieving rooms depending on user role
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (currentUserRole === "user") {
      // If the current users role is user, then use the user specific rooms socket
      socket.emit("requestUsersRooms", (rooms) => {
        setFullRoomsList(rooms);
      });
    }
  }, [currentUserRole, socket, setFullRoomsList]);

  // Update users data upon by event
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function globalRefreshUserData() {
      if(!firebaseUser) return;
      socket.emit("requestUserData", firebaseUser.fcm_token, (user) => {
        setCurrentUser((prev) => ({ ...prev, ...user }));

        socket.emit("requestCustomersByCode", (customers) => setFullCustomersList(customers));
      });
    }

    if (currentUserRole) {
      socket.on("requestUpdateUserData", globalRefreshUserData);
    }

    return () => socket.off("requestUpdateUserData", globalRefreshUserData);
  }, [currentUserRole, socket, setCurrentUser, setFullCustomersList, setFullRoomsList]);

  // Revoke a users session
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function globalRevokeSession() {
      socket.disconnect();
      handleSignOutUseCB();
    }

    if (currentUserRole) {
      socket.on("revokeUserSession", globalRevokeSession);
    }

    return () => socket.off("revokeUserSession", globalRevokeSession);
  }, [currentUserRole, socket, handleSignOutUseCB]);

  // Handle an error
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function globalHandleError(error) {
      setError(error);
    }

    if (currentUserRole) {
      socket.on("responseError", globalHandleError);
    }

    return () => socket.off("responseError", globalHandleError);
  }, [currentUserRole, socket, setError]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Customer useEffects
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // Add a new customer
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function globalNewCustomer(newCustomer) {
      setFullCustomersList((prev) => [...prev, newCustomer]);
    }

    if (currentUserRole) {
      socket.on("responseNewCustomer", globalNewCustomer);
    }

    return () => socket.off("responseNewCustomer", globalNewCustomer);
  }, [currentUserRole, socket, setFullCustomersList]);

  // Edit a customer
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    //Decrements Unattended Customers State by 1
    function decrementUnattendedCustomers() {
      const currentCount = unattendedCustomers;
      currentCount && setUnattendedCustomers(currentCount - 1);
    }
    //Takes the previous customerList record list and updates it with the new record
    function globalEditCustomer(editedCustomer: customersAttributes) {
      const existingCustomer = fullCustomersList.find(oldCustomer => editedCustomer.customer_id === oldCustomer.customer_id)
      if(!existingCustomer) return;
      
      setFullCustomersList((prevCustomerList) => {
        //Before Updating the State see if the customer's acknowledged value
        //has changed and update the state
        const acknowledgementChanged = existingCustomer.acknowledged !== editedCustomer.acknowledged
        if (acknowledgementChanged) decrementUnattendedCustomers();
        //Then update the customer record state
        return editRecord(editedCustomer, "customer_id", prevCustomerList);
      });
    }

    if (currentUserRole) {
      socket.on("responseEditedCustomer", globalEditCustomer);
      //socket.on("responseRestoreCustomer", restoreCustomer);
    }

    return () => socket.off("responseEditedCustomer", globalEditCustomer);
  }, [currentUserRole, socket, setFullCustomersList, setUnattendedCustomers, unattendedCustomers]);

  useEffect(() => {
    function handleRoomsMerged(cleanUpList: rooms["room_id"][]) {
      // Filter function to remove rooms from state that match the ids in the cleanup list
      const cleanUpMergedRooms = (oldState: RoomInstanceWithMembers[]) => oldState.filter(({ room_id }) => !cleanUpList.includes(room_id))
      setFullRoomsList(old => cleanUpMergedRooms(old))
    }
    // Listener Declaration
    socket.on("responseRoomsMerged", handleRoomsMerged)
  }, [socket, setFullRoomsList]);

  // Delete a customer
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
  
    function globalDeleteCustomer(deletedCustomer) {
      setFullCustomersList((prevCustomerList) => editRecord(deletedCustomer, "customer_id", prevCustomerList))
    }


    if (currentUserRole) {
      socket.on("responseDeleteCustomer", globalDeleteCustomer);
    }

    return () => socket.off("responseDeleteCustomer", globalDeleteCustomer);
  }, [currentUserRole, socket, setFullCustomersList]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Room useEffects
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Listen for new user to user rooms
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function globalNewRoom(newRoom) {
      setFullRoomsList((prev) => [...prev, newRoom]);
    }

    if (currentUserRole) {
      socket.on("responseNewUserRoom", globalNewRoom);
    }

    return () => socket.off("responseNewUserRoom", globalNewRoom);
  }, [currentUserRole, socket, setFullRoomsList]);

  // Listen for room updates
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function globalUpdateRoom(updatedRoom) {
      if(!updatedRoom) return console.warn(`Room came back ${typeof updatedRoom}`);
      if (
        // User is user role only
        currentUser.user_role === "user" &&
        // and the updated room has the user's id in it's room_users array
        !updatedRoom.room_users.some(({ user_id }) => currentUser.user_id === user_id)
      ) {
        setFullRoomsList((prev) => deleteRecord(updatedRoom.room_id, "room_id", prev));
        return;
      }

      removeRoomFromArchiveList(updatedRoom.room_id);
      setFullRoomsList((prev) => editOrAddRecord(updatedRoom, "room_id", prev));
    }

    if (currentUserRole) {
      socket.on("responseUpdateRoom", globalUpdateRoom);
    }

    return () => socket.off("responseUpdateRoom", globalUpdateRoom);
  }, [currentUserRole, socket, removeRoomFromArchiveList, setFullRoomsList, currentUser]);

  // Listen for new room messages and update room
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function handleUpdateRoomMessage(newMessage) {
      const foundRoom = roomsList.find((room) => room.room_id === newMessage.message_room_id);

      if (!foundRoom) {
        return console.error(`🛑 ERROR: NO ROOM FOUND BY ID: ${newMessage.message_room_id}`);
      }

      // Unhide the room if it was archived previously
      removeRoomFromArchiveList(newMessage.message_room_id);

      // Create an updated room object
      const updatedRoom = {
        ...foundRoom,
        last_message_from: newMessage.last_message_from,
        last_message_text: newMessage.message_text,
        room_updated_at: newMessage.sent_at,
      };

      // If user is a member of the room (which should always be the case for a user, but admins and managers might not be joined)
      // get the unread message count for the room and send notification
      if (foundRoom.room_users.some((roomUser) => roomUser.user_id === currentUser.user_id)) {
        socket.emit(
          "requestUpdatedUnreadMessageCount",
          newMessage.message_room_id,
          currentUser.user_id,
          (unreadCount) => {
            setFullRoomsList((prev) =>
              editRecord(
                {
                  ...updatedRoom,
                  unread_message_count: unreadCount.unread_message_count,
                },
                "room_id",
                prev
              )
            );
          }
        );
      } else {
        setFullRoomsList((prev) => editRecord({ ...updatedRoom }, "room_id", prev));
      }
    }

    if (currentUserRole) {
      socket.on("responseNewMessage", handleUpdateRoomMessage);
    }

    return () => socket.off("responseNewMessage", handleUpdateRoomMessage);
  }, [currentUserRole, socket, currentUser, removeRoomFromArchiveList, roomsList, setFullRoomsList]);

  // Listen for disabling a room
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function disableRooms(roomIDs: rooms["room_id"][]) {
      roomIDs.map(roomID => disableRoom(roomID));
    }
    
    function disableRoom(roomID: rooms["room_id"]) {
      setFullRoomsList((prev) => {
        // If the room is found in the rooms list update that room, otherwise just return previous state value
        const foundRoom = prev.find((room) => room.room_id === roomID);
        if (foundRoom) {
          return editRecord(
            {
              ...foundRoom,
              room_enabled: 0,
            },
            "room_id",
            prev
          );
        } else {
          return prev;
        }
      });
    }

    socket.on("responseDisableRooms", disableRooms);


    return () => socket.off("responseDisableRoom", disableRoom);
  }, [currentUserRole, socket, setFullRoomsList]);

  // Listen for when a room reopens
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function reopenRoom(openedRoomID) {
      setFullRoomsList((prev) => {
        const foundRoom = prev.find((room) => room.room_id === openedRoomID);
        if (foundRoom) {
          return editRecord(
            {
              ...foundRoom,
              room_enabled: 1,
            },
            "room_id",
            prev
          );
        } else {
          return prev;
        }
      });
    }

    if (currentUserRole) {
      socket.on("responseReopenRoom", reopenRoom);
    }

    return () => socket.off("responseReopenRoom", reopenRoom);
  }, [currentUserRole, socket, currentUser, setFullRoomsList]);

  // Listen for SMS delivery failure
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (currentUserRole) {
      socket.on("failureToDeliverSMS", handleDeliveryFailure);
    }

    return () => socket.off("failureToDeliverSMS");
  }, [currentUserRole, socket]);

  // Listen for MMS delivery failure
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (currentUserRole) {
      socket.on("failureToDeliverMMS", handleDeliveryFailure);
    }

    return () => socket.off("failureToDeliverMMS");
  }, [currentUserRole, socket]);

  // Room useEffects
  /////////////////////////////////////////////////////////////////////////////////////////////////
  /*  useEffect(() => {
    if (currentUserRole) {
      socket.on("responseClaimedRoom", (newRoom) => {
        if (currentUser.user_role === "user") {
          const userHasDIDAvailable = !currentUser.user_dids.some(
            (lookup) => lookup.did_number === newRoom.room_did_number
          );
          if (userHasDIDAvailable) {
            const roomCustomerIDs = newRoom.room_customers.map(
              (customer) => customer.customer_id
            );
            return roomCustomerIDs.forEach((id) =>
              setFullCustomersList((prev) => deleteRecord(id, "customer_id", prev))
            );
          }
        }
        setFullCustomersList((prev) => {
          newRoom.room_customers.forEach((customerInRoom) => {
            const foundCustomer = prev.find(
              (customerInState) =>
                customerInRoom.customer_id === customerInState.customer_id
            );
            foundCustomer.customer_rooms.push({
              room_id: newRoom.room_id,
              room_did_number: newRoom.room_did_number,
            });
          });
          return [...prev];
        });
      });
    }
    return () => socket.off("responseClaimedRoom");
  }, [currentUserRole, socket, currentUser, setFullCustomersList]); */

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // User Level Role Only useEffects
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // Update state or remove customer (if user does not have access to DID) when someone assigns a DID to a customer room
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function userCreateCustomerRoom(newRoom) {
      // Guard condition to prevent operating on null values
      if(Array.isArray(currentUser.user_dids) && Array.isArray(newRoom.room_customers)) {
        // Check if the new room has a DID number and is one the user has access too
        const userHasDID = currentUser.user_dids.some((did) => newRoom.room_did_number === did.did_number);
        
        const newRoomCustomerIDs = newRoom.room_customers.map((customer) => customer.customer_id);
        const foundRoom = roomsList.find((room) => {
          if(!room.room_customers) return console.error(`Could not find customers involved with room!`);
          const roomCustomerIDs = room.room_customers.map((customer) => customer.customer_id);

          return arrayEquals(roomCustomerIDs, newRoomCustomerIDs);
        });
        // WHY?!?!??!
        if (!userHasDID && !foundRoom) {
          setFullCustomersList((prev) => deleteRecord(newRoom.room_customers[0].customer_id, "customer_id", prev));
        }
      }
    }
    //TODO: What is this safeguarding?
    //if (currentUserRole === "user") {
    socket.on("responseCreateCustomerRoom", userCreateCustomerRoom);
    //}

    return () => socket.off("responseCreateCustomerRoom", userCreateCustomerRoom);
  }, [currentUserRole, socket, currentUser, roomsList, setFullCustomersList]);

  useEffect(() => {
    function receiveNewCustomerRoom(newRoom) {
      console.log(`Receiving New Customer Room:`, newRoom);
      setFullRoomsList((prev) => [...prev, newRoom]);
    }
    socket.on(`responseReceiveNewCustomerRoom`, receiveNewCustomerRoom);

    return () => socket.off(`responseReceiveNewCustomerRoom`, receiveNewCustomerRoom);
  }, [socket, roomsList, setFullRoomsList]);

  // Update state when a new user is added
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function userNewUser(newUser) {
      const { user_id, first_name, last_name } = newUser;
      setFullUsersInCode((prev) => [...prev, { user_id, first_name, last_name }]);
    }

    // If current user is a user only,
    if (currentUserRole === "user") {
      socket.on("responseNewUser", userNewUser);
    }

    return () => socket.off("responseNewUser", userNewUser);
  }, [currentUserRole, socket, setFullUsersInCode]);

  // Update state when a user is edited
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function userEditedUser(editedUser) {
      const { user_id, first_name, last_name } = editedUser;
      setFullUsersInCode((prev) => editRecord({ user_id, first_name, last_name }, "user_id", prev));
    }

    if (currentUserRole === "user") {
      socket.on("responseEditUser", userEditedUser);
    }

    return () => socket.off("responseEditUser", userEditedUser);
  }, [currentUserRole, socket, setFullUsersInCode]);
  //Update state when user's settings have been edited, most likely remotely
  //////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function userSettingsUpdated(editedUser) {
      setCurrentUser((prev) => ({ ...prev, ...editedUser}));
    }
    socket.on("responseUpdateUserSetting", userSettingsUpdated);

    return () => socket.off("responseUpdateUserSetting", userSettingsUpdated);
  }, [socket, setCurrentUser])

  // UseEffects for side effects from Admin actions
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    function userUpdateAvailDIDs(editedCode) {
      const newUserDIDs = currentUser.user_dids.filter(
        (userDID) => !editedCode.removedDIDs.some((removedDID) => userDID.did_number === removedDID.did_number)
      );

      setCurrentUser((prev) => ({ ...prev, user_dids: newUserDIDs }));
    }

    if (currentUserRole === "user") {
      socket.on("responseAdminEditCode", userUpdateAvailDIDs);
    }

    return () => socket.off("responseAdminEditCode", userUpdateAvailDIDs);
  }, [socket, currentUserRole, currentUser, setCurrentUser]);
  // DELETE DID RESPONSE
  useEffect(() => {
    // Define Callback
    function removeDID(deletedDID) {
      setBillCodeDIDs((prev) => deleteRecord(deletedDID, "did_number", prev));
    }
    // Define response listener
    socket.on("responseDeleteDID", removeDID);
    // Define clean up function
    return () => { socket.off("responseDeleteDID", removeDID) };
  }, [socket, setBillCodeDIDs, currentUser]);

  //!!! NEED DELETE CUSTOMER AN ROOMS SOCKETS STILL
  /*         socket.on("responseDeleteUser", (res) =>
          setFullUsersInCode((prev) => deleteRecord(res, "user_id", prev))
        ); */

  return { setSelectedMessageViewRoomID };
}

  

function handleDeliveryFailure(/* bandwidthResponse */) {
  //TODO Get help from Derek with this:
  //This should rip the message failure reason and message id
  //Submits a failed event template to the chat as a message with a clickable button to attempt a resend
  //If resend suceeds it removes the red text styles and resend button
}
