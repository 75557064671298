import FormConstructor from "../../../../common/FormConstructor/FormConstructor";
import {
  combineFirstAndLastName,
} from "../../../../helpers/helpers.index";
import ContactsMobileViews from "../subcomponents/ContactsMobileViews";
// Modal logic to use when rendering form fields
function contactsModalLogic({
  currentUser,
  selectedContact,
  foundRooms,
  handleCreateRoom,
  handleOccupyRoom,
  userPermissionLevel,
  billCodeDIDs,
  tempBypass
}) {
  /* function formatOccupiedRoomResponse() {
    if (serverResponse && serverResponse["occupied-rooms"]) {
      if (serverResponse["occupied-rooms"].length === 1) {
        const foundDID = selectedContact
          ? currentUser.user_dids.find(
              (userDID) =>
                userDID.did_number ===
                serverResponse["occupied-rooms"][0].room_did_number
            )
          : "Unknown DID";

        return `${joinArrayOfNames(
          serverResponse["occupied-rooms"][0].room_users
        )} ${` via ${foundDID.did_description} (${foundDID.did_number})`}`;
      } else {
        //TODO: Error handling
        return null;
      }
    }
  } */

  /* function selectSendMessageDID() {
    if (
      selectedRoom
    ) {
      return `${combineFirstAndLastName(
        selectedContact
      )} via ${formatPhoneNumber(selectedRoom.room_did_number)}`;
    }

    return "Unknown Selected Room";
  } */

  const isInvalidFirstName = ({ first_name }) => {
    if (!first_name.length) {
      return "Please enter a first name";
    }
  };

  const isInvalidLastName = ({ last_name }) => {
    if (!last_name.length) {
      return "Please enter a last name";
    }
  };

  const isInvalidPhoneNumber = ({ phone_number }) => {
    if (phone_number.length < 16) {
      return "Please enter a valid phone number";
    }
  };

  return {
    "add-customer": {
      mobileView: (props, key) => (
        <ContactsMobileViews.AddEditContact {...props} key={key} />
      ),
      modalTitle: "Add a Customer",

      initialFormValues: {
        first_name: "",
        last_name: "",
        phone_number: "",
        sms_consent: false
      },
      fields: {
        first_name: {
          inputType: "text",
          formalName: "First name",
          keyName: "first_name",
          placeHolder: "First name",
          fieldComponent: (props, key) => (
            <FormConstructor.TextInput {...props} key={key} />
          ),
          isInvalid: isInvalidFirstName,
        },
        last_name: {
          inputType: "text",
          formalName: "Last name",
          keyName: "last_name",
          placeHolder: "Last name",
          fieldComponent: (props, key) => (
            <FormConstructor.TextInput {...props} key={key} />
          ),
          isInvalid: isInvalidLastName,
        },
        phone_number: {
          inputType: "tel",
          formalName: "Phone",
          keyName: "phone_number",
          placeHolder: "Phone Number",
          fieldComponent: (props, key) => (
            <FormConstructor.PhoneInput {...props} key={key} />
          ),
          isInvalid: isInvalidPhoneNumber,
        },
        consent_bypass: {
          inputType: "checkbox",
          keyName: "sms_consent",
          fieldComponent: (props, key) => (
            <FormConstructor.ConsentBypassCheckBox 
              {...props} 
              key={key} 
            />
          )
        }
      },
    },
    "edit-customer": {
      mobileView: (props, key) => (
        <ContactsMobileViews.AddEditContact {...props} key={key} />
      ),
      modalTitle: "Editing the record for",
      type: "form",
      initialFormValues: {
        first_name: "",
        last_name: "",
        sms_consent: false
      },

      fields: {
        first_name: {
          inputType: "text",
          formalName: "First name",
          keyName: "first_name",
          placeHolder: "First name",
          fieldComponent: (props, key) => (
            <FormConstructor.TextInput {...props} key={key} />
          ),
          isInvalid: isInvalidFirstName,
        },
        last_name: {
          inputType: "text",
          formalName: "Last name",
          keyName: "last_name",
          placeHolder: "Last name",
          fieldComponent: (props, key) => (
            <FormConstructor.TextInput {...props} key={key} />
          ),
          isInvalid: isInvalidLastName,
        },
        phone_number: {
          inputType: "text",
          formalName: "Phone Number",
          keyName: "phone_number",
          placeHolder: "Phone Number",
          fieldComponent:(props, key) => (
            <FormConstructor.PhoneInput {...props} key={key}></FormConstructor.PhoneInput>
          )
        },
        consent_bypass: {
          inputType: "checkbox",
          formalName: "Consent Bypass",
          keyName: "sms_consent",
          fieldComponent: (props, key) => (
            <FormConstructor.ConsentBypassCheckBox 
              {...props} 
              key={key} 
              isChecked={selectedContact.sms_consent_type === 3}
            />
          )
        }
      },
    },

    /* "send-message": {
      modalTitle: `Send message to ${selectSendMessageDID()}`,
      initialFormValues: {
        message_text: "",
      },

      fields: {
        message_text: {
          formalName: "Send Message",
          keyName: "message_text",
          placeHolder: "Enter message here",
          inputGroup: false,
          fieldComponent: (props, key) => (
            <FormConstructor.TextArea {...props} key={key} />
          ),
        },
      },
    }, */
    "create-room": {
      mobileView: (props, key) => (
        <ContactsMobileViews.CreateRoom {...props} key={key} />
      ),
      modalTitle: `Create room with ${combineFirstAndLastName(
        selectedContact
      )}`,
      submitType: (() => {
        if (userPermissionLevel("user")) {
          return currentUser && currentUser.user_dids.length
            ? "yes/no"
            : "none";
        }

        if (userPermissionLevel("mid")) {
          return billCodeDIDs.length ? "yes/no" : "none";
        }
      })(),

      initialFormValues: {
        selected_did: null,
      },
      text:
        currentUser &&
        currentUser.user_dids.length &&
        `You have no existing chat history with ${combineFirstAndLastName(
          selectedContact
        )}- Please select a DID to start a conversation.`,
      fields: {
        did_select: {
          formalName: "DID Numbers",
          keyName: "selected_did",
          inputGroup: false,
          fieldComponent: (props, key) => {
            // Convert to userPermission function
            if (userPermissionLevel("user") && !currentUser.user_dids.length) {
              return (
                <p>
                  You have no DID numbers assigned to your user, please contact
                  a Manager or Administrator if you believe this to be a
                  mistake.
                </p>
              );
            }

            return <FormConstructor.RoomDIDSelect {...props} key={key} />;
          },
        },
      },
    },
    "occupied-rooms": {
      mobileView: (props, key) => (
        <ContactsMobileViews.OccupiedRoom 
          {...props} 
          tempBypass={tempBypass}
          occupyRoom={handleOccupyRoom}
          newRoom={handleCreateRoom} 
          key={key} 
        />
      ),
      modalTitle: "Room Is Occupied",
      submitType: "none",
      initialFormValues: {
        selected_did: "",
      },
      fields: {
        did_select: {
          formalName: "DID Numbers",
          keyName: "selected_did",
          inputGroup: false,
          fieldComponent: (props, key) => (
            <FormConstructor.OccupiedRoomDIDSelect
              {...props}
              selectedContact={selectedContact}
              occupyRoom={handleOccupyRoom} //😒
              newRoom={handleCreateRoom}
              key={key}
              tempBypass={tempBypass}
            />
          ),
        },
      },
    },
    "select-did": {
      mobileView: (props, key) => (
        <ContactsMobileViews.DIDselect {...props} key={key}/>
      ),
      modalTitle: "Select a DID Number for this room",
      submitType: (() => {
        if (userPermissionLevel("user")) {
          return currentUser && currentUser.user_dids.length
            ? "yes/no"
            : "none";
        }

        if (userPermissionLevel("mid")) {
          return billCodeDIDs.length ? "yes/no" : "none";
        }
      })(),
      initialFormValues: {
        selected_did: "",
      },
      text:
        currentUser &&
        currentUser.user_dids.length &&
        `You have no existing chat history with ${combineFirstAndLastName(
          selectedContact
        )}- Please select a DID to start a conversation.`,
      fields: {
        did_select: {
          formalName: "DID Numbers",
          keyName: "selected_did",
          inputGroup: false,
          fieldComponent: (props, key) => {
            // Convert to userPermission function
            if (userPermissionLevel("user") && !currentUser.user_dids.length) {
              return (
                <p>
                  You have no DID numbers assigned to your user, please contact
                  a Manager or Administrator if you believe this to be a
                  mistake.
                </p>
              );
            }

            return <FormConstructor.RoomDIDSelect {...props} key={key} />;
          },
        },
      },
    },
    
    "room-select": {
      mobileView: (props, key) => (
        <ContactsMobileViews.SelectRoom {...props} key={key} />
      ),
      modalTitle: "Select room for ",
      text: `There are multiple rooms with this user- which one would you like to view?`,
      initialFormValues: {
        selected_room: "",
      },
      fields: {
        room_select: {
          roomsList: foundRooms,
          inputType: "room_radio",
          formalName: "Room ID",
          keyName: "selected_room",
          inputGroup: false,
          fieldComponent: (props, key) => (
            <FormConstructor.RoomSelect {...props} key={key} />
          ),
        },
      },
    },
    "disabled-room": {
      modalTitle: "This contact and its room have been disabled for",
      text: `This contact and their room has been disabled by an administrator- please contact support if you believe this was an error`,
    },
    "no-available-dids": {
      modalTitle: "No Available DIDs",
      text: `There are no DID numbers assigned to your user- please contact your manager or an administrator to have DID numbers assigned to your account.`,
    },
    "delete-customer": {
      submitType: "yes/no",
      modalTitle: "Deleting the record for",
      text: "You are about to permanently delete this record from the clients list- are you sure?",
    },
    "duplicate-customer": {
      submitType: "yes/no",
      mobileView: (props, key) => (<ContactsMobileViews.ModalYesNo {...props} key={key} />),
      modalTitle: "Customer Already Exists",
      text: `Customer ${selectedContact ? `with phone number +${selectedContact.phone_number_country}${selectedContact.phone_number}` : null} already exists. Would you like to view to their record?`
    }
  };
}

export default contactsModalLogic;
