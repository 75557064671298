import { useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import { rankItem } from "@tanstack/match-sorter-utils";
import TableListLayout from "./TableListLayout";
import TableDesktopLayout from "./TableDesktopLayout";
import { LAYOUTS } from "src/constants/layout";

export default function Table(props) {
  const { data, columns, layout } = props;
  const [globalFilter, setGlobalFilter] = useState("");

  // React-table basic filter example
  const fuzzyFilter = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);
    // Store the itemRank info
    addMeta({
      itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  const table = useReactTable({
    data,
    columns,
    /*    onSortingChange: setSorting, */
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      globalFilter,
    },
    globalFilterFn: fuzzyFilter,
    defaultColumn: {
      size: 150,
      minSize: 20,
      maxSize: 1000,
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
    initialState: {
      pagination: {
        pageSize: 20,
      },
      // TODO hardcoded
      sorting: [
        {
          id: "company_name",
          desc: false,
        },
      ],
    },
  });

  const paginateOptions = {
    selectableRows: [20, 30, 40, 50],
  };

  return layout === LAYOUTS.MOBILE ? (
    <TableListLayout table={table} setGlobalFilter={setGlobalFilter} {...props} />
  ) : (
    <TableDesktopLayout table={table} setGlobalFilter={setGlobalFilter} paginateOptions={paginateOptions} {...props} />
  );
}
