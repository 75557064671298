import ContactsMain from "../mobile/ContactsMain";
import AddEditContact from "../mobile/AddEditContact";
import SelectedContact from "../mobile/SelectedContact";
import CreateRoom from "../mobile/CreateRoom";
import SelectRoom from "../mobile/SelectRoom";
import OccupiedRoom from "../mobile/OccupiedRoom";
import DIDselect from "../mobile/DIDselect"
import ModalYesNo from "../mobile/ModalYesNo";
export default function ContactsMobileViews(props) {
  const { selectedView, selectedRecord, logicMap } = props;

  const recordTitle = selectedRecord
    ? `${selectedRecord.first_name} ${selectedRecord.last_name}`
    : null;

  if (selectedRecord && !selectedView) {
    return <SelectedContact {...props} recordTitle={recordTitle} />;
  }

  if (selectedView && logicMap[selectedView].mobileView) {
    return logicMap[selectedView].mobileView({
      ...props,
      recordTitle,
    });
  }

  return <ContactsMain {...props} recordTitle={recordTitle} />;
}

ContactsMobileViews.AddEditContact = AddEditContact;
ContactsMobileViews.CreateRoom = CreateRoom;
ContactsMobileViews.SelectRoom = SelectRoom;
ContactsMobileViews.OccupiedRoom = OccupiedRoom;
ContactsMobileViews.DIDselect = DIDselect;
ContactsMobileViews.ModalYesNo = ModalYesNo