import FormConstructor from "../../../../common/FormConstructor/FormConstructor";
import AdminCodesMobileViews from "../subcomponents/AdminCodesMobileViews";

function adminCodesFormLogic(adminCompanies) {
  return {
    "add-code": {
      mobileView: (props, key) => (
        <AdminCodesMobileViews.AddEditCodeView {...props} key={key} />
      ),
      modalTitle: "Add new billing code",
      buttonTitle: {
        title: "Submit",
        showOnModal: true,
      },

      initialFormValues: {
        billing_code: "",
        did_numbers: [],
        description: "",
        //Weird logic to deal with the brief period adminCompanies may be uninitialized on page load
        billcode_company_name: adminCompanies[0] ? adminCompanies[0].company_name : ""
      },

      fields: {
        billing_code: {
          inputType: "text",
          formalName: "Billing Code",
          keyName: "billing_code",
          placeHolder: "code1",
          fieldComponent: (props, key) => (
            <FormConstructor.TextInput {...props} key={key} />
          ),
        },
        company: {
          inputType: "select",
          formalName: "Company",
          keyName: "billcode_company_name",
          fieldComponent: (props, key) => (
            <FormConstructor.DropdownSelect 
              {...props} 
              dataList={adminCompanies.map(company => company.company_name)}
              key={key} 
            />
          )
        },
        description: {
          inputType: "text",
          formalName: "Description",
          keyName: "description",
          placeHolder: "Billing Code Description",
          fieldComponent: (props, key) => (
            <FormConstructor.TextInput {...props} key={key} />
          ),
        },
        did_assign: {
          formalName: "DID Numbers",
          keyName: "did_numbers",
          inputGroup: false,

          fieldComponent: (props, key) => (
            <FormConstructor.DIDAssign {...props} key={key} />
          ),
        },
      },
    },
    "edit-code": {
      mobileView: (props, key) => (
        <AdminCodesMobileViews.AddEditCodeView {...props} key={key} />
      ),
      modalTitle: "Editing billing code",
      buttonTitle: {
        title: "Submit",
        showOnModal: true,
      },
      initialFormValues: {
        billing_code: "",
        did_numbers: [],
        description: "",
      },
      fields: {
        billing_code: {
          inputType: "text",
          formalName: "Billing Code",
          keyName: "billing_code",
          placeHolder: "code1",
          fieldComponent: (props, key) => (
            <FormConstructor.TextInput {...props} key={key} />
          ),
        },
        company: {
            inputType: "select",
            formalName: "Company",
            keyName: "billcode_company_name",
            fieldComponent: (props, key) => (
              <FormConstructor.DropdownSelect 
                {...props} 
                dataList={adminCompanies.map(company => company.company_name)}
                key={key} 
              />
            )
        },
        description: {
          inputType: "text",
          formalName: "Description",
          keyName: "description",
          placeHolder: "Billing Code Description",
          fieldComponent: (props, key) => (
            <FormConstructor.TextInput {...props} key={key} />
          ),
        },
        did_assign: {
          formalName: "DID Numbers",
          keyName: "did_numbers",
          inputGroup: false,
          fieldComponent: (props, key) => (
            <FormConstructor.DIDAssign {...props} key={key} />
          ),
        },
      },
    },
    "delete-code": {
      modalTitle: "Deleting the record for",
      text: "You are about to permanently delete this record- are you sure?",
      submitType: "yes/no",
      fieldComponent: (props, key) => (
        <FormConstructor.DeleteAlert {...props} key={key} />
      ),
    },
  };
}

//Map deep objects and export to componet to help keep components readable
export default adminCodesFormLogic;
