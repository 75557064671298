// Libraries
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import FormConstructor from "../FormConstructor/FormConstructor";

export default function ModalMaker({
  selectedView,
  logicMap,
  selectedRecord,
  handleResetView,
  handleFunctions,
  modalTextResponse,
  foundRooms,
}) {
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Contexts
  /////////////////////////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // States
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const [modalActive, setModalActive] = useState(false);
  //TODO could most likely change to server response state then have that cleared by modal close
  /* const [warning, setWarning] = useState(null); */
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Render logic
  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    //TODO is this still needed?
    if (selectedView) {
      return setModalActive(true);
    } else {
      setModalActive(false);
    }
  }, [selectedView]);

  // If no selectedView is found return early (prevents undefined values)
  if (!selectedView) {
    return null;
  }

  //If user is to be added as admin remove billing code
  /*   //!! TODO maybe admins should verify?
  useEffect(() => {
    if (formInput.admin) {
      setFormInput({
        ...formInput,
        status: 1,
        billing_code: "",
      });
    }
  }, [formInput.admin]); */

  function compileTitle() {
    if (logicMap[selectedView].modalTitle) {
      return logicMap[selectedView].modalTitle;
    } else {
      return null;
    }
  }

  function modalText() {
    let text;
    if (modalTextResponse) {
      text = modalTextResponse;
    } else if (logicMap[selectedView].text) {
      text = logicMap[selectedView].text;
    }

    return text ? <div className={`pb-4`}>{text}</div> : null;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Return
  /////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <Modal
      contentClassName={"modal_main_style"}
      dialogClassName={"modal_dialog_style"}
      show={modalActive}
      onHide={() => setModalActive(false)}
      onExited={() => {
        /* setWarning(null); */
        handleResetView();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          //TODO abstract part after delete
          className={/delete-/.test(selectedView) ? "text-danger" : null}
        >
          {compileTitle()}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* {serverResponse ? <div>{serverResponse}</div> : null} */}
        {modalText()}
        <FormConstructor
          logicMap={logicMap}
          selectedView={selectedView}
          selectedRecord={selectedRecord}
          initialFormValues={logicMap[selectedView].initialFormValues}
          foundRooms={foundRooms}
          onSubmit={(formInput) => handleFunctions[selectedView](formInput)}
          setModalActive={setModalActive}
        />
      </Modal.Body>
    </Modal>
  );
}
