import { DataTableHeader, ModalMaker } from "../commonViews.index";

import useWindowDimensions, {
  BSBreakpoints,
} from "../../hooks/useWindowDimensions";
import { MemoDataTable } from "../DataTable/DataTable";
import MobileViewSelect from "../Mobile/MobileViewSelect";

export default function DataViewSelect(props) {
  const { width } = useWindowDimensions();

  if (width < BSBreakpoints.md) {
    if (props.mobileView) {
      return <MobileViewSelect {...props} />;
    } else {
      console.error("⛔ No mobile view file found!");
    }
  }

  const dataTableProps = () => {
    if (props.tableHeader) {
      return props.tableHeader();
    }

    return props.headerConstructor(props.headerProps);
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // RETURN
  /////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <ModalMaker {...props} />
      <DataTableHeader {...dataTableProps()} width={width} />
      <MemoDataTable {...props} />
      {/* <DataTable {...dataViewSelectProps} /> */}
    </>
  );
}
